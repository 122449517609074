import { Text } from '@mantine/core';
import dayjs from 'dayjs';
import { EntityTableColumnType } from '../../enums/entity-table-column-type.enum';

export function EntityTableDateTimeRenderer(props: { value?: string; type: EntityTableColumnType }) {
  const date = dayjs(props.value).toDate();

  return (
    <Text size="xs">
      {date.toLocaleDateString() +
        (props.type === EntityTableColumnType.DateTime ? ` ${date.toLocaleTimeString()}` : '')}
    </Text>
  );
}
