import { Badge, Box, Input, Loader, MultiSelect, Text } from '@mantine/core';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrMultiSelectInputProps extends BaseInputProps {
  data?: Record<string, unknown>[] | any[];
  valueField?: string;
  labelField?: string;
  simpleValue?: boolean;
  disabled?: boolean;
  creatable?: boolean;
}

export function GrMultiSelectInput({ name, data, loading, disabled, creatable, ...props }: GrMultiSelectInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  const valueField = props.valueField ?? 'id';
  const labelField = props.labelField ?? 'label';
  const simpleValue = props.simpleValue ?? false;
  const [preparedData, setPreparedData] = useState(
    !data || data[0] === ''
      ? []
      : data.map((item) => {
          if (typeof item === 'string') {
            return { label: item, value: item };
          } else {
            return { label: item[labelField], value: item[valueField] };
          }
        })
  );

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Box>
            {(!form.getValues(name) || form.getValues(name).length === 0) && <Text>-</Text>}

            {form.getValues(name) &&
              form.getValues(name).map((rowValue: any) => {
                const foundData = preparedData.find((d) => d.value === (simpleValue ? rowValue : rowValue[valueField]));
                return (
                  <Badge
                    color="gray"
                    radius="sm"
                    mr="sm"
                    key={`${name}.${foundData?.value}`}
                    sx={(theme) => ({
                      color: theme.colors.gray[7],
                      fontWeight: 500,
                      backgroundColor: theme.colors.gray[2],
                    })}
                  >
                    {foundData ? foundData.label : '-'}
                  </Badge>
                );
              })}
          </Box>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => (
        <MultiSelect
          {...field}
          {...props.forwardedProps}
          value={
            form.getValues(name)
              ? simpleValue
                ? form.getValues(name)
                : form.getValues(name).map((v: any) => v[valueField])
              : []
          }
          ref={undefined}
          onChange={(value: string[]) => {
            field.onChange(simpleValue ? value : value.map((v) => (data ?? []).find((d) => d[valueField] === v)));
          }}
          label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
          data={preparedData}
          error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
          description={props.description}
          disabled={formState.isSubmitting || disabled}
          required={form.requiredFields.includes(labelName)}
          key={`key_${name}`}
          searchable={true}
          clearable={true}
          creatable={creatable && simpleValue}
          getCreateLabel={(query) => {
            query = query.trim();
            return `${query} ${t('common.message.addItem')}`;
          }}
          shouldCreate={(query) => query.trim() !== ''}
          onCreate={(query) => {
            query = query.trim();
            const item = { value: query, label: query };
            setPreparedData((current) => [...current, item]);
            return item;
          }}
          transitionProps={{ transition: 'scale-y', duration: 200, timingFunction: 'ease' }}
          rightSection={loading && <Loader size="sm" />}
          autoComplete={'off'}
          nothingFound={t(`common.message.selectNoOptionFound`)}
        />
      )}
    />
  );
}
