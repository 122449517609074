import { Permission } from '@prisma/client';
import { useAuth } from '../stores/auth.store';

export function usePermission() {
  const { user } = useAuth();
  // Load permissions from user
  const permissions = new Set<Permission>();
  user?.roles?.forEach((role) => role.userRole.permissions?.forEach((permission) => permissions.add(permission)));

  const isAdmin = permissions.has('admin');

  function hasAnyPermission(permissionsToCheck: Permission[]): boolean {
    let result = false;
    permissionsToCheck.forEach((permission) => {
      if (permissions.has(permission)) {
        result = true;
      }
    });
    return isAdmin || result;
  }

  function hasAllPermission(permissionsToCheck: Permission[]): boolean {
    let result = true;
    permissionsToCheck.forEach((permission) => {
      if (!permissions.has(permission)) {
        result = false;
      }
    });
    return isAdmin || result;
  }

  return { hasAnyPermission, hasAllPermission };
}

export function checkPermissions(...permissionsToCheck: Permission[]) {
  const user = useAuth.getState().user;

  // Load permissions from user
  const permissions = new Set<Permission>();
  user?.roles?.forEach((role) => role.userRole.permissions?.forEach((permission) => permissions.add(permission)));

  const isAdmin = permissions.has('admin');

  let result = false;
  permissionsToCheck.forEach((permission) => {
    if (permissions.has(permission)) {
      result = true;
    }
  });

  if (!isAdmin && !result) {
    throw new Error('No permission');
  }
}
