import { Prisma } from '@prisma/client';
import { IsBoolean, IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString } from 'class-validator';

export class OrderItemCreateRequest {
  /**
   * The product of the order item
   */
  @IsString()
  @IsNotEmpty()
  public productId: string;

  /**
   * Product attribute configurations
   */
  @IsObject()
  @IsOptional()
  public productConfig?: Prisma.JsonValue;

  /**
   * False if the reseller added
   */
  @IsBoolean()
  public addedByVendor: boolean;

  /**
   * The quantity of the product
   */
  @IsNumber()
  @IsPositive()
  public quantity: number;

  /**
   * The price of the order item
   */
  @IsNumber()
  public price: number;
}

export class OrderItemUpdateRequest {
  /**
   * CUID identifier of the order item
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The product of the order item
   */
  @IsString()
  @IsNotEmpty()
  public productId: string;

  /**
   * Product attribute configurations
   */
  @IsObject()
  @IsOptional()
  public productConfig?: Prisma.JsonValue;

  /**
   * False if the reseller added
   */
  @IsBoolean()
  public addedByVendor: boolean;

  /**
   * The quantity of the product
   */
  @IsNumber()
  @IsPositive()
  public quantity: number;
  /**
   * The price of the order item
   */
  @IsNumber()
  public price: number;
}
