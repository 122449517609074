import { AppSettingsResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getOne } from '../plugins/axios';

export function useAppSettings(key: string) {
  const result = useQuery(['app-settings', key], getOne<AppSettingsResponse>('app-settings', key), {
    staleTime: Infinity,
  });
  const values = result.data?.value?.split(',');

  return {
    appSetting: result.data ? { ...result.data, values } : undefined,
    isFetching: result.isFetching,
  };
}
