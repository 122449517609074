import { Avatar, Box, Menu } from '@mantine/core';
import { IconLogout, IconUserCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../stores/auth.store';
import { useTranslation } from 'react-i18next';

export function UserMenu() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleUserProfile() {
    navigate('/profile');
  }

  return (
    <Box my="sm">
      <Menu position="right-end" shadow="xl" withArrow>
        <Menu.Target>
          <Avatar
            sx={{
              cursor: 'pointer',
              backgroundColor: 'whitesmoke',
            }}
            src={auth.user?.avatar}
            radius="xl"
            alt="it's me"
          >
            {auth.user?.lastName?.charAt(0)}

            {auth.user?.firstName?.charAt(0)}
          </Avatar>
        </Menu.Target>

        <Menu.Dropdown sx={{ width: 200 }}>
          <Menu.Item icon={<IconUserCircle size={16} />} onClick={handleUserProfile}>
            {t('component.userMenu.profile')}
          </Menu.Item>

          <Menu.Item icon={<IconLogout size={16} />} onClick={auth.logout} color="red">
            {t('component.userMenu.logOut')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
}
