import { GrForm, GrFormTitle, GrMultiSelectInput, GrSelectInput, GrTextInput, useDtoForm } from '@gravity/frontend/ui';
import {
  ProductAttributeDefinitionCreateRequest,
  ProductAttributeDefinitionResponse,
  ProductAttributeDefinitionUpdateRequest,
} from '@gravity/shared/dto';
import { ActionIcon, Button, Grid, Group, ScrollArea } from '@mantine/core';
import { Permission, ProductAttributeType } from '@prisma/client';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { axiosInstance } from '../../../plugins/axios';

export function ProductAttributeDefinitionFormPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const entity = useLoaderData() as ProductAttributeDefinitionResponse | undefined;

  const productAttributeDefinitionForm = useDtoForm(
    [ProductAttributeDefinitionCreateRequest, ProductAttributeDefinitionUpdateRequest],
    'entity.productAttributeDefinitions',
    {
      isEditForm: !!entity,
      defaultValues: entity ? entity : undefined,
      validators: {
        name: nameValidation,
      },
    }
  );

  async function nameValidation(name: string) {
    if (productAttributeDefinitionForm.isEditForm && name === entity?.name) {
      return;
    }
    const result = await axiosInstance.get(`product-attribute-definitions/check-name?name=${name}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictProductAD') {
      return { type: result.response?.data.message };
    }
  }

  const productAttributeDefinitionMutation = useDtoMutation('product-attribute-definitions', {
    invalidateQueries: [['product-attribute-definitions'], ['product-attribute-definitions', 'list-select-values']],
  });

  const productAttributeTypes = Object.keys(ProductAttributeType).map((attributeType) => ({
    id: attributeType,
    label: t(`common.productAttributeType.${attributeType}`),
  }));

  const handleSubmit = async (
    data: ProductAttributeDefinitionCreateRequest | ProductAttributeDefinitionUpdateRequest
  ) => {
    if (productAttributeDefinitionForm.isCreateForm) {
      const productAttributeDefinition = (await productAttributeDefinitionMutation.create(
        data
      )) as ProductAttributeDefinitionResponse;
      navigate(`/sales/product-attribute-definitions/${productAttributeDefinition.id}`);
      productAttributeDefinitionForm.toggleReadonlyMode(productAttributeDefinition);
    } else {
      await productAttributeDefinitionMutation.update(data);
      productAttributeDefinitionForm.toggleReadonlyMode(data);
    }
  };

  return (
    <Page>
      <GrForm form={productAttributeDefinitionForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/sales/product-attribute-definitions">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="sales.productAttributeDefinitionsForm" />
          </Group>

          {!entity?.internal && (
            <Group>
              {productAttributeDefinitionForm.readonlyMode ? (
                <PermissionCheck hasAll={[Permission.product_read, Permission.product_update]}>
                  <Button
                    onClick={() => productAttributeDefinitionForm.toggleReadonlyMode()}
                    variant="light"
                    leftIcon={<IconPencil />}
                  >
                    {t('common.button.edit')}
                  </Button>
                </PermissionCheck>
              ) : (
                <>
                  <PermissionCheck
                    hasAll={
                      productAttributeDefinitionForm.isEditForm
                        ? [Permission.product_read, Permission.product_update]
                        : [Permission.product_create]
                    }
                  >
                    <Button
                      loading={productAttributeDefinitionForm.isSubmitting}
                      color="green"
                      type="submit"
                      leftIcon={<IconCheck />}
                    >
                      {t('common.button.save')}
                    </Button>
                  </PermissionCheck>

                  <Button
                    loading={productAttributeDefinitionForm.isSubmitting}
                    type="button"
                    color="gray"
                    variant="light"
                    leftIcon={<IconX />}
                    onClick={() =>
                      productAttributeDefinitionForm.isCreateForm
                        ? navigate(-1)
                        : productAttributeDefinitionForm.toggleReadonlyMode()
                    }
                  >
                    {t('common.button.cancel')}
                  </Button>
                </>
              )}

              {productAttributeDefinitionForm.isEditForm && productAttributeDefinitionForm.readonlyMode && (
                <PermissionCheck
                  hasAll={[Permission.product_read, Permission.product_update, Permission.product_delete]}
                >
                  <DeleteConfirmButton
                    loading={productAttributeDefinitionForm.isSubmitting}
                    onDelete={async () => {
                      try {
                        await productAttributeDefinitionMutation.delete(
                          productAttributeDefinitionForm.getValues('id' as never)
                        );
                        navigate('/sales/product-attribute-definitions');
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </PermissionCheck>
              )}
            </Group>
          )}
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col>
              <GrTextInput name="name" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrSelectInput data={productAttributeTypes} name="type" simpleValue />
            </Grid.Col>

            {productAttributeDefinitionForm.watch('type') === ProductAttributeType.select && (
              <Grid.Col xs={6}>
                <GrMultiSelectInput data={entity?.selectValues} name="selectValues" creatable simpleValue />
              </Grid.Col>
            )}
          </Grid>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
