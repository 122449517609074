import { Prisma } from '@prisma/client';
import { IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, Max, ValidateIf } from 'class-validator';

export class PlisseRequest {
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public p_width: number; // Szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public p_height: number; // Magasság

  @IsNumber()
  @IsNotEmpty()
  public p_area: number; // Terület

  @IsNumber()
  @IsNotEmpty()
  public p_roundedArea: number; // Kerekített terület

  @IsString()
  @IsNotEmpty()
  public p_color: string; // Szín

  @IsString()
  @IsNotEmpty()
  public p_type: string; // Típus

  @IsString()
  @IsNotEmpty()
  public p_operationType: string; // Működtetés

  @IsString()
  @IsNotEmpty()
  @ValidateIf(
    (o) =>
      (o.p_type === 'Almarde Mini Plissé' && o.p_operationType === 'Egyszárnyú') ||
      (o.p_type === 'Almarde 26 Plissé' && o.p_operationType === 'Egyszárnyú')
  )
  public p_collectingDirection?: string; // Gyűjtési irány

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.p_type === 'Almarde Mini Plissé')
  public p_lowerRailSize?: string; // Alsó sín mérete

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public p_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.p_color === 'RAL')
  public p_ralCode?: string; // Ral kód

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
