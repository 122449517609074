import { ReactElement } from 'react';
import { Group, Title } from '@mantine/core';

export function LocalNavTitle(props: { children: string | ReactElement | ReactElement[] }) {
  return (
    <Group m="sm">
      <Title order={5} pb="0">
        {props.children}
      </Title>
    </Group>
  );
}
