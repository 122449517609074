import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';

export function IsSameAs(compareWithProperty: string, validationOptions?: ValidationOptions) {
  return function (object: unknown, propertyName: string) {
    registerDecorator({
      name: 'isSameAs',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      target: (object as any).constructor,
      propertyName: propertyName,
      constraints: [compareWithProperty],
      options: {
        message: `"${propertyName}" and "${compareWithProperty}" must match`,
        ...validationOptions,
      },
      validator: {
        validate(targetValue: string, args: ValidationArguments) {
          const [propertyConstraint] = args.constraints;
          const compareWithValue = args.object[propertyConstraint];
          return targetValue === compareWithValue;
        },
      },
    });
  };
}
