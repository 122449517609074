import { Prisma } from '@prisma/client';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
} from 'class-validator';

export class UpperRollerBlindRequest {
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public ftr_width: number; // Teljes szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public ftr_height: number; // Teljes magasság

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  @ValidateIf((o) => o.ftr_split === true)
  public ftr_splitRightSideWidth?: number; // Jobb oldal szélessége

  @IsNumber()
  @IsNotEmpty()
  public ftr_areaLeftSide: number; // Terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public ftr_areaRightSide: number; // Terület (jobb)

  @IsNumber()
  @IsNotEmpty()
  public ftr_roundedAreaLeft: number; // Kerekített terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public ftr_roundedAreaRight: number; // Kerekített terület (jobb)

  @IsBoolean()
  @IsOptional()
  public ftr_split?: boolean; // Osztott

  @IsBoolean()
  @IsOptional()
  public ftr_combinedRail?: boolean; // Kombi lefutó

  @IsBoolean()
  @IsOptional()
  public ftr_builtInMosquitoNet?: boolean; // Beépített szúnyogháló

  @IsBoolean()
  @IsOptional()
  public ftr_builtInMosquitoNetRight?: boolean; // Beépített szúnyogháló (jobb oldal)

  @IsString()
  @IsNotEmpty()
  public ftr_operationType: string; // Működtetés

  @IsString()
  @IsNotEmpty()
  public ftr_operationSide: string; // Kezelés

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.ftr_operationType === 'Motor')
  public ftr_motor?: string; // Motor

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.ftr_operationType === 'Motor')
  public ftr_motorType?: string; // Típusa

  @IsString()
  @IsOptional()
  public ftr_remote_controller?: string; // Távirányító

  @IsString()
  @IsNotEmpty()
  public ftr_caseSize: string; // Tok méret

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public ftr_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  public ftr_reedColor: string; // Lamella szín

  @IsString()
  @IsNotEmpty()
  public ftr_reedMaterial: string; // Lamella anyag

  @IsString()
  @IsNotEmpty()
  public ftr_closingSlatColor: string; // Záróléc színe

  @IsString()
  @IsNotEmpty()
  public ftr_railColor: string; // Lefutó szín

  @IsString()
  @IsNotEmpty()
  public ftr_railMaterial: string; // Lefutó anyag

  @IsString()
  @IsNotEmpty()
  public ftr_outerCaseColor: string; // Külsőtok szín

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.ftr_operationType !== 'Motor')
  public ftr_internalFitmentColor?: string; // Belső szerelék színe

  @IsString()
  @IsNotEmpty()
  @ValidateIf(
    (o) =>
      o.ftr_reedColor === 'RAL' ||
      o.ftr_closingSlatColor === 'RAL' ||
      o.ftr_railColor === 'RAL' ||
      o.ftr_caseColor === 'RAL' ||
      o.ftr_internalFitmentColor === 'RAL'
  )
  public ftr_ralCode?: string; // Ral kód

  @IsString()
  @IsOptional()
  public ftr_plasterable?: string; // Vakolható

  @IsString()
  @IsNotEmpty()
  public ftr_outerBrushStroke: string; // Külső kefenút

  @IsString()
  @IsNotEmpty()
  public ftr_innerMountingCover: string; // Belső szerelőfedél

  @IsString()
  @IsNotEmpty()
  public ftr_lowerMountingCover: string; // Alsó szerelőfedél

  @IsBoolean()
  @IsOptional()
  public ftr_withoutAutomation?: boolean; // Automata nélkül

  @IsBoolean()
  @IsOptional()
  public ftr_withoutSidePlate?: boolean; // Oldalerősítő lemez nélkül

  @IsBoolean()
  @IsOptional()
  public ftr_withoutUnivSpacer?: boolean; // Univerzális távtartó nélkül

  @IsBoolean()
  @IsOptional()
  public ftr_withoutSnapScrew?: boolean; // Pattintó csavar nélkül

  @IsBoolean()
  @IsOptional()
  public ftr_top10SlatUnperforated?: boolean; // Felső 10 léc perforálatlan

  @IsBoolean()
  @IsOptional()
  public ftr_withCaseSize?: boolean; // Redőny tokkal együtti méret

  @IsNumber()
  @IsNotEmpty()
  public ftr_automationQuantity: number; // Automata db

  @IsString()
  @IsOptional()
  public ftr_sidePlate?: string; // Oldalerősítő lemez neve

  @IsNumber()
  @IsNotEmpty()
  public ftr_sidePlateQuantity: number; // Oldalerősítő lemez db

  @IsString()
  @IsOptional()
  public ftr_univSpacer?: string; // Univerzális távtartó neve

  @IsNumber()
  @IsNotEmpty()
  public ftr_univSpacerQuantity: number; // Univerzális távtartó db

  @IsString()
  @IsOptional()
  public ftr_snapScrew?: string; // Pattintó csavar neve

  @IsNumber()
  @IsNotEmpty()
  public ftr_snapScrewQuantity: number; // Pattintó csavar db

  @IsNumber()
  @IsNotEmpty()
  public ftr_rltpQuantity: number; // RLTP db

  @IsNumber()
  @IsNotEmpty()
  public ftr_motorQuantity: number; // Motor db

  @IsNumber()
  @IsNotEmpty()
  public ftr_remoteControllerQuantity: number; // Távirányító db

  @IsString()
  @IsOptional()
  public ftr_automation?: string; // Automaták

  @IsString()
  @IsNotEmpty()
  public ftr_typeLeftSide: string; // Redőny típusa jobb oldal

  @IsString()
  @IsOptional()
  public ftr_typeRightSide?: string; // Redőny típusa jobb oldal

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsString()
  @IsOptional()
  public invoiceName?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
