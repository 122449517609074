import { Box, createStyles, getStylesRef } from '@mantine/core';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { createElement } from 'react';
import { Icon } from '@tabler/icons-react';

const useStyles = createStyles((theme, _params) => {
  const icon = getStylesRef('icon');
  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      transition: 'all 0.1s linear, border-radius 0ms',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
      marginTop: 4,
      borderRadius: theme.radius.md,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.xs,
    },

    linkActive: {
      borderLeft: 'solid 4px',
      borderRadius: `0 ${theme.radius.md} ${theme.radius.md} 0`,
      borderColor: theme.colors[theme.primaryColor][9],
      color: theme.colors[theme.primaryColor][9],
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      '&:hover': {
        color: theme.colors[theme.primaryColor][9],
        [`& .${icon}`]: {
          color: theme.colors[theme.primaryColor][9],
        },
      },
      [`& .${icon}`]: {
        color: theme.colors[theme.primaryColor][9],
      },
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: 0.3,
      '&, &:hover': {
        backgroundColor: 'inherit',
        color: 'inherit',
        [`& .${icon}`]: {
          color: 'inherit',
        },
      },
    },
  };
});

interface LocalNavLinkProps {
  to?: string;
  icon: Icon;
  label: string;
  disabled?: boolean;
}

export function LocalNavLink({ to, icon, label, disabled }: LocalNavLinkProps) {
  const { classes, cx } = useStyles();

  if (to) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: false });

    return disabled ? (
      <Box className={cx(classes.link, classes.disabled)}>
        {createElement(icon, { className: classes.linkIcon, size: 20 })}

        <Box sx={{ fontSize: 14 }}>{label}</Box>
      </Box>
    ) : (
      <NavLink
        className={cx(classes.link, {
          [classes.linkActive]: match,
        })}
        to={to}
        key={to}
      >
        {createElement(icon, { className: classes.linkIcon, size: 20 })}

        <Box sx={{ fontSize: 14 }}>{label}</Box>
      </NavLink>
    );
  } else {
    return <Box>ERROR</Box>;
  }
}
