import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { UnitOfMeasurementResponse } from '@gravity/shared/dto';
import { Box, Button, Center, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../components/PageTitle';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { IconPlus } from '@tabler/icons-react';
import { CellContext } from '@tanstack/react-table';
import { Permission, UnitOfMeasurementReferenceType } from '@prisma/client';
import { Link } from 'react-router-dom';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';

function refTypeShow(ctx: CellContext<UnitOfMeasurementResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      <>{t('common.unitOfMeasurementReferenceType.' + ctx.getValue())}</>
    </Center>
  );
}

export function UnitOfMeasurementPage() {
  const { t } = useTranslation();

  const query = useTableQuery<UnitOfMeasurementResponse>('units-of-measurement');

  const cols: EntityTableColumnDef<UnitOfMeasurementResponse>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'sign' },
    { accessorKey: 'category' },
    {
      accessorKey: 'type',
      type: EntityTableColumnType.Enum,
      filterOptions: Object.keys(UnitOfMeasurementReferenceType).map((refType) => ({
        value: refType,
        label: t(`common.unitOfMeasurementReferenceType.${refType}`),
      })),
      cell: (row) => refTypeShow(row),
    },
    { accessorKey: 'conversionRatio', type: EntityTableColumnType.Number },
  ];

  return (
    <Box p="md">
      <PageTitle title={t('coreData.unitOfMeasurement.title')} subtitle={t('coreData.unitOfMeasurement.subtitle')}>
        <Link to="create">
          <PermissionCheck hasAll={[Permission.appAdmin]}>
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </PermissionCheck>
        </Link>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <Box pt="md">
          <EntityTable
            resourceName="unitOfMeasurement"
            tableQuery={query}
            columns={cols}
            onSettingsChange={query.handleTableChange}
          />
        </Box>
      </ScrollArea>
    </Box>
  );
}
