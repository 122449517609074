import { GrDateInput, GrForm, GrSelectInput, useDtoForm } from '@gravity/frontend/ui';
import { PriceListVersionCreateRequest } from '@gravity/shared/dto';
import { Button, Grid, Group, Modal, Select, useMantineTheme } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { Permission } from '@prisma/client';
import { IconCheck, IconPlus, IconX } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page';
import { PageTitle } from '../../../components/PageTitle';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { usePermission } from '../../../hooks/use-permission.hook';
import { usePriceListVersions } from '../../../hooks/use-price-list-version.hook';
import { axiosInstance } from '../../../plugins/axios';
import { PriceListComponent } from '../components/PriceListComponent';
import { usePartnerCustomer } from '../../../hooks/use-partner-customer.hook';
import { NotFound } from '../../core/pages/NotFound';

export function PriceListsPage() {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();

  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  const partnersList = hasAnyPermission([Permission.pricing]) ? usePartnerCustomer() : [];
  const priceListVersions = hasAnyPermission([Permission.pricing]) ? usePriceListVersions() : [];

  const [currentPartnerId, setcurrentPartnerId] = useLocalStorage({
    key: 'gravity.priceListPartnerId',
    getInitialValueInEffect: true,
  });

  const defaultVersion = useMemo(
    () =>
      priceListVersions
        ?.filter((version) => new Date(version.validityStartDate) <= new Date() && !version.partnerId)
        .reduce(
          (versionA, versionB) =>
            new Date(versionA.validityStartDate) > new Date(versionB.validityStartDate) ? versionA : versionB,
          {}
        ),
    [priceListVersions]
  );

  const [currentVersionId, setCurrentVersionId] = useLocalStorage({
    key: 'gravity.priceListVersionId',
    defaultValue: defaultVersion.id,
    getInitialValueInEffect: true,
  });

  const selectVersions = useMemo(
    () =>
      priceListVersions
        .filter((version) => version.partnerId === currentPartnerId || (!currentPartnerId && !version.partnerId))
        .map((version) => {
          const date = new Date(version.validityStartDate).toLocaleDateString();
          return {
            label: date,
            value: version.id,
          };
        }),
    [currentPartnerId, currentVersionId, priceListVersions, defaultVersion]
  );

  const partners = useMemo(
    () =>
      partnersList
        ? partnersList
            .filter((partner) => partner.priceListVersions && partner.priceListVersions.length !== 0)
            .map((partner) => ({
              label: partner.name,
              value: partner.id,
            }))
        : [],
    [partnersList, currentPartnerId]
  );

  const handleVersionChange = async (id: string) => {
    setCurrentVersionId(id);
  };

  const priceListVersionForm = useDtoForm(
    [PriceListVersionCreateRequest, PriceListVersionCreateRequest],
    'entity.pricing',
    {
      isEditForm: false,
      defaultValues: undefined,
      validators: {
        validityStartDate: startDateValidation,
        validityEndDate: endDateValidation,
      },
    }
  );

  function handlePartnerChange(id: string) {
    setcurrentPartnerId(id);
    setCurrentVersionId(priceListVersions.find((version) => version.partnerId === id).id);
  }

  useEffect(() => {
    priceListVersionForm.reset();
  }, [opened]);

  async function startDateValidation(date: string) {
    const result = await axiosInstance.get(
      `price-list-versions/check-start-date?startDate=${date}&partnerId=${priceListVersionForm.watch('partnerId')}`
    );
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictVersionStart') {
      return { type: result.response?.data.message };
    }
  }

  async function endDateValidation(date: string) {
    if (!date) {
      return;
    }

    const result = await axiosInstance.get(
      `price-list-versions/check-end-date?startDate=${priceListVersionForm.watch('validityStartDate')}&endDate=${date}`
    );
    if (result instanceof AxiosError && result.response?.data.message === 'error.badVersionEndDate') {
      return { type: result.response?.data.message };
    }
  }

  const priceListVersionMutation = useDtoMutation('price-list-versions', {
    invalidateQueries: [['price-list-versions'], ['partners', 'list-customers']],
  });

  async function handleSubmit(data: PriceListVersionCreateRequest) {
    const version = priceListVersions.find((version) => version.id == currentVersionId);
    const newVersion = {
      ...version,
      validityStartDate: data.validityStartDate,
      validityEndDate: data.validityEndDate,
      partnerId: data.partnerId,
    };
    delete newVersion.id;
    if (!newVersion.priceLists) {
      newVersion.priceLists = [];
    } else {
      newVersion.priceLists?.forEach((priceList) => {
        delete priceList.id;
        delete priceList.priceListVersionId;
        delete priceList.attributeDefinition;
        delete priceList.product;
        priceList.priceListItems?.forEach((priceListItem) => {
          delete priceListItem.id;
          delete priceListItem['priceListId'];
        });
      });
    }

    const createdVersion = await priceListVersionMutation.create(newVersion);

    if (createdVersion && createdVersion['id']) {
      await setCurrentVersionId(createdVersion['id']);
      await setcurrentPartnerId(createdVersion['partnerId']);
    }

    close();
  }

  return !hasAnyPermission([Permission.pricing]) ? (
    <NotFound />
  ) : (
    <Page>
      <PageTitle title={t('pricing.priceLists.title')} subtitle={t('pricing.priceLists.subtitle')}>
        <Button leftIcon={<IconPlus />} variant="light" onClick={open}>
          {t('common.button.clone')}
        </Button>
      </PageTitle>

      <Modal.Root opened={opened} onClose={close}>
        <Modal.Overlay color={theme.fn.darken(theme.colors[theme.primaryColor][5], 0.25)} opacity={0.25} blur={4} />

        <Modal.Content p={16}>
          <Modal.Header>
            <Modal.Title>
              <PageTitle title={t('pricing.priceListVersionForm.createTitle')} />
            </Modal.Title>

            <Modal.CloseButton />
          </Modal.Header>

          <Modal.Body sx={{ height: '500px' }}>
            <GrForm form={priceListVersionForm} onSubmit={handleSubmit}>
              <Grid>
                <Grid.Col>
                  <GrDateInput name="validityStartDate" />
                </Grid.Col>

                <Grid.Col>
                  <GrDateInput name="validityEndDate" minDate={priceListVersionForm.watch('validityStartDate')} />
                </Grid.Col>

                <Grid.Col>
                  <GrSelectInput data={partnersList} name="partnerId" labelField="name" simpleValue />
                </Grid.Col>
              </Grid>

              <Group position="right" mt={220}>
                <Button
                  loading={priceListVersionForm.isSubmitting}
                  color="green"
                  type="submit"
                  leftIcon={<IconCheck />}
                >
                  {t('common.button.save')}
                </Button>

                <Button type="button" color="gray" variant="light" leftIcon={<IconX />} onClick={close}>
                  {t('common.button.cancel')}
                </Button>
              </Group>
            </GrForm>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {currentVersionId && (
        <>
          <Grid mt="sm">
            <Select
              data={selectVersions}
              label={t('entity.pricing.validityStartDate')}
              transitionProps={{ transition: 'scale-y', duration: 200, timingFunction: 'ease' }}
              nothingFound={t(`common.message.selectNoOptionFound`)}
              value={currentVersionId}
              onChange={handleVersionChange}
              styles={{ root: { width: '200px', marginRight: '30px' } }}
            />

            <Select
              data={partners}
              label={t('entity.pricing.partnerId')}
              transitionProps={{ transition: 'scale-y', duration: 200, timingFunction: 'ease' }}
              nothingFound={t(`common.message.selectNoOptionFound`)}
              value={currentPartnerId}
              onChange={handlePartnerChange}
              styles={{ root: { width: '200px' } }}
              allowDeselect={false}
              clearable
            />
          </Grid>

          <PriceListComponent versionId={currentVersionId} />
        </>
      )}
    </Page>
  );
}
