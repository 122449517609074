import { Avatar, Box, Center, Text, Title, Paper, Group, Button, ScrollArea } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconAt, IconUpload } from '@tabler/icons-react';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../stores/auth.store';
import { useStorageService } from '../../../stores/storage.store';

export function UserProfile() {
  const { user, updateUser } = useAuth();
  const { t } = useTranslation();
  const storageService = useStorageService();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  async function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files![0];
    if (file.size > 1048576) {
      return showNotification({ message: t('validation.error.imageTooLarge1'), color: 'red' });
    }
    if (!file.type.startsWith('image')) {
      return showNotification({ message: t('validation.error.notAnImage'), color: 'red' });
    }
    await storageService.uploadAvatar(file);
    updateUser();
  }

  return (
    <Box p="md">
      <Title order={2} pb="lg">
        {t('component.userMenu.profile')}
      </Title>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <Group position="left" spacing="lg">
          <Paper radius="md" withBorder p="lg">
            <Avatar src={user?.avatar} size={120} radius={120} mx="auto" />

            <Center>
              <input
                type="file"
                id="image-upload"
                style={{ display: 'none' }}
                ref={fileUploadRef}
                onChange={handleFileUpload}
                accept="image/*"
              />

              <Button variant="subtle" compact mt="xs" onClick={() => fileUploadRef.current?.click()}>
                <IconUpload size={20} />

                <Box>{t('component.profile.uploadAvatar')}</Box>
              </Button>
            </Center>

            <Text align="center" size="lg" weight={700} mt="md">
              {`${user?.lastName} ${user?.firstName}`}
            </Text>

            <Text align="center" size="sm" weight={500}>
              {user?.position}
            </Text>

            <Center mt="md">
              <IconAt width="20" height="20" />

              <Text size="sm" ml="xs">
                {user?.email}
              </Text>
            </Center>
          </Paper>

          <Paper radius="md" withBorder p="lg"></Paper>
        </Group>
      </ScrollArea>
    </Box>
  );
}
