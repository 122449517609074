import { Box, Input, PasswordInput, Text } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export type GrPasswordInputProps = BaseInputProps;

export function GrPasswordInput({ name, ...props }: GrPasswordInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Text>{form.getValues(name) ? form.getValues(name)?.replace(/[^\s]/g, '*') : '-'}</Text>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => (
        <PasswordInput
          {...field}
          {...props.forwardedProps}
          label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
          error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
          description={props.description}
          disabled={formState.isSubmitting}
          icon={props.icon}
          required={form.requiredFields.includes(labelName)}
          autoComplete="new-password"
        />
      )}
    />
  );
}
