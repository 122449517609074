import { redirect, RouteObject } from 'react-router-dom';
import { AppLayout } from '../../layouts/AppLayout';
import { getMany, getOne } from '../../plugins/axios';
import { queryClient } from '../../plugins/react-query';
import { useAuth } from '../../stores/auth.store';
import { createGuardedLoader } from '../../utils';
import { CoreDataLayout } from './layouts/CoreDataLayout';
import { NewsPostFormPage } from './pages/NewsPostFormPage';
import { NewsPostsPage } from './pages/NewsPostsPage';
import { PartnerFormPage } from './pages/PartnerFormPage';
import { PartnersPage } from './pages/PartnersPage';
import { UnitOfMeasurementFormPage } from './pages/UnitOfMeasurementFormPage';
import { UnitOfMeasurementPage } from './pages/UnitOfMeasurementPage';
import { NotFound } from '../core/pages/NotFound';

export function authGuard() {
  const auth = useAuth.getState();

  if (!auth.isAuthenticated()) {
    return redirect('/login');
  }

  if (auth.isReseller() && !location.pathname.includes('/reseller')) {
    return redirect('/reseller');
  }
}

export const coreDataRoutes: RouteObject[] = [
  {
    path: 'core-data',
    element: <AppLayout />,  
    children: [
      {
        element: <CoreDataLayout />,
        children: [
          {
            path: 'partners',
            element: <PartnersPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['partners'], getMany('partners'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'partners/:idOrAction',
            element: <PartnerFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['partners', params.idOrAction], getOne('partners', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },

          {
            path: 'units-of-measurement',
            element: <UnitOfMeasurementPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['units-of-measurement'], getMany('units-of-measurement'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'units-of-measurement/:idOrAction',
            element: <UnitOfMeasurementFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(
                ['units-of-measurement', params.idOrAction],
                getOne('units-of-measurement', params.idOrAction)
              );
            }),
            errorElement: <NotFound />,
          },

          {
            path: 'news-posts',
            element: <NewsPostsPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['news-posts'], getMany('news-posts'));
            }),
            errorElement: <NotFound />,
            
          },
          {
            path: 'news-posts/:idOrAction',
            element: <NewsPostFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['news-posts', params.idOrAction], getOne('news-posts', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },
        ],
      },
    ],
  },
];
