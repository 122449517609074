import { Box, Button, Collapse } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function EntityTableJsonRenderer(props: { value?: string; minWidth?: number }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <Box sx={{ minWidth: props.minWidth }}>
      {props.value && (
        <>
          <Button size="xs" variant="subtle" onClick={() => setOpened((o) => !o)}>
            {t('entityTable.prism.showData')}
          </Button>

          <Collapse in={opened}>
            <Prism
              language="json"
              withLineNumbers
              styles={{ code: { fontSize: 10 } }}
              copyLabel={t('entityTable.prism.copyCode')}
              copiedLabel={t('entityTable.prism.codeCopied')}
            >
              {JSON.stringify(props.value, undefined, 2)}
            </Prism>
          </Collapse>
        </>
      )}

      {!props.value && '-'}
    </Box>
  );
}
