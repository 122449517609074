import { EntityTable, EntityTableColumnDef } from '@gravity/frontend/ui';
import { PriceListResponse } from '@gravity/shared/dto';
import { Box, Center } from '@mantine/core';
import { CellContext } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useTableQuery } from '../../../hooks/use-table-query.hook';

function generateName(ctx: CellContext<PriceListResponse, unknown>) {
  return (
    <Center inline>
      <>{ctx.row.original.product ? ctx.row.original.product.name : ctx.row.original.attributeDefinition?.name}</>
    </Center>
  );
}

export function PriceListComponent({ versionId }) {
  const navigate = useNavigate();
  const query = useTableQuery<PriceListResponse>(`price-list-versions/price-lists?versionId=${versionId}&`);
  const cols: EntityTableColumnDef<PriceListResponse>[] = [
    {
      accessorKey: `product.name`,
      cell: (row) => generateName(row),
      enableSorting: false,
    },
  ];

  return (
    query && (
      <Box pt="md">
        <EntityTable
          resourceName="pricing"
          tableQuery={query}
          columns={cols}
          onSettingsChange={query.handleTableChange}
          onRowClick={(row) => navigate(`/pricing/price-list-versions/price-lists/${row.id}`)}
          hideTableFilter
        />
      </Box>
    )
  );
}
