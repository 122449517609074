import { Box, Input } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { useEffect } from 'react';

export function GrRichTextInput({ name, ...props }: BaseInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();
  const initialValue = form.getValues(name);

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Highlight, TextAlign.configure({ types: ['heading', 'paragraph'] })],
    onUpdate({ editor }) {
      form.setValue(name, editor.getHTML());
    },
    content: initialValue,
  });

  useEffect(() => {
    form.existingFields.includes(name) ? editor?.setEditable(true) : editor?.setEditable(false);
  }, [form.existingFields, editor]);

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <RichTextEditor editor={editor}>
            <RichTextEditor.Content />
          </RichTextEditor>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={() => (
        <Box sx={{ wordWrap: 'break-word' }} mt={6}>
          <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
            <RichTextEditor editor={editor}>
              <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />

                  <RichTextEditor.Italic />

                  <RichTextEditor.Underline />

                  <RichTextEditor.Strikethrough />

                  <RichTextEditor.ClearFormatting />

                  <RichTextEditor.Highlight />

                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />

                  <RichTextEditor.H2 />

                  <RichTextEditor.H3 />

                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />

                  <RichTextEditor.Hr />

                  <RichTextEditor.BulletList />

                  <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />

                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.AlignLeft />

                  <RichTextEditor.AlignCenter />

                  <RichTextEditor.AlignJustify />

                  <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>

              <RichTextEditor.Content />
            </RichTextEditor>
          </Input.Wrapper>
        </Box>
      )}
    />
  );
}
