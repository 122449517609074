import {
  GrForm,
  GrFormTitle,
  GrMultiSelectInput,
  GrTextareaInput,
  GrTextInput,
  useDtoForm,
} from '@gravity/frontend/ui';
import { UserRoleCreateRequest, UserRoleResponse, UserRoleUpdateRequest } from '@gravity/shared/dto';
import { Button, Text, SelectItem, Group, ActionIcon, Grid, ScrollArea } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { axiosInstance } from '../../../plugins/axios';

const SelectItemComponent = forwardRef<HTMLDivElement, { value: string; label: string }>(
  ({ value, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Text>{label}</Text>

      <Text size="xs" color="dimmed">
        {value}
      </Text>
    </div>
  )
);

export function UserRoleFormPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const entity = useLoaderData() as UserRoleResponse | undefined;

  const userRoleForm = useDtoForm([UserRoleCreateRequest, UserRoleUpdateRequest], 'entity.userRole', {
    isEditForm: !!entity,
    defaultValues: entity,
    validators: {
      name: nameValidation,
    },
  });

  const userRoleMutation = useDtoMutation<UserRoleUpdateRequest | UserRoleCreateRequest>('user-roles', {
    successNotification: true,
    invalidateQueries: [['user-roles']],
  });

  const permissions = Object.keys(Permission).map((permission) => ({
    id: permission,
    label: t(`common.permission.${permission}`),
  }));

  async function nameValidation(name: string) {
    if (userRoleForm.isEditForm && name === entity?.name) {
      return;
    }

    const result = await axiosInstance.get(`user-roles/check-user-role?name=${name}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictUserRole') {
      return { type: result.response?.data.message };
    }
  }

  async function handleSubmit(data: UserRoleCreateRequest | UserRoleUpdateRequest) {
    if (userRoleForm.isCreateForm) {
      const userRole = (await userRoleMutation.create(data)) as UserRoleResponse;
      navigate(`/settings/user-roles/${userRole.id}`);
      userRoleForm.toggleReadonlyMode(userRole);
    } else {
      await userRoleMutation.update(data);
      userRoleForm.toggleReadonlyMode(data);
    }
  }

  return (
    <Page>
      <GrForm form={userRoleForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/settings/user-roles">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="settings.userRoleForm" />
          </Group>

          {!entity?.internal && (
            <Group>
              {userRoleForm.readonlyMode ? (
                <PermissionCheck hasAll={[Permission.user_role_read, Permission.user_role_update]}>
                  <Button onClick={() => userRoleForm.toggleReadonlyMode()} variant="light" leftIcon={<IconPencil />}>
                    {t('common.button.edit')}
                  </Button>
                </PermissionCheck>
              ) : (
                <>
                  <PermissionCheck
                    hasAll={
                      userRoleForm.isEditForm
                        ? [Permission.user_role_read, Permission.user_role_update]
                        : [Permission.user_role_create]
                    }
                  >
                    <Button
                      loading={userRoleForm.isSubmitting}
                      color="green"
                      type="submit"
                      variant="light"
                      leftIcon={<IconCheck />}
                    >
                      {t('common.button.save')}
                    </Button>
                  </PermissionCheck>

                  <Button
                    loading={userRoleForm.isSubmitting}
                    type="button"
                    color="gray"
                    variant="light"
                    leftIcon={<IconX />}
                    onClick={() => (userRoleForm.isCreateForm ? navigate(-1) : userRoleForm.toggleReadonlyMode())}
                  >
                    {t('common.button.cancel')}
                  </Button>
                </>
              )}

              {userRoleForm.isEditForm && userRoleForm.readonlyMode && (
                <PermissionCheck
                  hasAll={[Permission.user_role_read, Permission.user_role_update, Permission.user_role_delete]}
                >
                  <DeleteConfirmButton
                    loading={userRoleForm.isSubmitting}
                    onDelete={async () => {
                      try {
                        await userRoleMutation.delete(userRoleForm.getValues('id' as never));
                        navigate('/settings/user-roles');
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </PermissionCheck>
              )}
            </Group>
          )}
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col>
              <GrTextInput name="name" />
            </Grid.Col>

            <Grid.Col>
              <GrTextareaInput name="description" />
            </Grid.Col>

            <Grid.Col>
              <GrMultiSelectInput
                multiple
                data={permissions}
                name="permissions"
                simpleValue
                forwardedProps={{
                  filter: (value: string, selected: boolean, item: SelectItem) =>
                    !selected &&
                    (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                      item.value.toLowerCase().includes(value.toLowerCase().trim())),
                  itemComponent: SelectItemComponent,
                }}
              />
            </Grid.Col>
          </Grid>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
