import { Prisma } from '@prisma/client';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
} from 'class-validator';

export class PvcRollerBlindRequest {
  @IsString()
  @IsNotEmpty()
  public prb_type: string; // Termék típusa

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public prb_width: number; // Teljes szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public prb_height: number; // Magasság

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  @ValidateIf((o) => o.prb_split === true)
  public prb_splitRightSideWidth?: number; // Jobb oldal szélessége

  @IsNumber()
  @IsNotEmpty()
  public prb_areaLeftSide: number; // Terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public prb_areaRightSide: number; // Terület (jobb)

  @IsNumber()
  @IsNotEmpty()
  public prb_roundedAreaLeft: number; // Kerekített terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public prb_roundedAreaRight: number; // Kerekített terület (jobb)

  @IsBoolean()
  @IsOptional()
  public prb_split?: boolean; // Osztott

  @IsBoolean()
  @IsOptional()
  public prb_combinedRail?: boolean; // Kombi lefutó

  @IsBoolean()
  @IsOptional()
  public prb_builtInMosquitoNet?: boolean; // Beépített szúnyogháló

  @IsBoolean()
  @IsOptional()
  public prb_builtInMosquitoNetRight?: boolean; // Beépített szúnyogháló (jobb oldal)

  @IsBoolean()
  @IsOptional()
  public prb_upperOutlet?: boolean; // Felső kivezetés

  @IsString()
  @IsNotEmpty()
  public prb_operationType: string; // Működtetés

  @IsString()
  @IsNotEmpty()
  public prb_operationSide: string; // Kezelés

  @IsString()
  @IsNotEmpty()
  public prb_caseSize: string; // Tok méret

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public prb_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  public prb_reedColor: string; // Lamella szín

  @IsString()
  @IsNotEmpty()
  public prb_caseColor: string; // Tok, lefutó, alumínium záróléc szín

  @IsString()
  @IsNotEmpty()
  public prb_internalFitmentColor: string; // Belső szerelék színe

  @IsBoolean()
  @IsOptional()
  public prb_withoutAccessories?: boolean; // Tartozékok nélkül

  @IsBoolean()
  @IsOptional()
  public prb_plasterable?: boolean; // Vakolható

  @IsBoolean()
  @IsOptional()
  public prb_wideFlangeCaseEnding?: boolean; // Széles peremes tokvéggel

  @IsBoolean()
  @IsOptional()
  public prb_top10SlatUnperforated?: boolean; // Felső 10 léc perforálatlan

  @IsBoolean()
  @IsOptional()
  public prb_withoutAutomation?: boolean; // Automata nélkül

  @IsBoolean()
  @IsOptional()
  public prb_withoutCoverButtons?: boolean; // Takarógomb/tükörgomb nélkül

  @IsBoolean()
  @IsOptional()
  public prb_wihtoutSpring?: boolean; // Rugó nélkül

  @IsString()
  @IsNotEmpty()
  public prb_typeLeftSide: string; // Redőny típusa jobb oldal

  @IsString()
  @IsOptional()
  public prb_typeRightSide?: string; // Redőny típusa jobb oldal

  @IsOptional()
  public prb_accessories?: string[]; // Redőny tartozékok

  @IsString()
  @IsOptional()
  public prb_automation?: string; // Automata neve

  @IsNumber()
  @IsOptional()
  public prb_automationQuantity?: number; // Automata darabszáma

  @IsString()
  @IsOptional()
  public prb_inlet?: string; // Bevezető neve

  @IsNumber()
  @IsOptional()
  public prb_inletQuantity?: number; // Bevezető darabszáma

  @IsString()
  @IsOptional()
  public prb_coverButton?: string; // Takarógomb neve

  @IsNumber()
  @IsOptional()
  public prb_coverButtonQuantity?: number; // Takarógombok/tükörgombok darabszáma

  @IsString()
  @IsOptional()
  public prb_spring?: string; // Rugó neve

  @IsNumber()
  @IsOptional()
  public prb_springQuantity?: number; // Rugó darabszáma

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsString()
  @IsOptional()
  public invoiceName?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
