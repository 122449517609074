import { ApiProperty } from '@nestjs/swagger';
import { IsOptional } from 'class-validator';

export class FilteredResponse<Entity> {
  @ApiProperty({ description: 'The total count of the filtered entities' })
  @IsOptional()
  totalCount?: number;

  @ApiProperty({ description: 'Current page number' })
  @IsOptional()
  page?: number;

  data: Entity[];
}
