import { Prisma } from '@prisma/client';
import { IsArray, IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, Max, ValidateIf } from 'class-validator';

export class LindabLedgeRequest {
  @Max(300)
  @IsPositive()
  @IsNumber()
  @IsNotEmpty()
  public ll_width: number; // Szélesség

  @Max(47)
  @IsPositive()
  @IsNumber()
  @IsNotEmpty()
  public ll_depth: number; // Mélység

  @IsString()
  @IsNotEmpty()
  public ll_roundedDepth: string; // Kerekített mélység

  @IsNumber()
  @IsNotEmpty()
  public ll_roundedWidth: number; // Kerekített szélesség

  @IsString()
  @IsNotEmpty()
  public ll_color: string; // Szín

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.ll_color === 'RAL')
  public ll_ralCode?: string; // Ral kód

  @IsString()
  @IsNotEmpty()
  public ll_endClosingColor: string; // Párkány végzáró

  @IsArray()
  @IsOptional()
  public ll_endClosingType: string[]; // Párkány végzáró neve

  @IsString()
  @IsOptional()
  public ll_endCloser21?: string; // Végzáró 21 neve

  @IsNumber()
  @IsOptional()
  public ll_endCloser21Quantity: number; // Végzáró 21 darab

  @IsString()
  @IsOptional()
  public ll_endCloser30?: string; // Végzáró 30 neve

  @IsNumber()
  @IsOptional()
  public ll_endCloser30Quantity: number; // Végzáró 30 darab

  @IsString()
  @IsOptional()
  public ll_endCloser35?: string; // Végzáró 35 neve

  @IsNumber()
  @IsOptional()
  public ll_endCloser35Quantity: number; // Végzáró 35 darab

  @IsString()
  @IsOptional()
  public ll_endCloser40?: string; // Végzáró 40 neve

  @IsNumber()
  @IsOptional()
  public ll_endCloser40Quantity: number; // Végzáró 40 darab

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public ll_quantity: number; // Darabszám

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
