import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { PriceListItemResponse, PriceListResponse, UpperRollerBlindRequest } from '@gravity/shared/dto';
import {
  Group,
  Button,
  Divider,
  ScrollArea,
  Grid,
  Title,
  Text,
  Collapse,
  Input,
  Image,
  Modal,
  ActionIcon,
} from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle, IconZoomIn } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';

interface UpperRollerBlindProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: UpperRollerBlindRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function UpperRollerBlind(props: UpperRollerBlindProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const [opened, { open, close }] = useDisclosure(false);

  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  const ftrPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];
  const ftrMotorPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_motor')
      ?.priceListItems ?? [];
  const ftrRemoteControllerPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_remote_controller')
      ?.priceListItems ?? [];
  const ftrAccessoriesPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_accessories')
      ?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const productForm = useDtoForm([UpperRollerBlindRequest, UpperRollerBlindRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), ftr_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  const hasRal =
    productForm.getValues('ftr_closingSlatColor') === 'RAL' || productForm.getValues('ftr_railColor') === 'RAL';

  const itemsBySide = ['ftr_motorType', 'ftr_automation', 'ftr_motorization', 'ftr_remote_controller'];
  const itemsByPiece = ['ftr_split', 'ftr_rltp', 'ftr_univSpacer', 'ftr_snapScrew', 'ftr_sidePlate'];
  const itemsByTypeAndSide = ['ftr_typeLeftSide', 'ftr_typeRightSide', 'ftr_combinedRail', 'ftr_combinedRailRight'];

  //Calculated values for pricing
  useEffect(() => {
    const width = productForm.getValues('ftr_width') ?? 0;
    const height = productForm.getValues('ftr_height') ?? 0;
    const rightSideWidth = productForm.getValues('ftr_splitRightSideWidth') ?? 0;
    const caseSize = productForm.getValues('ftr_caseSize')?.includes('250') ? 25 : 21;
    const areaLeftSide = watch('ftr_withCaseSize')
      ? ((width - rightSideWidth) * height) / 10000
      : ((width - rightSideWidth) * (height + caseSize)) / 10000;
    const areaRightSide = watch('ftr_withCaseSize')
      ? (rightSideWidth * height) / 10000
      : (rightSideWidth * (height + caseSize)) / 10000;

    productForm.setValue('ftr_areaLeftSide', areaLeftSide);
    productForm.setValue('ftr_areaRightSide', areaRightSide);
    productForm.setValue('ftr_roundedAreaLeft', getRoundedArea(productForm.getValues('ftr_areaLeftSide')));
    productForm.setValue('ftr_roundedAreaRight', getRoundedArea(productForm.getValues('ftr_areaRightSide')));

    console.log('Terület (bal)', watch('ftr_areaLeftSide'));
    console.log('Terület (jobb)', watch('ftr_areaRightSide'));
    console.log('Kerekített terület (bal)', watch('ftr_roundedAreaLeft'));
    console.log('Kerekített terület (jobb)', watch('ftr_roundedAreaRight'));

    getLockToPlayPrice();
    getAccessoriesPrice();
  }, [watch('ftr_width'), watch('ftr_height'), watch('ftr_splitRightSideWidth'), watch('ftr_withCaseSize')]);

  //Set default values if split is unchecked
  useEffect(() => {
    if (!watch('ftr_split')) {
      productForm.setValue('ftr_splitRightSideWidth', 0);
      productForm.setValue('ftr_combinedRailRight', false);
      productForm.setValue('ftr_builtInMosquitoNetRight', false);
    }
  }, [watch('ftr_split')]);

  //Built in mosquito net: true -> combined rail: true
  useEffect(() => {
    if (watch('ftr_builtInMosquitoNet') || watch('ftr_builtInMosquitoNetRight')) {
      productForm.setValue('ftr_combinedRail', true);
      if (watch('ftr_split')) {
        productForm.setValue('ftr_combinedRailRight', true);
      }
    } else if (watch('ftr_split')) {
      productForm.setValue('ftr_combinedRailRight', watch('ftr_combinedRail'));
    }
  }, [
    watch('ftr_builtInMosquitoNet'),
    watch('ftr_builtInMosquitoNetRight'),
    watch('ftr_combinedRail'),
    watch('ftr_combinedRailRight'),
    watch('ftr_split'),
  ]);

  useEffect(() => {
    if (watch('ftr_operationType') !== 'Motor') {
      productForm.setValue('ftr_motor', '');
      productForm.setValue('ftr_motorType', '');
      productForm.setValue('ftr_remote_controller', '');
      productForm.setValue('ftr_remoteControllerQuantity', 0);
    } else {
      productForm.setValue('ftr_internalFitmentColor', '');
      productForm.setValue('ftr_withoutAutomation', false);
    }

    getMotorPrice();
    getLockToPlayPrice();
  }, [watch('ftr_operationType')]);

  useEffect(() => {
    getTypePrice('ftr_typeLeftSide');
  }, [watch('ftr_reedMaterial'), watch('ftr_railMaterial'), watch('ftr_caseSize'), watch('ftr_builtInMosquitoNet')]);

  useEffect(() => {
    if (watch('ftr_split')) {
      getTypePrice('ftr_typeRightSide');
      getCombinedRailPrice('ftr_combinedRailRight');
    }
    getCombinedRailPrice('ftr_combinedRail');
  }, [
    watch('ftr_reedMaterial'),
    watch('ftr_railMaterial'),
    watch('ftr_caseSize'),
    watch('ftr_builtInMosquitoNet'),
    watch('ftr_builtInMosquitoNetRight'),
    watch('ftr_combinedRail'),
    watch('ftr_split'),
  ]);

  useEffect(() => {
    getMotorPrice();
    if (!watch('ftr_motor')) {
      productForm.setValue('ftr_motorType', '');
      productForm.setValue('ftr_motorQuantity', 0);
      productForm.setValue('ftr_remote_controller', '');
      productForm.setValue('ftr_remoteControllerQuantity', 0);
    }
  }, [watch('ftr_motor'), watch('ftr_remoteControllerQuantity')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('ftr_width'), watch('ftr_height'), watch('ftr_splitRightSideWidth')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let leftTypePrice = 0; // Prices that depend on the type and split properties by area
    let rightTypePrice = 0; // Prices that depend on the type and split properties by area
    let piecePrice = 0; // Prices by piece for the entire aluminium roller blind
    let sidePrice = 0; // Prices by piece and by side

    Object.keys(productForm.getValues('priceList') as any).forEach((key) => {
      if (itemsBySide.includes(key)) {
        sidePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByPiece.includes(key)) {
        piecePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByTypeAndSide.includes(key)) {
        if (key.includes('Right')) {
          rightTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        } else {
          leftTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        }
      } else {
        areaPrice += (productForm.getValues('priceList') as any)[`${key}`];
      }
    });

    const leftArea = watch('ftr_roundedAreaLeft') * 1000;
    const rightArea = watch('ftr_roundedAreaRight') * 1000;
    const area = leftArea + rightArea;
    areaPrice = areaPrice * area;
    leftTypePrice = leftTypePrice * leftArea;
    rightTypePrice = rightTypePrice * rightArea;
    sidePrice = watch('ftr_split') ? sidePrice * 2 : sidePrice;
    productForm.setValue('price', (areaPrice + leftTypePrice + rightTypePrice) / 1000 + sidePrice + piecePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1.3) {
      return 1.3;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getTypePrice(type: string) {
    let ftr_type = '';
    if (getConditionSublistsFor(`${type}`).length > 1) {
      productForm.setValue(`${type}`, '');
    } else {
      ftr_type = getConditionSublistsFor(`${type}`)[0];
      productForm.setValue(`${type}`, ftr_type);
    }
    let combinedType = '';
    if (type.includes('Left')) {
      combinedType = watch('ftr_builtInMosquitoNet') ? 'k' : 's';
    } else {
      combinedType = watch('ftr_builtInMosquitoNetRight') ? 'k' : 's';
    }

    const price =
      ftrPriceList.find(
        (priceListItem: PriceListItemResponse) =>
          priceListItem.label === ftr_type && priceListItem.code.includes(combinedType)
      )?.price ?? 0;

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ [`${type}`]: price },
    });
  }

  function getCombinedRailPrice(label: string) {
    const code = watch('ftr_railMaterial')?.includes('Alu') ? 'ftr_024' : 'ftr_023';

    const isbuiltInMosquitoNet = label.includes('Right')
      ? watch('ftr_builtInMosquitoNetRight')
      : watch('ftr_builtInMosquitoNet');

    const price =
      watch(label) && !isbuiltInMosquitoNet
        ? ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0
        : 0;

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
  }

  function getMotorPrice() {
    let ftr_motorType = 0;
    let ftr_motorization = 0;
    let ftr_remote_controller = 0;
    const quantity = watch('ftr_remoteControllerQuantity') ?? 1;

    if (watch('ftr_motorType')) {
      ftr_motorType =
        ftrMotorPriceList?.find(
          (priceListItem: PriceListItemResponse) => priceListItem.label === watch('ftr_motorType')
        )?.price ?? 0;
    }

    if (watch('ftr_motorType')) {
      ftr_motorization =
        ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ftr_022_db')?.price ?? 0;
    }

    if (watch('ftr_remote_controller')) {
      ftr_remote_controller =
        ftrRemoteControllerPriceList?.find(
          (priceListItem: PriceListItemResponse) => priceListItem.label === watch('ftr_remote_controller')
        )?.price ?? 0;

      if (!watch('ftr_remoteControllerQuantity')) {
        productForm.setValue('ftr_remoteControllerQuantity', 1);
      }
    } else {
      productForm.setValue('ftr_remote_controller', '');
      productForm.setValue('ftr_remoteControllerQuantity', 0);
    }

    if (watch('ftr_motorType')) {
      productForm.setValue('ftr_motorQuantity', watch('ftr_split') ? 2 : 1);
    } else {
      productForm.setValue('ftr_motorQuantity', 0);
    }

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_motorType } });
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_motorization } });
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ftr_remote_controller: ftr_remote_controller * quantity },
    });
  }

  function getLockToPlayPrice() {
    let rltp = 0;
    let ftr_rltp = 0;
    if (watch('ftr_operationType') === 'Motor') {
      const lockToPlay =
        ftrMotorPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.label === 'RLTP')?.price ?? 0;
      const rightWidth = productForm.getValues('ftr_splitRightSideWidth') ?? 0;
      const leftWidth = productForm.getValues('ftr_width') - rightWidth ?? 0;
      rltp = Math.ceil(leftWidth / 50) + Math.ceil(rightWidth / 50);

      if (productForm.getValues('ftr_width') <= 50) {
        ftr_rltp = 2 * lockToPlay;
      } else {
        ftr_rltp = Number((rltp * lockToPlay).toFixed(2));
      }
      productForm.setValue('ftr_motorQuantity', watch('ftr_split') ? 2 : 1);
    }
    productForm.setValue('ftr_rltpQuantity', rltp);
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_rltp } });
  }

  function getRailAndClosingSlatPrice() {
    const renolit = ['Aranytölgy', 'Dió', 'Mahagóni'];
    let ftr_railAndClosingSlat = 0;
    if (renolit.includes(watch('ftr_railColor')) || renolit.includes(watch('ftr_closingSlatColor'))) {
      ftr_railAndClosingSlat =
        ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ftr_033')?.price ?? 0;
    }
    if (watch('ftr_railColor') === 'RAL' || watch('ftr_closingSlatColor') === 'RAL') {
      ftr_railAndClosingSlat +=
        ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ftr_027')?.price ?? 0;
    }
    if (watch('ftr_railColor') === 'Barna' || watch('ftr_railColor') === 'Antracit') {
      ftr_railAndClosingSlat +=
        ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ftr_028')?.price ?? 0;
    }

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ftr_railAndClosingSlat },
    });
  }

  function getRenolitPriceFor(label: string, code: string) {
    const renolit = ['Aranytölgy', 'Dió', 'Mahagóni'];
    const price = renolit.includes(watch(label))
      ? ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0
      : 0;

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
  }

  function getOuterBrushStrokePrice() {
    const renolit = ['Aranytölgy', 'Dió', 'Mahagóni'];
    const isRenolit = renolit.includes(watch('ftr_outerBrushStroke')) && !renolit.includes(watch('ftr_outerCaseColor'));
    const ftr_outerBrushStroke = isRenolit
      ? ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ftr_030')?.price ?? 0
      : 0;

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_outerBrushStroke } });
  }

  function getAccessoriesPrice() {
    const isMotor = watch('ftr_operationType') === 'Motor';

    // Automata felár
    const automation =
      isMotor || !watch('ftr_height') || watch('ftr_withoutAutomation')
        ? ''
        : getConditionSublistsFor('ftr_automation')[0];
    productForm.setValue('ftr_automation', automation);
    const ftr_automation = !automation
      ? 0
      : ftrAccessoriesPriceList.find(
          (priceListItem: PriceListItemResponse) =>
            priceListItem.label.includes(automation) &&
            !priceListItem.code.includes('db') &&
            !priceListItem.label.includes('(')
        )?.price ?? 0;

    productForm.setValue(
      'ftr_automationQuantity',
      isMotor || watch('ftr_withoutAutomation') ? 0 : watch('ftr_split') ? 2 : 1
    );

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_automation } });

    // Oldalerősítő lemez
    const sidePlate = ftrAccessoriesPriceList.find(
      (priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_042_db'
    );
    const ftr_sidePlate = !watch('ftr_withoutSidePlate') && watch('ftr_height') ? (sidePlate?.price ?? 0) * 2 : 0;
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_sidePlate } });
    productForm.setValue('ftr_sidePlateQuantity', watch('ftr_withoutSidePlate') ? 0 : 2);
    productForm.setValue('ftr_sidePlate', watch('ftr_withoutSidePlate') ? '' : sidePlate?.label);

    // Pattintó csavar
    const snapScrew = ftrAccessoriesPriceList.find(
      (priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_043_db'
    );
    const snapScrewPrice = snapScrew?.price ?? 0;
    let ftr_snapScrew = 0;
    if (!watch('ftr_withoutSnapScrew')) {
      const caseSize = watch('ftr_caseSize')?.includes('250') ? 25 : 21;
      const height = watch('ftr_withCaseSize') ? watch('ftr_height') - caseSize : watch('ftr_height');
      const splitNumber = watch('ftr_split') ? 3 : 2;
      const snapScrewQuantity = Math.ceil(height / 50) * splitNumber;
      productForm.setValue('ftr_snapScrewQuantity', snapScrewQuantity);
      ftr_snapScrew = snapScrewPrice * snapScrewQuantity;
      productForm.setValue('ftr_snapScrew', snapScrew?.label);
    } else {
      productForm.setValue('ftr_snapScrewQuantity', 0);
      productForm.setValue('ftr_snapScrew', '');
    }
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_snapScrew } });

    // Univerzális távtartó
    const spacer = ftrAccessoriesPriceList.find(
      (priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_044_fm'
    );
    const spacerPrice = spacer?.price ?? 0;
    let ftr_univSpacer = 0;
    if (!watch('ftr_withoutUnivSpacer')) {
      const spacerQuantity = Math.ceil(watch('ftr_width') / 30);
      productForm.setValue('ftr_univSpacerQuantity', spacerQuantity);
      ftr_univSpacer = spacerPrice * spacerQuantity;
      productForm.setValue('ftr_univSpacer', spacer?.label);
    } else {
      productForm.setValue('ftr_univSpacerQuantity', 0);
      productForm.setValue('ftr_univSpacer', '');
    }
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ ftr_univSpacer } });
  }

  function getPriceFor(label: string, code: string) {
    const isExtraCharge = watch(label) && watch(label) !== 'Fehér';
    let price = isExtraCharge
      ? ftrPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0
      : 0;
    if (label === 'ftr_plasterable' && watch('ftr_plasterable') === 'Kívül-Belül') {
      price = price * 2;
    }

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
  }

  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: UpperRollerBlindRequest) => {
        if (!hasRal) {
          await delete data.ftr_ralCode;
        }
        data.summary = !data.ftr_split
          ? 'Szélesség: ' +
            data.ftr_width.toLocaleString() +
            ' cm, Magasság: ' +
            data.ftr_height.toLocaleString() +
            ' cm'
          : 'Bal oldal szélessége: ' +
            (data.ftr_width - (data.ftr_splitRightSideWidth ? data.ftr_splitRightSideWidth : 0)).toLocaleString() +
            ' cm, Jobb oldal szélessége: ' +
            (data.ftr_splitRightSideWidth ? data.ftr_splitRightSideWidth.toLocaleString() : 0) +
            ' cm, Magasság: ' +
            data.ftr_height.toLocaleString() +
            ' cm';

        data.summary +=
          ', Működtetés: ' +
          (data.ftr_operationType != 'Motor'
            ? data.ftr_operationType
            : data.ftr_operationType + ' - ' + data.ftr_motorType);

        const dash = ' - ';
        const combined = data.ftr_builtInMosquitoNet || data.ftr_builtInMosquitoNetRight;
        const withCombinedRail =
          !data.ftr_builtInMosquitoNet && !data.ftr_builtInMosquitoNetRight && data.ftr_combinedRail;
        data.invoiceName =
          (data.ftr_plasterable ? getLabelFor('ftr_plasterable') + ' ' : '') + //Vakolható
          product['name'].split(' ')[0] + //Alumínium
          ' ' +
          (combined ? uppercaseFirstLetter(getLabelFor('ftr_combinedRail').split(' ')[0]) + ' ' : '') + //Kombi
          uppercaseFirstLetter(product['name'].split(' ')[1]) + //Redőny
          (withCombinedRail ? ', ' + uppercaseFirstLetter(getLabelFor('ftr_combinedRail')) + 'val ' : '') + //Kombi lefutóval
          dash +
          data.ftr_reedColor + //Szín
          ' színben';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="ftr_width" label={getLabelFor('ftr_width')} min={0} precision={2} controls />

            {getErrorMessageFor('ftr_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('ftr_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput
              name="ftr_height"
              label={getLabelFor('ftr_height')}
              max={330}
              min={0}
              precision={2}
              controls
            />
          </Grid.Col>

          {getErrorMessageFor('ftr_height') && (
            <Grid.Col xs={4} mt="lg">
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('ftr_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          <Grid.Col>
            <GrSwitchInput
              name="ftr_split"
              label={getLabelFor('ftr_split')}
              description={getDescriptionFor('ftr_split')}
            />
          </Grid.Col>

          <Grid.Col>
            <Title pb={0} order={5}>
              {t('entity.form.combined')}
            </Title>

            <Text fs="italic">{getDescriptionFor('ftr_builtInMosquitoNet')}</Text>
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="ftr_builtInMosquitoNet" label={getLabelFor('ftr_builtInMosquitoNet')} />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="ftr_combinedRail" label={getLabelFor('ftr_combinedRail')} />
          </Grid.Col>
        </Grid>

        <Collapse in={!!watch('ftr_split')}>
          <Divider m="lg" />

          <Grid m={10}>
            <Grid.Col xs={4}>
              <GrNumberInput
                name="ftr_splitRightSideWidth"
                label={getLabelFor('ftr_splitRightSideWidth')}
                min={10}
                max={watch('ftr_width') - 10}
                precision={2}
                controls
              />

              {getErrorMessageFor('ftr_splitRightSideWidth') && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {getErrorMessageFor('ftr_splitRightSideWidth')}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          </Grid>

          <Grid m={10}>
            <Grid.Col>
              <Title pb={0} order={5}>
                {t('entity.form.combinedRight')}
              </Title>

              <Text fs="italic">{getDescriptionFor('ftr_builtInMosquitoNetRight')}</Text>
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="ftr_builtInMosquitoNetRight" label={getLabelFor('ftr_builtInMosquitoNetRight')} />
            </Grid.Col>

            <Grid.Col xs={8}>
              <GrSwitchInput name="ftr_combinedRailRight" label={getLabelFor('ftr_combinedRailRight')} />

              {!orderReadonlyMode && (
                <Text c="dimmed" fz="xs">
                  {getDescriptionFor('ftr_combinedRailRight')}
                </Text>
              )}
            </Grid.Col>
          </Grid>
        </Collapse>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="ftr_operationType"
              label={getLabelFor('ftr_operationType')}
              data={getConditionSublistsFor('ftr_operationType')}
              description={getConditionDescriptionFor('ftr_operationType')}
              disabled={getDisabledStateFor('ftr_operationType')}
              onEmptyState={() => warningNotification(getLabelFor('ftr_operationType'))}
              onChange={getAccessoriesPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="ftr_operationSide"
              label={getLabelFor('ftr_operationSide')}
              data={getConditionSublistsFor('ftr_operationSide')}
              description={getConditionDescriptionFor('ftr_operationSide')}
              disabled={getDisabledStateFor('ftr_operationSide')}
              onEmptyState={() => warningNotification(getLabelFor('ftr_operationSide'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            {!orderReadonlyMode && (
              <Grid style={{ color: 'dodgerblue' }} mt={25}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getDescriptionFor('ftr_operationSide')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        {watch('ftr_operationType') === 'Motor' && (
          <Grid m={10} style={{ transition: 'top 5s ease-in-out' }}>
            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_motor"
                label={getLabelFor('ftr_motor')}
                data={getConditionSublistsFor('ftr_motor')}
                description={getConditionDescriptionFor('ftr_motor')}
                disabled={getDisabledStateFor('ftr_motor')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_motor'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_motorType"
                label={getLabelFor('ftr_motorType')}
                data={watch('ftr_motor') ? getConditionSublistsFor('ftr_motorType') : []}
                description={getConditionDescriptionFor('ftr_motorType')}
                disabled={getDisabledStateFor('ftr_motorType')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_motorType'))}
                onChange={getMotorPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_remote_controller"
                label={getLabelFor('ftr_remote_controller')}
                data={watch('ftr_motor') ? getConditionSublistsFor('ftr_remote_controller') : []}
                description={getConditionDescriptionFor('ftr_remote_controller')}
                disabled={getDisabledStateFor('ftr_remote_controller')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_remote_controller'))}
                onChange={getMotorPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            {watch('ftr_remote_controller') && (
              <Grid.Col xs={3}>
                <GrNumberInput
                  name="ftr_remoteControllerQuantity"
                  label={getLabelFor('ftr_remoteControllerQuantity')}
                  min={1}
                  max={100}
                  controls
                />
              </Grid.Col>
            )}
          </Grid>
        )}

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="ftr_caseSize"
              label={getLabelFor('ftr_caseSize')}
              data={getConditionSublistsFor('ftr_caseSize')}
              description={getConditionDescriptionFor('ftr_caseSize')}
              disabled={getDisabledStateFor('ftr_caseSize')}
              onEmptyState={() => warningNotification(getLabelFor('ftr_caseSize'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="ftr_quantity" label={getLabelFor('ftr_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid m={0} p={5}>
          <Grid m={10} mb={0}>
            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_closingSlatColor"
                label={getLabelFor('ftr_closingSlatColor')}
                data={getConditionSublistsFor('ftr_closingSlatColor')}
                description={getConditionDescriptionFor('ftr_closingSlatColor')}
                disabled={getDisabledStateFor('ftr_closingSlatColor')}
                onChange={getRailAndClosingSlatPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_railMaterial"
                label={getLabelFor('ftr_railMaterial')}
                data={getConditionSublistsFor('ftr_railMaterial')}
                description={getConditionDescriptionFor('ftr_railMaterial')}
                disabled={getDisabledStateFor('ftr_railMaterial')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_railMaterial'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_railColor"
                label={getLabelFor('ftr_railColor')}
                data={getConditionSublistsFor('ftr_railColor')}
                description={getConditionDescriptionFor('ftr_railColor')}
                disabled={getDisabledStateFor('ftr_railColor')}
                onChange={getRailAndClosingSlatPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_reedMaterial"
                label={getLabelFor('ftr_reedMaterial')}
                data={getConditionSublistsFor('ftr_reedMaterial')}
                description={getConditionDescriptionFor('ftr_reedMaterial')}
                disabled={getDisabledStateFor('ftr_reedMaterial')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_reedMaterial'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_reedColor"
                label={getLabelFor('ftr_reedColor')}
                data={getConditionSublistsFor('ftr_reedColor')}
                description={getConditionDescriptionFor('ftr_reedColor')}
                disabled={getDisabledStateFor('ftr_reedColor')}
                onEmptyState={() => warningNotification(getLabelFor('ftr_reedColor'))}
                onChange={() => getPriceFor('ftr_reedColor', 'ftr_025')}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_plasterable"
                label={getLabelFor('ftr_plasterable')}
                data={getConditionSublistsFor('ftr_plasterable')}
                description={getConditionDescriptionFor('ftr_plasterable')}
                disabled={getDisabledStateFor('ftr_plasterable')}
                onChange={() => getPriceFor('ftr_plasterable', 'ftr_021')}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              {watch('ftr_operationType') !== 'Motor' && (
                <GrSelectInput
                  name="ftr_internalFitmentColor"
                  label={getLabelFor('ftr_internalFitmentColor')}
                  data={getConditionSublistsFor('ftr_internalFitmentColor')}
                  description={getConditionDescriptionFor('ftr_internalFitmentColor')}
                  disabled={getDisabledStateFor('ftr_internalFitmentColor')}
                  onChange={getAccessoriesPrice}
                  emptyOnStateChange
                  simpleValue
                />
              )}
            </Grid.Col>
          </Grid>

          <Grid m={10} mt={0}>
            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_outerCaseColor"
                label={getLabelFor('ftr_outerCaseColor')}
                data={getConditionSublistsFor('ftr_outerCaseColor')}
                description={getConditionDescriptionFor('ftr_outerCaseColor')}
                disabled={getDisabledStateFor('ftr_outerCaseColor')}
                onChange={() => {
                  getRenolitPriceFor('ftr_outerCaseColor', 'ftr_029');
                  getOuterBrushStrokePrice();
                }}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_outerBrushStroke"
                label={getLabelFor('ftr_outerBrushStroke')}
                data={getConditionSublistsFor('ftr_outerBrushStroke')}
                description={getConditionDescriptionFor('ftr_outerBrushStroke')}
                disabled={getDisabledStateFor('ftr_outerBrushStroke')}
                onChange={getOuterBrushStrokePrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_innerMountingCover"
                label={getLabelFor('ftr_innerMountingCover')}
                data={getConditionSublistsFor('ftr_innerMountingCover')}
                description={getConditionDescriptionFor('ftr_innerMountingCover')}
                disabled={getDisabledStateFor('ftr_innerMountingCover')}
                onChange={() => getRenolitPriceFor('ftr_innerMountingCover', 'ftr_031')}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="ftr_lowerMountingCover"
                label={getLabelFor('ftr_lowerMountingCover')}
                data={getConditionSublistsFor('ftr_lowerMountingCover')}
                description={getConditionDescriptionFor('ftr_lowerMountingCover')}
                disabled={getDisabledStateFor('ftr_lowerMountingCover')}
                onChange={() => getRenolitPriceFor('ftr_lowerMountingCover', 'ftr_032')}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            {hasRal && (
              <>
                <Grid.Col xs={3}>
                  <GrTextInput name="ftr_ralCode" label={getLabelFor('ftr_ralCode')} mask="9999" />
                </Grid.Col>

                <Grid.Col xs={3}>
                  {!orderReadonlyMode && (
                    <Grid style={{ color: 'dodgerblue' }} mt={25}>
                      <Grid.Col xs={1}>
                        <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                      </Grid.Col>

                      <Grid.Col xs={11}>
                        <Text fz="xs" ta="center">
                          {'(4 számjegy)'}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  )}
                </Grid.Col>
              </>
            )}
          </Grid>
        </Grid>

        <Grid>
          <Grid.Col span={'auto'}>
            <Grid m={10}>
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="ftr_withCaseSize"
                  label={getLabelFor('ftr_withCaseSize')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="ftr_withoutSidePlate"
                  label={getLabelFor('ftr_withoutSidePlate')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              {watch('ftr_reedColor') === 'Fehér' && (
                <Grid.Col xs={4}>
                  <GrSwitchInput name="ftr_top10SlatUnperforated" label={getLabelFor('ftr_top10SlatUnperforated')} />
                </Grid.Col>
              )}
            </Grid>

            <Grid m={10}>
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="ftr_withoutUnivSpacer"
                  label={getLabelFor('ftr_withoutUnivSpacer')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="ftr_withoutSnapScrew"
                  label={getLabelFor('ftr_withoutSnapScrew')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              {watch('ftr_operationType') !== 'Motor' && (
                <Grid.Col xs={4}>
                  <GrSwitchInput
                    name="ftr_withoutAutomation"
                    label={getLabelFor('ftr_withoutAutomation')}
                    onChange={getAccessoriesPrice}
                  />
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col span={'content'}>
            <Modal.Root opened={opened} onClose={close} zIndex={1000}>
              <Modal.Overlay opacity={0.7} />

              <Modal.Content>
                <Modal.Header>
                  <Modal.CloseButton pos={'fixed'} right={10} top={10} />
                </Modal.Header>

                <Modal.Body>
                  <Image mx="auto" radius="md" src={`/assets/images/${'ftr'}.jpg`} />
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>

            <Group pos={'relative'}>
              <div style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 10 }}>
                <ActionIcon onClick={open} variant="light">
                  <IconZoomIn />
                </ActionIcon>
              </div>

              <Image
                width={150}
                mx="auto"
                radius="md"
                src={`/assets/images/${'ftr'}.jpg`}
                pr={20}
                onClick={open}
                sx={{ cursor: 'pointer' }}
              />
            </Group>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.automation')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_automation')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_automationQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.sidePlate')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_sidePlate')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_sidePlateQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.spacer')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_univSpacer')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_univSpacerQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.snapScrew')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_snapScrew')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_snapScrewQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.rltp')}</Text>
          </Grid.Col>

          <Grid.Col xs={3} offset={5}>
            <Text>{`${productForm.getValues('ftr_rltpQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.motor')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_motorType')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_motorQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.ftr.remoteController')}</Text>
          </Grid.Col>

          <Grid.Col xs={5}>
            <Text>{`${productForm.getValues('ftr_remote_controller')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ftr_remoteControllerQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
