import { Prisma } from '@prisma/client';
import { IsDateString, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';

export class PriceDiscountCreateRequest {
  /**
   * The validity start date of the price discount
   */
  @IsDateString()
  @IsNotEmpty()
  public fromDate: Date;

  /**
   * The validity end date of the price discount
   */
  @IsDateString()
  @IsOptional()
  public toDate?: Date;

  /**
   * The discounted list items of the price discount
   */
  @IsObject()
  public discountedListItems?: Prisma.JsonValue;

  /**
   * The type of the price discount
   */
  // @IsString()
  // @IsNotEmpty()
  // public discountType: DiscountType;

  /**
   * The value of the price discount
   */
  @IsNumber()
  @IsNotEmpty()
  public discountValue: number;

  /**
   * The related price list item id if the discount is for one price list item
   */
  @IsString()
  public priceListItemId?: string;

  /**
   * The related price list id if the discount is for the whole price list
   */
  @IsString()
  public priceListId?: string;

  /**
   * The related product category id if the discount is for a product category
   */
  @IsString()
  public productCategoryId?: string;

  /**
   * The related partner id if the discount is for a partner
   */
  @IsString()
  public partnerId?: string;
}

export class PriceDiscountUpdateRequest {
  /**
   * CUID identifier of the price discount
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The validity start date of the price discount
   */
  @IsDateString()
  @IsNotEmpty()
  public fromDate: Date;

  /**
   * The validity end date of the price discount
   */
  @IsDateString()
  @IsOptional()
  public toDate?: Date;

  /**
   * The discounted list items of the price discount
   */
  @IsObject()
  public discountedListItems?: Prisma.JsonValue;

  /**
   * The type of the price discount
   */
  // @IsString()
  // @IsNotEmpty()
  // public discountType: DiscountType;

  /**
   * The value of the price discount
   */
  @IsNumber()
  @IsNotEmpty()
  public discountValue: number;

  /**
   * The related price list item id if the discount is for one price list item
   */
  @IsString()
  public priceListItemId?: string;

  /**
   * The related price list id if the discount is for the whole price list
   */
  @IsString()
  public priceListId?: string;

  /**
   * The related product category id if the discount is for a product category
   */
  @IsString()
  public productCategoryId?: string;

  /**
   * The related partner id if the discount is for a partner
   */
  @IsString()
  public partnerId?: string;
}
