import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useSelectValues() {
  const selectValues = useQuery(
    ['product-attribute-definitions', 'list-select-values'],
    getMany<string[]>('product-attribute-definitions/list-select-values')
  );

  return selectValues ? selectValues.data : [];
}
