import { LinkProps, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { ReactElement } from 'react';
import { createStyles, Tooltip, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface GlobalNavLinkProps extends LinkProps {
  tooltip: string;
  icon: ReactElement;
  exact?: boolean;
}

const useStyles = createStyles((theme) => ({
  mainLink: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.xl,
    marginTop: `calc(${theme.radius.md} / 2)`,
    marginBottom: `calc(${theme.radius.md} / 2)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    transition: 'all 0.1s linear',

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },

  mainLinkActive: {
    '&, &:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.colors[theme.primaryColor][0],
    },
  },
}));

export function GlobalNavLink({ to, tooltip, icon, exact, ...props }: GlobalNavLinkProps) {
  const resolved = useResolvedPath(to);
  const pathPattern = '/' + resolved.pathname.split('/')[1];
  const match = useMatch({ path: pathPattern, end: exact ?? false });
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink to={to} {...props}>
      <Tooltip label={t(tooltip)} position="right" withArrow transitionProps={{ transition: 'fade' }}>
        <UnstyledButton
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]: match,
          })}
        >
          {icon}
        </UnstyledButton>
      </Tooltip>
    </NavLink>
  );
}
