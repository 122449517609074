import { Center, Space } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export function EntityTableBooleanRenderer(props: { value?: string }) {
  const { t } = useTranslation();

  return (
    <Center inline>
      {props.value ? <IconCheck size={16} style={{ marginTop: -1 }} /> : <IconX size={16} style={{ marginTop: -1 }} />}

      <Space w={5} />

      {props.value ? t('common.message.true') : t('common.message.false')}
    </Center>
  );
}
