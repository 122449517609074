import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class AppSettingsRequest {
  /**
   * The key of the app settings
   */
  @IsString()
  @IsNotEmpty()
  public key: string;

  /**
   * The value of the app settings
   */
  @IsString()
  @IsOptional()
  public value?: string;

  /**
   * The values of the app settings
   */
  @IsEmail({}, { each: true })
  @IsOptional()
  public values?: string[];
}
