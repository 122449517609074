import 'reflect-metadata';

import { App } from './app/App';
import { createRoot } from 'react-dom/client';

// Load plugins
import './app/plugins/i18n';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(<App />);
