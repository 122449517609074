import { Type } from 'class-transformer';
import { IsDateString, IsOptional, IsString, ValidateNested } from 'class-validator';
import { PriceListItemCreateRequest, PriceListItemUpdateRequest } from './price-list-item.request';

export class PriceListCreateRequest {
  /**
   * The related price list version id
   */
  @IsString()
  @IsOptional()
  public priceListVersionId?: string;

  /**
   * The related product id
   */
  @IsString()
  @IsOptional()
  public productId?: string;

  /**
   * The related attribute definition id if the item is not for the whole product
   */
  @IsString()
  @IsOptional()
  public attributeDefinitionId?: string;

  /**
   * Array of price list items for the price list
   */
  @ValidateNested({ each: true, context: { type: PriceListItemCreateRequest } })
  @Type(() => PriceListItemCreateRequest)
  public priceListItems?: PriceListItemCreateRequest[];
}

export class PriceListUpdateRequest {
  /**
   * CUID identifier of the price list
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The related price list version id
   */
  @IsString()
  @IsOptional()
  public priceListVersionId?: string;

  /**
   * The related product id
   */
  @IsString()
  @IsOptional()
  public productId?: string;

  /**
   * The related attribute definition id if the item is not for the whole product
   */
  @IsString()
  @IsOptional()
  public attributeDefinitionId?: string;

  /**
   * Array of price list items for the price list
   */
  @ValidateNested({ each: true, context: { type: PriceListItemUpdateRequest } })
  @Type(() => PriceListItemUpdateRequest)
  public priceListItems?: PriceListItemUpdateRequest[];
}

export class PriceListQueryRequest {
  /**
   * The requested date of price list version
   */
  @IsDateString()
  @IsOptional()
  public date?: Date;

  /**
   * The partner id of the price list version
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The version id of the price list version
   */
  @IsString()
  @IsOptional()
  public versionId?: string;
}
