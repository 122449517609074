import { Box, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    navbar: {
      height: '100vh',
      width: 220,
      overflow: 'auto',
      padding: theme.spacing.xs,
      backgroundColor: '#f7f7fa',
      boxShadow: '1px 0px 8px 0px rgba(0,0,0,0.3)',
      flexShrink: 0,
      zIndex: 0,
    },
    localNavigationItem: {
      transition: 'transform 300ms ease',
    },
  };
});

export function LocalNav({ children }) {
  const { classes } = useStyles();

  return (
    <Box className={classes.navbar}>
      <Box sx={{ minWidth: 200 }} className={classes.localNavigationItem}>
        {children}
      </Box>
    </Box>
  );
}
