import { GrForm, GrNumberInput, GrSelectInput, GrTextareaInput, MoneyFormat, useDtoForm } from '@gravity/frontend/ui';
import { FoldingDoorRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface FoldingDoorProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: FoldingDoorRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function FoldingDoor(props: FoldingDoorProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  const fdPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const productForm = useDtoForm([FoldingDoorRequest, FoldingDoorRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), fd_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  //Calculated values for pricing
  useEffect(() => {
    const width = Number(productForm.getValues('fd_width')) ?? 0;
    const height = productForm.getValues('fd_height') ?? 0;

    productForm.setValue('fd_area', (width * height) / 10000);
    productForm.setValue('fd_roundedArea', getRoundedArea(productForm.getValues('fd_area')));

    console.log('Terület', watch('fd_area'));
    console.log('Kerekített terület', watch('fd_roundedArea'));

    getCutPrice();
  }, [watch('fd_width'), watch('fd_height')]);

  const itemsByPiece = ['fd_cutPrice'];

  useEffect(() => {
    getColorPrice();
  }, [watch('fd_color')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('fd_width'), watch('fd_height')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let piecePrice = 0; // Prices by piece for the entire folding door
    Object.keys(productForm.getValues('priceList') as any).forEach((key) => {
      if (itemsByPiece.includes(key)) {
        piecePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else {
        areaPrice += (productForm.getValues('priceList') as any)[`${key}`];
      }
    });

    const area = watch('fd_roundedArea');
    areaPrice = areaPrice * area;
    productForm.setValue('price', areaPrice + piecePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1.6) {
      return 1.6;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getColorPrice() {
    let price = 0;
    switch (watch('fd_color')) {
      case 'Fehér':
        price = fdPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'fd_001')?.price ?? 0;
        break;
      default:
        price = fdPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'fd_001')?.price ?? 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ['fd_color']: price },
    });
  }
  function getCutPrice() {
    let price = 0;
    if (watch('fd_height') !== 202 && watch('fd_height') !== 220) {
      price =
        fdPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'fd_006_db')?.price ?? 0;
    }

    productForm.setValue('fd_cutPrice', price);
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ['fd_cutPrice']: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: FoldingDoorRequest) => {
        data.summary = 'Szélesség: ' + data.fd_width + ' cm, Magasság: ' + data.fd_height.toLocaleString() + ' cm';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="fd_width"
              label={getLabelFor('fd_width')}
              data={getConditionSublistsFor('fd_width')}
              description={getConditionDescriptionFor('fd_width')}
              disabled={getDisabledStateFor('fd_width')}
              onEmptyState={() => warningNotification(getLabelFor('fd_width'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="fd_height" label={getLabelFor('fd_height')} min={0} max={240} precision={2} controls />

            {getErrorMessageFor('fd_height') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('fd_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="fd_color"
              label={getLabelFor('fd_color')}
              data={getConditionSublistsFor('fd_color')}
              description={getConditionDescriptionFor('fd_color')}
              disabled={getDisabledStateFor('fd_color')}
              onEmptyState={() => warningNotification(getLabelFor('fd_color'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="fd_quantity" label={getLabelFor('fd_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
