import { Prisma } from '@prisma/client';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
} from 'class-validator';

export class MosquitoNetRequest {
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mn_width: number; // Szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mn_height: number; // Magasság

  @IsNumber()
  @IsNotEmpty()
  public mn_area: number; // Terület

  @IsNumber()
  @IsNotEmpty()
  public mn_roundedArea: number; // Kerekített terület

  @IsString()
  @IsNotEmpty()
  public mn_color: string; // Szín

  @IsString()
  @IsNotEmpty()
  public mn_type: string; // Típus

  @IsBoolean()
  @IsOptional()
  public mn_petNet?: boolean; // Kisállat háló

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.mn_type === 'Alu Isso fix' || o.mn_type === 'PVC Isso fix')
  public mn_reversibleStrap?: string; // Ráfordítható vaspánt

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mn_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.mn_color === 'RAL')
  public mn_ralCode?: string; // Ral kód

  @IsBoolean()
  @IsOptional()
  public mn_withoutAdditionalStraps?: boolean; // Plusz vaspánt nélkül

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
