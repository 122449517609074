import { Prisma } from '@prisma/client';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
} from 'class-validator';

export class AluminiumRollerBlindRequest {
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public arb_width: number; // Teljes szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public arb_height: number; // Teljes magasság

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  @ValidateIf((o) => o.arb_split === true)
  public arb_splitRightSideWidth?: number; // Jobb oldal szélessége

  @IsNumber()
  @IsNotEmpty()
  public arb_areaLeftSide: number; // Terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public arb_areaRightSide: number; // Terület (jobb)

  @IsNumber()
  @IsNotEmpty()
  public arb_roundedAreaLeft: number; // Kerekített terület (bal)

  @IsNumber()
  @IsNotEmpty()
  public arb_roundedAreaRight: number; // Kerekített terület (jobb)

  @IsBoolean()
  @IsOptional()
  public arb_split?: boolean; // Osztott

  @IsBoolean()
  @IsOptional()
  public arb_combinedRail?: boolean; // Kombi lefutó

  @IsBoolean()
  @IsOptional()
  public arb_builtInMosquitoNet?: boolean; // Beépített szúnyogháló

  @IsBoolean()
  @IsOptional()
  public arb_builtInMosquitoNetRight?: boolean; // Beépített szúnyogháló (jobb oldal)

  @IsString()
  @IsNotEmpty()
  public arb_operationType: string; // Működtetés

  @IsString()
  @IsNotEmpty()
  public arb_operationSide: string; // Kezelés

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.arb_operationType === 'Motor')
  public arb_motor?: string; // Motor

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.arb_operationType === 'Motor')
  public arb_motorType?: string; // Típusa

  @IsString()
  @IsOptional()
  public arb_remote_controller?: string; // Távirányító

  @IsBoolean()
  @IsOptional()
  public arb_upperOutlet?: boolean; // Felső kivezetés

  @IsString()
  @IsNotEmpty()
  public arb_caseSize: string; // Tok méret

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public arb_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  public arb_reedColor: string; // Lamella szín

  @IsString()
  @IsNotEmpty()
  public arb_closingSlatColor: string; // Záróléc színe

  @IsString()
  @IsNotEmpty()
  public arb_railColor: string; // Lefutó szín

  @IsString()
  @IsNotEmpty()
  public arb_caseColor: string; // Tok szín

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.arb_operationType !== 'Motor')
  public arb_internalFitmentColor?: string; // Belső szerelék színe

  @IsString()
  @IsNotEmpty()
  @ValidateIf(
    (o) =>
      o.arb_reedColor === 'RAL' ||
      o.arb_closingSlatColor === 'RAL' ||
      o.arb_railColor === 'RAL' ||
      o.arb_caseColor === 'RAL' ||
      o.arb_internalFitmentColor === 'RAL'
  )
  public arb_ralCode?: string; // Ral kód

  @IsBoolean()
  @IsOptional()
  public arb_withoutAccessories?: boolean; // Tartozékok nélkül

  @IsBoolean()
  @IsOptional()
  public arb_plasterable?: boolean; // Vakolható

  @IsBoolean()
  @IsOptional()
  public arb_wideFlangeCaseEnding?: boolean; // Széles peremes tokvéggel

  @IsBoolean()
  @IsOptional()
  public arb_top10SlatUnperforated?: boolean; // Felső 10 léc perforálatlan

  @IsBoolean()
  @IsOptional()
  public arb_withoutAutomation?: boolean; // Automata nélkül

  @IsBoolean()
  @IsOptional()
  public arb_withoutCoverButtons?: boolean; // Takarógomb nélkül

  @IsBoolean()
  @IsOptional()
  public arb_wihtoutSpring?: boolean; // Rugó nélkül

  @IsString()
  @IsNotEmpty()
  public arb_typeLeftSide: string; // Redőny típusa jobb oldal

  @IsString()
  @IsOptional()
  public arb_typeRightSide?: string; // Redőny típusa jobb oldal

  @IsString()
  @IsOptional()
  public arb_ral?: string; // Alap RAL szín (tok, lefutó, záróléc)

  @IsNumber()
  @IsOptional()
  public arb_rltp?: number; // RLTP (tartozék)

  @IsOptional()
  public arb_accessories?: string[]; // Redőny tartozékok

  @IsString()
  @IsOptional()
  public arb_coverButton?: string; // Takarógomb neve

  @IsNumber()
  @IsOptional()
  public arb_coverButtonQuantity?: number; // Takarógombok darabszáma

  @IsString()
  @IsOptional()
  public arb_automation?: string; // Automata neve

  @IsNumber()
  @IsOptional()
  public arb_automationQuantity?: number; // Automata darabszáma

  @IsString()
  @IsOptional()
  public arb_inlet?: string; // Bevezető neve

  @IsNumber()
  @IsOptional()
  public arb_inletQuantity?: number; // Bevezető darabszáma

  @IsString()
  @IsOptional()
  public arb_spring?: string; // Rugó neve

  @IsNumber()
  @IsOptional()
  public arb_springQuantity?: number; // Rugó darabszáma

  @IsNumber()
  @IsOptional()
  public arb_motorQuantity?: number; // Motor darabszáma

  @IsNumber()
  @IsOptional()
  public arb_remoteControllerQuantity?: number; // Távirányító darabszáma

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsString()
  @IsOptional()
  public invoiceName?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
