import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';
import { FilteredResponse, FilterQuery } from '@gravity/shared/dto';
import { useState } from 'react';

export function useTableQuery<Entity = unknown>(resource: string, isInitiallyEnabled = false) {
  const [queryParams, setQueryParams] = useState<FilterQuery<FilteredResponse<Entity>>>();
  const [isEnabled, setIsEnabled] = useState(isInitiallyEnabled);

  const useQueryReturn = useQuery<FilteredResponse<Entity>, unknown, FilteredResponse<Entity>>(
    [resource, queryParams],
    getMany<FilteredResponse<Entity>>(resource, queryParams),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    }
  );

  function handleTableChange(newQueryParams: FilterQuery<FilteredResponse<Entity>>) {
    setQueryParams(newQueryParams);
    setIsEnabled(true);
  }

  return { ...useQueryReturn, handleTableChange };
}
