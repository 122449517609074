import { AddressType } from '@prisma/client';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class PartnerAddressCreateRequest {
  /**
   * The name of the partner address
   */
  @IsString()
  public name?: string;

  /**
   * The type of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public type: AddressType;

  /**
   * The country of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public countryId: string;

  /**
   * The zip code of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public zipCode: string;

  /**
   * The city of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public city: string;

  /**
   * The address of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public address: string;

  /**
   * Internal notes of the partner address
   */
  @IsString()
  public notes?: string;

  /**
   * True if the partner has forklift
   */
  @IsBoolean()
  public hasForklift?: boolean;

  /**
   * True if the partner has high lift pallet jack
   */
  @IsBoolean()
  public hasHighLiftPalletJack?: boolean;

  /**
   * True if the partner has pallet jack
   */
  @IsBoolean()
  public hasPalletJack?: boolean;

  /**
   * True if the partner requests delivery
   */
  @IsBoolean()
  public withDelivery?: boolean;
}

export class PartnerAddressUpdateRequest {
  /**
   * CUID identifier of the partner address
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The name of the partner address
   */
  @IsString()
  public name?: string;

  /**
   * The type of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public type: AddressType;

  /**
   * The country of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public countryId: string;

  /**
   * The zip code of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public zipCode: string;

  /**
   * The city of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public city: string;

  /**
   * The address of the partner address
   */
  @IsString()
  @IsNotEmpty()
  public address: string;

  /**
   * Internal notes of the partner address
   */
  @IsString()
  public notes?: string;

  /**
   * True if the partner has forklift
   */
  @IsBoolean()
  public hasForklift?: boolean;

  /**
   * True if the partner has high lift pallet jack
   */
  @IsBoolean()
  public hasHighLiftPalletJack?: boolean;

  /**
   * True if the partner has pallet jack
   */
  @IsBoolean()
  public hasPalletJack?: boolean;

  /**
   * True if the partner requests delivery
   */
  @IsBoolean()
  public withDelivery?: boolean;
}
