import { ActionIcon, Button, Grid, Group, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  ProductCategoryCreateRequest,
  ProductCategoryResponse,
  ProductCategoryUpdateRequest,
} from '@gravity/shared/dto';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { GrForm, GrFormTitle, GrTextInput, useDtoForm } from '@gravity/frontend/ui';
import { axiosInstance } from '../../../plugins/axios';
import { AxiosError } from 'axios';
import { Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { Page } from '../../../components/Page';

export function ProductCategoriesFormPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const entity = useLoaderData() as ProductCategoryResponse | undefined;

  const productCategoryForm = useDtoForm(
    [ProductCategoryCreateRequest, ProductCategoryUpdateRequest],
    'entity.productCategories',
    {
      isEditForm: !!entity,
      defaultValues: entity ? { ...entity } : undefined,
      validators: {
        name: nameValidation,
      },
    }
  );

  const productCategoryMutation = useDtoMutation<ProductCategoryUpdateRequest | ProductCategoryCreateRequest>(
    'product-categories',
    {
      successNotification: true,
      invalidateQueries: [['product-categories']],
    }
  );

  async function handleSubmit(data: ProductCategoryCreateRequest | ProductCategoryUpdateRequest) {
    if (productCategoryForm.isCreateForm) {
      const productCategory = (await productCategoryMutation.create(data)) as ProductCategoryResponse;
      navigate(`/sales/product-categories/${productCategory.id}`);
      productCategoryForm.toggleReadonlyMode(productCategory);
    } else {
      await productCategoryMutation.update(data);
      productCategoryForm.toggleReadonlyMode(data);
    }
  }

  async function nameValidation(name: string) {
    if (productCategoryForm.isEditForm && name === entity?.name) {
      return;
    }

    const result = await axiosInstance.get(`product-categories/check-name?name=${name}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictProductCategory') {
      return { type: result.response?.data.message };
    }
  }

  return (
    <Page>
      <GrForm form={productCategoryForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/sales/product-categories">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="sales.productCategoriesForm" />
          </Group>

          <Group>
            {productCategoryForm.readonlyMode ? (
              <PermissionCheck hasAll={[Permission.product_read, Permission.product_update]}>
                <Button
                  onClick={() => productCategoryForm.toggleReadonlyMode()}
                  variant="light"
                  leftIcon={<IconPencil />}
                >
                  {t('common.button.edit')}
                </Button>
              </PermissionCheck>
            ) : (
              <>
                <PermissionCheck
                  hasAll={
                    productCategoryForm.isEditForm
                      ? [Permission.product_read, Permission.product_update]
                      : [Permission.product_create]
                  }
                >
                  <Button
                    loading={productCategoryForm.isSubmitting}
                    color="green"
                    type="submit"
                    variant="light"
                    leftIcon={<IconCheck />}
                  >
                    {t('common.button.save')}
                  </Button>
                </PermissionCheck>

                <Button
                  loading={productCategoryForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() =>
                    productCategoryForm.isCreateForm ? navigate(-1) : productCategoryForm.toggleReadonlyMode()
                  }
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {productCategoryForm.isEditForm && productCategoryForm.readonlyMode && (
              <PermissionCheck hasAll={[Permission.product_read, Permission.product_update, Permission.product_delete]}>
                <DeleteConfirmButton
                  loading={productCategoryForm.isSubmitting}
                  onDelete={async () => {
                    try {
                      await productCategoryMutation.delete(productCategoryForm.getValues('id' as never));
                      navigate('/sales/product-categories');
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </PermissionCheck>
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col xs={6}>
              <GrTextInput name="name" />
            </Grid.Col>
          </Grid>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
