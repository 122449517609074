import { OrderStatus } from '@prisma/client';
import { Type } from 'class-transformer';
import { IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { OrderItemCreateRequest, OrderItemUpdateRequest } from '../sales/order-item.request';

export class ResellerOrderUpdateRequest {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The id of customer of the order
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The invoice address of the order
   */
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsNotEmpty()
  public deadlineDate: Date;

  /**
   * The note of the order
   */
  @IsString()
  public note: string;
  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  public price: number;

  /**
   * The items of the order
   */
  @ValidateNested({ each: true, context: { type: OrderItemUpdateRequest } })
  @Type(() => OrderItemUpdateRequest)
  public orderItems: OrderItemUpdateRequest[];
}

export class ResellerOrderStatusUpdateRequest {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The status of the order
   */
  @IsString()
  @IsNotEmpty()
  public status?: OrderStatus;
}

export class ResellerOrderCreateRequest {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The id of customer of the order
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The invoice address of the order
   */
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsNotEmpty()
  public deadlineDate: Date;

  /**
   * The note of the order
   */
  @IsString()
  @IsOptional()
  public note?: string;
  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The number of the order by the seller
   */
  @IsString()
  @IsNotEmpty()
  public orderNumber: string;

  /**
   * The price of the order
   */
  @IsNumber()
  public price: number;

  /**
   * The items of the order
   */
  @IsOptional()
  @ValidateNested({ each: true, context: { type: OrderItemCreateRequest } })
  @Type(() => OrderItemCreateRequest)
  public orderItems?: OrderItemCreateRequest[];
}
