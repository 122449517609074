import { Navigate, useLocation } from 'react-router-dom';
import { ReactElement } from 'react';
import { useAuth } from '../stores/auth.store';

interface RouteHandlerProps {
  children: ReactElement | ReactElement[];
  authenticated?: boolean;
}

export function RouteGuard(props: RouteHandlerProps): ReactElement {
  // Handle auth guard functionality
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (auth.isReseller() && !location.pathname.includes('reseller')) {
    return <Navigate to="/reseller" state={{ from: location }} replace />;
  }

  // Render route
  return props.children as ReactElement;
}
