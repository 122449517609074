import { DashboardResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useDashboard() {
  const dasboard = useQuery(['dashboard'], getMany<DashboardResponse>('dashboard'));

  return dasboard.data;
}

export function useResellerDashboard() {
  const dasboard = useQuery(['reseller-dashboard'], getMany<DashboardResponse>('reseller-dashboard'));

  return dasboard.data;
}
