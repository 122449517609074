import { createStyles, Box, Text, Group, Paper, SimpleGrid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  stat: {
    paddingBottom: 5,
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },
}));

export function InfoCard(props) {
  const { classes } = useStyles();
  const { title, value, subStats } = props;

  return (
    <Paper withBorder p="md" radius="md">
      <Group position="apart">
        <div>
          <Text color="dimmed" transform="uppercase" weight={700} size="xs" className={classes.label}>
            {title}
          </Text>

          <Text weight={700} size="xl">
            {value}
          </Text>

          {subStats && (
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl">
              {subStats.map((stat, idx: number) => (
                <Box key={idx.toString() + stat.label} sx={{ borderBottomColor: stat.color }} className={classes.stat}>
                  <Text transform="uppercase" size="xs" color="dimmed" weight={700}>
                    {stat.title}
                  </Text>

                  <Group position="apart" align="flex-end" spacing={0}>
                    <Text weight={700}>{stat.value}</Text>
                  </Group>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </div>
      </Group>
    </Paper>
  );
}
