import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { IsSameAs } from '../../decorators/is-same-as.decorator';
import { PartnerUserUpdateRequest, ResellerPartnerUserCreateRequest } from '../core-data/partner-user.request';

export class UserCreateRequest {
  /**
   * The last name of the user
   */
  @IsString()
  public lastName: string;

  /**
   * The first name of the user
   */
  @IsString()
  public firstName: string;

  /**
   * The e-mail address of the user
   */
  @IsEmail()
  @IsNotEmpty()
  public email: string;

  /**
   * The plain password of the user
   */
  @IsString()
  @IsNotEmpty()
  public password: string;

  /**
   * The repeated plain password of the user
   */
  @IsString()
  @IsSameAs('password')
  @IsNotEmpty()
  public passwordRepeat: string;

  /**
   * Optional job position of the user
   */
  @IsString()
  public position?: string;

  /**
   * A fully qualified URL to the user's avatar picture
   */
  @IsString()
  @IsOptional()
  public avatar?: string;

  /**
   * ID array of the user roles to connect
   */
  @IsArray()
  @ValidateIf((o, e) => o !== '' && e !== '')
  @IsOptional()
  public roles?: { id: string }[];

  /**
   * Locked status of the user
   */
  @IsBoolean()
  @IsOptional()
  public locked?: boolean;

  /**
   * Reseller status of the user
   */
  @IsBoolean()
  @IsOptional()
  public isReseller?: boolean;

  /**
   * The connected partner and permission of the user
   */
  @ValidateNested({ context: { type: ResellerPartnerUserCreateRequest } })
  @Type(() => ResellerPartnerUserCreateRequest)
  @ValidateIf((o, e) => o !== '' && e !== '')
  @IsOptional()
  public partnerUser?: ResellerPartnerUserCreateRequest;
}

export class UserUpdateRequest {
  /**
   * The ID of the user
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The last name of the user
   */
  @IsString()
  @IsOptional()
  public lastName?: string;

  /**
   * The first name of the user
   */
  @IsString()
  @IsOptional()
  public firstName?: string;

  /**
   * Optional job position of the user
   */
  @IsString()
  @IsOptional()
  public position?: string;

  /**
   * ID array of the user roles to connect
   */
  @IsArray()
  @ValidateIf((o, e) => o !== '' && e !== '')
  public roles?: { id: string }[];

  /**
   * Locked status of the user
   */
  @IsBoolean()
  @IsOptional()
  public locked?: boolean;

  /**
   * The connected partner and permission of the user
   */
  @ValidateNested({ context: { type: PartnerUserUpdateRequest } })
  @Type(() => PartnerUserUpdateRequest)
  @IsOptional()
  public partnerUser?: PartnerUserUpdateRequest;
}
