import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { PartnerResponse } from '@gravity/shared/dto';
import { Box, Button, ScrollArea } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useTableQuery } from '../../../hooks/use-table-query.hook';

export function PartnersPage() {
  const { t } = useTranslation();

  const query = useTableQuery<PartnerResponse>('partners');

  const cols: EntityTableColumnDef<PartnerResponse>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'taxIdNumber' },
    { accessorKey: 'registrationNumber' },
    { accessorKey: 'centralPhoneNumber' },
    { accessorKey: 'centralEmail' },
    { accessorKey: 'isSupplier', type: EntityTableColumnType.Boolean },
    { accessorKey: 'isCustomer', type: EntityTableColumnType.Boolean },
    { accessorKey: 'isReseller', type: EntityTableColumnType.Boolean },
    { accessorKey: 'notes' },
  ];

  return (
    <Box p="md">
      <PageTitle title={t('coreData.partners.title')} subtitle={t('coreData.partners.subtitle')}>
        <PermissionCheck hasAll={[Permission.partner_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <PermissionCheck hasAll={[Permission.partner_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="partner"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Box>
  );
}
