import { UnitOfMeasurementReferenceType } from '@prisma/client';

export class UnitOfMeasurementResponse {
  /**
   * CUID identifier of the unit of measurement
   */
  public id: string;

  /**
   * The name of the unit of measurement
   */
  public name: string;

  /**
   * The sign of the unit of measurement
   */
  public sign?: string;

  /**
   * The category of the unit of measurement
   */
  public category?: string;

  /**
   * The type of the reference of the unit of measurement
   */
  public type?: UnitOfMeasurementReferenceType;

  /**
   * The conversion ratio of the unit of measurement
   */
  public convertionRatio?: number;
}
