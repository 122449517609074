import { Box, Button, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconPlus } from '@tabler/icons-react';
import { UserResponse } from '@gravity/shared/dto';
import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { PageTitle } from '../../../components/PageTitle';
import { Permission } from '@prisma/client';
import { useUserRoles } from '../../../hooks/use-user-roles.hook';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Link } from 'react-router-dom';
import { Page } from '../../../components/Page';

export function UsersPage() {
  const { t } = useTranslation();

  const query = useTableQuery<UserResponse>('users');

  const cols: EntityTableColumnDef<UserResponse>[] = [
    { accessorKey: 'avatar', type: EntityTableColumnType.Avatar, enableColumnFilter: false },
    { accessorKey: 'lastName' },
    { accessorKey: 'firstName' },
    { accessorKey: 'email' },
    {
      accessorKey: 'roles[].userRole.name',
      type: EntityTableColumnType.ObjectArray,
      enableSorting: false,
      filterOptions: useUserRoles().data?.map((r) => r.name),
    },
    { accessorKey: 'position' },
    { accessorKey: 'locked', type: EntityTableColumnType.Boolean },
    { accessorKey: 'isReseller', type: EntityTableColumnType.Boolean },
    { accessorKey: 'createdAt', type: EntityTableColumnType.Date },
  ];

  return (
    <Page>
      <PageTitle title={t('settings.users.title')} subtitle={t('settings.users.subtitle')}>
        <PermissionCheck hasAll={[Permission.user_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <PermissionCheck hasAll={[Permission.user_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="user"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Page>
  );
}
