import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class PriceListItemCreateRequest {
  /**
   * The label of the price list item
   */
  @IsString()
  @IsNotEmpty()
  public label: string;

  /**
   * The code of the price list item
   */
  @IsString()
  @IsNotEmpty()
  public code: string;

  /**
   * The code of the price list item
   */
  @IsString()
  @IsOptional()
  public packaging?: string;

  /**
   * The value of the price list item
   */
  @IsNumber()
  @IsNotEmpty()
  public price: number;
}

export class PriceListItemUpdateRequest {
  /**
   * CUID identifier of the price list item
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The label of the price list item
   */
  @IsString()
  @IsNotEmpty()
  public label: string;

  /**
   * The code of the price list item
   */
  @IsString()
  @IsNotEmpty()
  public code: string;

  /**
   * The code of the price list item
   */
  @IsString()
  @IsOptional()
  public packaging?: string;

  /**
   * The value of the price list item
   */
  @IsNumber()
  @IsNotEmpty()
  public price: number;
}
