import { Type } from 'class-transformer';
import { IsDateString, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { PartnerResponse } from '../../core-data/partner.response';
import { OrderItemUpdateFormDto } from '../../sales/forms/order-forms.dto';

export class ResellerOrderUpdateFormDto {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The id of customer of the order
   */
  @IsObject()
  @IsNotEmpty()
  public partner: PartnerResponse;

  /**
   * The invoice address of the order
   */
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsNotEmpty()
  public deadlineDate: Date;

  /**
   * The note of the order
   */
  @IsString()
  public note: string;
  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  public price: number;

  /**
   * The items of the order
   */
  @ValidateNested({ each: true, context: { type: OrderItemUpdateFormDto } })
  @Type(() => OrderItemUpdateFormDto)
  public orderItems: OrderItemUpdateFormDto[];
}
