import { LoginResponse } from '@gravity/shared/dto';
import { AxiosError } from 'axios';
import { Outlet, redirect, RouteObject } from 'react-router-dom';
import { ContentLayout } from '../../layouts/ContentLayout';
import { AppLayout } from '../../layouts/AppLayout';
import { BasicLayout } from '../../layouts/BasicLayout';
import { axiosInstance } from '../../plugins/axios';
import { useAuth } from '../../stores/auth.store';
import { createGuardedLoader, createLoader } from '../../utils';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { RequestPasswordReset } from './pages/RequestPasswordReset';
import { ResetPassword } from './pages/ResetPassword';
import { UserProfile } from './pages/UserProfile';

export const coreRoutes: RouteObject[] = [
  {
    element: <BasicLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
        loader: createLoader(() => {
          // Redirect if the user is already logged-in
          if (useAuth.getState().isAuthenticated()) {
            return redirect('/');
          }
        }),
        action: async ({ request }) => {
          const data = Object.fromEntries(await request.formData());
          const response = await axiosInstance.post<LoginResponse>('auth/login', data);

          if (response instanceof AxiosError) {
            return response.response?.data;
          }

          useAuth.getState().login(response.data);
          return null;
        },
      },
      { path: 'request-password-reset', element: <RequestPasswordReset /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        element: (
          <ContentLayout>
            <Outlet />
          </ContentLayout>
        ),
        loader: createGuardedLoader(),
        children: [
          { path: '/', element: <Dashboard /> },
          { path: 'profile', element: <UserProfile /> },
        ],
      },
    ],
  },
];
