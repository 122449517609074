import { PriceListVersionResponse } from '../pricing/price-list-version.response';
import { PartnerAddressResponse } from './partner-address.response';
import { PartnerBankAccountResponse } from './partner-bank-account.response';
import { PartnerContactResponse } from './partner-contact.response';
import { PartnerUserResponse } from './partner-user.response';

export class PartnerResponse {
  /**
   * CUID identifier of the partner
   */
  public id: string;

  /**
   * The name of the partner
   */
  public name: string;

  /**
   * The tax id number of the partner
   */
  public taxIdNumber: string;

  /**
   * The registration number of the partner
   */
  public registrationNumber?: string;

  /**
   * The central phone number of the partner
   */
  public centralPhoneNumber?: string;

  /**
   * The central e-mail address of the partner
   */
  public centralEmail?: string;

  /**
   * The website of the partner
   */
  public website?: string;

  /**
   * The company logo of the partner
   */
  public companyLogo?: string;

  /**
   * Internal notes of the partner
   */
  public notes?: string;

  /**
   * True if the partner is supplier
   */
  public isSupplier: boolean;

  /**
   * True if the partner is customer
   */
  public isCustomer: boolean;

  /**
   * True if the partner is reseller
   */
  public isReseller: boolean;

  /**
   * The addresses of the partner
   */
  public addresses?: PartnerAddressResponse[];

  /**
   * The contacts of the partner
   */
  public contacts?: PartnerContactResponse[];

  /**
   * The bank accounts of the partner
   */
  public bankAccounts?: PartnerBankAccountResponse[];

  /**
   * The connected users of the partner
   */
  public users?: PartnerUserResponse[];

  /**
   * The connected price list versions of the partner
   */
  public priceListVersions?: PriceListVersionResponse[];
}
