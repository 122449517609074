import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';
import { ConfigResponse } from '@gravity/shared/dto';

export function useAppConfig() {
  const result = useQuery(['config'], getMany<ConfigResponse>('config'), {
    staleTime: Infinity,
  });

  return result.data;
}
