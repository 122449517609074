import { GrForm, GrFormTitle, GrMultiSelectInput, useDtoForm } from '@gravity/frontend/ui';
import { AppSettingsRequest } from '@gravity/shared/dto';
import { Button, Grid, Group, LoadingOverlay, ScrollArea } from '@mantine/core';
import { IconCheck, IconPencil, IconX } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page';
import { useAppSettings } from '../../../hooks/use-app-settings.hook';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Permission } from '@prisma/client';
import { usePermission } from '../../../hooks/use-permission.hook';
import _ from 'lodash';

export function AppSettingsPage() {
  const { t } = useTranslation();
  const key = 'centralEmail';
  const { hasAnyPermission } = usePermission();
  const { appSetting, isFetching } = hasAnyPermission(['appAdmin'])
    ? useAppSettings(key)
    : { appSetting: undefined, isFetching: false };

  const appSettingsForm = useDtoForm([AppSettingsRequest, AppSettingsRequest], 'entity.appSettings', {
    isEditForm: true,
    defaultValues: appSetting ? appSetting : { key, value: '', values: [] },
  });

  useEffect(() => {
    appSettingsForm.reset(appSetting);
  }, [appSetting?.key]);

  const appSettingMutation = useDtoMutation('app-settings', {
    invalidateQueries: [['app-settings']],
  });

  const handleSubmit = async (data: AppSettingsRequest) => {
    const clone = _.clone(data);
    clone.value = data.values?.join(',') ?? '';
    delete clone.values;

    await appSettingMutation.create(clone);
    appSettingsForm.toggleReadonlyMode(data);
  };

  return (
    <Page>
      {isFetching ? (
        <LoadingOverlay
          visible={true}
          overlayOpacity={0.6}
          overlayBlur={2}
          loaderProps={{ color: 'dark' }}
          transitionDuration={300}
          zIndex={150}
        />
      ) : (
        <GrForm form={appSettingsForm} onSubmit={handleSubmit}>
          <Group position="apart" mb="md">
            <Group>
              <GrFormTitle i18nPrefix="settings.appSettings" />
            </Group>

            <PermissionCheck hasAll={[Permission.appAdmin]}>
              <Group>
                {appSettingsForm.readonlyMode ? (
                  <Button
                    onClick={() => {
                      appSettingsForm.toggleReadonlyMode();
                    }}
                    variant="light"
                    leftIcon={<IconPencil />}
                  >
                    {t('common.button.edit')}
                  </Button>
                ) : (
                  <>
                    <Button loading={appSettingsForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                      {t('common.button.save')}
                    </Button>

                    <Button
                      loading={appSettingsForm.isSubmitting}
                      type="button"
                      color="gray"
                      variant="light"
                      leftIcon={<IconX />}
                      onClick={() => appSettingsForm.toggleReadonlyMode()}
                    >
                      {t('common.button.cancel')}
                    </Button>
                  </>
                )}
              </Group>
            </PermissionCheck>
          </Group>

          <PermissionCheck hasAll={[Permission.appAdmin]}>
            <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
              <Grid m={0}>
                <Grid.Col xs={8}>
                  <GrMultiSelectInput
                    data={appSetting?.values}
                    name="values"
                    label={t('entity.appSettings.centralEmail')}
                    creatable
                    simpleValue
                  />
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </PermissionCheck>
        </GrForm>
      )}
    </Page>
  );
}
