import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class PartnerBankAccountCreateRequest {
  /**
   * True if the partner bank account is foreign
   */
  @IsBoolean()
  @IsNotEmpty()
  public isForeignBankAccount: boolean;

  /**
   * The account number of the partner bank account
   */
  @IsString()
  @IsNotEmpty()
  public accountNumber: string;

  /**
   * The accounting bank name of the partner bank account
   */
  @IsString()
  @IsNotEmpty()
  public accountingBankName: string;

  /**
   * The iban number of the partner bank account
   */
  @IsString()
  public ibanNumber?: string;

  /**
   * The swift code of the partner bank account
   */
  @IsString()
  public swiftCode?: string;

  /**
   * Internal notes of the partner bank account
   */
  @IsString()
  public notes?: string;
}

export class PartnerBankAccountUpdateRequest {
  /**
   * CUID identifier of the partner bank account
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * True if the partner bank account is foreign
   */
  @IsBoolean()
  @IsNotEmpty()
  public isForeignBankAccount: boolean;

  /**
   * The account number of the partner bank account
   */
  @IsString()
  @IsNotEmpty()
  public accountNumber: string;

  /**
   * The accounting bank name of the partner bank account
   */
  @IsString()
  @IsNotEmpty()
  public accountingBankName: string;

  /**
   * The iban number of the partner bank account
   */
  @IsString()
  public ibanNumber?: string;

  /**
   * The swift code of the partner bank account
   */
  @IsString()
  public swiftCode?: string;

  /**
   * Internal notes of the partner bank account
   */
  @IsString()
  public notes?: string;
}
