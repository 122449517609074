import {
  GrForm,
  GrFormTab,
  GrFormTabs,
  GrFormTitle,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  useDtoForm,
} from '@gravity/frontend/ui';
import {
  ProductAttributeUpdateRequest,
  ProductCreateRequest,
  ProductResponse,
  ProductUpdateRequest,
} from '@gravity/shared/dto';
import { ActionIcon, Button, Grid, Group, Paper, ScrollArea, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Permission } from '@prisma/client';
import {
  IconCheck,
  IconChevronLeft,
  IconListCheck,
  IconPencil,
  IconRowInsertTop,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { useProductAttributeDefinitions } from '../../../hooks/use-product-attribute-definition.hook';
import { useProductCategories } from '../../../hooks/use-product-category.hook';
import { useUnitsOfMeasurement } from '../../../hooks/use-unit-of-measurement.hook';
import { axiosInstance } from '../../../plugins/axios';
import { ConditionComponent } from '../components/ConditionComponent';

export function ProductFormPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const entity = useLoaderData() as ProductResponse | undefined;

  const fullScreenDrawer = useMediaQuery('(max-width: 1000px)');
  const halfScreenDrawer = useMediaQuery('(min-width: 1500px)');

  const unitsOfMeasurement = useUnitsOfMeasurement();
  const productCategories = useProductCategories();
  const productAttributeDefinitions = useProductAttributeDefinitions()?.map((attributeDefinition) => ({
    attributeDefinitionId: attributeDefinition.id,
    label: attributeDefinition.name + ' (' + attributeDefinition.variableName + ')',
  }));

  const productForm = useDtoForm([ProductCreateRequest, ProductUpdateRequest], 'entity.product', {
    isEditForm: !!entity,
    defaultValues: entity ? entity : undefined,
    validators: {
      name: nameValidation,
    },
  });

  async function nameValidation(name: string) {
    if (productForm.isEditForm && name === entity?.name) {
      return;
    }

    const result = await axiosInstance.get(`products/check-name?name=${name}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictProduct') {
      return { type: result.response?.data.message };
    }
  }

  const productMutation = useDtoMutation('products', {
    invalidateQueries: [
      ['products'],
      ['product-categories', 'list-product-categories'],
      ['units-of-measurement', 'list-units-of-measurement'],
      ['product-attribute-definitions', 'list-product-attribute-definitions'],
    ],
  });

  const { control, watch } = productForm;
  const {
    fields: productAttributes,
    append,
    remove,
  } = useFieldArray({ name: 'productAttributes', control, keyName: 'attributeId' });

  const newProductAttribute = (): ProductAttributeUpdateRequest => {
    return {
      attributeDefinitionId: '',
      conditions: [],
      description: '',
      productId: '',
    };
  };

  const handleSubmit = async (data: ProductCreateRequest | ProductUpdateRequest) => {
    data.productAttributes?.forEach((attribute) => {
      delete attribute['attributeDefinition'];
      attribute.conditions?.forEach((condition) => {
        delete condition['attributeProductId'];
        delete condition['attributeDefinitionId'];
      });
    });

    if (productForm.isCreateForm) {
      const product = (await productMutation.create(data)) as ProductResponse;
      navigate(`/sales/products/${product.id}`);
      productForm.toggleReadonlyMode(product);
    } else {
      await productMutation.update(data);
      productForm.toggleReadonlyMode(data);
    }
  };

  return (
    <Page>
      <GrForm form={productForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/sales/products">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="sales.productForm" />
          </Group>

          <Group>
            {productForm.readonlyMode ? (
              <PermissionCheck hasAll={[Permission.product_read, Permission.product_update]}>
                <Button onClick={() => productForm.toggleReadonlyMode()} variant="light" leftIcon={<IconPencil />}>
                  {t('common.button.edit')}
                </Button>
              </PermissionCheck>
            ) : (
              <>
                <PermissionCheck
                  hasAll={
                    productForm.isEditForm
                      ? [Permission.product_read, Permission.product_update]
                      : [Permission.product_create]
                  }
                >
                  <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                    {t('common.button.save')}
                  </Button>
                </PermissionCheck>

                <Button
                  loading={productForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() => (productForm.isCreateForm ? navigate(-1) : productForm.toggleReadonlyMode())}
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {productForm.isEditForm && productForm.readonlyMode && (
              <PermissionCheck hasAll={[Permission.product_read, Permission.product_update, Permission.product_delete]}>
                <DeleteConfirmButton
                  loading={productForm.isSubmitting}
                  onDelete={async () => {
                    try {
                      await productMutation.delete(productForm.getValues('id' as never));
                      navigate('/sales/products');
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </PermissionCheck>
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid m={0}>
            <Grid.Col xs={4}>
              <GrTextInput name="name" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSelectInput data={productCategories} name="category" labelField="name" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSelectInput data={unitsOfMeasurement} name="unitOfMeasurement" labelField="name" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="isFinalProduct" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="canBeOrdered" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="isAvailableForReseller" />
            </Grid.Col>

            <Grid.Col>
              <GrTextareaInput name="description" />
            </Grid.Col>

            <GrFormTabs
              tabs={[
                {
                  icon: <IconListCheck />,
                  name: 'productAttributes',
                  label: 'entity.product.productAttributeDetails',
                  badge: productAttributes.length,
                },
              ]}
            >
              <GrFormTab name="productAttributes">
                {productAttributes.map((attribute, index) => {
                  return (
                    <Paper
                      key={attribute.attributeId}
                      withBorder
                      mb="xs"
                      p="md"
                      sx={{
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        minWidth: fullScreenDrawer ? '91vw' : halfScreenDrawer ? '44vw' : '62vw',
                      }}
                    >
                      {!productForm.readonlyMode && (
                        <Group position="right">
                          <ActionIcon onClick={() => remove(index)} color="red" size="md" variant="light">
                            <IconTrash size={18} />
                          </ActionIcon>
                        </Group>
                      )}

                      <Grid>
                        <Grid.Col>
                          <GrSelectInput
                            data={productAttributeDefinitions}
                            name={`productAttributes.${index}.attributeDefinitionId`}
                            selectedData={watch('productAttributes')}
                            valueField="attributeDefinitionId"
                            onChange={() => productForm.setValue(`productAttributes.${index}.conditions`, [])}
                            simpleValue
                          />
                        </Grid.Col>

                        <Grid.Col>
                          <GrTextareaInput name={`productAttributes.${index}.description`} />
                        </Grid.Col>

                        <Grid.Col>
                          {watch('productAttributes') && (
                            <ConditionComponent
                              parentForm={productForm}
                              parent="productAttributes"
                              index={index}
                              control={control}
                            />
                          )}
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  );
                })}

                {productForm.readonlyMode && productAttributes.length === 0 && (
                  <Text>{t('common.message.noData')}</Text>
                )}

                {!productForm.readonlyMode &&
                  productAttributes.length <
                    (productAttributeDefinitions ? productAttributeDefinitions?.length : 1) && (
                    <Button
                      leftIcon={<IconRowInsertTop />}
                      variant="subtle"
                      mt="md"
                      compact
                      onClick={() => append(newProductAttribute())}
                    >
                      {t('common.button.addRow')}
                    </Button>
                  )}
              </GrFormTab>
            </GrFormTabs>
          </Grid>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
