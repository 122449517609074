import { IsNotEmpty, IsString } from 'class-validator';

export class CheckOrderNumberRequest {
  /**
   * Id of the order given by the company
   */
  @IsString()
  @IsNotEmpty()
  public orderNumber: string;
}
