import { Box, Flex, Grid } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconAddressBook, IconNews, IconRulerMeasure } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { LocalNavLink } from '../../../components/LocalNav/components/LocalNavLink';
import { LocalNavTitle } from '../../../components/LocalNav/components/LocalNavTitle';
import { LocalNav } from '../../../components/LocalNav/LocalNav';
import { usePermission } from '../../../hooks/use-permission.hook';
import { ContentLayout } from '../../../layouts/ContentLayout';

export function CoreDataLayout() {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();

  return (
    <Flex>
      <LocalNav>
        <LocalNavTitle>{t('coreData.menu.title')}</LocalNavTitle>

        <LocalNavLink
          to="/core-data/partners"
          icon={IconAddressBook}
          label={t('coreData.menu.partners')}
          disabled={
            !hasAnyPermission([
              Permission.partner_read,
              Permission.partner_create,
              Permission.partner_update,
              Permission.partner_delete,
            ])
          }
        />

        <LocalNavLink
          to="/core-data/units-of-measurement"
          icon={IconRulerMeasure}
          label={t('coreData.menu.unitOfMeasurement')}
        />

        <LocalNavLink
          to="/core-data/news-posts"
          icon={IconNews}
          label={t('coreData.menu.news')}
          disabled={
            !hasAnyPermission([
              Permission.news_post_create,
              Permission.news_post_delete,
              Permission.news_post_read,
              Permission.news_post_update,
            ])
          }
        />
      </LocalNav>

      <ContentLayout localNav>
        <Outlet />
      </ContentLayout>
    </Flex>
  );
}
