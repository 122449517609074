import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import huI18n from '../i18n/hu.json';

void i18n.use(initReactI18next).init({
  fallbackLng: 'hu',
  nsSeparator: '.',
  keySeparator: '.',
  ns: ['common', 'component', 'coreData', 'sales', 'entity', 'pricing', 'settings', 'dashboard'],
  interpolation: {
    escapeValue: false,
  },
  resources: {
    hu: huI18n,
  },
});

export const i18nInstance = i18n;
