import { Permission } from '@prisma/client';
import { ReactElement } from 'react';
import { usePermission } from '../../hooks/use-permission.hook';

interface PermissionProps {
  hasAny?: Permission[];
  hasAll?: Permission[];
  children: ReactElement;
}

export function PermissionCheck({ hasAny, hasAll, children }: PermissionProps) {
  const { hasAnyPermission, hasAllPermission } = usePermission();

  if ((hasAny && hasAnyPermission(hasAny)) || (hasAll && hasAllPermission(hasAll))) {
    return children;
  } else {
    return null;
  }
}
