import { PartnerUserResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getOne } from '../plugins/axios';

export function useCurrentPartner() {
  const result = useQuery(['partner-profile'], getOne<PartnerUserResponse>('partner-profile', ''), {
    staleTime: Infinity,
  });

  return {
    user: result.data?.user,
    partner: result.data?.partner,
    permission: result.data?.permission,
    isFetching: result.isFetching,
  };
}
