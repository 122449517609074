import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { usePrevious } from '@mantine/hooks';
import { Box } from '@mantine/core';

export function ContentLayout({ children, localNav }: { children: any; localNav?: boolean }) {
  const { pathname } = useLocation();

  const pathLength = pathname.split('/').length;
  const previousPathLength = usePrevious(pathLength);

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    initialFw: {
      opacity: 0,
      x: 20,
    },
    initialBw: {
      opacity: 0,
      x: -20,
    },
    in: {
      opacity: 1,
      x: 0,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  let direction = 'initial';
  if (previousPathLength) {
    if (previousPathLength > pathLength) {
      direction = 'initialBw';
    } else if (previousPathLength < pathLength) {
      direction = 'initialFw';
    }
  }

  return (
    <Box sx={{ width: `calc(100vw - ${localNav ? 270 : 50}px)` }}>
      <motion.div key={pathname} initial={direction} animate="in" variants={pageVariants} transition={pageTransition}>
        {children}
      </motion.div>
    </Box>
  );
}
