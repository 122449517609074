import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { ProductResponse } from '@gravity/shared/dto';
import { Box, Button, ScrollArea } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page } from '../../../components/Page';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useProductAttributeDefinitions } from '../../../hooks/use-product-attribute-definition.hook';
import { useProductCategories } from '../../../hooks/use-product-category.hook';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { useUnitsOfMeasurement } from '../../../hooks/use-unit-of-measurement.hook';
import { usePermission } from '../../../hooks/use-permission.hook';

export function ProductsPage() {
  const { t } = useTranslation();
  const query = useTableQuery<ProductResponse>('products');
  const { hasAnyPermission } = usePermission();
  const hasProductPermission = hasAnyPermission(['product_read', 'product_create', 'product_update', 'product_delete']);

  const cols: EntityTableColumnDef<ProductResponse>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'description' },
    { accessorKey: 'isFinalProduct', type: EntityTableColumnType.Boolean },
    { accessorKey: 'canBeOrdered', type: EntityTableColumnType.Boolean },
    { accessorKey: 'isAvailableForReseller', type: EntityTableColumnType.Boolean },
    {
      accessorKey: 'category[].name',
      filterOptions: hasProductPermission ? useProductCategories()?.map((c) => c.name) : [],
      type: EntityTableColumnType.Object,
    },
    {
      accessorKey: 'unitOfMeasurement[].name',
      filterOptions: hasProductPermission ? useUnitsOfMeasurement()?.map((u) => u.name) : [],
      type: EntityTableColumnType.Object,
    },
    {
      accessorKey: 'productAttributes[].attributeDefinition.name',
      i18n: 'entity.product.productAttributeDetails',
      type: EntityTableColumnType.ObjectArray,
      filterOptions: hasProductPermission
        ? useProductAttributeDefinitions()?.map((d) => ({
            value: d.name,
            label: d.name,
          }))
        : [],
      enableSorting: false,
    },
  ];

  return (
    <Page>
      <PageTitle title={t('sales.products.title')} subtitle={t('sales.products.subtitle')}>
        <PermissionCheck hasAll={[Permission.product_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 90px)' }}>
        <PermissionCheck hasAll={[Permission.product_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="product"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Page>
  );
}
