import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { PlisseRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface PlisseProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: PlisseRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function Plisse(props: PlisseProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const pPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  const productForm = useDtoForm([PlisseRequest, PlisseRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), p_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  const hasRal = productForm.getValues('p_color') === 'RAL';

  //Calculated values for pricing
  useEffect(() => {
    let width = productForm.getValues('p_width') ?? 0;
    const height = productForm.getValues('p_height') ?? 0;

    if (productForm.getValues('p_operationType') === 'Kétszárnyú') {
      width /= 2;
    } else if (productForm.getValues('p_operationType') === 'Háromszárnyú') {
      width /= 3;
    }

    productForm.setValue('p_area', (width * height) / 10000);
    productForm.setValue('p_roundedArea', getRoundedArea(productForm.getValues('p_area')));

    console.log('Terület egy szárnyra', watch('p_area'));
    console.log('Kerekített terület', watch('p_roundedArea'));
  }, [watch('p_width'), watch('p_height'), watch('p_operationType')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('p_roundedArea'), watch('priceList'), watch('p_operationType')]);

  useEffect(() => {
    getColorPrice();
  }, [watch('p_color'), watch('p_type')]);

  useEffect(() => {
    if (
      (watch('p_type') !== 'Almarde Mini Plissé' && watch('p_type') !== 'Almarde 26 Plissé') ||
      watch('p_operationType') !== 'Egyszárnyú'
    ) {
      productForm.setValue('p_collectingDirection', undefined);
    }
  }, [watch('p_type'), watch('p_operationType')]);

  useEffect(() => {
    if (watch('p_type') !== 'Almarde Mini Plissé') {
      productForm.setValue('p_lowerRailSize', undefined);
    }
  }, [watch('p_type')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let piecePrice = 0; // Prices by piece for the entire aluminium roller blind

    const width =
      watch('p_operationType') === 'Kétszárnyú'
        ? watch('p_width') / 2
        : watch('p_operationType') === 'Háromszárnyú'
        ? watch('p_width') / 3
        : watch('p_width');

    if (watch('p_type') === 'Maxi Plissé') {
      if (watch('p_height') <= 225) {
        if (width <= 100) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_001_db')?.price ?? 0;
        } else if (width <= 125) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_002_db')?.price ?? 0;
        } else if (width <= 160) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_003_db')?.price ?? 0;
        }
      } else if (watch('p_height') <= 260) {
        if (width <= 100) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_004_db')?.price ?? 0;
        } else if (width <= 125) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_005_db')?.price ?? 0;
        } else if (width <= 160) {
          piecePrice +=
            pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mpl_006_db')?.price ?? 0;
        }
      }
    }
    const colorPrice = (productForm.getValues('priceList') as any)['type_color'] ?? 0;
    if (watch('p_type') === 'Maxi Plissé') {
      piecePrice *= colorPrice;
    } else {
      areaPrice += colorPrice;
    }

    if (productForm.getValues('p_operationType') === 'Kétszárnyú') {
      piecePrice *= 2;
    } else if (productForm.getValues('p_operationType') === 'Háromszárnyú') {
      piecePrice *= 3;
    }

    const area = watch('p_roundedArea');
    areaPrice = areaPrice * area;
    productForm.setValue('price', areaPrice + piecePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    const type = productForm.getValues('p_type');
    if ((type === 'Almarde Mini Plissé' || type === 'Almarde 26 Plissé') && roundedArea > 0 && roundedArea < 2) {
      if (productForm.getValues('p_operationType') === 'Kétszárnyú') {
        return 4;
      } else if (productForm.getValues('p_operationType') === 'Háromszárnyú') {
        return 6;
      }
      return 2;
    } else if (roundedArea > 0 && roundedArea < 1) {
      if (productForm.getValues('p_operationType') === 'Kétszárnyú') {
        return 2;
      } else if (productForm.getValues('p_operationType') === 'Háromszárnyú') {
        return 3;
      }
      return 1;
    }
    if (productForm.getValues('p_operationType') === 'Kétszárnyú') {
      return Number(roundedArea.toFixed(2)) * 2;
    } else if (productForm.getValues('p_operationType') === 'Háromszárnyú') {
      return Number(roundedArea.toFixed(2)) * 3;
    }
    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getColorPrice() {
    let price = 0;
    switch (watch('p_type')) {
      case 'Almarde Mini Plissé':
        switch (watch('p_color')) {
          case 'Fehér':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ampl_001')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ampl_002')?.price ?? 0;
            break;
          case 'RAL':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ampl_004')?.price ?? 0;
            break;
          default:
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'ampl_003')?.price ?? 0;
            break;
        }
        break;
      case 'Takmatic Plissé':
        switch (watch('p_color')) {
          case 'Fehér':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'tpl_001')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'tpl_002')?.price ?? 0;
            break;
          case 'RAL':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'tpl_004')?.price ?? 0;
            break;
          default:
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'tpl_003')?.price ?? 0;
            break;
        }
        break;
      case 'Almarde 26 Plissé':
        switch (watch('p_color')) {
          case 'Fehér':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'a26pl_001')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'a26pl_002')?.price ?? 0;
            break;
          case 'RAL':
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'a26pl_004')?.price ?? 0;
            break;
          default:
            price =
              pPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'a26pl_003')?.price ?? 0;
            break;
        }
        break;
      case 'Maxi Plissé':
        switch (watch('p_color')) {
          case 'Fehér':
          case 'Barna':
          case 'Antracit':
            price = 1;
            break;
          case 'RAL':
            price = 1.4;
            break;
          default:
            price = 1.3;
            break;
        }
        break;
      default:
        price = 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ type_color: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: PlisseRequest) => {
        if (!hasRal) {
          await delete data.p_ralCode;
        }
        data.summary =
          'Szélesség: ' + data.p_width.toLocaleString() + ' cm, Magasság: ' + data.p_height.toLocaleString() + ' cm';
        data.summary += ', Típus: ' + data.p_type;
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="p_width" label={getLabelFor('p_width')} min={0} precision={2} controls />

            {getErrorMessageFor('p_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('p_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="p_height" label={getLabelFor('p_height')} min={0} precision={2} controls />

            {getErrorMessageFor('p_height') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('p_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="p_type"
              label={getLabelFor('p_type')}
              data={getConditionSublistsFor('p_type')}
              disabled={getDisabledStateFor('p_type')}
              onEmptyState={() => warningNotification(getLabelFor('p_type'))}
              emptyOnStateChange
              simpleValue
            />

            {getErrorMessageFor('p_type') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('p_type')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="p_operationType"
              label={getLabelFor('p_operationType')}
              data={getConditionSublistsFor('p_operationType')}
              description={getConditionDescriptionFor('p_operationType')}
              disabled={getDisabledStateFor('p_operationType')}
              onEmptyState={() => warningNotification(getLabelFor('p_operationType'))}
              emptyOnStateChange
              simpleValue
            />

            {!orderReadonlyMode && (
              <Text c="dimmed" fz="xs">
                {getDescriptionFor('p_operationType')}
              </Text>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="p_color"
              label={getLabelFor('p_color')}
              data={getConditionSublistsFor('p_color')}
              disabled={getDisabledStateFor('p_color')}
              onEmptyState={() => warningNotification(getLabelFor('p_color'))}
              emptyOnStateChange
              simpleValue
            />

            {getErrorMessageFor('p_color') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('p_color')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          {(watch('p_type') === 'Almarde Mini Plissé' || watch('p_type') === 'Almarde 26 Plissé') &&
            watch('p_operationType') === 'Egyszárnyú' && (
              <Grid.Col xs={4}>
                <GrSelectInput
                  name="p_collectingDirection"
                  label={getLabelFor('p_collectingDirection')}
                  data={getConditionSublistsFor('p_collectingDirection')}
                  description={getConditionDescriptionFor('p_collectingDirection')}
                  disabled={getDisabledStateFor('p_collectingDirection')}
                  onEmptyState={() => warningNotification(getLabelFor('p_collectingDirection'))}
                  emptyOnStateChange
                  simpleValue
                />
              </Grid.Col>
            )}

          {watch('p_type') === 'Almarde Mini Plissé' && (
            <Grid.Col xs={4}>
              <GrSelectInput
                name="p_lowerRailSize"
                label={getLabelFor('p_lowerRailSize')}
                data={getConditionSublistsFor('p_lowerRailSize')}
                description={getConditionDescriptionFor('p_lowerRailSize')}
                disabled={getDisabledStateFor('p_lowerRailSize')}
                onEmptyState={() => warningNotification(getLabelFor('p_lowerRailSize'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          {hasRal && (
            <Grid.Col xs={2}>
              <GrTextInput name="p_ralCode" label={getLabelFor('p_ralCode')} mask="9999" />

              {!orderReadonlyMode && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {'(4 számjegy)'}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="p_quantity" label={getLabelFor('p_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
