import { Text } from '@mantine/core';
import React from 'react';

export interface MoneyFormatProps {
  noSign?: boolean;
  children?: string | number;
  currency?: string; // JsonLogic string
  locales?: string;
  type?: string; // decimal or currency
  minimumFractionDigits?: number;
  size?: string;
}

export function MoneyFormat(props: MoneyFormatProps) {
  const { children, currency, locales, noSign, type, minimumFractionDigits, size } = props;

  const formatter = new Intl.NumberFormat(
    locales || 'en-GB',
    noSign
      ? {}
      : {
          style: type || 'currency',
          currency: type !== 'decimal' ? currency || 'EUR' : undefined,
          minimumFractionDigits: minimumFractionDigits || 0,
          maximumFractionDigits: 2,
        }
  );

  let value = 0;
  switch (typeof children) {
    case 'string':
      value = parseFloat(children);
      break;
    case 'number':
      value = children;
      break;
    case 'object':
      value = children[0];
      break;
    default:
      value = 0;
      break;
  }

  const formattedValue =
    type !== 'decimal' ? formatter.format(value) : `${formatter.format(value)}${currency ? ` ${currency}` : ''}`;

  return <Text size={size ? size : 'xs'}>{formattedValue}</Text>;
}
