import { UnitOfMeasurementReferenceType } from '@prisma/client';
import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class UnitOfMeasurementCreateRequest {
  /**
   * The name of the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The sign of the unit of measurement
   */
  @IsString()
  public sign: string;

  /**
   * The name of the role
   */
  @IsString()
  public category: string;

  /**
   * The provided reference type string for the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public type: UnitOfMeasurementReferenceType;

  /**
   * The conversion ratio for the unit of measurement
   */
  @IsNumber()
  @IsNotEmpty()
  public conversionRatio: number;
}

export class UnitOfMeasurementUpdateRequest {
  /**
   * The ID of the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The sign of the unit of measurement
   */
  @IsString()
  public sign: string;

  /**
   * The name of the role
   */
  @IsString()
  public category: string;

  /**
   * The provided reference type string for the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public type: UnitOfMeasurementReferenceType;

  /**
   * The conversion ratio for the unit of measurement
   */
  @IsNumber()
  @IsNotEmpty()
  public conversionRatio: number;
}
