import { IsString, IsNotEmpty } from 'class-validator';
import { PartnerUserResponse } from '../core-data/partner-user.response';
import { UserRoleResponse } from './user-role.response';

export class UserResponse {
  /**
   * CUID identifier of the user
   */
  public id: string;

  /**
   * The e-mail address of the user
   */
  public email: string;

  /**
   * The last name of the user
   */
  public lastName: string;

  /**
   * The first name of the user
   */
  public firstName: string;

  /**
   * The granted role assignments for the user
   */
  public roles?: UserRoleAssignmentDto[];

  /**
   * A fully qualified URL to the user's avatar picture
   */
  public avatar?: string;

  /**
   * Optional job position of the user
   */
  public position?: string;

  /**
   * Locked status of the user
   */
  public locked: boolean;

  /**
   * Reseller status of the user
   */
  public isReseller: boolean;

  /**
   * Partners of the user
   */
  public partnerUser?: PartnerUserResponse;
}

export class UserRoleAssignmentDto {
  /**
   * The ID of the user
   */
  @IsString()
  @IsNotEmpty()
  public userId: string;

  /**
   * The ID of the user role
   */
  @IsString()
  @IsNotEmpty()
  public userRoleId: string;

  /**
   * The assigned user role
   */
  public userRole: UserRoleResponse;
}
