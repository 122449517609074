import { IsNotEmpty, IsString } from 'class-validator';

export class CheckUnitOfMeasurementRequest {
  /**
   * The name of the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public name: string;
}
