import { ComponentOrUndefined } from '@gravity/frontend/ui';
import { create } from 'zustand';

interface DrawerViewStore {
  drawerViewComponent: ComponentOrUndefined;
  secondaryDrawerViewComponent: ComponentOrUndefined;
  drawerViewLocked: boolean;
  drawerViewProps?: unknown;
  secondaryDrawerViewProps?: unknown;
  closeDrawerView(): void;
  closeSecondaryDrawerView(): void;
  setDrawerViewLock(locked: boolean): void;
  openDrawerView(component: ComponentOrUndefined, componentProps?: unknown): void;
  openSecondaryDrawerView(component: ComponentOrUndefined, componentProps?: unknown): void;
}

export const useDrawerView = create<DrawerViewStore>()((set) => ({
  drawerViewComponent: undefined,
  secondaryDrawerViewComponent: undefined,
  drawerViewProps: undefined,
  secondaryDrawerViewProps: undefined,
  drawerViewLocked: false,
  closeDrawerView() {
    return set(() => ({
      drawerViewComponent: undefined,
      drawerViewProps: undefined,
      secondaryDrawerViewComponent: undefined,
      secondaryDrawerViewProps: undefined,
    }));
  },
  closeSecondaryDrawerView() {
    return set(() => ({
      secondaryDrawerViewComponent: undefined,
      secondaryDrawerViewProps: undefined,
    }));
  },
  setDrawerViewLock(locked: boolean) {
    set(() => ({ drawerViewLocked: locked }));
  },
  openDrawerView<T>(component: ComponentOrUndefined, componentProps?: T) {
    return set(() => ({
      drawerViewComponent: component,
      drawerViewProps: componentProps,
    }));
  },
  openSecondaryDrawerView<T>(component: ComponentOrUndefined, componentProps?: T) {
    return set(() => ({
      secondaryDrawerViewComponent: component,
      secondaryDrawerViewProps: componentProps,
    }));
  },
}));
