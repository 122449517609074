import { Button, Container, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container mt={50}>
      <Title>{t('common.error.404Title')}</Title>

      <Text fz={'md'} mb={20}>
        {t('common.error.404Content')}
      </Text>

      <Button onClick={() => navigate('/')}>{t('common.button.back')}</Button>
    </Container>
  );
}
