import {
  GrAutocompleteInput,
  GrDateInput,
  GrForm,
  GrFormTab,
  GrFormTabs,
  GrFormTitle,
  GrNumberInput,
  GrSelectInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  Stepper,
  useDtoForm,
} from '@gravity/frontend/ui';
import {
  OrderCreateRequest,
  OrderCreateFormDto,
  OrderResponse,
  ResellerOrderStatusUpdateRequest,
  ResellerOrderUpdateFormDto,
  OrderItemUpdateFormDto,
  ProductResponse,
} from '@gravity/shared/dto';
import {
  Button,
  Grid,
  Group,
  Paper,
  ActionIcon,
  Flex,
  Box,
  Input,
  ScrollArea,
  Text,
  Alert,
  Accordion,
} from '@mantine/core';
import { Permission, OrderStatus, OrderItem } from '@prisma/client';
import {
  IconChevronLeft,
  IconCategory2,
  IconCheck,
  IconEye,
  IconPencil,
  IconRowInsertTop,
  IconTrash,
  IconX,
  IconCopy,
  IconAlertTriangle,
  IconFileSpreadsheet,
  IconTableExport,
} from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { useCurrentPartner } from '../../../hooks/use-current-partner.hook';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { useProductFinal } from '../../../hooks/use-product-final.hook';
import { axiosInstance } from '../../../plugins/axios';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { AluminiumRollerBlind } from '../../sales/forms/AluminiumRollerBlind';
import { FoldingDoor } from '../../sales/forms/FoldingDoor';
import { MosquitoNet } from '../../sales/forms/MosquitoNet';
import { MosquitoNetDoor } from '../../sales/forms/MosquitoNetDoor';
import { Plisse } from '../../sales/forms/Plisse';
import { UpperRollerBlind } from '../../sales/forms/UpperRollerBlind';
import { PvcRollerBlind } from '../../sales/forms/PvcRollerBlind';
import { PvcLedge } from '../../sales/forms/PvcLedge';
import { LindabLedge } from '../../sales/forms/LindabLedge';

const productForms = {
  AluminiumRollerBlind: AluminiumRollerBlind,
  UpperRollerBlind: UpperRollerBlind,
  PvcRollerBlind: PvcRollerBlind,
  MosquitoNet: MosquitoNet,
  MosquitoNetDoor: MosquitoNetDoor,
  Plisse: Plisse,
  PvcLedge: PvcLedge,
  FoldingDoor: FoldingDoor,
  LindabLedge: LindabLedge,
};

export function ResellerOrdersFormPage() {
  const { t } = useTranslation();
  const [groupedOrderItems, setGroupedOrderItems] = useState({});

  const navigate = useNavigate();
  const entity = useLoaderData() as OrderResponse | undefined;

  const { openDrawerView } = useDrawerView();

  const { partner, permission } = useCurrentPartner();

  const resellerOrderForm = useDtoForm([OrderCreateFormDto, ResellerOrderUpdateFormDto], 'entity.order', {
    isEditForm: !!entity,
    defaultValues: entity ? entity : undefined,
    validators: {
      orderNumber: orderNumberValidation,
    },
  });

  useEffect(() => {
    if (resellerOrderForm.isCreateForm) {
      if (partner) {
        resellerOrderForm.setValue('partner', partner);
      }
      resellerOrderForm.setValue('status', 'beforeSubmission');
    }
  }, [partner]);

  const resellerOrderMutation = useDtoMutation('reseller-orders', {
    invalidateQueries: [['reseller-orders'], ['price-list-versions']],
  });

  const orderStatuses = Object.keys(OrderStatus).map((statusType) => ({
    id: statusType,
    label: t(`common.orderStatus.${statusType}`),
  }));

  const hasPermission =
    permission === Permission.reseller_system_admin || permission === Permission.reseller_system_write;

  const isEditable = resellerOrderForm.getValues('status') === 'beforeSubmission';

  const isStatusStepVisible = ['beforeSubmission', 'awaitingConfirmation'].includes(
    resellerOrderForm.getValues('status')
  );

  const partnerAddresses = partner?.addresses?.map(
    (addressObject) =>
      `${addressObject.zipCode}, ${addressObject.city}, ${addressObject.address} (${t(
        'common.addressType.' + addressObject.type
      )})`
  );

  const products = useProductFinal()?.filter((product) => product.isAvailableForReseller);
  const { control, watch } = resellerOrderForm;
  const {
    fields: orderItemFields,
    append: appendOrderItem,
    remove: removeOrderItem,
  } = useFieldArray({ name: 'orderItems', control, keyName: 'orderItemId' });

  const newOrderItemFields = (): OrderItemUpdateFormDto => {
    return {
      product: { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true },
      productConfig: {},
      quantity: 1,
      addedByVendor: false,
      price: 0,
    };
  };

  useEffect(() => {
    let sumPrice = 0;

    sumPrice = watch('orderItems')
      ? resellerOrderForm.watch('orderItems').reduce((acc, item) => {
          item.price = item.price ?? 0;
          item.quantity = item.quantity ?? 1;
          return (acc += item.price * item.quantity);
        }, 0)
      : 0;

    if (!resellerOrderForm.readonlyMode) resellerOrderForm.setValue('price', sumPrice);
  }, [watch()]);

  const handleStatusStep = async (data: ResellerOrderStatusUpdateRequest) => {
    const order = (await axiosInstance.patch(`reseller-orders/status/${data.id}`, data)).data as OrderResponse;
    resellerOrderForm.setValue('status', order.status);
    resellerOrderForm.setValue('logs', order.logs);
    resellerOrderForm.setValue('orderNumber', order.orderNumber);
    resellerOrderForm.reset(order, { keepValues: true });
  };

  const handleSubmit = async (formData: OrderCreateFormDto) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const verifiedOrderItems = formData.orderItems.filter((item) => item.product?.id !== '');
    const clone: any = _.cloneDeep({ ...formData, orderItems: verifiedOrderItems });
    clone.orderItems?.forEach((item) => {
      delete item['orderId'];
      item['productId'] = item['product']?.id;
      delete item['product'];
    });
    clone.partnerId = clone['partner']?.id;
    delete clone['partner'];

    const data: OrderCreateRequest = clone;

    if (resellerOrderForm.isCreateForm) {
      data.orderNumber = '';
      const order = (await resellerOrderMutation.create(data)) as OrderResponse;
      navigate(`/reseller/orders/${order.id}`);
      resellerOrderForm.toggleReadonlyMode(order);
    } else {
      const order = await resellerOrderMutation.update(data);
      const fullData = { ...data, orderDate: entity?.orderDate, status: entity?.status };
      resellerOrderForm.toggleReadonlyMode(fullData);
      resellerOrderForm.resetForm(order);
    }
  };

  const [priceLists, setPriceLists] = useState();

  useMemo(async () => {
    const date = resellerOrderForm.getValues('orderDate') ? resellerOrderForm.getValues('orderDate') : new Date();
    const partnerId = partner ? partner.id : null;
    const result = await axiosInstance.get(`price-list-versions?date=${date}&partnerId=${partnerId}&`);
    setPriceLists(result.data.data);
  }, [watch('orderDate')]);

  async function orderNumberValidation(orderNumber: string) {
    if (resellerOrderForm.isEditForm && orderNumber === entity?.orderNumber) {
      return;
    }
    const result = await axiosInstance.get(`orders/check-order-number?orderNumber=${orderNumber}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictOrderNumber') {
      return { type: result.response?.data.message };
    }
  }

  function updateOrderItemConfig(index: number, data: any) {
    const quantityKey = Object.keys(data).find((key) => key.endsWith('quantity'));
    const priceKey = Object.keys(data).find((key) => key.endsWith('price'));
    resellerOrderForm.setValue(`orderItems.${index}.productConfig` as string, data);
    resellerOrderForm.setValue(`orderItems.${index}.quantity`, quantityKey ? data[`${quantityKey}`] : 0);
    resellerOrderForm.setValue(`orderItems.${index}.price`, priceKey ? data[`${priceKey}`] : 0);
  }

  function resetOrderItem(index: number, prevProduct: ProductResponse) {
    resellerOrderForm.setValue(`orderItems.${index}.product`, { ...prevProduct });
  }

  function handleExportForItem(orderId: string, index: number) {
    axiosInstance
      .post(`reseller-orders/generateExcelForItem/${orderId}`, { index: index }, { responseType: 'arraybuffer' })
      .then(function (response) {
        // download the response
        if (response.status === 201) {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
          downloadLink.download = `${
            resellerOrderForm.getValues('orderItems')[`${index}`].product.name
          }_${resellerOrderForm.getValues('orderNumber')}.xlsx`;
          downloadLink.click();
        }
      })
      .catch(function (error) {
        // handle errors here
        console.error(error);
      });
  }

  function handleExport(orderId: string) {
    axiosInstance
      .get(`reseller-orders/generateExcel/${orderId}`, { responseType: 'arraybuffer' })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition.match(/filename=(.+)/);
        const filename = filenameMatch ? filenameMatch[1] : 'downloaded-file.zip';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  async function sortedOrderItems() {
    const result = (await axiosInstance.get(`orders/sortedOrderItems/${resellerOrderForm.getValues('id')}`)).data;
    setGroupedOrderItems(result);
  }

  return (
    <Page>
      <GrForm form={resellerOrderForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/reseller/orders">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="sales.ordersForm" />
          </Group>

          <Group>
            {resellerOrderForm.readonlyMode ? (
              hasPermission &&
              isEditable && (
                <Button
                  onClick={() => resellerOrderForm.toggleReadonlyMode()}
                  variant="light"
                  leftIcon={<IconPencil />}
                >
                  {t('common.button.edit')}
                </Button>
              )
            ) : (
              <>
                {hasPermission && (
                  <Button loading={resellerOrderForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                    {t('common.button.save')}
                  </Button>
                )}

                <Button
                  loading={resellerOrderForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() =>
                    resellerOrderForm.isCreateForm ? navigate(-1) : resellerOrderForm.toggleReadonlyMode()
                  }
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {resellerOrderForm.isEditForm && resellerOrderForm.readonlyMode && hasPermission && isEditable && (
              <DeleteConfirmButton
                loading={resellerOrderForm.isSubmitting}
                onDelete={async () => {
                  try {
                    await resellerOrderMutation.delete(resellerOrderForm.getValues('id' as never));
                    navigate('/reseller/orders');
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Group>
            <Stepper
              steps={orderStatuses}
              activeStep={orderStatuses.findIndex((item) => item.id === resellerOrderForm.getValues('status'))}
            />
          </Group>

          {!resellerOrderForm.isCreateForm && resellerOrderForm.getValues('status') === 'beforeSubmission' && (
            <Alert icon={<IconAlertTriangle />} title={t('sales.ordersForm.attention')} color="yellow" radius="xs">
              {t('sales.ordersForm.beforeSubmissionWarning')}{' '}
            </Alert>
          )}

          <Grid m={0}>
            <Grid.Col xs={4}>
              <GrTextInput name="orderId" />
            </Grid.Col>

            {!resellerOrderForm.isCreateForm && (
              <Grid.Col xs={4}>
                <GrDateInput name="orderDate" />
              </Grid.Col>
            )}

            <Grid.Col xs={4}>
              <GrDateInput
                name="deadlineDate"
                onlyDate
                minDate={
                  resellerOrderForm.getValues('orderDate')
                    ? new Date(resellerOrderForm.getValues('orderDate'))
                    : new Date()
                }
              />
            </Grid.Col>
          </Grid>

          <Grid m={0}>
            <Grid.Col xs={4}>
              <GrAutocompleteInput data={partnerAddresses} name="deliveryAddress" labelField="name" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrAutocompleteInput data={partnerAddresses} name="invoiceAddress" labelField="name" />
            </Grid.Col>
          </Grid>

          <Grid m={0}>
            {!resellerOrderForm.isCreateForm && (
              <Grid.Col xs={4}>
                <GrTextInput name="orderNumber" />
              </Grid.Col>
            )}

            {!resellerOrderForm.isCreateForm && resellerOrderForm.readonlyMode && (
              <Grid.Col xs={4}>
                <GrSelectInput data={orderStatuses} name={'status'} simpleValue />

                {resellerOrderForm.readonlyMode && isStatusStepVisible && hasPermission && (
                  <Button
                    onClick={() =>
                      handleStatusStep({
                        id: resellerOrderForm.getValues('id'),
                        status: orderStatuses[
                          orderStatuses.findIndex((item) => item.id === resellerOrderForm.getValues('status')) + 1
                        ].id as OrderStatus,
                      })
                    }
                  >
                    {t(
                      `common.nextStatusButton.${
                        orderStatuses[
                          orderStatuses.findIndex((item) => item.id === resellerOrderForm.getValues('status'))
                        ].id
                      }`
                    )}
                  </Button>
                )}
              </Grid.Col>
            )}

            {!resellerOrderForm.isCreateForm && !resellerOrderForm.readonlyMode && (
              <Grid.Col xs={4}>
                <GrSelectInput data={orderStatuses} name={'status'} simpleValue />
              </Grid.Col>
            )}

            <Grid.Col>
              <GrTextareaInput name="note" />
            </Grid.Col>
          </Grid>

          <GrFormTabs
            tabs={
              resellerOrderForm.readonlyMode
                ? [
                    {
                      icon: <IconCategory2 />,
                      name: 'orderItems',
                      label: 'entity.order.orderItemsDetails',
                      badge: orderItemFields.length,
                    },
                    {
                      icon: <IconFileSpreadsheet />,
                      name: 'excelGeneration',
                      label: 'entity.order.excelGeneration',
                    },
                  ]
                : [
                    {
                      icon: <IconCategory2 />,
                      name: 'orderItems',
                      label: 'entity.order.orderItemsDetails',
                      badge: orderItemFields.length,
                    },
                  ]
            }
            onTabChange={(value) => value === 'excelGeneration' && sortedOrderItems()}
          >
            <GrFormTab name="orderItems">
              {orderItemFields.map((item, index) => {
                const netPrice =
                  resellerOrderForm.getValues(`orderItems.${index}.price`) *
                  resellerOrderForm.getValues(`orderItems.${index}.quantity`);
                return (
                  <Paper
                    key={item.orderItemId}
                    withBorder
                    mb="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    {!resellerOrderForm.readonlyMode && watch(`orderItems.${index}.product.isAvailableForReseller`) && (
                      <Group position="right">
                        <ActionIcon
                          title={t('common.button.delete')}
                          onClick={() => removeOrderItem(index)}
                          color="red"
                          size="md"
                          variant="light"
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    )}

                    <Grid>
                      <Grid.Col xs={6}>
                        <Flex>
                          <Box sx={{ flexGrow: 1 }}>
                            {watch(`orderItems.${index}.product.id`) === '' && (
                              <GrSelectInput
                                data={products}
                                name={`orderItems.${index}.product`}
                                labelField="name"
                                disabled={watch(`orderItems.${index}.product.id` as string) !== ''}
                                onChange={() => {
                                  setTimeout(() => {
                                    const product = resellerOrderForm.getValues(`orderItems.${index}.product`);
                                    const prevProduct =
                                      Object.keys(
                                        resellerOrderForm.getValues(`orderItems.${index}.productConfig` as string)
                                      ).length === 0
                                        ? { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true }
                                        : product;
                                    if (product?.productForm && productForms[product.productForm]) {
                                      openDrawerView(productForms[product.productForm], {
                                        orderItem: resellerOrderForm.getValues(`orderItems.${index}`),
                                        onSave: (data) => updateOrderItemConfig(index, data),
                                        onClose: () => resetOrderItem(index, prevProduct),
                                        orderReadonlyMode: resellerOrderForm.readonlyMode,
                                        quantity: resellerOrderForm.getValues(`orderItems.${index}.quantity`),
                                        priceLists,
                                      });
                                    }
                                  });
                                }}
                              />
                            )}

                            {watch(`orderItems.${index}.product.id`) !== '' && (
                              <Input.Wrapper label={t('entity.order.orderItems.product') + ':'}>
                                <Text>{resellerOrderForm.getValues(`orderItems.${index}.product.name` as string)}</Text>

                                <Text color={'dimmed'}>
                                  {resellerOrderForm.getValues(`orderItems.${index}.productConfig.summary` as string)}
                                </Text>
                              </Input.Wrapper>
                            )}
                          </Box>

                          {watch(`orderItems.${index}.product.productForm`) &&
                            watch(`orderItems.${index}.product.productAttributes` as string)?.length > 0 && (
                              <>
                                <ActionIcon
                                  ml="sm"
                                  mt="xl"
                                  title={
                                    resellerOrderForm.readonlyMode ? t('common.button.show') : t('common.button.edit')
                                  }
                                  onClick={() => {
                                    const product = resellerOrderForm.getValues(`orderItems.${index}.product`);
                                    const prevProduct =
                                      Object.keys(
                                        resellerOrderForm.getValues(`orderItems.${index}.productConfig` as string)
                                      ).length === 0
                                        ? { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true }
                                        : product;
                                    if (product.productForm && productForms[product.productForm]) {
                                      openDrawerView(productForms[product.productForm], {
                                        orderItem: resellerOrderForm.getValues(`orderItems.${index}`),
                                        onSave: (data) => updateOrderItemConfig(index, data),
                                        onClose: () => resetOrderItem(index, prevProduct),
                                        orderReadonlyMode: resellerOrderForm.readonlyMode,
                                        quantity: resellerOrderForm.getValues(`orderItems.${index}.quantity`),

                                        priceLists,
                                      });
                                    }
                                  }}
                                >
                                  {resellerOrderForm.readonlyMode ? <IconEye /> : <IconPencil />}
                                </ActionIcon>

                                {resellerOrderForm.readonlyMode && (
                                  <ActionIcon
                                    title={t('common.button.export')}
                                    mx="sm"
                                    mt="xl"
                                    onClick={() => handleExportForItem(resellerOrderForm.getValues('id'), index)}
                                  >
                                    {<IconTableExport />}
                                  </ActionIcon>
                                )}
                              </>
                            )}

                          {!resellerOrderForm.readonlyMode &&
                            watch(`orderItems.${index}.product.id`) !== '' &&
                            watch(`orderItems.${index}.product.isAvailableForReseller`) &&
                            watch(`orderItems.${index}.product.canBeOrdered`) && (
                              <ActionIcon
                                title={t('common.button.duplicate')}
                                onClick={() => {
                                  const newOrderItem = resellerOrderForm.getValues(`orderItems.${index}`);
                                  delete newOrderItem.id;
                                  appendOrderItem(newOrderItem);
                                  const product = resellerOrderForm.getValues(`orderItems.${index}.product`);
                                  if (product.productForm && productForms[product.productForm]) {
                                    openDrawerView(productForms[product.productForm], {
                                      orderItem: resellerOrderForm.getValues(`orderItems.${index}`),
                                      onSave: (data) => updateOrderItemConfig(orderItemFields.length, data),
                                      onClose: () => {
                                        resetOrderItem(orderItemFields.length, {
                                          id: '',
                                          name: '',
                                          canBeOrdered: true,
                                          isAvailableForReseller: true,
                                        });
                                        resellerOrderForm.setValue(
                                          `orderItems.${orderItemFields.length}.productConfig`,
                                          {}
                                        );
                                        resellerOrderForm.setValue(`orderItems.${orderItemFields.length}.price`, 0);
                                      },
                                      orderReadonlyMode: resellerOrderForm.readonlyMode,
                                      quantity: resellerOrderForm.getValues(
                                        `orderItems.${orderItemFields.length}.quantity`
                                      ),
                                      priceLists,
                                    });
                                  }
                                }}
                                color="primaryColor"
                                mx="sm"
                                mt="xl"
                              >
                                <IconCopy />
                              </ActionIcon>
                            )}
                        </Flex>
                      </Grid.Col>

                      <Grid.Col xs={2} pr={30}>
                        <GrNumberInput
                          name={`orderItems.${index}.quantity`}
                          min={1}
                          max={100}
                          controls
                          readonly={!watch(`orderItems.${index}.product.isAvailableForReseller`)}
                        />
                      </Grid.Col>

                      <Grid.Col xs={2}>
                        <GrNumberInput
                          name={`orderItems.${index}.price`}
                          isPrice
                          readonly={
                            (watch(`orderItems.${index}.product.productForm`) !== '' &&
                              watch(`orderItems.${index}.product.productAttributes` as string)?.length > 0) ||
                            !watch(`orderItems.${index}.product.isAvailableForReseller`)
                          }
                        />
                      </Grid.Col>

                      <Grid.Col xs={2}>
                        <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                          <Input.Wrapper label={t('entity.order.orderItems.netPrice') + ':'}>
                            <Text>
                              {resellerOrderForm.getValues(`orderItems.${index}.price`) ? (
                                <MoneyFormat>{netPrice}</MoneyFormat>
                              ) : (
                                '-'
                              )}
                            </Text>
                          </Input.Wrapper>
                        </Box>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                );
              })}

              {resellerOrderForm.readonlyMode && orderItemFields.length === 0 && (
                <Text>{t('common.message.noData')}</Text>
              )}

              {!resellerOrderForm.readonlyMode && (
                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendOrderItem(newOrderItemFields())}
                >
                  {t('common.button.addRow')}
                </Button>
              )}

              {orderItemFields.length > 0 && (
                <Grid sx={{ marginTop: '5px' }}>
                  <Grid.Col xs={2} offset={10}>
                    <Box ml={-25} mr={30}>
                      <GrNumberInput name="price" isPrice readonly />
                    </Box>
                  </Grid.Col>
                </Grid>
              )}
            </GrFormTab>

            <GrFormTab name="excelGeneration">
              {resellerOrderForm.readonlyMode && orderItemFields.length === 0 && (
                <Text>{t('common.message.noData')}</Text>
              )}

              {orderItemFields.length > 0 && (
                <Paper withBorder mb="xs" sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)', width: 'fit-content' }}>
                  <Grid p={25}>
                    <Grid.Col xs={11} p={0}>
                      {t('entity.order.excelGenerationMessage')}:
                    </Grid.Col>

                    <Grid.Col xs={1} p={0}>
                      <ActionIcon
                        title={t('common.button.export')}
                        onClick={() => handleExport(resellerOrderForm.getValues('id'))}
                      >
                        {<IconTableExport />}
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                </Paper>
              )}

              {resellerOrderForm.readonlyMode && (
                <Accordion variant="filled" multiple>
                  {Object.keys(groupedOrderItems)
                    ?.filter((key) => key !== 'null')
                    .map((key) => {
                      return (
                        <Paper key={key} withBorder mb="xs" sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}>
                          <Accordion.Item value={key}>
                            <Accordion.Control>
                              <Text>{t(`entity.order.products.${key}`)}</Text>
                            </Accordion.Control>

                            <Accordion.Panel>
                              {Object.keys(groupedOrderItems[`${key}`]).map((subListKey, index) => {
                                const subList: OrderItem[] = groupedOrderItems[`${key}`][`${subListKey}`];

                                return (
                                  <Paper
                                    key={subListKey}
                                    withBorder
                                    mb="xs"
                                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                                  >
                                    <Text m={15}>{index + 1}.</Text>

                                    {subList.map((item) => {
                                      const netPrice = item.price * item.quantity;

                                      return (
                                        <Grid m={15} key={`${item.id}`}>
                                          <Grid.Col xs={6}>
                                            <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                              <Input.Wrapper label={t('entity.order.orderItems.product') + ':'}>
                                                <Text color={'dimmed'}>
                                                  {item.productConfig && item.productConfig['summary']}
                                                </Text>
                                              </Input.Wrapper>
                                            </Box>
                                          </Grid.Col>

                                          <Grid.Col xs={2}>
                                            <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                              <Input.Wrapper label={t('entity.order.orderItems.quantity') + ':'}>
                                                <Text>{item.quantity}</Text>
                                              </Input.Wrapper>
                                            </Box>
                                          </Grid.Col>

                                          <Grid.Col xs={2}>
                                            <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                              <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
                                                <Text>
                                                  {item.price ? <MoneyFormat>{item.price}</MoneyFormat> : '-'}
                                                </Text>
                                              </Input.Wrapper>
                                            </Box>
                                          </Grid.Col>

                                          <Grid.Col xs={2}>
                                            <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                              <Input.Wrapper label={t('entity.order.orderItems.netPrice') + ':'}>
                                                <Text>
                                                  {resellerOrderForm.getValues(`orderItems.${index}.price`) ? (
                                                    <MoneyFormat>{netPrice}</MoneyFormat>
                                                  ) : (
                                                    '-'
                                                  )}
                                                </Text>
                                              </Input.Wrapper>
                                            </Box>
                                          </Grid.Col>
                                        </Grid>
                                      );
                                    })}
                                  </Paper>
                                );
                              })}
                            </Accordion.Panel>
                          </Accordion.Item>
                        </Paper>
                      );
                    })}
                </Accordion>
              )}
            </GrFormTab>
          </GrFormTabs>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
