import { Prisma } from '@prisma/client';
import { IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, Max } from 'class-validator';

export class FoldingDoorRequest {
  @IsString()
  @IsNotEmpty()
  public fd_width: string; // Szélesség

  @Max(240)
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public fd_height: number; // Magasság

  @IsNumber()
  @IsNotEmpty()
  public fd_area: number; // Terület

  @IsNumber()
  @IsNotEmpty()
  public fd_roundedArea: number; // Kerekített terület

  @IsString()
  @IsNotEmpty()
  public fd_color: string; // Szín

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public fd_quantity: number; // Darabszám

  @IsNumber()
  @IsOptional()
  public fd_cutPrice?: number; // Vágási díj

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
