import { UnitOfMeasurementResponse } from '../core-data/unit-of-measurement.response';
import { BillOfMaterialsResponse } from './bill-of-materials.response';
import { BomComponentResponse } from './bom-component.response';
import { ProductAttributeResponse } from './product-attribute.response';
import { ProductCategoryResponse } from './product-category.response';

export class ProductResponse {
  /**
   * CUID identifier of the product
   */
  public id?: string;

  /**
   * The name of the product
   */
  public name: string;

  /**
   * The description of the product
   */
  public description?: string;

  /**
   * True if the product is final
   */
  public isFinalProduct?: boolean;

  /**
   * True if the product can be ordered
   */
  public canBeOrdered: boolean;

  /**
   * True if the product is available for resellers
   */
  public isAvailableForReseller: boolean;

  /**
   * The category of the product
   */
  public category?: ProductCategoryResponse;

  /**
   * The unit of measurement of the product
   */
  public unitOfMeasurement?: UnitOfMeasurementResponse;

  /**
   * The bill of materials of the product
   */
  public billOfMaterials?: BillOfMaterialsResponse;

  /**
   * Array of product attributes for the product
   */
  public productAttributes?: ProductAttributeResponse[];

  /**
   * Array of bill of materials component for the product
   */
  public bomComponents?: BomComponentResponse[];

  /**
   * The form to display when ordering the product
   */
  public productForm?: string;
}
