import { Box, createStyles, getStylesRef } from '@mantine/core';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { createElement } from 'react';
import { Icon } from '@tabler/icons-react';

interface ResellerNavLinkProps {
  to?: string;
  icon: Icon;
  label: string;
  disabled?: boolean;
}

const useStyles = createStyles((theme, _params) => {
  const icon = getStylesRef('icon');
  return {
    mainLink: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      transition: 'all 0.1s linear, border-radius 0ms',
      color: 'white',
      padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
      marginTop: 4,
      borderRadius: theme.radius.md,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][7],
      },
    },

    linkIcon: {
      ref: icon,
      marginRight: theme.spacing.xs,
    },

    mainLinkActive: {
      '&, &:hover': {
        backgroundColor: theme.colors[theme.primaryColor][6],
        color: theme.colors[theme.primaryColor][0],
      },
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: 0.3,
      '&, &:hover': {
        backgroundColor: 'inherit',
        color: 'inherit',
        [`& .${icon}`]: {
          color: 'inherit',
        },
      },
    },
  };
});

export function ResellerNavLink({ to, icon, label, disabled }: ResellerNavLinkProps) {
  const { classes, cx } = useStyles();
  if (to) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return disabled ? (
      <Box className={cx(classes.mainLink, classes.disabled)}>
        {createElement(icon, { className: classes.linkIcon, size: 20 })}

        <Box sx={{ fontSize: 14 }}>{label}</Box>
      </Box>
    ) : (
      <NavLink
        className={cx(classes.mainLink, {
          [classes.mainLinkActive]: match,
        })}
        to={to}
        key={to}
      >
        {createElement(icon, { className: classes.linkIcon, size: 20 })}

        <Box sx={{ fontSize: 14 }}>{label}</Box>
      </NavLink>
    );
  } else {
    return <Box>ERROR</Box>;
  }
}
