import { gravityTheme } from './theme';
import { StrictMode, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Box, Center, createEmotionCache, Loader, MantineProvider } from '@mantine/core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Notifications } from '@mantine/notifications';
import { queryClient } from './plugins/react-query';
import { router } from './plugins/router';
import { ConfigResponse } from '@gravity/shared/dto';
import { axiosInstance } from './plugins/axios';
import { useInterval } from '@mantine/hooks';

export function App() {
  const emotionCache = createEmotionCache({ key: 'gravity' });
  const [config, setConfig] = useState<ConfigResponse>();
  const interval = useInterval(() => getConfig(), 1000);

  async function getConfig() {
    const result = await axiosInstance.get(`config`);
    setConfig(result.data);
    interval.stop();
  }

  useEffect(() => {
    interval.start();
  }, []);

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        {config ? (
          <MantineProvider
            theme={gravityTheme(config.uiColor)}
            emotionCache={emotionCache}
            withGlobalStyles
            withNormalizeCSS
            withCSSVariables
          >
            <Notifications position="bottom-right" limit={5} autoClose={6000} id="gravity-notifications" />

            <RouterProvider router={router} />
          </MantineProvider>
        ) : (
          <MantineProvider>
            <Center h="100vh">
              <Loader size="lg" color="dark" />
            </Center>
          </MantineProvider>
        )}

        <Box ml={40}>
          <ReactQueryDevtools position={'bottom-left'} toggleButtonProps={{ style: { left: '270px' } }} />
        </Box>
      </QueryClientProvider>
    </StrictMode>
  );
}
