import { Box, Input, Loader, Select, SelectItem, Text } from '@mantine/core';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrSelectInputProps extends BaseInputProps {
  data?: Record<string, unknown>[] | any[];
  selectedData?: Record<string, unknown>[] | any[];
  valueField?: string;
  labelField?: string;
  simpleValue?: boolean;
  disabled?: boolean;
  emptyOnStateChange?: boolean;
  onChange?: () => void;
  onEmptyState?: () => void;
}

export function GrSelectInput({ name, data, loading, disabled, selectedData, onChange, ...props }: GrSelectInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');
  const valueField = props.valueField ?? 'id';
  const labelField = props.labelField ?? 'label';
  const simpleValue = props.simpleValue ?? false;
  const preparedData: SelectItem[] = !data
    ? []
    : data.map((item) => ({
        label: typeof item === 'string' ? item : item[labelField],
        value: typeof item === 'string' ? item : item[valueField],
        disabled: selectedData?.some((value) => (value ? value[valueField] === item[valueField] : false)),
      }));

  // Remove value from the select if the underlying data changed and the selected value is not valid anymore
  useEffect(() => {
    if (form.getValues(name) && props.emptyOnStateChange) {
      const foundData = preparedData.find(
        (d) => d.value === (simpleValue ? form.getValues(name) : form.getValues(name)[valueField])
      );

      if (!foundData) {
        form.setValue(name, null);
        if (props.onEmptyState) {
          props.onEmptyState();
        }
      }

      if (disabled) {
        form.setValue(name, null);
      }
    }
  }, [preparedData]);

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    const foundData = preparedData.find(
      (d) => d.value === (simpleValue ? form.getValues(name) : form.getValues(name)[valueField])
    );

    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Box>
            {(!form.getValues(name) || form.getValues(name).length === 0) && <Text>-</Text>}

            {form.getValues(name) && <Text>{foundData ? foundData.label : '-'}</Text>}
          </Box>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => (
        <Select
          {...field}
          {...props.forwardedProps}
          value={form.getValues(name) ? (simpleValue ? form.getValues(name) : form.getValues(name)[valueField]) : null}
          ref={null}
          onChange={(value: string) => {
            field.onChange(simpleValue ? value : (data ?? []).find((d) => d[valueField] === value));

            if (onChange) {
              onChange();
            }
          }}
          label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
          data={preparedData}
          error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
          description={props.description}
          disabled={formState.isSubmitting || disabled}
          required={form.requiredFields.includes(labelName)}
          key={`key_${name}`}
          searchable={true}
          clearable={true}
          allowDeselect={!form.requiredFields.includes(labelName)} // To allow items deselection set allowDeselect, user will also be able to deselect item if clearable prop is set
          rightSection={loading && <Loader size="sm" />}
          transitionProps={{ transition: 'scale-y', duration: 200, timingFunction: 'ease' }}
          autoComplete={'off'}
          nothingFound={t(`common.message.selectNoOptionFound`)}
        />
      )}
    />
  );
}
