import { AppShell, Box, Burger, createStyles, Header, MediaQuery, Navbar } from '@mantine/core';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DrawerView } from '../../../components/DrawerView/DrawerView';
import { RouteGuard } from '../../../components/RouteGuard';
import { ResellerNav } from '../components/ResellerNav/ResellerNav';
import { useAppConfig } from '../../../hooks/use-app-config.hook';

const useStyles = createStyles((theme) => ({
  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    height: 40,
    color: 'white',
    img: {
      objectFit: 'contain',
      maxWidth: '100%',
      height: 'auto',
    },
  },
  header: {
    backgroundColor: theme.colors[theme.primaryColor][9],
    paddingLeft: theme.spacing.xl,
  },
}));

export function ResellerLayout() {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const config = useAppConfig();

  return (
    <AppShell
      padding={0}
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 220 }}
          sx={{
            border: 'none',
          }}
        >
          <Navbar.Section onClick={() => setOpened((o) => !o)}>
            <ResellerNav />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Header height={{ base: 55, sm: 0 }} sx={{ border: 'none' }}>
            <div className={classes.header} style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div className={classes.logo}>
                <img src={config?.logo} alt="Logo" />
              </div>

              <Burger opened={opened} onClick={() => setOpened((o) => !o)} size="sm" mr="xl" color="white" />
            </div>
          </Header>
        </MediaQuery>
      }
    >
      <Box
        component="main"
        sx={{
          minHeight: '100%',
        }}
      >
        <RouteGuard>
          <Outlet />
        </RouteGuard>
      </Box>

      <DrawerView />
    </AppShell>
  );
}
