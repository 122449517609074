import { Box, Center, Input, MantineColor, Space, Switch, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrSwitchProps extends BaseInputProps {
  required?: boolean;
  color?: MantineColor;
  disabled?: boolean;
  onChange?: () => void;
}

export function GrSwitchInput({ name, disabled, onChange, ...props }: GrSwitchProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  const translatedLabel = t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`);

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Input.Wrapper
        label={translatedLabel.endsWith('?') ? translatedLabel : translatedLabel + ':'}
        sx={{ wordWrap: 'break-word' }}
      >
        <Box>
          <Center inline>
            {form.getValues(name) ? <IconCheck size={16} /> : <IconX size={16} />}

            <Space w={5} />

            <Text>{form.getValues(name) ? t('common.message.yes') : t('common.message.no')}</Text>
          </Center>
        </Box>
      </Input.Wrapper>
    );
  }

  return (
    <Input.Wrapper
      label={translatedLabel.endsWith('?') ? translatedLabel : translatedLabel + ':'}
      required={form.requiredFields.includes(labelName)}
      sx={{ wordWrap: 'break-word' }}
    >
      <Box>
        <Controller
          name={name}
          control={form.control}
          render={({ field, formState, fieldState }) => (
            <Switch
              {...field}
              {...props.forwardedProps}
              styles={{
                body: {
                  alignItems: 'center',
                  minHeight: 20,
                },
              }}
              checked={Boolean(field.value)}
              onChange={(event) => {
                field.onChange(event.currentTarget.checked);

                if (onChange) {
                  onChange();
                }
              }}
              disabled={formState.isSubmitting || disabled}
              required={form.requiredFields.includes(labelName)}
              error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
              description={props.description}
            />
          )}
        />
      </Box>
    </Input.Wrapper>
  );
}
