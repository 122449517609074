import { FilteredResponse, FilterQuery, NewsPostResponse } from '@gravity/shared/dto';
import { NewsPost } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useNewsPostsQuery(
  params: FilterQuery<NewsPost | undefined>
): FilteredResponse<NewsPostResponse> | undefined {
  const newsPosts = useQuery(
    ['news-posts', 'list-published-news', params],
    getMany<FilteredResponse<NewsPostResponse>>('news-posts/list-published-news', params)
  );
  return newsPosts.data;
}
