import { Navbar, Box, AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { GlobalNav } from '../components/GlobalNav/GlobalNav';
import { DrawerView } from '../components/DrawerView/DrawerView';
import { RouteGuard } from '../components/RouteGuard';

export function AppLayout() {
  return (
    <AppShell
      padding={0}
      navbar={
        <Navbar
          height="100vh"
          sx={{
            border: 'none',
            minWidth: 50,
            maxWidth: 270,
            width: 'auto',
            flexShrink: 0,
          }}
        >
          <Navbar.Section sx={{ display: 'flex' }}>
            <GlobalNav />
          </Navbar.Section>
        </Navbar>
      }
    >
      <Box
        component="main"
        sx={{
          marginLeft: 50,
          width: `calc(100vw - 50px)`,
          minHeight: '100%',
        }}
      >
        <RouteGuard>
          <Outlet />
        </RouteGuard>
      </Box>

      <DrawerView />
    </AppShell>
  );
}
