import { createBrowserRouter } from 'react-router-dom';
import { coreDataRoutes } from '../modules/core-data/routes';
import { coreRoutes } from '../modules/core/routes';
import { pricingRoutes } from '../modules/pricing/routes';
import { resellerRoutes } from '../modules/reseller/routes';
import { salesRoutes } from '../modules/sales/routes';
import { settingsRoutes } from '../modules/settings/routes';

export const router = createBrowserRouter([
  ...coreRoutes,
  ...resellerRoutes,
  ...settingsRoutes,
  ...salesRoutes,
  ...coreDataRoutes,
  ...pricingRoutes,
]);
