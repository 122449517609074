import { UserRoleResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useUserRoles() {
  const useQueryReturn = useQuery(
    ['user-roles', 'list-user-roles'],
    getMany<UserRoleResponse[]>('user-roles/list-user-roles')
  );

  return useQueryReturn;
}
