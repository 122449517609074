export enum EntityTableColumnType {
  String,
  Number,
  Date,
  DateTime,
  EnumArray,
  SimpleArray,
  ObjectArray,
  Boolean,
  Id,
  JSON,
  JSONDiff,
  Enum,
  Object,
  Avatar,
  Price,
}
