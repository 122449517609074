import { MoneyFormat } from '@gravity/frontend/ui';
import { ActionIcon, Box, Button, Grid, Group, Input, LoadingOverlay, Paper, ScrollArea, Text } from '@mantine/core';
import { IconChevronLeft, IconEye } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import { Page } from '../../../components/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../../../plugins/axios';
import { PageTitle } from '../../../components/PageTitle';

export function BillableItemsPage() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const { search } = useLocation();

  async function fetchOrders() {
    const result = await axiosInstance.get(`orders/billable-items${search}`);
    setOrders(result.data);
    setIsFetching(false);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  async function changeBilledState() {
    const billedOrders = await axiosInstance.patch(`orders/billable-items${search}`);
    setOrders(billedOrders.data);
  }

  return (
    <Page>
      <Box w="100%">
        <Group position="apart">
          <Group>
            <Link to="/sales/orders">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <PageTitle title={t('sales.billableItems.title')} />
          </Group>

          <Button variant="light" onClick={changeBilledState}>
            {t('entity.order.billed')}
          </Button>
        </Group>

        <LoadingOverlay
          visible={isFetching}
          overlayOpacity={0.6}
          overlayBlur={2}
          loaderProps={{ color: 'dark' }}
          transitionDuration={300}
          zIndex={150}
        />

        <ScrollArea type="scroll" pt="lg" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          {orders?.map((order) => (
            <Paper
              key={order['partnerId']}
              withBorder
              mb="xs"
              p="md"
              sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#f7f7fa' }}
            >
              <Grid>
                <Grid.Col xs={3}>
                  <Input.Wrapper label={t('entity.order.partner') + ':'}>
                    <Text>{order['partnerName']}</Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col xs={5}>
                  <Input.Wrapper label={t('entity.order.invoiceAddress') + ':'}>
                    <Text>{order['partnerInvoiceAddress']}</Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col xs={2}>
                  <Input.Wrapper label={t('entity.partner.taxIdNumber') + ':'}>
                    <Text>{order['partnerTaxId']}</Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col xs={2}>
                  <Input.Wrapper label={t('entity.order.totalAmount') + ':'}>
                    <Text>
                      <MoneyFormat size="sm">{order['sumPrice']}</MoneyFormat>
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>

              <Text size="sm" weight={500} mt="md">
                {t('entity.order.orders')}:
              </Text>

              {Object.keys(order['ordersByPartner']).map((orderKey) => {
                const orderNumber = order['ordersByPartner'][`${orderKey}`]['orderNumber']
                  ? order['ordersByPartner'][`${orderKey}`]['orderNumber'] + ' - '
                  : '';
                const billed = order['ordersByPartner'][`${orderKey}`]['billed']
                  ? t('entity.order.billed')
                  : t('entity.order.toBeBilled');
                const orderText =
                  orderNumber +
                  new Date(order['ordersByPartner'][`${orderKey}`]['orderDate']).toLocaleDateString() +
                  ' - ' +
                  billed;

                return (
                  <Group key={order['ordersByPartner'][`${orderKey}`]['orderId']} position="left">
                    <Link
                      to={`../orders/${order['ordersByPartner'][`${orderKey}`]['orderId']}`}
                      target="_blank"
                      relative="route"
                    >
                      <IconEye />
                    </Link>

                    <Text pb={9}>{orderText}</Text>

                    <Text pb={9}>
                      <MoneyFormat size="sm">{order['ordersByPartner'][`${orderKey}`]['orderPrice']}</MoneyFormat>
                    </Text>
                  </Group>
                );
              })}

              {Object.keys(order['ordersByProduct']).map((key) => {
                const productName = order['ordersByProduct'][`${key}`]['productWithoutForm'];

                return (
                  <Paper
                    key={order['ordersByProduct'][`${key}`]['productId']}
                    withBorder
                    m="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    <Text size="md" weight={500}>
                      {productName}
                    </Text>

                    <Grid mt="md">
                      <Grid.Col xs={6}>
                        <Text size="sm" weight={500}>
                          {t('entity.order.orderItems.product') + ':'}
                        </Text>
                      </Grid.Col>

                      <Grid.Col xs={2}>
                        <Text size="sm" weight={500}>
                          {t('entity.order.orderItems.amount') + ':'}
                        </Text>
                      </Grid.Col>

                      <Grid.Col xs={2}>
                        <Text size="sm" weight={500}>
                          {t('entity.order.orderItems.price') + ':'}
                        </Text>
                      </Grid.Col>

                      <Grid.Col xs={2}>
                        <Text size="sm" weight={500}>
                          {t('entity.order.orderItems.netPrice') + ':'}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {Object.keys(order['ordersByProduct'][`${key}`]['orderItemsByProduct']).map((orderItems) => {
                      const orderItem = order['ordersByProduct'][`${key}`]['orderItemsByProduct'][`${orderItems}`];
                      const summary = orderItem['summary']
                        ? orderItem['summary']
                        : order['ordersByProduct'][`${key}`]['productWithoutForm'];
                      const productQuantity = orderItem['quantity'];
                      const areaOrWidth = orderItem['productArea']
                        ? `${+(orderItem['productArea'] * productQuantity).toFixed(2)} m2`
                        : orderItem['productWidth']
                        ? `${+(orderItem['productWidth'] * productQuantity).toFixed(2)} fm`
                        : productQuantity;

                      const netPrice = orderItem['netPrice'] || 0;
                      const productNetPrice = orderItem['productPrice'] * productQuantity;

                      return (
                        <Grid key={`${key}_${orderItems}`}>
                          <Grid.Col xs={6}>
                            <Text>{summary}</Text>
                          </Grid.Col>

                          <Grid.Col xs={2}>
                            <Text>{areaOrWidth}</Text>
                          </Grid.Col>

                          {netPrice !== null && (
                            <Grid.Col xs={2}>
                              <Text>
                                <MoneyFormat size="sm">{netPrice}</MoneyFormat>
                              </Text>
                            </Grid.Col>
                          )}

                          {netPrice !== null && (
                            <Grid.Col xs={2}>
                              <Text>
                                <MoneyFormat size="sm">{productNetPrice}</MoneyFormat>
                              </Text>
                            </Grid.Col>
                          )}
                        </Grid>
                      );
                    })}

                    {Object.keys(order['ordersByProduct'][`${key}`]['accessories']).length > 0 && (
                      <Grid mt="md" mb="xs">
                        <Grid.Col xs={6}>
                          <Text size="sm" weight={500}>
                            {t('entity.form.accessories')}:
                          </Text>
                        </Grid.Col>

                        <Grid.Col xs={2}>
                          <Text size="sm" weight={500}>
                            {t('entity.order.orderItems.quantity') + ':'}
                          </Text>
                        </Grid.Col>

                        <Grid.Col xs={2}>
                          <Text size="sm" weight={500}>
                            {t('entity.order.orderItems.price') + ':'}
                          </Text>
                        </Grid.Col>

                        <Grid.Col xs={2}>
                          <Text size="sm" weight={500}>
                            {t('entity.order.orderItems.netPrice') + ':'}
                          </Text>
                        </Grid.Col>
                      </Grid>
                    )}

                    {Object.prototype.hasOwnProperty.call(order['ordersByProduct'][`${key}`], 'accessories') &&
                      Object.keys(order['ordersByProduct'][`${key}`]['accessories'])
                        ?.sort()
                        .map((accessoryKey) => {
                          const quantity =
                            order['ordersByProduct'][`${key}`]['accessories'][`${accessoryKey}`]['quantity'];
                          const netPrice =
                            order['ordersByProduct'][`${key}`]['accessories'][`${accessoryKey}`]['price'] || 0;
                          const price = quantity !== 0 ? netPrice / quantity : 0;

                          return (
                            <Grid key={accessoryKey}>
                              <Grid.Col xs={6}>
                                <Text>{accessoryKey}</Text>
                              </Grid.Col>

                              <Grid.Col xs={2}>
                                <Text>{quantity ?? ''}</Text>
                              </Grid.Col>

                              {netPrice > 0 && (
                                <Grid.Col xs={2}>
                                  <Text>
                                    <MoneyFormat size="sm">{price ?? ''}</MoneyFormat>
                                  </Text>
                                </Grid.Col>
                              )}

                              {netPrice > 0 && (
                                <Grid.Col xs={2}>
                                  <Text>
                                    <MoneyFormat size="sm">{netPrice ?? ''}</MoneyFormat>
                                  </Text>
                                </Grid.Col>
                              )}
                            </Grid>
                          );
                        })}

                    {order['ordersByProduct'][`${key}`]['accessoriesPrice'] > 0 && (
                      <Group position="left">
                        <Text size="sm" weight={500} mt="md">
                          {t('entity.form.accessories')} ára:{' '}
                        </Text>

                        <Text mt="md">
                          <MoneyFormat size="sm">{order['ordersByProduct'][`${key}`]['accessoriesPrice']}</MoneyFormat>
                        </Text>
                      </Group>
                    )}
                  </Paper>
                );
              })}
            </Paper>
          ))}
        </ScrollArea>
      </Box>
    </Page>
  );
}
