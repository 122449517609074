import { Type } from 'class-transformer';
import { ArrayNotEmpty, IsBoolean, IsEmail, IsNotEmpty, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { IsTrue } from '../../decorators/is-true.decorator';
import { UserCreateRequest } from '../settings/user.request';
import { PartnerAddressCreateRequest } from '../core-data/partner-address.request';
import { PartnerBankAccountCreateRequest } from '../core-data/partner-bank-account.request';
import { PartnerContactCreateRequest } from '../core-data/partner-contact.request';
import { PartnerCreateRequest } from '../core-data/partner.request';
import { IsSameAs } from '../../decorators/is-same-as.decorator';

export class ResellerCompanyCreateRequest {
  /**
   * The name of the partner
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The tax id number of the partner
   */
  @IsString()
  @IsNotEmpty()
  public taxIdNumber: string;

  /**
   * The registration number of the partner
   */
  @IsString()
  public registrationNumber?: string;

  /**
   * The central phone number of the partner
   */
  @IsString()
  public centralPhoneNumber?: string;

  /**
   * The central e-mail address of the partner
   */
  @IsEmail()
  @ValidateIf((o, e) => e !== '')
  public centralEmail?: string;

  /**
   * The website of the partner
   */
  @IsString()
  public website?: string;

  /**
   * The company logo of the partner
   */
  @IsString()
  public companyLogo?: string;

  /**
   * Internal notes of the partner
   */
  @IsString()
  public notes?: string;
}

export class ResellerContactsCreateRequest {
  /**
   * The contacts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerContactCreateRequest } })
  @Type(() => PartnerContactCreateRequest)
  public contacts?: PartnerContactCreateRequest[];
}
export class ResellerBankAccountsCreateRequest {
  /**
   * The bank accounts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerBankAccountCreateRequest } })
  @Type(() => PartnerBankAccountCreateRequest)
  public bankAccounts?: PartnerBankAccountCreateRequest[];
}
export class ResellerAddressesCreateRequest {
  /**
   * The addresses of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerAddressCreateRequest } })
  @Type(() => PartnerAddressCreateRequest)
  @ArrayNotEmpty()
  public addresses?: PartnerAddressCreateRequest[];
}

export class ResellerConfirmCreateRequest {
  /**
   * The confirmation of the Privacy and Terms of Use
   */
  @IsTrue(true)
  @IsBoolean()
  @IsNotEmpty()
  public confirmedPTU: boolean;
}

export class ResellerUserCreateRequest {
  /**
   * The last name of the user
   */
  @IsString()
  public lastName: string;

  /**
   * The first name of the user
   */
  @IsString()
  public firstName: string;

  /**
   * The e-mail address of the user
   */
  @IsEmail()
  @IsNotEmpty()
  public email: string;

  /**
   * The plain password of the user
   */
  @IsString()
  @IsNotEmpty()
  public password: string;

  /**
   * The repeated plain password of the user
   */
  @IsString()
  @IsSameAs('password')
  @IsNotEmpty()
  public passwordRepeat: string;

  /**
   * Optional job position of the user
   */
  @IsString()
  public position?: string;

  /**
   * The repeated email of the user
   */
  @IsSameAs('email')
  @IsNotEmpty()
  public emailRepeat: string;
}
export class ResellerCreateRequest {
  /**
   * The partner
   */
  @ValidateNested({ each: true, context: { type: PartnerCreateRequest } })
  @Type(() => PartnerCreateRequest)
  public partner: PartnerCreateRequest;

  /**
   * The connected users of the partner
   */
  @ValidateNested({ each: true, context: { type: UserCreateRequest } })
  @Type(() => UserCreateRequest)
  public user: UserCreateRequest;
}
