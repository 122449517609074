import { OrderStatus } from '@prisma/client';
import { Type } from 'class-transformer';
import { IsNotEmpty, IsString, ValidateNested, IsDateString, IsNumber, IsOptional, IsBoolean } from 'class-validator';
import { OrderItemCreateRequest, OrderItemUpdateRequest } from './order-item.request';

export class OrderCreateRequest {
  /**
   * True if the order is billed
   */
   @IsBoolean()
   @IsNotEmpty()
   public billed: boolean;

  /**
   * The id of customer of the order
   */
  @IsString()
  @IsNotEmpty()
  public partnerId: string;

  /**
   * The invoice address of the order
   */
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The status of the order
   */
  @IsString()
  @IsNotEmpty()
  public status: OrderStatus;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsNotEmpty()
  public deadlineDate: Date;

  /**
   * The note of the order
   */
  @IsString()
  public note: string;

  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The generated number of the order by the seller
   */
  @IsString()
  @IsOptional()
  public orderNumber?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  public price: number;

  /**
   * The items of the order
   */
  @ValidateNested({ each: true, context: { type: OrderItemCreateRequest } })
  @Type(() => OrderItemCreateRequest)
  public orderItems: OrderItemCreateRequest[];
}

export class OrderUpdateRequest {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * True if the order is billed
   */
  @IsBoolean()
  @IsOptional()
  public billed?: boolean;

  /**
   * The id of customer of the order
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The invoice address of the order
   */
  @IsString()
  @IsOptional()
  public invoiceAddress?: string;

  /**
   * The delivery address of the order
   */
  @IsString()
  @IsOptional()
  public deliveryAddress?: string;

  /**
   * The status of the order
   */
  @IsString()
  @IsOptional()
  public status?: OrderStatus;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsOptional()
  public deadlineDate?: Date;

  /**
   * The note of the order
   */
  @IsString()
  @IsOptional()
  public note?: string;

  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  @IsOptional()
  public price?: number;

  /**
   * The items of the order
   */
  @IsOptional()
  @ValidateNested({ each: true, context: { type: OrderItemUpdateRequest } })
  @Type(() => OrderItemUpdateRequest)
  public orderItems?: OrderItemUpdateRequest[];
}
