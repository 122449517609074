import { OrderStatus, Prisma } from '@prisma/client';
import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsString,
  ValidateNested,
  IsObject,
  IsDateString,
  IsNumber,
  IsOptional,
  IsBoolean,
  IsPositive,
} from 'class-validator';
import { IsNotDefaultObject } from '../../../decorators/is-not-default-object.decorator';
import { PartnerResponse } from '../../core-data/partner.response';
import { ProductResponse } from '../product.response';

export class OrderItemCreateFormDto {
  /**
   * The product of the order item
   */
  @IsNotDefaultObject({ id: '', name: '' })
  @IsObject()
  @IsOptional()
  public product?: ProductResponse;

  /**
   * Product attribute configurations
   */
  @IsObject()
  @IsOptional()
  public productConfig?: Prisma.JsonValue;

  /**
   * False if the reseller added
   */
  @IsBoolean()
  public addedByVendor: boolean;

  /**
   * The quantity of the product
   */
  @IsNumber()
  @IsPositive()
  public quantity: number;

  /**
   * The price of the order item
   */
  @IsNumber()
  public price: number;
}

export class OrderItemUpdateFormDto {
  /**
   * CUID identifier of the order item
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The product of the order item
   */
  @IsNotDefaultObject({ id: '', name: '' })
  @IsObject()
  @IsNotEmpty()
  public product: ProductResponse;

  /**
   * Product attribute configurations
   */
  @IsObject()
  @IsOptional()
  public productConfig?: Prisma.JsonValue;

  /**
   * False if the reseller added
   */
  @IsBoolean()
  public addedByVendor: boolean;

  /**
   * The quantity of the product
   */
  @IsNumber()
  @IsPositive()
  public quantity: number;
  /**
   * The price of the order item
   */
  @IsNumber()
  public price: number;
}

export class OrderCreateFormDto {
  /**
   * True if the order is billed
   */
  @IsBoolean()
  @IsNotEmpty()
  public billed: boolean;

  /**
   * The id of customer of the order
   */
  @IsObject()
  @IsNotEmpty()
  public partner: PartnerResponse;

  /**
   * The invoice address of the order
   */
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The status of the order
   */
  @IsString()
  @IsNotEmpty()
  public status: OrderStatus;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsNotEmpty()
  public deadlineDate: Date;

  /**
   * The note of the order
   */
  @IsString()
  public note: string;

  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  public price: number;

  /**
   * The items of the order
   */
  @ValidateNested({ each: true, context: { type: OrderItemCreateFormDto } })
  @Type(() => OrderItemCreateFormDto)
  public orderItems: OrderItemCreateFormDto[];
}

export class OrderUpdateFormDto {
  /**
   * CUID identifier of the order
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * True if the order is billed
   */
  @IsBoolean()
  @IsNotEmpty()
  public billed: boolean;

  /**
   * The id of customer of the order
   */
  @IsObject()
  @IsNotEmpty()
  public partner: PartnerResponse;

  /**
   * The invoice address of the order
   */
  @IsString()
  @IsNotEmpty()
  public invoiceAddress: string;

  /**
   * The delivery address of the order
   */
  @IsString()
  @IsNotEmpty()
  public deliveryAddress: string;

  /**
   * The status of the order
   */
  @IsString()
  @IsNotEmpty()
  public status: OrderStatus;

  /**
   * The date of the deadline of the order
   */
  @IsDateString()
  @IsOptional()
  public deadlineDate?: Date;

  /**
   * The note of the order
   */
  @IsString()
  @IsOptional()
  public note?: string;
  /**
   * The unique id of the order by the partner
   */
  @IsString()
  @IsOptional()
  public orderId?: string;

  /**
   * The price of the order
   */
  @IsNumber()
  @IsOptional()
  public price?: number;

  /**
   * The items of the order
   */
  @IsOptional()
  @ValidateNested({ each: true, context: { type: OrderItemUpdateFormDto } })
  @Type(() => OrderItemUpdateFormDto)
  public orderItems?: OrderItemUpdateFormDto[];
}
