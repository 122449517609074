import { IsEmail, IsNotEmpty } from 'class-validator';

export class LoginRequest {
  /**
   * E-mail address of the user
   */
  @IsEmail()
  @IsNotEmpty()
  email: string;

  /**
   * Plain password of the user
   */
  @IsNotEmpty()
  password: string;
}
