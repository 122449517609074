import { PriceListVersionResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany, getOne } from '../plugins/axios';

export function usePriceListVersions(id?: string) {
  let result;
  if (id) {
    result = useQuery(['price-list-versions', id], getOne<PriceListVersionResponse>('price-list-versions', id));
  } else {
    result = useQuery(
      ['price-list-versions', 'list-versions'],
      getMany<PriceListVersionResponse[]>('price-list-versions/list-versions')
    );
  }

  return result.data ? result.data : [];
}
