import { Box, Button, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconPlus } from '@tabler/icons-react';
import { ProductCategoryResponse } from '@gravity/shared/dto';
import { EntityTable, EntityTableColumnDef } from '@gravity/frontend/ui';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { PageTitle } from '../../../components/PageTitle';
import { Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Page } from '../../../components/Page';
import { Link } from 'react-router-dom';

export function ProductCategoriesPage() {
  const { t } = useTranslation();

  const query = useTableQuery<ProductCategoryResponse>('product-categories');

  const cols: EntityTableColumnDef<ProductCategoryResponse>[] = [{ accessorKey: 'name' }];

  return (
    <Page>
      <PageTitle title={t('sales.productCategories.title')} subtitle={t('sales.productCategories.subtitle')}>
        <PermissionCheck hasAll={[Permission.product_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" style={{ height: 'calc(100vh - 58px)' }} pb="sm">
        <PermissionCheck hasAll={[Permission.product_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="productCategories"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Page>
  );
}
