import { Box, Button, Center, FileInput, FileInputProps, Group, Input } from '@mantine/core';
import { StorageFile } from '@prisma/client';
import { IconFileAnalytics, IconFileText, IconPhoto, IconUpload } from '@tabler/icons-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrFileInputProps extends BaseInputProps {
  multiple?: boolean;
  file?: StorageFile;
  onClick: (fileId: string) => void;
}

function GrFileInputValue({ file }: { file: File | null }) {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        borderRadius: theme.radius.sm,
      })}
    >
      {file &&
        file.type &&
        ((file.type.startsWith('image') && <IconPhoto size={14} style={{ marginRight: 5 }} />) ||
          ((file.type.startsWith('application/pdf') ||
            file.type.startsWith('application/msword') ||
            file.type.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) && (
            <IconFileText size={14} style={{ marginRight: 5 }} />
          )) ||
          ((file.type.startsWith('application/vnd.ms-excel') ||
            file.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) && (
            <IconFileAnalytics size={14} style={{ marginRight: 5 }} />
          )))}

      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 200,
          display: 'inline-block',
        }}
      >
        {file?.name}
      </span>
    </Center>
  );
}

const GrFileInputValueComponent: FileInputProps['valueComponent'] = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <Group spacing="sm" py="xs">
        {value.map((file, index) => (
          <GrFileInputValue file={file} key={index} />
        ))}
      </Group>
    );
  }

  return <GrFileInputValue file={value} />;
};

export function GrFileInput({ name, data, loading, disabled, creatable, ...props }: GrFileInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  const file = props.file;

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName) || file) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mb={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Group>
            {file && (
              <Center
                key={labelName + file.filename}
                inline
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
                  fontSize: theme.fontSizes.xs,
                  padding: '3px 7px',
                  borderRadius: theme.radius.sm,
                })}
              >
                <>
                  {(file.mimetype.startsWith('image') && <IconPhoto size={18} />) ||
                    ((file.mimetype.startsWith('application/pdf') ||
                      file.mimetype.startsWith('application/msword') ||
                      file.mimetype.startsWith(
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      )) && <IconFileText size={18} />) ||
                    ((file.mimetype.startsWith('application/vnd.ms-excel') ||
                      file.mimetype.startsWith(
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      )) && <IconFileAnalytics size={18} />)}

                  <Button
                    style={{
                      backgroundColor: 'inherit',
                      color: 'inherit',
                    }}
                    onClick={() => props.onClick(file.id)}
                  >
                    {file.filename + '.' + file.extension}

                    <br />

                    {Math.ceil((file.fileSize.valueOf() / (1024 * 1024)) * 100) / 100 + 'MB'}
                  </Button>
                </>
              </Center>
            )}
          </Group>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => (
        <FileInput
          {...field}
          {...props.forwardedProps}
          error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
          mb="12px"
          label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
          description={props.description}
          disabled={formState.isSubmitting}
          required={form.requiredFields.includes(labelName)}
          icon={<IconUpload size={16} />}
          multiple={props.multiple}
          accept={
            'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          } //elfogadott típusok
          onChange={(payload: File[] | File) => {
            if (payload) {
              field.onChange(payload as File);
            } else {
              field.onChange(undefined);
            }
          }}
          valueComponent={field.value ? GrFileInputValueComponent : undefined}
        />
      )}
    />
  );
}
