import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { AluminiumRollerBlindRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import {
  Group,
  Button,
  Divider,
  ScrollArea,
  Grid,
  Title,
  Text,
  Collapse,
  Input,
  Image,
  ActionIcon,
  Modal,
} from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle, IconZoomIn } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';

interface AluminiumRollerBlindProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: AluminiumRollerBlindRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function AluminiumRollerBlind(props: AluminiumRollerBlindProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const [opened, { open, close }] = useDisclosure(false);

  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;
  const arbPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];
  const arbMotorPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_motor')
      ?.priceListItems ?? [];
  const arbRemoteControllerPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_remote_controller')
      ?.priceListItems ?? [];
  const arbAccessoriesPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_accessories')
      ?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }
  const productForm = useDtoForm([AluminiumRollerBlindRequest, AluminiumRollerBlindRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues:
      {
        ...(orderItem.productConfig as any),
        arb_quantity: props.quantity,
        arb_wideFlangeCaseEnding: false,
      } ?? undefined,
  });

  const { watch } = productForm;

  const hasRal =
    productForm.getValues('arb_reedColor') === 'RAL' ||
    productForm.getValues('arb_closingSlatColor') === 'RAL' ||
    productForm.getValues('arb_railColor') === 'RAL' ||
    productForm.getValues('arb_caseColor') === 'RAL' ||
    productForm.getValues('arb_internalFitmentColor') === 'RAL';

  const itemsBySide = ['arb_upperOutlet', 'arb_motorType', 'arb_remote_controller'];
  const itemsByPiece = ['arb_split', 'arb_rltp', 'arb_automation', 'arb_inlet', 'arb_spring', 'arb_coverButton'];
  const itemsByTypeAndSide = ['arb_typeLeftSide', 'arb_typeRightSide', 'arb_combinedRail', 'arb_combinedRailRight'];

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  function getCoverButtonQuantity() {
    const height = productForm.getValues('arb_height') ?? 0;
    const coverButtonByHeight = Math.ceil(height / 50) * 2;
    const coverButtonQuantity =
      watch('arb_withoutCoverButtons') || watch('arb_withoutAccessories')
        ? 0
        : watch('arb_split')
        ? (coverButtonByHeight ?? 0) * 2
        : coverButtonByHeight;

    productForm.setValue('arb_coverButtonQuantity', coverButtonQuantity);

    if (coverButtonQuantity === 0) {
      productForm.setValue('arb_coverButton', '');
    } else {
      const coverButton = arbAccessoriesPriceList.find((priceListItem: PriceListItemResponse) =>
        watch('arb_railColor') === 'Fehér'
          ? priceListItem.code === 'rt_032_csomag'
          : priceListItem.code === 'rt_034_csomag'
      )?.label;

      productForm.setValue('arb_coverButton', coverButton);
    }
  }

  //Calculated area values for pricing
  useEffect(() => {
    const width = productForm.getValues('arb_width') ?? 0;
    const height = productForm.getValues('arb_height') ?? 0;
    const rightSideWidth = productForm.getValues('arb_splitRightSideWidth') ?? 0;

    getCoverButtonQuantity();

    productForm.setValue('arb_areaLeftSide', ((width - rightSideWidth) * height) / 10000);
    productForm.setValue('arb_areaRightSide', (rightSideWidth * height) / 10000);
    productForm.setValue('arb_roundedAreaLeft', getRoundedArea(productForm.getValues('arb_areaLeftSide')));
    productForm.setValue('arb_roundedAreaRight', getRoundedArea(productForm.getValues('arb_areaRightSide')));

    console.log('Terület (bal)', watch('arb_areaLeftSide'));
    console.log('Terület (jobb)', watch('arb_areaRightSide'));
    console.log('Kerekített terület (bal)', watch('arb_roundedAreaLeft'));
    console.log('Kerekített terület (jobb)', watch('arb_roundedAreaRight'));

    getLockToPlayPrice();
    getAccessoriesPrice();
  }, [watch('arb_width'), watch('arb_height'), watch('arb_splitRightSideWidth')]);

  //Set default values if split is unchecked
  useEffect(() => {
    if (!watch('arb_split')) {
      productForm.setValue('arb_splitRightSideWidth', 0);
      productForm.setValue('arb_combinedRailRight', false);
      productForm.setValue('arb_builtInMosquitoNetRight', false);
    }
  }, [watch('arb_split')]);

  //Built in mosquito net: true -> combined rail: true
  useEffect(() => {
    if (watch('arb_builtInMosquitoNet') || watch('arb_builtInMosquitoNetRight')) {
      productForm.setValue('arb_combinedRail', true);
      if (watch('arb_split')) {
        productForm.setValue('arb_combinedRailRight', true);
      }
    } else if (watch('arb_split')) {
      productForm.setValue('arb_combinedRailRight', watch('arb_combinedRail'));
    }
  }, [
    watch('arb_builtInMosquitoNet'),
    watch('arb_builtInMosquitoNetRight'),
    watch('arb_combinedRail'),
    watch('arb_split'),
  ]);

  useEffect(() => {
    if (watch('arb_operationType') === 'Motor') {
      productForm.setValue('arb_upperOutlet', false);
    } else {
      productForm.setValue('arb_motor', '');
      productForm.setValue('arb_motorType', '');
      productForm.setValue('arb_remote_controller', '');
      productForm.setValue('arb_remoteControllerQuantity', 0);
    }

    if (watch('arb_operationType') !== 'Zsinór') {
      productForm.setValue('arb_wihtoutSpring', false);
    }

    getMotorPrice();
    getLockToPlayPrice();
  }, [watch('arb_operationType')]);

  useEffect(() => {
    getTypePrice('arb_typeLeftSide');
  }, [watch('arb_closingSlatColor'), watch('arb_railColor'), watch('arb_caseColor'), watch('arb_builtInMosquitoNet')]);

  useEffect(() => {
    if (watch('arb_split')) {
      getTypePrice('arb_typeRightSide');
      getCombinedRailPrice('arb_combinedRailRight');
    }
    getCombinedRailPrice('arb_combinedRail');
  }, [
    watch('arb_closingSlatColor'),
    watch('arb_railColor'),
    watch('arb_caseColor'),
    watch('arb_builtInMosquitoNet'),
    watch('arb_builtInMosquitoNetRight'),
    watch('arb_split'),
    watch('arb_combinedRail'),
  ]);

  useEffect(() => {
    getMotorPrice();
    if (!watch('arb_motor')) {
      productForm.setValue('arb_motorType', '');
      productForm.setValue('arb_motorQuantity', 0);
      productForm.setValue('arb_remote_controller', '');
      productForm.setValue('arb_remoteControllerQuantity', 0);
    }
  }, [watch('arb_motor'), watch('arb_remoteControllerQuantity')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('arb_width'), watch('arb_height'), watch('arb_splitRightSideWidth')]);

  useEffect(() => {
    getReedColorPrice();
    getRalColorPrice();
  }, [watch('arb_typeLeftSide'), watch('arb_typeRightSide')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let leftTypePrice = 0; // Prices that depend on the type and split properties by area
    let rightTypePrice = 0; // Prices that depend on the type and split properties by area
    let piecePrice = 0; // Prices by piece for the entire aluminium roller blind
    let sidePrice = 0; // Prices by piece and by side

    Object.keys(productForm.getValues('priceList') as any).forEach((key) => {
      if (itemsBySide.includes(key)) {
        sidePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByPiece.includes(key)) {
        piecePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByTypeAndSide.includes(key)) {
        if (key.includes('Right')) {
          rightTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        } else {
          leftTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        }
      } else {
        areaPrice += (productForm.getValues('priceList') as any)[`${key}`];
      }
    });

    const leftArea = watch('arb_roundedAreaLeft');
    const rightArea = watch('arb_roundedAreaRight');
    const area = leftArea + rightArea;
    areaPrice = areaPrice * area;
    leftTypePrice = leftTypePrice * leftArea;
    rightTypePrice = rightTypePrice * rightArea;
    sidePrice = watch('arb_split') ? sidePrice * 2 : sidePrice;
    productForm.setValue('price', areaPrice + sidePrice + piecePrice + leftTypePrice + rightTypePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getCaseSizeSublist(): string[] {
    if (getConditionSublistsFor('arb_caseSize')[0] === 'Egyedi') {
      return getConditionSublistsFor('arb_caseSize');
    }

    const caseSizeList =
      watch('arb_caseColor') === 'Mahagóni' && !watch('arb_plasterable')
        ? getConditionSublistsFor('arb_caseSize').filter((caseSize) => !caseSize.startsWith('180'))
        : getConditionSublistsFor('arb_caseSize');

    if (!watch('arb_plasterable')) {
      return caseSizeList.filter((caseSize) => caseSize.includes('45'));
    } else {
      return caseSizeList.filter((caseSize) => caseSize.includes('20'));
    }
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;

    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1.3) {
      return 1.3;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getTypePrice(arb_type: string) {
    if (getConditionSublistsFor(`${arb_type}`).length > 1) {
      productForm.setValue(`${arb_type}`, '');
    } else {
      productForm.setValue(`${arb_type}`, getConditionSublistsFor(`${arb_type}`)[0]);
    }

    let price = 0;
    switch (watch(`${arb_type}`)) {
      case 'Fehér':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_001_s')?.price ?? 0;
        break;
      case 'Fehér - kombi':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_002_k')?.price ?? 0;
        break;
      case 'Antracit, Barna':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_003_s')?.price ?? 0;
        break;
      case 'Antracit, Barna - kombi':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_004_k')?.price ?? 0;
        break;
      case 'Fa imitációs':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_005_s')?.price ?? 0;
        break;
      case 'Fa imitációs - kombi':
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_006_k')?.price ?? 0;
        break;
      default:
        price =
          arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_001_s')?.price ?? 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ [`${arb_type}`]: price },
    });
  }

  function getCaseSizePrice() {
    const arb_caseSize =
      arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_020')?.price ?? 0;
    if (getCaseSizeSublist().sort()[0] !== watch('arb_caseSize')) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_caseSize } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_caseSize: 0 } });
    }
  }

  function getReedColorPrice() {
    const reedColor = watch('arb_reedColor');
    const type = watch('arb_typeLeftSide');

    if (
      reedColor !== 'Fehér' &&
      (type.startsWith('Fehér') || (type.startsWith('Antracit') && !['Sötétbarna', 'Antracit'].includes(reedColor)))
    ) {
      const arb_reedColor =
        arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_013')?.price ?? 0;

      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_reedColor } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_reedColor: 0 } });
    }
  }

  function getRalColorPrice() {
    if (getConditionSublistsFor('arb_ral').length > 1) {
      productForm.setValue('arb_ral', '');
    } else {
      productForm.setValue('arb_ral', getConditionSublistsFor('arb_ral')[0]);
    }

    let arb_ral = 0;
    if (watch('arb_ral') === 'Záróléc') {
      arb_ral =
        arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_017')?.price ?? 0;
    } else if (watch('arb_ral') === 'Ral') {
      arb_ral =
        arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'arb_016')?.price ?? 0;
    } else {
      arb_ral = 0;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ arb_ral },
    });
  }

  function getCombinedRailPrice(label: string) {
    const renolit = ['Aranytölgy', 'Dió', 'Mahagóni', 'RAL'];
    const code = renolit.includes(watch('arb_railColor')) ? 'arb_019' : 'arb_018';
    const price = arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0;
    const isbuiltInMosquitoNet = label.includes('Right')
      ? watch('arb_builtInMosquitoNetRight')
      : watch('arb_builtInMosquitoNet');

    if (watch(label) && !isbuiltInMosquitoNet) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: 0 } });
    }
  }

  function getMotorPrice() {
    let arb_motorType = 0;
    let arb_remote_controller = 0;
    const quantity = watch('arb_remoteControllerQuantity') ?? 1;

    if (watch('arb_motorType')) {
      arb_motorType =
        arbMotorPriceList?.find(
          (priceListItem: PriceListItemResponse) => priceListItem.label === watch('arb_motorType')
        )?.price ?? 0;
    }

    if (watch('arb_remote_controller')) {
      arb_remote_controller =
        arbRemoteControllerPriceList?.find(
          (priceListItem: PriceListItemResponse) => priceListItem.label === watch('arb_remote_controller')
        )?.price ?? 0;
      if (!watch('arb_remoteControllerQuantity')) {
        productForm.setValue('arb_remoteControllerQuantity', 1);
      }
    } else {
      productForm.setValue('arb_remote_controller', '');
      productForm.setValue('arb_remoteControllerQuantity', 0);
    }

    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_motorType } });
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ arb_remote_controller: arb_remote_controller * quantity },
    });
  }

  function getLockToPlayPrice() {
    const lockToPlay =
      arbMotorPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.label === 'RLTP')?.price ?? 0;
    const rightWidth = productForm.getValues('arb_splitRightSideWidth') ?? 0;
    const leftWidth = productForm.getValues('arb_width') - rightWidth ?? 0;
    const rltp = Math.ceil(leftWidth / 50) + Math.ceil(rightWidth / 50);
    if (watch('arb_operationType') === 'Motor') {
      productForm.setValue('arb_rltp', rltp);
      productForm.setValue('arb_motorQuantity', watch('arb_split') ? 2 : 1);
    } else {
      productForm.setValue('arb_rltp', 0);
      productForm.setValue('arb_motorQuantity', 0);
    }

    let arb_rltp = 0;
    if (watch('arb_operationType') === 'Motor') {
      if (productForm.getValues('arb_width') <= 50) {
        arb_rltp = 2 * lockToPlay;
      } else {
        arb_rltp = rltp * lockToPlay;
      }
    } else {
      arb_rltp = 0;
    }
    productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ arb_rltp } });
  }

  function getAccessoriesPrice() {
    const accessoriesList = watch('arb_operationType') !== 'Motor' ? getConditionSublistsFor('arb_accessories') : [];
    let arb_automation = 0;
    let arb_inlet = 0;
    let arb_spring = 0;
    let arb_coverButton = 0;

    // Automata és bevezető felár és darabszám
    if (accessoriesList.length === 2) {
      productForm.setValue('arb_automation', accessoriesList[0]);
      productForm.setValue('arb_inlet', accessoriesList[1]);
      accessoriesList.forEach((accessory, index) => {
        const accessoryPrice =
          arbAccessoriesPriceList.find(
            (priceListItem: PriceListItemResponse) =>
              priceListItem.label.includes(accessory) &&
              !priceListItem.code.includes('db') &&
              !priceListItem.packaging?.includes('(')
          )?.price ?? 0;
        if (index === 0) {
          arb_automation = watch('arb_split') ? accessoryPrice * 2 : accessoryPrice;
        } else {
          arb_inlet = watch('arb_split') ? accessoryPrice * 2 : accessoryPrice;
        }
      });
    } else {
      productForm.setValue('arb_automation', '');
      productForm.setValue('arb_inlet', '');
    }
    const accessoryQuantity =
      watch('arb_withoutAccessories') || watch('arb_withoutAutomation') || watch('arb_operationType') === 'Motor'
        ? 0
        : watch('arb_split')
        ? 2
        : 1;
    productForm.setValue('arb_automationQuantity', accessoryQuantity);
    productForm.setValue('arb_inletQuantity', accessoryQuantity);

    // Rugó felár és darabszám
    if (watch('arb_operationType') === 'Zsinór' && !watch('arb_wihtoutSpring') && !watch('arb_withoutAccessories')) {
      const spring = arbAccessoriesPriceList.find(
        (priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_028_csomag'
      );
      const springPrice = spring?.price ?? 0;
      arb_spring = watch('arb_split') ? springPrice * 2 : springPrice;
      productForm.setValue('arb_spring', spring?.label);
    } else {
      productForm.setValue('arb_spring', '');
    }
    productForm.setValue(
      'arb_springQuantity',
      watch('arb_withoutAccessories') || watch('arb_operationType') !== 'Zsinór' || watch('arb_wihtoutSpring')
        ? 0
        : watch('arb_split')
        ? 2
        : 1
    );

    // Takarógomb felár és darabszám
    getCoverButtonQuantity();
    if (!watch('arb_withoutCoverButtons') && !watch('arb_withoutAccessories')) {
      let coverButtonPrice = 0;
      if (watch('arb_railColor') === 'Fehér' || watch('arb_railColor') === '') {
        coverButtonPrice =
          arbAccessoriesPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_032_csomag')
            ?.price ?? 0;
      } else {
        coverButtonPrice =
          arbAccessoriesPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_034_csomag')
            ?.price ?? 0;
      }
      arb_coverButton = coverButtonPrice * (watch('arb_coverButtonQuantity') ?? 0);
    }

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ arb_automation, arb_inlet, arb_spring, arb_coverButton },
    });
  }

  function getPriceFor(label: string, code: string) {
    const price = arbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0;
    if (watch(label)) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: 0 } });
    }
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: AluminiumRollerBlindRequest) => {
        if (!hasRal) {
          await delete data.arb_ralCode;
        }
        data.summary = !data.arb_split
          ? 'Szélesség: ' +
            data.arb_width.toLocaleString() +
            ' cm, Magasság: ' +
            data.arb_height.toLocaleString() +
            ' cm'
          : 'Bal oldal szélessége: ' +
            (data.arb_width - (data.arb_splitRightSideWidth ? data.arb_splitRightSideWidth : 0)).toLocaleString() +
            ' cm, Jobb oldal szélessége: ' +
            (data.arb_splitRightSideWidth ? data.arb_splitRightSideWidth.toLocaleString() : 0) +
            ' cm, Magasság: ' +
            data.arb_height.toLocaleString() +
            ' cm';

        data.summary +=
          ', Működtetés: ' +
          (data.arb_operationType != 'Motor'
            ? data.arb_operationType
            : data.arb_operationType + ' - ' + data.arb_motorType);
        data.summary += ', Kezelés: ' + data.arb_operationSide;

        const dash = ' - ';
        const combined = data.arb_builtInMosquitoNet || data.arb_builtInMosquitoNetRight;
        const withCombinedRail =
          !data.arb_builtInMosquitoNet && !data.arb_builtInMosquitoNetRight && data.arb_combinedRail;
        data.invoiceName =
          (data.arb_plasterable ? getLabelFor('arb_plasterable') + ' ' : '') + //Vakolható
          product['name'].split(' ')[0] + //Alumínium
          ' ' +
          (combined ? uppercaseFirstLetter(getLabelFor('arb_combinedRail').split(' ')[0]) + ' ' : '') + //Kombi
          uppercaseFirstLetter(product['name'].split(' ')[1]) + //Redőny
          (withCombinedRail ? ', ' + uppercaseFirstLetter(getLabelFor('arb_combinedRail')) + 'val ' : '') + //Kombi lefutóval
          dash +
          data.arb_reedColor + //Szín
          ' színben';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="arb_width" label={getLabelFor('arb_width')} min={0} precision={2} controls />

            {!orderReadonlyMode && getErrorMessageFor('arb_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('arb_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput
              name="arb_height"
              label={getLabelFor('arb_height')}
              max={330}
              min={0}
              precision={2}
              controls
            />

            {!orderReadonlyMode && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getDescriptionFor('arb_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          {!orderReadonlyMode && getErrorMessageFor('arb_height') && (
            <Grid.Col xs={4} mt="lg">
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('arb_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          <Grid.Col>
            <GrSwitchInput
              name="arb_split"
              label={getLabelFor('arb_split')}
              description={getDescriptionFor('arb_split')}
              onChange={() => getPriceFor('arb_split', 'arb_009_db')}
            />
          </Grid.Col>

          <Grid.Col>
            <Title pb={0} order={5}>
              {t('entity.form.combined')}
            </Title>

            <Text fs="italic">{getDescriptionFor('arb_builtInMosquitoNet')}</Text>
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="arb_builtInMosquitoNet" label={getLabelFor('arb_builtInMosquitoNet')} />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="arb_combinedRail" label={getLabelFor('arb_combinedRail')} />
          </Grid.Col>
        </Grid>

        <Collapse in={!!watch('arb_split')}>
          <Divider m="lg" />

          <Grid m={10}>
            <Grid.Col xs={4}>
              <GrNumberInput
                name="arb_splitRightSideWidth"
                label={getLabelFor('arb_splitRightSideWidth')}
                min={10}
                max={watch('arb_width') - 10}
                precision={2}
                controls
              />
            </Grid.Col>
          </Grid>

          <Grid m={10}>
            <Grid.Col>
              <Title pb={0} order={5}>
                {t('entity.form.combinedRight')}
              </Title>

              <Text fs="italic">{getDescriptionFor('arb_builtInMosquitoNetRight')}</Text>
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="arb_builtInMosquitoNetRight" label={getLabelFor('arb_builtInMosquitoNetRight')} />
            </Grid.Col>

            <Grid.Col xs={8}>
              <GrSwitchInput name="arb_combinedRailRight" label={getLabelFor('arb_combinedRailRight')} />

              {!orderReadonlyMode && (
                <Text c="dimmed" fz="xs">
                  {getDescriptionFor('arb_combinedRailRight')}
                </Text>
              )}
            </Grid.Col>
          </Grid>
        </Collapse>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="arb_operationType"
              label={getLabelFor('arb_operationType')}
              data={getConditionSublistsFor('arb_operationType')}
              description={getConditionDescriptionFor('arb_operationType')}
              disabled={getDisabledStateFor('arb_operationType')}
              onEmptyState={() => warningNotification(getLabelFor('arb_operationType'))}
              onChange={getAccessoriesPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="arb_operationSide"
              label={getLabelFor('arb_operationSide')}
              data={getConditionSublistsFor('arb_operationSide')}
              description={getConditionDescriptionFor('arb_operationSide')}
              disabled={getDisabledStateFor('arb_operationSide')}
              onEmptyState={() => warningNotification(getLabelFor('arb_operationSide'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          {watch('arb_operationType') !== 'Motor' && (
            <Grid.Col xs={4}>
              <GrSwitchInput
                name="arb_upperOutlet"
                label={getLabelFor('arb_upperOutlet')}
                description={getDescriptionFor('arb_upperOutlet')}
                onChange={() => getPriceFor('arb_upperOutlet', 'arb_012_db')}
              />
            </Grid.Col>
          )}
        </Grid>

        {watch('arb_operationType') === 'Motor' && (
          <Grid m={10} style={{ transition: 'top 5s ease-in-out' }}>
            <Grid.Col xs={3}>
              <GrSelectInput
                name="arb_motor"
                label={getLabelFor('arb_motor')}
                data={getConditionSublistsFor('arb_motor')}
                description={getConditionDescriptionFor('arb_motor')}
                disabled={getDisabledStateFor('arb_motor')}
                onEmptyState={() => warningNotification(getLabelFor('arb_motor'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="arb_motorType"
                label={getLabelFor('arb_motorType')}
                data={watch('arb_motor') ? getConditionSublistsFor('arb_motorType') : []}
                description={getConditionDescriptionFor('arb_motorType')}
                disabled={getDisabledStateFor('arb_motorType')}
                onEmptyState={() => warningNotification(getLabelFor('arb_motorType'))}
                onChange={getMotorPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            <Grid.Col xs={3}>
              <GrSelectInput
                name="arb_remote_controller"
                label={getLabelFor('arb_remote_controller')}
                data={watch('arb_motor') ? getConditionSublistsFor('arb_remote_controller') : []}
                description={getConditionDescriptionFor('arb_remote_controller')}
                disabled={getDisabledStateFor('arb_remote_controller')}
                onEmptyState={() => warningNotification(getLabelFor('arb_remote_controller'))}
                onChange={getMotorPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>

            {watch('arb_remote_controller') && (
              <Grid.Col xs={3}>
                <GrNumberInput
                  name="arb_remoteControllerQuantity"
                  label={getLabelFor('arb_remoteControllerQuantity')}
                  min={1}
                  max={100}
                  controls
                />
              </Grid.Col>
            )}
          </Grid>
        )}

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="arb_caseSize"
              label={getLabelFor('arb_caseSize')}
              data={getCaseSizeSublist()}
              description={getConditionDescriptionFor('arb_caseSize')}
              disabled={getDisabledStateFor('arb_caseSize')}
              onEmptyState={() => warningNotification(getLabelFor('arb_caseSize'))}
              onChange={getCaseSizePrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="arb_quantity" label={getLabelFor('arb_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={2}>
            <GrSelectInput
              name="arb_reedColor"
              label={getLabelFor('arb_reedColor')}
              data={getConditionSublistsFor('arb_reedColor')}
              description={getConditionDescriptionFor('arb_reedColor')}
              disabled={getDisabledStateFor('arb_reedColor')}
              onChange={() => {
                getReedColorPrice();
                getRalColorPrice();
              }}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={2}>
            <GrSelectInput
              name="arb_closingSlatColor"
              label={getLabelFor('arb_closingSlatColor')}
              data={getConditionSublistsFor('arb_closingSlatColor')}
              description={getConditionDescriptionFor('arb_closingSlatColor')}
              disabled={getDisabledStateFor('arb_closingSlatColor')}
              onChange={getRalColorPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={2}>
            <GrSelectInput
              name="arb_railColor"
              label={getLabelFor('arb_railColor')}
              data={getConditionSublistsFor('arb_railColor')}
              description={getConditionDescriptionFor('arb_railColor')}
              disabled={getDisabledStateFor('arb_railColor')}
              onChange={getRalColorPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={2}>
            <GrSelectInput
              name="arb_caseColor"
              label={getLabelFor('arb_caseColor')}
              data={getConditionSublistsFor('arb_caseColor')}
              description={getConditionDescriptionFor('arb_caseColor')}
              disabled={getDisabledStateFor('arb_caseColor')}
              onChange={getRalColorPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          {watch('arb_operationType') !== 'Motor' && (
            <Grid.Col xs={3}>
              <GrSelectInput
                name="arb_internalFitmentColor"
                label={getLabelFor('arb_internalFitmentColor')}
                data={getConditionSublistsFor('arb_internalFitmentColor')}
                description={getConditionDescriptionFor('arb_internalFitmentColor')}
                disabled={getDisabledStateFor('arb_internalFitmentColor')}
                onChange={getAccessoriesPrice}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          {hasRal && (
            <Grid.Col xs={2}>
              <GrTextInput name="arb_ralCode" label={getLabelFor('arb_ralCode')} mask="9999" />

              {!orderReadonlyMode && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {'(4 számjegy)'}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          )}
        </Grid>

        <Grid>
          <Grid.Col span={'auto'}>
            <Grid m={10}>
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="arb_withoutAccessories"
                  label={getLabelFor('arb_withoutAccessories')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="arb_plasterable"
                  label={getLabelFor('arb_plasterable')}
                  onChange={() => getPriceFor('arb_plasterable', 'arb_010')}
                />
              </Grid.Col>
            </Grid>

            <Grid m={10}>
              {watch('arb_operationType') !== 'Motor' && (
                <Grid.Col xs={4}>
                  <GrSwitchInput
                    name="arb_withoutAutomation"
                    label={getLabelFor('arb_withoutAutomation')}
                    onChange={getAccessoriesPrice}
                  />
                </Grid.Col>
              )}

              {watch('arb_operationType') === 'Zsinór' && (
                <Grid.Col xs={4}>
                  <GrSwitchInput
                    name="arb_wihtoutSpring"
                    label={getLabelFor('arb_wihtoutSpring')}
                    onChange={getAccessoriesPrice}
                  />
                </Grid.Col>
              )}

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="arb_withoutCoverButtons"
                  label={getLabelFor('arb_withoutCoverButtons')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              {watch('arb_reedColor') === 'Fehér' && (
                <Grid.Col xs={4}>
                  <GrSwitchInput name="arb_top10SlatUnperforated" label={getLabelFor('arb_top10SlatUnperforated')} />
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col span={'content'}>
            <Modal.Root opened={opened} onClose={close} zIndex={1000}>
              <Modal.Overlay opacity={0.7} />

              <Modal.Content>
                <Modal.Header>
                  <Modal.CloseButton pos={'fixed'} right={10} top={10} />
                </Modal.Header>

                <Modal.Body>
                  <Image
                    mx="auto"
                    radius="md"
                    src={`/assets/images/${
                      !watch('arb_plasterable') ? 'arb-nv' : !watch('arb_wideFlangeCaseEnding') ? 'arb-v' : 'arb-vsz'
                    }.png`}
                  />
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>

            <Group pos={'relative'}>
              <div style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 10 }}>
                <ActionIcon onClick={open} variant="light">
                  <IconZoomIn />
                </ActionIcon>
              </div>

              <Image
                width={150}
                mx="auto"
                radius="md"
                src={`/assets/images/${
                  !watch('arb_plasterable') ? 'arb-nv' : !watch('arb_wideFlangeCaseEnding') ? 'arb-v' : 'arb-vsz'
                }.png`}
                pr={20}
                onClick={open}
                sx={{ cursor: 'pointer' }}
              />
            </Group>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.automation')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('arb_automation')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_automationQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.inlet')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('arb_inlet')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_inletQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.spring')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('arb_spring')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_springQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.coverButton')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{`${productForm.getValues('arb_coverButton')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_coverButtonQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.rltp')}</Text>
          </Grid.Col>

          <Grid.Col xs={3} offset={6}>
            <Text>{`${productForm.getValues('arb_rltp')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.motor')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{`${productForm.getValues('arb_motorType')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_motorQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.arb.remoteController')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{`${productForm.getValues('arb_remote_controller')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('arb_remoteControllerQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
