import { ProductAttributeDefinitionResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useProductAttributeDefinitions() {
  const useQueryReturn = useQuery(
    ['product-attribute-definitions', 'list-product-attribute-definitions'],
    getMany<ProductAttributeDefinitionResponse[]>('product-attribute-definitions/list-product-attribute-definitions')
  );

  return useQueryReturn ? useQueryReturn.data : [];
}
