import { Drawer, useMantineTheme } from '@mantine/core';
import { useDrawerView } from '../../stores/drawer-view.store';
import { createElement } from 'react';
import { useMediaQuery } from '@mantine/hooks';

export function DrawerView() {
  const fullScreenDrawer = useMediaQuery('(max-width: 1000px)');
  const halfScreenDrawer = useMediaQuery('(min-width: 1500px)');
  const theme = useMantineTheme();

  const {
    drawerViewComponent,
    secondaryDrawerViewComponent,
    drawerViewProps,
    secondaryDrawerViewProps,
    drawerViewLocked,
    closeDrawerView,
  } = useDrawerView();

  return (
    <>
      <Drawer
        opened={!!drawerViewComponent}
        onClose={() => closeDrawerView()}
        withCloseButton={false}
        closeOnClickOutside={!drawerViewLocked}
        position="right"
        size={fullScreenDrawer ? '100vw' : halfScreenDrawer ? '60vw' : '70vw'}
        overlayProps={{ blur: 4, color: theme.fn.darken(theme.colors[theme.primaryColor][5], 0.25), opacity: 0.25 }}
        styles={(theme) => ({
          content: {
            borderRadius: fullScreenDrawer ? 0 : `${theme.radius.md} 0 0 ${theme.radius.md}`,
          },
        })}
      >
        {drawerViewComponent && createElement(drawerViewComponent, drawerViewProps as never)}
      </Drawer>

      <Drawer
        opened={!!secondaryDrawerViewComponent}
        onClose={() => closeDrawerView()}
        withCloseButton={false}
        closeOnClickOutside={false}
        position="right"
        size={`calc(${fullScreenDrawer ? '100vw' : halfScreenDrawer ? '60vw' : '70vw'} - 25px)`}
        overlayProps={{ opacity: 0 }}
        styles={(theme) => ({
          content: {
            borderRadius: fullScreenDrawer ? 0 : `${theme.radius.md} 0 0 ${theme.radius.md}`,
            filter: 'drop-shadow(-5px 0px 16px rgba(0, 0, 0, 0.25))            ',
          },
        })}
      >
        {secondaryDrawerViewComponent && createElement(secondaryDrawerViewComponent, secondaryDrawerViewProps as never)}
      </Drawer>
    </>
  );
}
