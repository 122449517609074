import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { MosquitoNetDoorRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface MosquitoNetDoorProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: MosquitoNetDoorRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function MosquitoNetDoor(props: MosquitoNetDoorProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const mndPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  const productForm = useDtoForm([MosquitoNetDoorRequest, MosquitoNetDoorRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), mnd_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  const hasRal = productForm.getValues('mnd_color') === 'RAL';

  //Calculated values for pricing
  useEffect(() => {
    const width = productForm.getValues('mnd_width') ?? 0;
    const height = productForm.getValues('mnd_height') ?? 0;

    productForm.setValue('mnd_area', (width * height) / 10000);
    productForm.setValue('mnd_roundedArea', getRoundedArea(productForm.getValues('mnd_area')));

    console.log('Terület', watch('mnd_area'));
    console.log('Kerekített terület', watch('mnd_roundedArea'));
  }, [watch('mnd_width'), watch('mnd_height'), watch('mnd_type')]);

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  useEffect(() => {
    setSetType();
  }, [watch('mnd_type')]);

  useEffect(() => {
    getColorPrice();
  }, [watch('mnd_color'), watch('mnd_type')]);

  useEffect(() => {
    getSetPrice();
  }, [watch('mnd_color'), watch('mnd_set'), watch('mnd_type')]);

  useEffect(() => {
    getKickerPrice();
  }, [watch('mnd_color'), watch('mnd_kicking')]);

  useEffect(() => {
    getHighlightPrice();
  }, [watch('mnd_color'), watch('mnd_highlight'), watch('mnd_highlightPage')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('mnd_roundedArea'), watch('priceList'), watch('mnd_color'), watch('mnd_petNet'), watch('mnd_brush')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let piecePrice = 0; // Prices that only depend on the piece

    const colorPrice = (productForm.getValues('priceList') as any)['type_color'] ?? 0;
    areaPrice += colorPrice;

    const highlightPrice = (productForm.getValues('priceList') as any)['highlight'] ?? 0;
    piecePrice += highlightPrice;

    if (watch('mnd_type') === 'Nyíló ajtó 45x20' || watch('mnd_type') === 'Nyíló ajtó íves 45x27,5') {
      const setPrice =
        (productForm.getValues('priceList') as any)['set_color'] ??
        0 + (productForm.getValues('priceList') as any)['mnd_setType'] ??
        0;
      piecePrice += setPrice;
      const brushPrice =
        mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_074')?.price ?? 0;
      if (watch('mnd_brush') === 'Alul' || watch('mnd_brush') === 'Felül') {
        piecePrice += brushPrice;
      } else if (watch('mnd_brush') === 'Alul - Felül') {
        piecePrice += brushPrice * 2;
      }
      const kickerPrice = (productForm.getValues('priceList') as any)['kicker_color'] ?? 0;
      piecePrice += kickerPrice;
    }

    if (watch('mnd_type') === 'Alu tokos Mobil Ajtó') {
      const caseFastenerPrice =
        3 * (mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_073')?.price ?? 0);
      piecePrice += caseFastenerPrice;
    } else if (watch('mnd_type') === 'Kétszárnyas Mobil Ajtó') {
      const caseFastenerPrice =
        6 * (mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_073')?.price ?? 0);
      piecePrice += caseFastenerPrice;
    }

    const petNetPrice =
      mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_077')?.price ?? 0;
    if (watch('mnd_petNet') === 'Alul' || watch('mnd_petNet') === 'Felül') {
      piecePrice += petNetPrice;
    } else if (watch('mnd_petNet') === 'Egész') {
      piecePrice += petNetPrice * 2;
    }

    const area = watch('mnd_roundedArea');
    areaPrice = areaPrice * area;
    productForm.setValue('price', areaPrice + piecePrice);

    console.log(watch('priceList'));
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function setSetType() {
    switch (watch('mnd_type')) {
      case 'Nyíló ajtó 45x20':
      case 'Nyíló ajtó íves 45x27,5':
        productForm.getValues('mnd_set') ? {} : productForm.setValue('mnd_set', 'Sima');
        break;
      case 'Kétszárnyas Mobil Ajtó':
      case 'Alu tokos Mobil Ajtó':
        productForm.setValue('mnd_set', undefined);
        productForm.setValue('mnd_kicking', undefined);
        productForm.setValue('mnd_brush', undefined);
        break;

      default:
        break;
    }
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1.6 && watch('mnd_type') !== 'Kétszárnyas Mobil Ajtó') {
      return 1.6;
    }
    if (roundedArea > 0 && roundedArea < 3.2 && watch('mnd_type') === 'Kétszárnyas Mobil Ajtó') {
      return 3.2;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getColorPrice() {
    let price = 0;
    switch (watch('mnd_type')) {
      case 'Nyíló ajtó 45x20':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_023')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_022')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_024')?.price ?? 0;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_021')?.price ?? 0;
            break;
        }
        break;
      case 'Nyíló ajtó íves 45x27,5':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_027')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_026')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_028')?.price ?? 0;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_025')?.price ?? 0;
            break;
        }
        break;
      case 'Kétszárnyas Mobil Ajtó':
      case 'Alu tokos Mobil Ajtó':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_007')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_006')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_008')?.price ?? 0;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_005')?.price ?? 0;
            break;
        }
        break;
      default:
        price = 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ type_color: price },
    });
  }

  function getHighlightPrice() {
    let price = 0;
    switch (watch('mnd_highlight')) {
      case 'Alu zártsz. 53x40':
        switch (watch('mnd_color')) {
          case 'Barna':
          case 'Antracit':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_043')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_040')
                    ?.price ?? 0;
                break;
            }
            break;
          case 'RAL':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_044')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_041')
                    ?.price ?? 0;
                break;
            }
            break;
          default:
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_042')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_039')
                    ?.price ?? 0;
                break;
            }
            break;
        }
        break;
      case 'Alu lefutó 53x40':
      case 'Alu lefutó 53x22':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_037')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_033')
                    ?.price ?? 0;
                break;
            }
            break;
          case 'Barna':
          case 'Antracit':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_036')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_032')
                    ?.price ?? 0;
                break;
            }
            break;
          case 'RAL':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_038')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_034')
                    ?.price ?? 0;
                break;
            }
            break;
          default:
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_035')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_031')
                    ?.price ?? 0;
                break;
            }
            break;
        }
        break;
      case 'Alu zártsz. 45x30':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_051')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_047')
                    ?.price ?? 0;
                break;
            }
            break;
          case 'Barna':
          case 'Antracit':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_050')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_046')
                    ?.price ?? 0;
                break;
            }
            break;
          case 'RAL':
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_052')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_048')
                    ?.price ?? 0;
                break;
            }
            break;
          default:
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_049')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_045')
                    ?.price ?? 0;
                break;
            }
            break;
        }
        break;
      case 'PVC lefutó 53x40':
      case 'PVC zártsz.30x50':
      case 'PVC zártsz. 20x30':
        switch (watch('mnd_color')) {
          default:
            switch (watch('mnd_highlightPage')) {
              case '4 oldal':
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_030')
                    ?.price ?? 0;
                break;
              default:
                price =
                  mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_029')
                    ?.price ?? 0;
                break;
            }
            break;
        }
        break;
      default:
        price = 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ highlight: price },
    });
  }

  function getSetPrice() {
    //Szett neve, darabszáma és ára
    const setType =
      getConditionSublistsFor('mnd_setType').length === 1 ? getConditionSublistsFor('mnd_setType')[0] : '';
    let mnd_setType = 0;
    if (!setType) {
      productForm.setValue('mnd_setQuantity', 0);
      productForm.setValue('mnd_setType', '');
    } else {
      productForm.setValue('mnd_setQuantity', 1);
      productForm.setValue('mnd_setType', setType);
      mnd_setType =
        mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.label === setType)?.price ?? 0;
    }

    //Mágnes neve, darabszáma és ára
    const magnet = getConditionSublistsFor('mnd_magnet').length === 1 ? getConditionSublistsFor('mnd_magnet')[0] : '';
    if (!magnet) {
      productForm.setValue('mnd_magnetQuantity', 0);
      productForm.setValue('mnd_magnet', '');
    } else {
      productForm.setValue('mnd_magnetQuantity', 2);
      productForm.setValue('mnd_magnet', magnet);
    }

    //Sima zsanér neve, darabszáma és ára
    const simpleHinge =
      getConditionSublistsFor('mnd_simpleHinge').length === 1 ? getConditionSublistsFor('mnd_simpleHinge')[0] : '';
    if (!simpleHinge) {
      productForm.setValue('mnd_simpleHingeQuantity', 0);
      productForm.setValue('mnd_simpleHinge', '');
    } else {
      if (watch('mnd_set')?.includes('Rugós')) {
        productForm.setValue('mnd_simpleHingeQuantity', 1);
      } else {
        productForm.setValue('mnd_simpleHingeQuantity', 3);
      }
      productForm.setValue('mnd_simpleHinge', simpleHinge);
    }

    //Rugós zsanér - BAL neve, darabszáma és ára
    const sprungHingeLeft =
      getConditionSublistsFor('mnd_sprungHingeLeft').length === 1
        ? getConditionSublistsFor('mnd_sprungHingeLeft')[0]
        : '';

    if (!sprungHingeLeft) {
      productForm.setValue('mnd_sprungHingeLeftQuantity', 0);
      productForm.setValue('mnd_sprungHingeLeft', '');
    } else {
      productForm.setValue('mnd_sprungHingeLeftQuantity', 2);
      productForm.setValue('mnd_sprungHingeLeft', sprungHingeLeft);
    }

    //Rugós zsanér - JOBB neve, darabszáma és ára
    const sprungHingeRight =
      getConditionSublistsFor('mnd_sprungHingeRight').length === 1
        ? getConditionSublistsFor('mnd_sprungHingeRight')[0]
        : '';

    if (!sprungHingeRight) {
      productForm.setValue('mnd_sprungHingeRightQuantity', 0);
      productForm.setValue('mnd_sprungHingeRight', '');
    } else {
      productForm.setValue('mnd_sprungHingeRightQuantity', 2);
      productForm.setValue('mnd_sprungHingeRight', sprungHingeRight);
    }

    //Fém zsanér neve, darabszáma és ára
    const metalHinge =
      getConditionSublistsFor('mnd_metalHinge').length === 1 ? getConditionSublistsFor('mnd_metalHinge')[0] : '';

    if (!metalHinge) {
      productForm.setValue('mnd_metalHingeQuantity', 0);
      productForm.setValue('mnd_metalHinge', '');
    } else {
      productForm.setValue('mnd_metalHingeQuantity', 3);
      productForm.setValue('mnd_metalHinge', metalHinge);
    }

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ mnd_setType },
    });
  }

  function getKickerPrice() {
    let price = 0;
    switch (watch('mnd_kicking')) {
      case '75x17':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_059')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_058')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_060')?.price ?? 0;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_057')?.price ?? 0;
            break;
        }
        break;
      case '100x20':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_055')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_054')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_056')?.price ?? 0;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_053')?.price ?? 0;
            break;
        }
        break;
      case '75x17 2db':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_059')?.price ?? 0;
            price *= 2;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_058')?.price ?? 0;
            price *= 2;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_060')?.price ?? 0;
            price *= 2;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_057')?.price ?? 0;
            price *= 2;
            break;
        }
        break;
      case '100x20 2db':
        switch (watch('mnd_color')) {
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_055')?.price ?? 0;
            price *= 2;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_054')?.price ?? 0;
            price *= 2;
            break;
          case 'RAL':
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_056')?.price ?? 0;
            price *= 2;
            break;
          default:
            price =
              mndPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_053')?.price ?? 0;
            price *= 2;
            break;
        }
        break;
      default:
        price = 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ kicker_color: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: MosquitoNetDoorRequest) => {
        if (!hasRal) {
          await delete data.mnd_ralCode;
        }
        data.summary =
          'Szélesség: ' +
          data.mnd_width.toLocaleString() +
          ' cm, Magasság: ' +
          data.mnd_height.toLocaleString() +
          ' cm';
        data.summary += ', Típus: ' + data.mnd_type;
        props.onSave(data);
        console.log(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="mnd_width" label={getLabelFor('mnd_width')} min={0} precision={2} controls />

            {getErrorMessageFor('mnd_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('mnd_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="mnd_height" label={getLabelFor('mnd_height')} min={0} precision={2} controls />

            {getErrorMessageFor('mnd_height') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('mnd_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          {getErrorMessageFor('mnd_roundedArea') && (
            <Grid.Col xs={4} mt="lg">
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('mnd_roundedArea')}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="mnd_type"
              label={getLabelFor('mnd_type')}
              data={getConditionSublistsFor('mnd_type')}
              description={getConditionDescriptionFor('mnd_type')}
              disabled={getDisabledStateFor('mnd_type')}
              onEmptyState={() => warningNotification(getLabelFor('mnd_type'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="mnd_color"
              label={getLabelFor('mnd_color')}
              data={getConditionSublistsFor('mnd_color')}
              description={getConditionDescriptionFor('mnd_color')}
              disabled={getDisabledStateFor('mnd_color')}
              onEmptyState={() => warningNotification(getLabelFor('mnd_color'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          {hasRal && (
            <Grid.Col xs={2}>
              <GrTextInput name="mnd_ralCode" label={getLabelFor('mnd_ralCode')} mask="9999" />

              {!orderReadonlyMode && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {'(4 számjegy)'}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="mnd_highlight"
              label={getLabelFor('mnd_highlight')}
              data={getConditionSublistsFor('mnd_highlight')}
              description={getConditionDescriptionFor('mnd_highlight')}
              disabled={getDisabledStateFor('mnd_highlight')}
              onEmptyState={() => warningNotification(getLabelFor('mnd_highlight'))}
              emptyOnStateChange
              simpleValue
            />

            {!orderReadonlyMode && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getDescriptionFor('mnd_highlight')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="mnd_highlightPage"
              label={getLabelFor('mnd_highlightPage')}
              data={getConditionSublistsFor('mnd_highlightPage')}
              description={getConditionDescriptionFor('mnd_highlightPage')}
              disabled={getDisabledStateFor('mnd_highlightPage')}
              onEmptyState={() => warningNotification(getLabelFor('mnd_highlightPage'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="mnd_petNet"
              label={getLabelFor('mnd_petNet')}
              data={getConditionSublistsFor('mnd_petNet')}
              description={getConditionDescriptionFor('mnd_petNet')}
              disabled={getDisabledStateFor('mnd_petNet')}
              onEmptyState={() => warningNotification(getLabelFor('mnd_petNet'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col>
            {watch('mnd_type') !== 'Kétszárnyas Mobil Ajtó' && watch('mnd_type') !== 'Alu tokos Mobil Ajtó' && (
              <Grid>
                <Grid.Col xs={4}>
                  <GrSelectInput
                    name="mnd_kicking"
                    label={getLabelFor('mnd_kicking')}
                    data={getConditionSublistsFor('mnd_kicking')}
                    description={getConditionDescriptionFor('mnd_kicking')}
                    disabled={getDisabledStateFor('mnd_kicking')}
                    onEmptyState={() => warningNotification(getLabelFor('mnd_kicking'))}
                    emptyOnStateChange
                    simpleValue
                  />
                </Grid.Col>

                <Grid.Col xs={4}>
                  <GrSelectInput
                    name="mnd_brush"
                    label={getLabelFor('mnd_brush')}
                    data={getConditionSublistsFor('mnd_brush')}
                    description={getConditionDescriptionFor('mnd_brush')}
                    disabled={getDisabledStateFor('mnd_brush')}
                    onEmptyState={() => warningNotification(getLabelFor('mnd_brush'))}
                    emptyOnStateChange
                    simpleValue
                  />
                </Grid.Col>

                <Grid.Col xs={4}>
                  <GrSelectInput
                    name="mnd_set"
                    label={getLabelFor('mnd_set')}
                    data={getConditionSublistsFor('mnd_set')}
                    description={getConditionDescriptionFor('mnd_set')}
                    disabled={getDisabledStateFor('mnd_set')}
                    onEmptyState={() => warningNotification(getLabelFor('mnd_set'))}
                    emptyOnStateChange
                    simpleValue
                  />
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="mnd_quantity" label={getLabelFor('mnd_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        {productForm.getValues('mnd_setType') && (
          <Grid>
            <Grid.Col>
              <Text>{productForm.getValues('mnd_setType')}:</Text>
            </Grid.Col>
          </Grid>
        )}

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.mnd.simpleHinge')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('mnd_simpleHinge')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('mnd_simpleHingeQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.mnd.sprungHingeRight')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('mnd_sprungHingeRight')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('mnd_sprungHingeRightQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.mnd.sprungHingeLeft')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('mnd_sprungHingeLeft')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('mnd_sprungHingeLeftQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.mnd.metalHinge')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('mnd_metalHinge')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('mnd_metalHingeQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{t('entity.form.mnd.magnet')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('mnd_magnet')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('mnd_magnetQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
