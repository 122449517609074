import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { Grid, NumberInput, SegmentedControl, Space, Text } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
}

export function EntityTablePriceFilter<T>({ selectedColumn, filterFormValues, onChange }: FilterProps<T>) {
  const { t } = useTranslation();

  // Initialize missing operation
  useEffect(() => {
    if (!filterFormValues.operation) {
      filterFormValues.operation = 'equalsP';
      onChange({
        operation: filterFormValues.operation,
      });
    }
  }, []);

  return (
    <>
      <Text size="sm">{t('entityTable.filter.conditionType')}:</Text>

      <SegmentedControl
        fullWidth
        value={filterFormValues.operation}
        onChange={(type) => {
          onChange({
            operation: type,
          });
        }}
        data={[
          { label: t('entityTable.filter.operation.equals'), value: 'equalsP' },
          { label: t('entityTable.filter.operation.gt'), value: 'gtP' },
          { label: t('entityTable.filter.operation.lt'), value: 'ltP' },
          { label: t('entityTable.filter.operation.between'), value: 'betweenP' },
        ].map((r) => ({ ...r, label: uppercaseFirstLetter(r.label) }))}
      />

      <Space h={16} />

      {filterFormValues.operation === 'betweenP' && (
        <Grid>
          <Grid.Col xs={6}>
            <NumberInput
              label={`${selectedColumn.header}tól:`}
              value={filterFormValues.value ? parseFloat(filterFormValues.value) : ''}
              name="filterValue"
              onChange={(value) => {
                if (value !== undefined && value !== '' && value > -1) {
                  onChange({ ...filterFormValues, value: value?.toString() });
                }
              }}
              min={0}
              rightSection={'€'}
              placeholder={t('entityTable.filter.filterInputPlaceholder')}
              autoFocus
              autoComplete="0"
            />
          </Grid.Col>

          <Grid.Col xs={6}>
            <NumberInput
              label={`${selectedColumn.header}ig:`}
              value={filterFormValues.secondaryValue ? parseFloat(filterFormValues.secondaryValue) : ''}
              name="filterValueSecondary"
              onChange={(value) => {
                if (value !== undefined && value !== '' && value > -1) {
                  onChange({ ...filterFormValues, secondaryValue: value?.toString() });
                }
              }}
              min={0}
              rightSection={'€'}
              placeholder={t('entityTable.filter.filterInputPlaceholder')}
              autoComplete="0"
            />
          </Grid.Col>
        </Grid>
      )}

      {filterFormValues.operation !== 'betweenP' && (
        <NumberInput
          label={`${selectedColumn.header}:`}
          value={filterFormValues.value ? parseFloat(filterFormValues.value) : ''}
          name="filterValue"
          onChange={(value) => {
            if (value !== undefined && value !== '' && value > -1) {
              onChange({ value: value?.toString() });
            }
          }}
          min={0}
          rightSection={'€'}
          placeholder={t('entityTable.filter.filterInputPlaceholder')}
          autoFocus
          autoComplete="0"
        />
      )}
    </>
  );
}
