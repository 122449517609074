import { UsersPage } from './pages/UsersPage';
import { UserRolePage } from './pages/UserRolePage';
import { AuditLogPage } from './pages/AuditLogPage';
import { AppLayout } from '../../layouts/AppLayout';
import { RouteObject } from 'react-router-dom';
import { UserFormPage } from './pages/UserFormPage';
import { SettingsLayout } from './layouts/SettingsLayout';
import { queryClient } from '../../plugins/react-query';
import { getMany, getOne } from '../../plugins/axios';
import { UserRoleFormPage } from './pages/UserRoleFormPage';
import { createGuardedLoader } from '../../utils';
import { AppSettingsPage } from './pages/AppSettingsPage';
import { NotFound } from '../core/pages/NotFound';

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings',
    element: <AppLayout />,
    children: [
      {
        element: <SettingsLayout />,
        children: [
          {
            path: 'users',
            element: <UsersPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['users'], getMany('users'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'users/:idOrAction',
            element: <UserFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['users', params.idOrAction], getOne('users', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'user-roles',
            element: <UserRolePage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['user-roles'], getMany('user-roles'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'user-roles/:idOrAction',
            element: <UserRoleFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['user-roles', params.idOrAction], getOne('user-roles', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'app-settings',
            element: <AppSettingsPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['app-settings'], getMany('app-settings'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'audit-logs',
            element: <AuditLogPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['audit-logs'], getMany('audit-logs'));
            }),
            errorElement: <NotFound />,
          },
        ],
      },
    ],
  },
];
