import { create } from 'zustand';
import { axiosInstance, setAxiosHeader } from '../plugins/axios';
import { persist } from 'zustand/middleware';
import { LoginResponse, UserResponse } from '@gravity/shared/dto';
import { queryClient } from '../plugins/react-query';
import { router } from '../plugins/router';

interface AuthStore {
  token?: string;
  user?: UserResponse;
  login(loginResponse: LoginResponse): void;
  logout(): void;
  isAuthenticated(): boolean;
  isReseller(): boolean;
  updateUser(): Promise<void>;
}

export const useAuth = create<AuthStore>()(
  persist(
    (set, get) => ({
      token: undefined,
      user: undefined,
      login(loginResponse: LoginResponse) {
        set({ token: loginResponse.token, user: loginResponse.user });
        setAxiosHeader('Authorization', `Bearer ${loginResponse.token}`);
      },
      async logout() {
        set({ token: undefined });

        await router.navigate('/login');

        setAxiosHeader('Authorization', undefined);
        queryClient.removeQueries();
        set({ user: undefined });
      },
      isAuthenticated() {
        return !!get().token;
      },
      isReseller() {
        return get().user?.isReseller ?? false;
      },
      async updateUser() {
        const response = await axiosInstance.get('/users/me');
        set({ user: response.data });
      },
    }),
    {
      name: 'gravity.auth',
      onRehydrateStorage() {
        return (state) => {
          setAxiosHeader('Authorization', `Bearer ${state?.token}`);
        };
      },
    }
  )
);
