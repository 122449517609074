import { ConditionType } from '@prisma/client';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class BomComponentConditionCreateRequest {
  /**
   * The condition of the bill of materials component condition
   */
  @IsString()
  @IsNotEmpty()
  public condition: string;

  /**
   * The type of the bill of materials component condition
   */
  @IsString()
  public conditionType: ConditionType;

  /**
   * The conditional select values of the bill of materials component condition
   */
  @IsOptional()
  public conditionalSelectValues?: string[];
}

export class BomComponentConditionUpdateRequest {
  /**
   * CUID identifier of the bill of materials component condition
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The condition of the bill of materials component condition
   */
  @IsString()
  @IsNotEmpty()
  public condition: string;

  /**
   * The type of the bill of materials component condition
   */
  @IsString()
  public conditionType: ConditionType;

  /**
   * The conditional select values of the bill of materials component condition
   */
  @IsOptional()
  public conditionalSelectValues?: string[];
}
