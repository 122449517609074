import { GrMultiSelectInput, GrQueryBuilderInput, GrSelectInput, GrTextInput } from '@gravity/frontend/ui';
import { ProductAttributeConditionUpdateRequest } from '@gravity/shared/dto';
import { ActionIcon, Button, Grid, Group, Paper } from '@mantine/core';
import { ConditionType, ProductAttributeType } from '@prisma/client';
import { IconRowInsertTop, IconTrash } from '@tabler/icons-react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-querybuilder';
import { useProductAttributeDefinitions } from '../../../hooks/use-product-attribute-definition.hook';

export function ConditionComponent({ parentForm, parent, index, control }) {
  const { t } = useTranslation();
  const { watch } = parentForm;
  const productAttributeDefinitions = useProductAttributeDefinitions();
  const actualProductAttributes: string[] = parentForm.getValues('productAttributes').map((productAttribute) => {
    return productAttribute.attributeDefinitionId;
  });
  const attributeDefinitionFields: Field[] =
    productAttributeDefinitions !== undefined
      ? productAttributeDefinitions
          .filter((def) => {
            return def.id ? actualProductAttributes.includes(def.id) : false;
          })
          .map((def) => ({
            key: `${index}.${def.id}`,
            name: def.variableName ? def.variableName : def.name,
            label: def.name,
            type: def.type,
            selectValues: def.selectValues ?? [],
          }))
      : [{ name: 'Name', label: 'Name' }];

  const conditionTypes = Object.keys(ConditionType).map((conditionType) => ({
    id: conditionType,
    label: t(`common.conditionType.${conditionType}`),
  }));

  const { fields, append, remove } = useFieldArray({
    name: `${parent}.${index}.conditions`,
    control: control,
    keyName: 'conditionId',
  });

  const newCondition = (): ProductAttributeConditionUpdateRequest => {
    return {
      condition: '',
      conditionType: ConditionType.enable,
      conditionalSelectValues: [],
    };
  };

  const actualAttributeDefinition = productAttributeDefinitions?.find(
    (attributeDefinition) => attributeDefinition.id === watch(`${parent}[${index}].attributeDefinitionId`)
  );
  const hasSelectType = actualAttributeDefinition?.type === ProductAttributeType.select;
  const selectValues = actualAttributeDefinition?.selectValues;

  return (
    <Grid>
      {fields.map((condition, nestedIndex) => {
        const hasCondition = watch(`${parent}[${index}].conditions[${nestedIndex}].condition`) !== '1 == 1';

        return (
          <Paper key={condition.conditionId} m="lg" p="md" withBorder>
            {!parentForm.readonlyMode && (
              <Group position="right">
                <ActionIcon onClick={() => remove(nestedIndex)} color="red" size="md" variant="light">
                  <IconTrash size={18} />
                </ActionIcon>
              </Group>
            )}

            <Grid>
              {(hasCondition || !parentForm.readonlyMode) && (
                <Grid.Col>
                  <GrQueryBuilderInput
                    form={parentForm}
                    name={`${parent}.${index}.conditions.${nestedIndex}.condition`}
                    fields={attributeDefinitionFields}
                  />
                </Grid.Col>
              )}

              <Grid.Col xs={6}>
                <GrSelectInput
                  form={parentForm}
                  data={conditionTypes}
                  name={`${parent}.${index}.conditions.${nestedIndex}.conditionType`}
                  simpleValue
                />
              </Grid.Col>

              {hasSelectType && (
                <Grid.Col xs={6}>
                  <GrMultiSelectInput
                    form={parentForm}
                    data={selectValues}
                    name={`${parent}.${index}.conditions.${nestedIndex}.conditionalSelectValues`}
                    simpleValue
                  />
                </Grid.Col>
              )}

              <Grid.Col>
                <GrTextInput form={parentForm} name={`${parent}.${index}.conditions.${nestedIndex}.errorMessage`} />
              </Grid.Col>
            </Grid>
          </Paper>
        );
      })}

      {!parentForm.readonlyMode && (
        <Grid.Col>
          <Button
            aria-label={t('common.button.addCondition')}
            leftIcon={<IconRowInsertTop />}
            variant="subtle"
            mt="md"
            compact
            onClick={() => append(newCondition())}
          >
            {t('common.button.addCondition')}
          </Button>
        </Grid.Col>
      )}
    </Grid>
  );
}
