import { createStyles, Paper, Group, Text, SimpleGrid, Box, Title, Grid, ScrollArea } from '@mantine/core';
import { NewsCard } from '../../core/components/NewsCard';
import { useCurrentPartner } from '../../../hooks/use-current-partner.hook';
import { useResellerDashboard } from '../../../hooks/use-dashboard.hook';
import { DashboardOrdersType } from '@gravity/shared/dto';
import { round, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BarchartCard } from '../../core/components/BarchartCard';
import { InfoCard } from '../../core/components/InfoCard';
import { useAppConfig } from '../../../hooks/use-app-config.hook';
import { hungarianArticleFormat } from '@gravity/shared/utils';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function ResellerDashboard() {
  const { partner, user } = useCurrentPartner();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const config = useAppConfig();
  const dashboardData = useResellerDashboard();

  function prepareBarchartData(data: DashboardOrdersType): {
    label: string;
    count: number;
    color: string;
    part: number;
  }[] {
    const barchartColors = ['#F8230C', '#F07B00', '#F8D00C', '#A2AD40', '#478749'];
    const sumData = sum(Object.values(data));

    return Object.entries(data).map(([key, value], idx) => {
      return {
        label: t(`common.orderStatus.${key}`),
        count: value,
        color: barchartColors[`${idx}`],
        part: round((data[`${key}`] / sumData) * 100, 1),
      };
    });
  }

  const infos = [
    {
      title: t('dashboard.infos.orderCount'),
      value: dashboardData?.allTimeOrderCount,
    },
  ];

  const dummyContacts = [
    { label: t('dashboard.infos.contact.address'), value: '6500 Baja, Schweidel J. u. 12. (Telephely)' },
    { label: t('dashboard.infos.contact.email'), value: 'rebamax1000@gmail.com' },
    { label: t('dashboard.infos.contact.telephone'), value: '+36 30/725-4000' },
    { label: '', value: '+36 30/725-8000' },
    { label: '', value: '+36 30/725-9000' },
  ];

  return (
    <ScrollArea type="scroll" style={{ height: 'calc(100vh - 58px)' }} pb="sm">
      <div className={classes.root}>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} mb={35}>
          <Paper p="md">
            <Group>
              {partner?.companyLogo && <img src={partner.companyLogo} style={{ maxHeight: 60, marginRight: 10 }} />}

              <Box>
                <Title order={4} pb={0}>
                  {t('dashboard.greeting', { user: user?.firstName ? user?.firstName : 'Felhasználó' })}
                </Title>

                <Title order={6} pb={0} sx={{ fontWeight: 'normal' }}>
                  {t('dashboard.welcome', {
                    company: config?.company,
                    article: hungarianArticleFormat(config?.company),
                  })}
                </Title>
              </Box>
            </Group>
          </Paper>
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <NewsCard />

          <SimpleGrid>
            <Grid>
              <Grid.Col md={12}>
                <BarchartCard
                  title={t('sales.menu.orders')}
                  data={dashboardData && prepareBarchartData(dashboardData?.orders)}
                />
              </Grid.Col>
            </Grid>

            <SimpleGrid cols={1}>
              <Paper withBorder p="md" radius="md">
                <Group position="apart">
                  <div>
                    <Text color="dimmed" transform="uppercase" weight={700} size="xs" className={classes.label}>
                      {t('dashboard.infos.contact.title')}
                    </Text>

                    <SimpleGrid cols={1} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl" verticalSpacing="xs">
                      {dummyContacts.map((stat, index) => (
                        <Box key={index}>
                          {stat.label && (
                            <Text transform="uppercase" size="xs" color="dimmed" weight={700}>
                              {stat.label}
                            </Text>
                          )}

                          <Text weight={700}>{stat.value}</Text>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </div>
                </Group>
              </Paper>

              {infos.map((info, idx) => (
                <InfoCard {...info} key={idx} />
              ))}
            </SimpleGrid>
          </SimpleGrid>
        </SimpleGrid>
      </div>
    </ScrollArea>
  );
}
