import { SalesLayout } from './layouts/SalesLayout';
import { ProductCategoriesPage } from './pages/ProductCategoriesPage';
import { ProductAttributeDefinitionsPage } from './pages/ProductAttributeDefinitionsPage';
import { OrdersPage } from './pages/OrdersPage';
import { ProductsPage } from './pages/ProductsPage';
import { RouteObject } from 'react-router-dom';
import { AppLayout } from '../../layouts/AppLayout';
import { ProductCategoriesFormPage } from './pages/ProductCategoriesFormPage';
import { queryClient } from '../../plugins/react-query';
import { getMany, getOne } from '../../plugins/axios';
import { ProductFormPage } from './pages/ProductFormPage';
import { ProductAttributeDefinitionFormPage } from './pages/ProductAttributeDefinitionFormPage';
import { OrdersFormPage } from './pages/OrdersFormPage';
import { createGuardedLoader } from '../../utils';
import { NotFound } from '../core/pages/NotFound';
import { BillableItemsPage } from './pages/BillableItemsPage';

export const salesRoutes: RouteObject[] = [
  {
    path: 'sales',
    element: <AppLayout />,
    children: [
      {
        element: <SalesLayout />,
        children: [
          {
            path: 'orders',
            element: <OrdersPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['orders'], getMany('orders'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'orders/:idOrAction',
            element: <OrdersFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['orders', params.idOrAction], getOne('orders', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'product-categories',
            element: <ProductCategoriesPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['product-categories'], getMany('product-categories'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'product-categories/:idOrAction',
            element: <ProductCategoriesFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(
                ['product-categories', params.idOrAction],
                getOne('product-categories', params.idOrAction)
              );
            }),
            errorElement: <NotFound />,
          },

          {
            path: 'products',
            element: <ProductsPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(['products'], getMany('products'));
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'products/:idOrAction',
            element: <ProductFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(['products', params.idOrAction], getOne('products', params.idOrAction));
            }),
            errorElement: <NotFound />,
          },

          {
            path: 'product-attribute-definitions',
            element: <ProductAttributeDefinitionsPage />,
            loader: createGuardedLoader(() => {
              return queryClient.fetchQuery(
                ['product-attribute-definitions'],
                getMany('product-attribute-definitions')
              );
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'product-attribute-definitions/:idOrAction',
            element: <ProductAttributeDefinitionFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(
                ['product-attribute-definitions', params.idOrAction],
                getOne('product-attribute-definitions', params.idOrAction)
              );
            }),
            errorElement: <NotFound />,
          },
          {
            path: 'orders/billable-items',
            element: <BillableItemsPage />,
            errorElement: <NotFound />,
          },
        ],
      },
    ],
  },
];
