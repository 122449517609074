import { ProductAttributeType } from '@prisma/client';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class ProductAttributeDefinitionCreateRequest {
  /**
   * The name of the product attribute definition
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The display name of the product attribute definition
   */
  @IsString()
  public variableName?: string;

  /**
   * The type of the product attribute definition
   */
  @IsString()
  @IsNotEmpty()
  public type: ProductAttributeType;

  /**
   * Values if the type is select: ['Opt1', 'Opt2',...]
   */
  @IsOptional()
  public selectValues?: string[];

  /**
   * True if the product attribute definition is calculated
   */
  @IsBoolean()
  public calculated: boolean;

  /**
   * Calculated formula if the product attribute definition is calculated
   */
  @IsString()
  public calculationFormula?: string;

  /**
   * True if the product attribute definition is internal
   */
  @IsBoolean()
  public internal: boolean;
}

export class ProductAttributeDefinitionUpdateRequest {
  /**
   * CUID identifier of the product attribute definition
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the product attribute definition
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The display name of the product attribute definition
   */
  @IsString()
  public variableName?: string;

  /**
   * The type of the product attribute definition
   */
  @IsString()
  @IsNotEmpty()
  public type: ProductAttributeType;

  /**
   * Values if the type is select: ['Opt1', 'Opt2',...]
   */
  @IsOptional()
  public selectValues?: string[];

  /**
   * True if the product attribute definition is calculated
   */
  @IsBoolean()
  public calculated: boolean;

  /**
   * Calculated formula if the product attribute definition is calculated
   */
  @IsString()
  public calculationFormula?: string;

  /**
   * True if the product attribute definition is internal
   */
  @IsBoolean()
  public internal: boolean;
}
