import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';

export function IsNotDefaultObject(defaultObject: object, validationOptions?: ValidationOptions) {
  return function (object: unknown, propertyName: string) {
    registerDecorator({
      name: 'isNotDefaultObject',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      target: (object as any).constructor,
      propertyName: propertyName,
      constraints: [defaultObject],
      options: {
        message: `"${propertyName}" must not be empty`,
        ...validationOptions,
      },
      validator: {
        validate(targetValue: object, args: ValidationArguments) {
          const [propertyConstraint] = args.constraints;
          let difference = false;
          Object.keys(targetValue).forEach((key: string) => {
            difference = targetValue[key] !== propertyConstraint[key];
          });
          return difference;
        },
      },
    });
  };
}
