import { Prisma } from '@prisma/client';
import { IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, ValidateIf } from 'class-validator';

export class MosquitoNetDoorRequest {
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mnd_width: number; // Szélesség

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mnd_height: number; // Magasság

  @IsNumber()
  @IsNotEmpty()
  public mnd_area: number; // Terület

  @IsNumber()
  @IsNotEmpty()
  public mnd_roundedArea: number; // Kerekített terület

  @IsString()
  @IsNotEmpty()
  public mnd_color: string; // Szín

  @IsString()
  @IsNotEmpty()
  public mnd_type: string; // Típus

  @IsString()
  @IsOptional()
  public mnd_petNet: string; // Kisállat háló

  @IsString()
  @IsOptional()
  public mnd_highlight?: string; // Kiemelés

  @IsString()
  @IsOptional()
  public mnd_highlightPage?: string; // Kiemelés oldalszám

  @IsString()
  @IsOptional()
  @ValidateIf((o) => o.mnd_type !== 'Kétszárnyas Mobil Ajtó' && o.mnd_type !== 'Alu tokos Mobil Ajtó')
  public mnd_kicking?: string; // Rúgdosó

  @IsString()
  @IsOptional()
  @ValidateIf((o) => o.mnd_type !== 'Kétszárnyas Mobil Ajtó' && o.mnd_type !== 'Alu tokos Mobil Ajtó')
  public mnd_brush?: string; // Kefe

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.mnd_type !== 'Kétszárnyas Mobil Ajtó' && o.mnd_type !== 'Alu tokos Mobil Ajtó')
  public mnd_set?: string; // Szett

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public mnd_quantity: number; // Darabszám

  @IsString()
  @IsNotEmpty()
  @ValidateIf((o) => o.mnd_color === 'RAL')
  public mnd_ralCode?: string; // Ral kód

  @IsString()
  @IsOptional()
  public mnd_setType?: string; // Szett típus

  @IsNumber()
  @IsNotEmpty()
  public mnd_setQuantity: number; //Szett db

  @IsString()
  @IsOptional()
  public mnd_simpleHinge?: string; // Sima zsanér neve

  @IsNumber()
  @IsNotEmpty()
  public mnd_simpleHingeQuantity: number; //Sima zsanér db

  @IsString()
  @IsOptional()
  public mnd_sprungHingeRight?: string; // Rugós zsanér (jobb) neve

  @IsNumber()
  @IsNotEmpty()
  public mnd_sprungHingeRightQuantity: number; //Rugós zsanér (jobb) db

  @IsString()
  @IsOptional()
  public mnd_sprungHingeLeft?: string; // Rugós zsanér (bal) neve

  @IsNumber()
  @IsNotEmpty()
  public mnd_sprungHingeLeftQuantity: number; //Rugós zsanér (bal) db
  @IsString()
  @IsOptional()
  public mnd_metalHinge?: string; // Fém zsanér neve

  @IsNumber()
  @IsNotEmpty()
  public mnd_metalHingeQuantity: number; //Fém zsanér db

  @IsString()
  @IsOptional()
  public mnd_magnet?: string; // Mágnes neve

  @IsNumber()
  @IsNotEmpty()
  public mnd_magnetQuantity: number; //Mágnes db

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
