import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import {
  ProductAttributeConditionCreateRequest,
  ProductAttributeConditionUpdateRequest,
} from './product-attribute-condition.request';

export class ProductAttributeCreateRequest {
  /**
   * The product identifier of the product attribute
   */
  @IsString()
  @IsOptional()
  public productId?: string;

  /**
   * The attribute definition identifier of the product attribute
   */
  @IsString()
  @IsNotEmpty()
  public attributeDefinitionId: string;

  /**
   * The description of the product attribute
   */
  @IsString()
  @IsOptional()
  public description?: string;

  /**
   * Array of conditions for the product attribute
   */
  @ValidateNested({ each: true, context: { type: ProductAttributeConditionCreateRequest } })
  @Type(() => ProductAttributeConditionCreateRequest)
  public conditions?: ProductAttributeConditionCreateRequest[];
}

export class ProductAttributeUpdateRequest {
  /**
   * The product identifier of the product attribute
   */
  @IsString()
  @IsOptional()
  public productId?: string;

  /**
   * The attribute definition identifier of the product attribute
   */
  @IsString()
  @IsNotEmpty()
  public attributeDefinitionId: string;

  /**
   * The description of the product attribute
   */
  @IsString()
  @IsOptional()
  public description?: string;

  /**
   * Array of conditions for the product attribute
   */
  @ValidateNested({ each: true, context: { type: ProductAttributeConditionUpdateRequest } })
  @Type(() => ProductAttributeConditionUpdateRequest)
  public conditions?: ProductAttributeConditionUpdateRequest[];
}
