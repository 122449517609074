import { Box, Input, Autocomplete, Text, TextInput, Loader } from '@mantine/core';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { useTranslation } from 'react-i18next';
import { BaseInputProps } from '../interfaces/base-input-props.interface';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';

export interface GrAutocompleteInputProps extends BaseInputProps {
  data?: string[];
  disabledAutocomplete?: boolean;
  emptyOnStateChange?: boolean;
}

export function GrAutocompleteInput({ name, data, disabledAutocomplete, ...props }: GrAutocompleteInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  // Remove value from the automcomplete input if the underlying data changed and the selected value is not valid anymore
  useEffect(() => {
    if (form.getValues(name) && props.emptyOnStateChange && !form.readonlyMode) {
      const foundData = data?.find((d) => d === form.getValues(name));

      if (!foundData) {
        form.setValue(name, '');
      }

      if (disabledAutocomplete) {
        form.setValue(name, '');
      }
    }
  }, [data]);

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Text>{form.getValues(name) ? form.getValues(name) : '-'}</Text>
        </Input.Wrapper>
      </Box>
    );
  }
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => {
        return disabledAutocomplete ? (
          <TextInput
            {...field}
            {...props.forwardedProps}
            disabled={formState.isSubmitting}
            label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
            error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
            description={props.description}
            required={form.requiredFields.includes(labelName)}
            autoComplete={'off'}
            icon={props.icon}
            rightSection={props.loading && <Loader size="xs" color="dark" />}
            onBlur={(e) => {
              form.setValue(name, e.target.value.trim());
            }}
          />
        ) : (
          <Autocomplete
            {...field}
            {...props.forwardedProps}
            data={data ? data : []}
            label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
            error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
            description={props.description}
            disabled={formState.isSubmitting}
            required={form.requiredFields.includes(labelName)}
            icon={props.icon}
            rightSection={props.loading && <Loader size="xs" color="dark" />}
            onBlur={(e) => {
              form.setValue(name, e.target.value.trim());
            }}
          />
        );
      }}
    />
  );
}
