import { ProductCategoryResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useProductCategories() {
  const useQueryReturn = useQuery(
    ['product-categories', 'list-product-categories'],
    getMany<ProductCategoryResponse[]>('product-categories/list-product-categories')
  );

  return useQueryReturn ? useQueryReturn.data : [];
}
