import { FilterQuery } from '@gravity/shared/dto';
import { showNotification } from '@mantine/notifications';
import Axios, { AxiosError } from 'axios';
import { stringify } from 'qs';
import { useAuth } from '../stores/auth.store';
import { i18nInstance } from './i18n';

export const axiosInstance = Axios.create({
  baseURL: '/api',
});

export function setAxiosHeader(headerName: string, value: string | undefined): void {
  if (typeof value === 'string') {
    axiosInstance.defaults.headers.common[headerName] = value;
  } else {
    delete axiosInstance.defaults.headers.common[headerName];
  }
}

// Set interceptor for 401 - Unauthorized exceptions
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      useAuth.getState().logout();
    } else if (error.response.status === 403) {
      showNotification({ message: i18nInstance.t('common.error.forbidden').toString(), color: 'red' });
    } else {
      console.error('Error during HTTP request:', error.response.data);
    }

    return error;
  }
);

///// REST functions

export function getMany<Entity = unknown>(resource: string, queryParams?: FilterQuery<Entity>): () => Promise<Entity> {
  return async () => {
    const queryString = queryParams ? `?${stringify(queryParams, { encodeValuesOnly: true })}` : '';

    const response = await axiosInstance.get(`${resource}${queryString}`);

    if (response instanceof AxiosError) {
      throw response;
    } else {
      return response.data;
    }
  };
}

export function getOne<Entity = unknown>(resource: string, id: string): () => Promise<Entity> {
  return async () => {
    const response = await axiosInstance.get(`${resource}/${id}`);

    if (response instanceof AxiosError) {
      throw response;
    } else {
      return response.data;
    }
  };
}

export function postOne<Entity = unknown>(resource: string): (entity: Entity) => Promise<Entity> {
  return async (entity: Entity) => {
    const response = await axiosInstance.post(resource, entity);

    if (response instanceof AxiosError) {
      throw response;
    } else {
      return response.data;
    }
  };
}

export function patchOne<Entity = unknown>(resource: string): (entity: Entity) => Promise<Entity> {
  return async (entity: Entity) => {
    const response = await axiosInstance.patch(`${resource}/${(entity as Entity & { id: string }).id}`, entity);

    if (response instanceof AxiosError) {
      throw response;
    } else {
      return response.data;
    }
  };
}

export function deleteOne<EntityId = string>(resource: string): (entity: EntityId) => Promise<EntityId> {
  return async (entityId: EntityId) => {
    const response = await axiosInstance.delete(`${resource}/${entityId}`);

    if (response instanceof AxiosError) {
      throw response;
    } else {
      return response.data;
    }
  };
}
