import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { SegmentedControl, Space, Text } from '@mantine/core';
import { DateInput, DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
  onPopoverLock: (lockState: boolean) => void;
}

export function EntityTableDateTimeFilter<T>({ selectedColumn, filterFormValues, onChange }: FilterProps<T>) {
  const { t } = useTranslation();

  // Initialize missing operation
  useEffect(() => {
    if (!filterFormValues.operation) {
      filterFormValues.operation = 'equals';
      onChange({
        operation: filterFormValues.operation,
      });
    }
  }, []);

  return (
    <>
      <Text size="sm">{t('entityTable.filter.conditionType')}:</Text>

      <SegmentedControl
        fullWidth
        value={filterFormValues.operation}
        onChange={(type) => {
          onChange({
            operation: type,
          });
        }}
        data={[
          { label: t('entityTable.filter.operation.equals'), value: 'equals' },
          { label: t('entityTable.filter.operation.gt'), value: 'gt' },
          { label: t('entityTable.filter.operation.lt'), value: 'lt' },
          { label: t('entityTable.filter.operation.between'), value: 'between' },
        ].map((r) => ({ ...r, label: uppercaseFirstLetter(r.label) }))}
      />

      <Space h={16} />

      {filterFormValues.operation === 'between' && (
        <DatePickerInput
          locale="hu"
          placeholder={t('entityTable.filter.selectDateRange')}
          label={`${selectedColumn.header}:`}
          type="range"
          allowSingleDateInRange
          value={
            filterFormValues.value && filterFormValues.secondaryValue
              ? [new Date(filterFormValues.value), new Date(filterFormValues.secondaryValue)]
              : filterFormValues.value
              ? [new Date(filterFormValues.value), null]
              : undefined
          }
          onChange={(dateRange) => {
            if (dateRange[0] && dateRange[1]) {
              onChange({
                value: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                secondaryValue: dayjs(dateRange[1]).format('YYYY-MM-DD'),
              });
            } else if (dateRange[0]) {
              onChange({
                value: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                secondaryValue: '',
              });
            } else {
              onChange({ value: '', secondaryValue: '' });
            }
          }}
          valueFormat="YYYY. MM. DD."
        />
      )}

      {filterFormValues.operation !== 'between' && (
        <DateInput
          lang="hu"
          placeholder={t('entityTable.filter.selectDate')}
          value={filterFormValues.value ? new Date(filterFormValues.value) : undefined}
          onChange={(date) => {
            if (date) {
              onChange({
                value: dayjs(date).startOf('day').format('YYYY-MM-DD'),
                secondaryValue: undefined,
              });
            }
          }}
          label={`${selectedColumn.header}:`}
          valueFormat="YYYY. MM. DD."
          locale="hu"
        />
      )}
    </>
  );
}
