import { Flex } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconDiscount2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { LocalNavLink } from '../../../components/LocalNav/components/LocalNavLink';
import { LocalNavTitle } from '../../../components/LocalNav/components/LocalNavTitle';
import { LocalNav } from '../../../components/LocalNav/LocalNav';
import { usePermission } from '../../../hooks/use-permission.hook';
import { ContentLayout } from '../../../layouts/ContentLayout';

export function PricingLayout() {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();

  return (
    <Flex>
      <LocalNav>
        <LocalNavTitle>{t('pricing.menu.title')}</LocalNavTitle>

        <LocalNavLink
          to="/pricing/price-list-versions"
          icon={IconDiscount2}
          label={t('pricing.menu.priceLists')}
          disabled={!hasAnyPermission([Permission.pricing])}
        />
      </LocalNav>

      <ContentLayout localNav>
        <Outlet />
      </ContentLayout>
    </Flex>
  );
}
