import { Box, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { EntityAuditLogResponse } from '@gravity/shared/dto';
import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { PageTitle } from '../../../components/PageTitle';
import { Page } from '../../../components/Page';

export function AuditLogPage() {
  const { t } = useTranslation();

  const query = useTableQuery<EntityAuditLogResponse>('audit-logs');

  const cols: EntityTableColumnDef<EntityAuditLogResponse>[] = [
    {
      accessorKey: 'logDate',
      type: EntityTableColumnType.DateTime,
    },
    { accessorKey: 'model' },
    { accessorKey: 'entityId', type: EntityTableColumnType.Id },
    { accessorKey: 'version', type: EntityTableColumnType.Number },
    { accessorKey: 'action' },
    {
      accessorKey: 'user.email',
      i18n: 'entity.auditLog.user',
    },
    { accessorKey: 'diffToPrevious', type: EntityTableColumnType.JSONDiff, minSize: 350 },
  ];

  return (
    <Page>
      <PageTitle title={t('settings.auditLog.title')} subtitle={t('settings.auditLog.subtitle')} />

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <PermissionCheck hasAll={[Permission.entity_audit_log_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="auditLog"
              tableQuery={query}
              columns={cols}
              initialState={{
                sorting: [
                  {
                    id: 'logDate',
                    desc: true,
                  },
                ],
              }}
              hideActionColumn
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Page>
  );
}
