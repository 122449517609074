import { Stack, Title, Text, Flex, Box } from '@mantine/core';
import { ReactElement } from 'react';

interface PageTitleProps {
  title: string;
  subtitle?: string;
  children?: ReactElement;
}

export function PageTitle(props: PageTitleProps) {
  return (
    <Flex align={props?.subtitle ? 'top' : 'center'} gap={0}>
      <Stack spacing={0} sx={{ flexGrow: 1 }}>
        <Title order={4} pb={0}>
          {props.title}
        </Title>

        {props.subtitle && (
          <Text size="xs" color="gray.7">
            {props.subtitle}
          </Text>
        )}
      </Stack>

      {props.children && <Box ml="xl">{props.children}</Box>}
    </Flex>
  );
}
