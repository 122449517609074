import { GrForm, GrFormTitle, MoneyFormat, useDtoForm } from '@gravity/frontend/ui';
import { PriceListResponse, PriceListUpdateRequest } from '@gravity/shared/dto';
import { ActionIcon, Button, Grid, Group, NumberInput, ScrollArea, Text, Title } from '@mantine/core';
import { Controller, useFieldArray } from 'react-hook-form';
import { Permission } from '@prisma/client';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';

export function PriceListFormPage() {
  const { t } = useTranslation();
  const priceUnits = ['db', 'fm', 'par', 'doboz', 'csomag'];
  const entity = useLoaderData() as PriceListResponse | undefined;

  const priceListForm = useDtoForm([PriceListUpdateRequest, PriceListUpdateRequest], 'entity.pricing', {
    isEditForm: true,
    defaultValues: entity ? entity : undefined,
  });

  const { control } = priceListForm;
  const { fields: priceListItems } = useFieldArray({ name: 'priceListItems', control, keyName: 'priceListItemId' });
  const priceListMutation = useDtoMutation('price-list-versions/price-lists', {
    invalidateQueries: [['price-list-versions/price-lists']],
  });

  async function handleSubmit(data: PriceListUpdateRequest) {
    data.priceListItems?.forEach((item) => {
      delete item['priceListId'];
    });

    await priceListMutation.update(data);
    priceListForm.toggleReadonlyMode(data);
  }
  return (
    <Page>
      <GrForm form={priceListForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to={`/pricing/price-list-versions`}>
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle
              i18nPrefix="pricing.priceListForm"
              variable={entity?.product ? entity.product.name : entity?.attributeDefinition?.name}
            />
          </Group>

          <Group>
            {priceListForm.readonlyMode ? (
              <PermissionCheck hasAll={[Permission.pricing]}>
                <Button
                  onClick={() => {
                    priceListForm.toggleReadonlyMode();
                  }}
                  variant="light"
                  leftIcon={<IconPencil />}
                >
                  {t('common.button.edit')}
                </Button>
              </PermissionCheck>
            ) : (
              <>
                <PermissionCheck hasAll={[Permission.pricing]}>
                  <Button loading={priceListForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                    {t('common.button.save')}
                  </Button>
                </PermissionCheck>

                <Button
                  loading={priceListForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() => priceListForm.toggleReadonlyMode()}
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}
          </Group>
        </Group>

        <Title order={5}>{`${t('entity.pricing.validFrom')}: ${new Date(
          entity?.priceListVersion?.validityStartDate as any
        ).toLocaleDateString()} - ${
          entity?.priceListVersion?.validityEndDate
            ? new Date(entity?.priceListVersion?.validityEndDate as any).toLocaleDateString()
            : t('entity.pricing.indefinitePeriod')
        } `}</Title>

        <Title order={5}>
          {`${t('entity.pricing.partnerId')}: ${
            entity?.priceListVersion?.partner ? entity?.priceListVersion?.partner?.name : '-'
          }`}
        </Title>

        <ScrollArea type="scroll" mb="lg" style={{ height: 'calc(100vh - 250px)' }}>
          {priceListItems?.map((priceListItem, index) => (
            <Grid
              m={0}
              key={priceListItem.priceListItemId}
              sx={
                index & 1
                  ? (theme) => ({
                      background: theme.colors[theme.primaryColor][0],
                    })
                  : {}
              }
            >
              <Grid.Col xs={10}>
                <Text>
                  {priceListItem.label}

                  {priceListItem.packaging ? ' - ' : ''}

                  {priceListItem.packaging ? priceListItem.packaging : ''}

                  {priceListItem.code.includes('_k') ? ' - Kombi' : priceListItem.code.includes('_s') ? ' - Sima' : ''}
                </Text>
              </Grid.Col>

              <Grid.Col xs={2}>
                {priceListForm.readonlyMode ? (
                  <>
                    <Text>
                      <MoneyFormat
                        currency={
                          priceUnits.includes(priceListItem.code.split('_')[2])
                            ? `€/${priceListItem.code.split('_')[2]}`
                            : priceUnits.includes(priceListItem.code.split('_')[3])
                            ? `€/${priceListItem.code.split('_')[3]}`
                            : '€'
                        }
                        type="decimal"
                        minimumFractionDigits={2}
                      >
                        {priceListItem.price}
                      </MoneyFormat>
                    </Text>
                  </>
                ) : (
                  <Controller
                    name={`priceListItems.${index}.price`}
                    control={priceListForm.control}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        onChange={(e) => {
                          e !== '' ? field.onChange(e) : field.onChange(0);
                        }}
                        min={0}
                        max={1000}
                        size="xs"
                        decimalSeparator=","
                        precision={2}
                        style={{ width: '80px' }}
                      />
                    )}
                  />
                )}
              </Grid.Col>
            </Grid>
          ))}
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
