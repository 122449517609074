import { TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
}

export function EntityTableStringFilter<T>({ selectedColumn, filterFormValues, onChange }: FilterProps<T>) {
  const { t } = useTranslation();

  return (
    <TextInput
      label={`${selectedColumn.header}:`}
      value={filterFormValues.value}
      name="filterValue"
      onChange={({ currentTarget: { value } }) => onChange({ value, operation: 'contains' })}
      placeholder={t('entityTable.filter.filterInputPlaceholder')}
      autoFocus
    />
  );
}
