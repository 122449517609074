import { UnitOfMeasurementResponse } from '@gravity/shared/dto';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useUnitsOfMeasurement() {
  const useQueryReturn = useQuery(
    ['units-of-measurement', 'list-units-of-measurement'],
    getMany<UnitOfMeasurementResponse[]>('units-of-measurement/list-units-of-measurement'),
    {
      staleTime: Infinity,
    }
  );

  return useQueryReturn ? useQueryReturn.data : [];
}
