import { StorageUploadResponse } from '@gravity/shared/dto';
import { create } from 'zustand';
import { axiosInstance } from '../plugins/axios';

export const useStorageService = create(() => ({
  async upload(file: File, metadata?: Record<string, any>) {
    const results = await axiosInstance.post<StorageUploadResponse>(
      `storage/upload`,
      { file, ...metadata },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return results.data;
  },

  async uploadNewsPostAttachment(file: File, metadata?: Record<string, any>) {
    const results = await axiosInstance.post<StorageUploadResponse>(
      `news-posts/upload-file`,
      { file, ...metadata },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return results.data;
  },

  async uploadAvatar(file: File, metadata?: Record<string, any>) {
    const results = await axiosInstance.post<StorageUploadResponse>(
      `users/upload-avatar`,
      { file, ...metadata },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return results.data;
  },

  async getFile(fileId: string) {
    return (await axiosInstance.get(`storage/${fileId}`, { responseType: 'arraybuffer' })).data;
  },

  async getFileToImgSrc(fileId: string) {
    const response = await axiosInstance.get(`storage/${fileId}`, { responseType: 'blob' });
    return URL.createObjectURL(response.data);
  },
}));
