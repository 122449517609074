import { SegmentedControl, TextInput } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
}

export function EntityTableBooleanFilter<T>({ selectedColumn, filterFormValues, onChange }: FilterProps<T>) {
  const { t } = useTranslation();

  // Initialize missing operation
  useEffect(() => {
    if (!filterFormValues.value) {
      filterFormValues.value = 'true';
      filterFormValues.operation = 'equals';
      onChange({
        value: filterFormValues.value,
        operation: 'equals',
      });
    }
  }, []);

  return (
    <SegmentedControl
      fullWidth
      value={filterFormValues.value}
      onChange={(value) => {
        onChange({
          value: value,
          operation: 'equals',
        });
      }}
      data={[
        { label: t('common.message.true'), value: 'true' },
        { label: t('common.message.false'), value: 'false' },
      ]}
    />
  );
}
