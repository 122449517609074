import { RouteObject } from 'react-router-dom';
import { AppLayout } from '../../layouts/AppLayout';
import { getOne } from '../../plugins/axios';
import { queryClient } from '../../plugins/react-query';
import { createGuardedLoader } from '../../utils';
import { PricingLayout } from './layouts/PricingLayout';
import { PriceListFormPage } from './pages/PriceListFormPage';
import { PriceListsPage } from './pages/PriceListsPage';
import { NotFound } from '../core/pages/NotFound';

export const pricingRoutes: RouteObject[] = [
  {
    path: 'pricing',
    element: <AppLayout />,
    children: [
      {
        element: <PricingLayout />,
        children: [
          {
            path: 'price-list-versions',
            element: <PriceListsPage />,
          },
          {
            path: 'price-list-versions/price-lists/:idOrAction',
            element: <PriceListFormPage />,
            loader: createGuardedLoader(({ params }) => {
              if (!params.idOrAction || params.idOrAction === 'create') {
                return null;
              }

              return queryClient.fetchQuery(
                ['price-list-versions/price-lists', params.idOrAction],
                getOne('price-list-versions/price-lists', params.idOrAction)
              );
            }),
            errorElement: <NotFound />,
          },
        ],
      },
    ],
  },
];
