import { Permission } from '@prisma/client';
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom';
import { checkPermissions } from './hooks/use-permission.hook';
import { useAuth } from './stores/auth.store';

type DataFunctionValue = Response | NonNullable<unknown> | null;

export function createLoader(
  loaderFn: (args: LoaderFunctionArgs) => Promise<DataFunctionValue> | DataFunctionValue | undefined
): LoaderFunction {
  return (args: LoaderFunctionArgs) => {
    if (loaderFn) {
      const loaderResult = loaderFn(args);

      if (loaderResult) {
        return loaderResult;
      }
    }

    return null;
  };
}

export function createGuardedLoader(loaderFn?: LoaderFunction, permissions?: Permission[]): LoaderFunction {
  return (args: LoaderFunctionArgs) => {
    // Handle authentication logic
    const auth = useAuth.getState();

    if (!auth.isAuthenticated()) {
      return redirect('/login');
    }

    if (auth.isReseller() && !location.pathname.includes('reseller')) {
      return redirect('/reseller');
    }

    // Check for optional permissions
    if (permissions) {
      checkPermissions(...permissions);
    }

    if (loaderFn) {
      const loaderResult = loaderFn(args);

      if (loaderResult) {
        return loaderResult;
      }
    }

    return null;
  };
}
