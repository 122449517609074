import { Country } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';
import { getMany } from '../plugins/axios';

export function useCountry() {
  const countries = useQuery(['countries'], getMany<Country[]>('countries'), {
    staleTime: Infinity,
  });

  return countries.data;
}
