import { Box } from '@mantine/core';
import React, { BaseSyntheticEvent } from 'react';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { GrFormTitle } from './components/GrFormTitle';
import { GrNumberInput } from './GrNumberInput';
import { GrDateInput } from './GrDateInput';
import { GrFileInput } from './GrFileInput';
import { GrMultiSelectInput } from './GrMultiSelectInput';
import { GrPasswordInput } from './GrPasswordInput';
import { GrSelectInput } from './GrSelectInput';
import { GrSwitchInput } from './GrSwitchInput';
import { GrTextareaInput } from './GrTextareaInput';
import { GrTextInput } from './GrTextInput';
import { GrFormTabs } from './components/GrFormTabs';
import { GrQueryBuilderInput } from './GrQueryBuilderInput';
import { GrAutocompleteInput } from './GrAutocompleteInput';
import { GrRichTextInput } from './GrRichTextInput';
import { useSubmit } from 'react-router-dom';

interface FormProps<CreateDto, EditDto> {
  form: UseDtoFormReturn;
  children: JSX.Element | JSX.Element[] | any[];
  onSubmit?: (data: CreateDto | EditDto) => void;
  submitAction?: string;
}

const decoratedComponents = [
  GrFormTitle,
  GrTextInput,
  GrTextareaInput,
  GrSwitchInput,
  GrSelectInput,
  GrPasswordInput,
  GrNumberInput,
  GrMultiSelectInput,
  GrFormTabs,
  GrQueryBuilderInput,
  GrDateInput,
  GrAutocompleteInput,
  GrRichTextInput,
  GrFileInput,
];

export function GrForm<CreateDto, EditDto>({ form, children, onSubmit }: FormProps<CreateDto, EditDto>) {
  const submit = useSubmit();

  function recursiveMap(children: JSX.Element | JSX.Element[]) {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if ((child as JSX.Element).props.children) {
        const recursiveChildren = recursiveMap((child as JSX.Element).props.children);
        child = React.cloneElement(
          child,
          undefined,
          recursiveChildren.length === 1 ? recursiveChildren[0] : recursiveChildren
        );
      }

      // If the found component should be decorated add the form prop
      if (
        ((child as JSX.Element).type.name &&
          decoratedComponents.find((c) => c.name === (child as JSX.Element).type.name)) ||
        decoratedComponents.includes((child as JSX.Element).type.name)
      ) {
        return React.cloneElement(child, {
          form,
        });
      }

      return child;
    });
  }

  // Fallback submit to catch in router
  async function fallbackSubmit(e: BaseSyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    form.handleSubmit((data) => {
      submit(form.getValues(), { method: 'post', action: window.location.pathname });
    })();
  }

  return (
    <Box>
      <form onSubmit={onSubmit ? form.handleSubmit(onSubmit) : fallbackSubmit} noValidate autoComplete="new-password">
        <Box hidden>
          <GrTextInput form={form} name="id" />
        </Box>

        {recursiveMap(children)}
      </form>
    </Box>
  );
}
