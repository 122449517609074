import { Type } from 'class-transformer';
import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { PartnerAddressCreateRequest, PartnerAddressUpdateRequest } from './partner-address.request';
import { PartnerBankAccountCreateRequest, PartnerBankAccountUpdateRequest } from './partner-bank-account.request';
import { PartnerContactCreateRequest, PartnerContactUpdateRequest } from './partner-contact.request';
import { PartnerUserCreateRequest, PartnerUserUpdateRequest } from './partner-user.request';

export class PartnerCreateRequest {
  /**
   * The name of the partner
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The tax id number of the partner
   */
  @IsString()
  @IsNotEmpty()
  public taxIdNumber: string;

  /**
   * The registration number of the partner
   */
  @IsString()
  public registrationNumber?: string;

  /**
   * The central phone number of the partner
   */
  @IsString()
  public centralPhoneNumber?: string;

  /**
   * The central e-mail address of the partner
   */
  @IsEmail()
  @ValidateIf((o, e) => e !== '')
  public centralEmail?: string;

  /**
   * The website of the partner
   */
  @IsString()
  public website?: string;

  /**
   * The company logo of the partner
   */
  @IsString()
  public companyLogo?: string;

  /**
   * Internal notes of the partner
   */
  @IsString()
  public notes?: string;

  /**
   * True if the partner is supplier
   */
  @IsBoolean()
  @IsOptional()
  public isSupplier?: boolean;

  /**
   * True if the partner is customer
   */
  @IsBoolean()
  @IsOptional()
  public isCustomer?: boolean;

  /**
   * True if the partner is reseller
   */
  @IsBoolean()
  @IsOptional()
  public isReseller?: boolean;

  /**
   * True if the partner confirmed Privacy and Term of Use
   */
  @IsBoolean()
  @IsOptional()
  public confirmedPTU?: boolean;

  /**
   * The addresses of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerAddressCreateRequest } })
  @Type(() => PartnerAddressCreateRequest)
  public addresses?: PartnerAddressCreateRequest[];

  /**
   * The contacts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerContactCreateRequest } })
  @Type(() => PartnerContactCreateRequest)
  public contacts?: PartnerContactCreateRequest[];

  /**
   * The bank accounts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerBankAccountCreateRequest } })
  @Type(() => PartnerBankAccountCreateRequest)
  public bankAccounts?: PartnerBankAccountCreateRequest[];

  /**
   * The connected users of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerUserCreateRequest } })
  @Type(() => PartnerUserCreateRequest)
  public users?: PartnerUserCreateRequest[];
}

export class PartnerUpdateRequest {
  /**
   * CUID identifier of the partner
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the partner
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The tax id number of the partner
   */
  @IsString()
  @IsNotEmpty()
  public taxIdNumber: string;

  /**
   * The registration number of the partner
   */
  @IsString()
  public registrationNumber?: string;

  /**
   * The central phone number of the partner
   */
  @IsString()
  public centralPhoneNumber?: string;

  /**
   * The central e-mail address of the partner
   */
  @IsEmail()
  @ValidateIf((o, e) => e !== '')
  public centralEmail?: string;

  /**
   * The website of the partner
   */
  @IsString()
  public website?: string;

  /**
   * The company logo of the partner
   */
  @IsString()
  public companyLogo?: string;

  /**
   * Internal notes of the partner
   */
  @IsString()
  public notes?: string;

  /**
   * True if the partner is supplier
   */
  @IsBoolean()
  public isSupplier?: boolean;

  /**
   * True if the partner is customer
   */
  @IsBoolean()
  public isCustomer?: boolean;

  /**
   * True if the partner is reseller
   */
  @IsBoolean()
  public isReseller?: boolean;

  /**
   * The addresses of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerAddressUpdateRequest } })
  @Type(() => PartnerAddressUpdateRequest)
  public addresses?: PartnerAddressUpdateRequest[];

  /**
   * The contacts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerContactUpdateRequest } })
  @Type(() => PartnerContactUpdateRequest)
  public contacts?: PartnerContactUpdateRequest[];

  /**
   * The bank accounts of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerBankAccountUpdateRequest } })
  @Type(() => PartnerBankAccountUpdateRequest)
  public bankAccounts?: PartnerBankAccountUpdateRequest[];

  /**
   * The connected users of the partner
   */
  @ValidateNested({ each: true, context: { type: PartnerUserUpdateRequest } })
  @Type(() => PartnerUserUpdateRequest)
  public users?: PartnerUserUpdateRequest[];

  /**
   * True if the partner confirmed Privacy and Term of Use
   */
  @IsBoolean()
  @IsOptional()
  public confirmedPTU?: boolean;
}
