import { registerDecorator } from 'class-validator';

export function IsTrue(ptu = false) {
  return function (object: unknown, propertyName: string) {
    registerDecorator({
      name: ptu ? 'pleaseConfirm' : 'isTrue',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      target: (object as any).constructor,
      propertyName: propertyName,
      options: {
        message: `"${propertyName}" must be true`,
      },
      validator: {
        validate(targetValue: boolean) {
          return targetValue;
        },
      },
    });
  };
}
