import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { UserResponse } from '@gravity/shared/dto';
import { Box, Button, ScrollArea } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../components/PageTitle';
import { useTableQuery } from '../../../hooks/use-table-query.hook';

export function ResellerUsersPage() {
  const { t } = useTranslation();

  const query = useTableQuery<UserResponse>('reseller/users');

  const cols: EntityTableColumnDef<UserResponse>[] = [
    { accessorKey: 'avatar', type: EntityTableColumnType.Avatar, enableColumnFilter: false },
    { accessorKey: 'lastName' },
    { accessorKey: 'firstName' },
    { accessorKey: 'email' },
    { accessorKey: 'position' },
    {
      accessorKey: 'partnerUser.permission',
      i18n: 'entity.user.partnerUser.permission',
      type: EntityTableColumnType.Enum,
      cell: (row) => <>{row.getValue() && t('common.permission.' + row.getValue())}</>,
      filterOptions: Object.keys(Permission)
        .filter((permission) => permission.startsWith('reseller'))
        .map((permission) => ({
          value: permission,
          label: t(`common.permission.${permission}`),
        })),
    },
    { accessorKey: 'createdAt', type: EntityTableColumnType.Date },
  ];

  return (
    <Box p="lg">
      <PageTitle title={t('settings.users.title')} subtitle={t('component.reseller.users.subtitle')}>
        <Link to="create">
          <Button leftIcon={<IconPlus />} variant="light">
            {t('common.button.add')}
          </Button>
        </Link>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <Box pt="md">
          <EntityTable
            resourceName="user"
            tableQuery={query}
            columns={cols}
            onSettingsChange={query.handleTableChange}
          />
        </Box>
      </ScrollArea>
    </Box>
  );
}
