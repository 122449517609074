import { NumberInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
}

export function EntityTableNumberFilter<T>({ selectedColumn, filterFormValues, onChange }: FilterProps<T>) {
  const { t } = useTranslation();

  return (
    <NumberInput
      label={`${selectedColumn.header}:`}
      value={filterFormValues.value ? parseFloat(filterFormValues.value) : ''}
      name="filterValue"
      onChange={(value) => {
        if (value !== undefined && value !== '' && value > -1) {
          onChange({ value: value?.toString(), operation: 'equals' });
        }
      }}
      min={0}
      placeholder={t('entityTable.filter.filterInputPlaceholder')}
      autoFocus
    />
  );
}
