import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { DefaultValues, SubmitHandler } from 'react-hook-form/dist/types/form';
import { ClassConstructor } from 'class-transformer';
import { FieldErrorsImpl, FieldValues, UseFormProps } from 'react-hook-form/dist/types';
import { useMemo, useState } from 'react';
import { getMetadataStorage } from 'class-validator';
import { get } from 'lodash';
import { ValidationMetadata } from 'class-validator/types/metadata/ValidationMetadata';

interface UseDtoFormOptions<CreateDto, EditDto> extends UseFormProps<CreateDto & EditDto & FieldValues> {
  validators?: Record<
    string,
    (fieldData: string) => Promise<FieldErrorsImpl | undefined> | FieldErrorsImpl | undefined
  >;
  isEditForm: boolean;
  readonlyMode?: boolean;
}

export type UseDtoFormReturn = ReturnType<typeof useDtoForm<any, any>>;

export function useDtoForm<CreateDto, EditDto>(
  dtoArray: [ClassConstructor<CreateDto>, ClassConstructor<EditDto>] | ClassConstructor<CreateDto>,
  i18nPrefix: string,
  options?: UseDtoFormOptions<CreateDto, EditDto>
) {
  type MergedDto = CreateDto & EditDto & FieldValues;

  const isEditForm = options?.isEditForm ?? false;

  const [readonlyMode, setReadonlyMode] = useState(options?.readonlyMode ?? isEditForm);

  // Determine DTO class
  let dtoClass: ClassConstructor<unknown>;
  if (Array.isArray(dtoArray)) {
    dtoClass = isEditForm ? dtoArray[1] : dtoArray[0];
  } else {
    dtoClass = dtoArray;
  }

  // Calculate required and existing fields from class-validator resolver
  const [requiredFields, existingFields, calculatedDefaultValues] = useMemo(() => {
    const requiredFieldsArray: string[] = [];
    const existingFieldsArray: string[] = [];
    const calculatedDefaultValues: Record<string, boolean> = {};

    const metadataStorage = getMetadataStorage();
    const targetValidationMetadata = metadataStorage.getTargetValidationMetadatas(dtoClass, '', true, false);

    const nestedValidationMetadata = targetValidationMetadata
      .filter((v) => v.type === 'nestedValidation')
      .reduce((acc, v) => {
        acc.push(
          ...metadataStorage.getTargetValidationMetadatas(v.context?.type, '', true, false).map((m) => {
            return { ...m, propertyName: `${v.propertyName}.${m.propertyName}` };
          })
        );
        return acc;
      }, [] as ValidationMetadata[]);

    const secondNestedValidationMetadata = nestedValidationMetadata
      .filter((v) => v.type === 'nestedValidation')
      .reduce((acc, v) => {
        acc.push(
          ...metadataStorage.getTargetValidationMetadatas(v.context?.type, '', true, false).map((m) => {
            return { ...m, propertyName: `${v.propertyName}.${m.propertyName}` };
          })
        );
        return acc;
      }, [] as ValidationMetadata[]);

    targetValidationMetadata.push(...nestedValidationMetadata);
    targetValidationMetadata.push(...secondNestedValidationMetadata);

    if (!readonlyMode) {
      targetValidationMetadata.forEach((validator) => {
        existingFieldsArray.push(validator.propertyName);

        const hasIsNotNullDecorator = metadataStorage
          .getTargetValidatorConstraints(validator.constraintCls)
          .find((constraint) => ['isNotEmpty', 'arrayNotEmpty'].includes(constraint.name));

        if (hasIsNotNullDecorator) {
          requiredFieldsArray.push(validator.propertyName);
        }

        // Initialize boolean fields
        const hasIsBooleanDecorator = metadataStorage
          .getTargetValidatorConstraints(validator.constraintCls)
          .find((constraint) => ['isBoolean'].includes(constraint.name));

        if (hasIsBooleanDecorator) {
          calculatedDefaultValues[validator.propertyName] = false;
        }
      });
    }

    return [requiredFieldsArray, [...new Set(existingFieldsArray)], calculatedDefaultValues];
  }, [readonlyMode, dtoClass]);

  const defaultValues = {
    // Init fields with empty string
    ...(Object.fromEntries(existingFields.map((field) => [field, ''])) as DefaultValues<MergedDto>),

    ...calculatedDefaultValues,
    // Overwrite it with the default values provided in the hook call
    ...options?.defaultValues,
  };

  // Call form hook
  const useFormReturn = useForm<MergedDto>({
    ...options,
    mode: 'onSubmit',
    defaultValues,
    reValidateMode: 'onChange',
    // Custom validation resolver: uses class-validator for DTO files and additional validators from the parent component
    resolver: async (data, _, resolverOptions) => {
      // Errors from the class-validator resolver
      const classValidatorErrors = (await classValidatorResolver(dtoClass as never)(data, undefined, {} as never))
        .errors;

      // Custom validators attached to fields
      const customValidationErrors: Record<string, FieldErrorsImpl> = {};
      if (options && options.validators) {
        for (const field in options.validators) {
          const validatorFunction = options.validators[field];
          if (!get(classValidatorErrors, field) && resolverOptions.names?.includes(field)) {
            const error = await validatorFunction(data[field]);
            if (error) {
              customValidationErrors[field] = error;
            }
          }
        }
      }

      // Return merged error
      return {
        values: Object.keys(classValidatorErrors).length > 0 ? {} : data,
        errors: { ...classValidatorErrors, ...customValidationErrors } as never,
      };
    },
  });

  function handleSubmit(onValidHandler?: SubmitHandler<CreateDto & EditDto & FieldValues>) {
    return useFormReturn.handleSubmit(
      (data) => {
        Object.keys(data).forEach((key) => {
          if (!existingFields.includes(key) || key.includes('.')) {
            delete data[key];
          }
        });

        return onValidHandler ? onValidHandler(data) : null;
      },
      () => {
        if (!useFormReturn.formState.isValid) {
          setTimeout(() => {
            console.error('Form is not valid!', useFormReturn.formState.errors, useFormReturn.getValues());
          });
        }
      }
    );
  }

  function toggleReadonlyMode(formValues?: any) {
    setReadonlyMode(!readonlyMode);
    useFormReturn.reset(formValues);
  }

  function resetForm(formValues?: any) {
    useFormReturn.reset(formValues);
  }

  return {
    ...useFormReturn,
    isSubmitting: useFormReturn.formState.isSubmitting,
    i18nPrefix,
    dtoClass,
    requiredFields,
    existingFields,
    defaultValues,
    isEditForm,
    isCreateForm: !isEditForm,
    readonlyMode,
    handleSubmit,
    toggleReadonlyMode,
    resetForm,
  };
}
