import { IsNotEmpty, IsString } from 'class-validator';

export class ProductCategoryCreateRequest {
  /**
   * The name of the category
   */
  @IsString()
  @IsNotEmpty()
  public name: string;
}

export class ProductCategoryUpdateRequest {
  /**
   * The ID of the category
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the category
   */
  @IsString()
  @IsNotEmpty()
  public name?: string;
}
