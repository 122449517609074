import { IsNotEmpty, IsString } from 'class-validator';

export class CheckUserRoleRequest {
  /**
   * The name of the role
   */
  @IsString()
  @IsNotEmpty()
  public name: string;
}
