import { Box, Center, Tooltip, Button, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UserRoleResponse } from '@gravity/shared/dto';
import {
  EntityTable,
  EntityTableColumnDef,
  EntityTableColumnType,
  EntityTableListRenderer,
} from '@gravity/frontend/ui';
import { CellContext } from '@tanstack/react-table';
import { IconLock } from '@tabler/icons-react';
import { IconPlus } from '@tabler/icons-react';
import { PageTitle } from '../../../components/PageTitle';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Link } from 'react-router-dom';

function generateName(ctx: CellContext<UserRoleResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      <>
        {ctx.row.original.internal && (
          <Tooltip label={t('entity.userRole.internal')} position="right">
            {ctx.row.original.internal && (
              <Box mr={4} sx={{ position: 'relative', top: 2 }}>
                <IconLock color="grey" size={20} />
              </Box>
            )}
          </Tooltip>
        )}

        {ctx.getValue()}
      </>
    </Center>
  );
}

export function UserRolePage() {
  const { t } = useTranslation();

  const query = useTableQuery<UserRoleResponse>('user-roles');

  const cols: EntityTableColumnDef<UserRoleResponse>[] = [
    { accessorKey: 'name', cell: (row) => generateName(row) },
    { accessorKey: 'description' },
    {
      accessorKey: 'permissions',
      type: EntityTableColumnType.EnumArray,
      renderer: <EntityTableListRenderer i18nPrefix="common.permission" />,
      enableSorting: false,
      filterOptions: Object.keys(Permission).map((permission) => ({
        value: permission,
        label: t(`common.permission.${permission}`),
      })),
    },
  ];

  return (
    <Box p="lg">
      <PageTitle title={t('settings.userRoles.title')} subtitle={t('settings.userRoles.subtitle')}>
        <PermissionCheck hasAll={[Permission.user_role_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 90px)' }}>
        <PermissionCheck hasAll={[Permission.user_role_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="userRole"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Box>
  );
}
