import { focusManager, QueryClient } from '@tanstack/react-query';

// Fix GR-46: Throttle automatic window focus reload to preserve clickable buttons in re-rendered components outside of the browser window.
focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange and focus
  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('visibilitychange', handleFocus as unknown as EventListener, false);
    window.addEventListener('focus', () => setTimeout(() => handleFocus(), 100), false);
  }

  return () => {
    // Be sure to unsubscribe if a new handler is set
    window.removeEventListener('visibilitychange', handleFocus as unknown as EventListener);
    window.removeEventListener('focus', handleFocus as unknown as EventListener);
  };
});

export const queryClient = new QueryClient();
