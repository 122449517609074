import { GrForm, GrFormTitle, GrPasswordInput, GrSelectInput, GrTextInput, useDtoForm } from '@gravity/frontend/ui';
import { UserCreateRequest, UserResponse, UserUpdateRequest } from '@gravity/shared/dto';
import { ActionIcon, Button, Divider, Grid, Group, ScrollArea } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconChevronLeft, IconCheck, IconPencil, IconX } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { useCurrentPartner } from '../../../hooks/use-current-partner.hook';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { axiosInstance } from '../../../plugins/axios';
import { useCurrentUser } from '../../../hooks/use-current-user.hook';

export function ResellerUserFormPage() {
  const { t } = useTranslation();
  const { partner } = useCurrentPartner();
  const { user } = useCurrentUser();

  const navigate = useNavigate();
  const entity = useLoaderData() as UserResponse | undefined;

  const userForm = useDtoForm([UserCreateRequest, UserUpdateRequest], 'entity.user', {
    isEditForm: !!entity,
    defaultValues: entity
      ? {
          ...entity,
          roles: entity?.roles?.map((r) => r.userRole),
        }
      : undefined,
    validators: {
      email: emailValidation,
    },
  });

  async function emailValidation(email: string) {
    const result = await axiosInstance.get(`users/check-email?email=${email}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictEmail') {
      return { type: result.response?.data.message };
    }
  }

  const userMutation = useDtoMutation('reseller/users', {
    invalidateQueries: [['reseller/users'], ['partner-profile']],
  });

  const permissions = Object.keys(Permission)
    .filter((permission) => permission.startsWith('reseller'))
    .map((permission) => ({
      id: permission,
      label: t(`common.permission.${permission}`),
    }));

  async function handleSubmit(data: UserCreateRequest | UserUpdateRequest) {
    if (userForm.isCreateForm) {
      data.partnerUser = {
        ...data.partnerUser,
        partnerId: partner?.id,
      };

      const user = (await userMutation.create(data)) as UserResponse;
      navigate(`/reseller/users/${user.id}`);
      userForm.toggleReadonlyMode(data);
    } else {
      if (data.partnerUser) {
        delete data.partnerUser['partner'];
        delete data.partnerUser['userId'];
      }
      await userMutation.update(data);
      const updatedData = {
        ...data,
        email: entity?.email,
      };
      if (data['id'] === user?.id && data.partnerUser?.permission !== Permission.reseller_system_admin) {
        navigate('/reseller');
      } else {
        userForm.toggleReadonlyMode(updatedData);
      }
    }
  }

  return (
    <Page>
      <GrForm form={userForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/reseller/users">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="settings.userForm" />
          </Group>

          <Group>
            {userForm.readonlyMode ? (
              <Button
                onClick={() => {
                  userForm.toggleReadonlyMode();
                }}
                variant="light"
                leftIcon={<IconPencil />}
              >
                {t('common.button.edit')}
              </Button>
            ) : (
              <>
                <Button
                  loading={userForm.isSubmitting}
                  color="green"
                  type="submit"
                  variant="light"
                  leftIcon={<IconCheck />}
                >
                  {t('common.button.save')}
                </Button>

                <Button
                  loading={userForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() => (userForm.isCreateForm ? navigate(-1) : userForm.toggleReadonlyMode())}
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {userForm.isEditForm && userForm.readonlyMode && (
              <DeleteConfirmButton
                loading={userForm.isSubmitting}
                onDelete={async () => {
                  try {
                    await userMutation.delete(userForm.getValues('id' as never));
                    navigate('/reseller/users');
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col>
              <GrTextInput name="email" />
            </Grid.Col>

            {userForm.isCreateForm && (
              <>
                <Grid.Col xs={6}>
                  <GrPasswordInput name="password" />
                </Grid.Col>

                <Grid.Col xs={6}>
                  <GrPasswordInput name="passwordRepeat" />
                </Grid.Col>
              </>
            )}
          </Grid>

          <Divider my="xl" />

          <Grid>
            <Grid.Col xs={6}>
              <GrTextInput name="lastName" autocomplete="family-name" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="firstName" autocomplete="given-name" />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col xs={6}>
              <GrTextInput name="position" />
            </Grid.Col>
          </Grid>

          <GrSelectInput data={permissions} name="partnerUser.permission" simpleValue />
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
