import { ErrorResponse } from '@gravity/shared/dto';
import { useState } from 'react';
import { useActionData } from 'react-router-dom';

export function useErrorState(defaultValue?: unknown) {
  const [lastError, setLastError] = useState<ErrorResponse | undefined>();
  const [errorState, setErrorState] = useState<ErrorResponse | undefined>(defaultValue as ErrorResponse | undefined);

  if (!errorState && defaultValue && lastError !== defaultValue) {
    setErrorState(defaultValue as ErrorResponse | undefined);
  }

  return {
    error: errorState,
    clearError() {
      if (errorState) {
        setLastError(errorState);
        setErrorState(undefined);
      }
    },
  };
}

export function useActionError() {
  return useErrorState(useActionData());
}
