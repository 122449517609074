import {
  GrForm,
  GrPasswordInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  useDtoForm,
} from '@gravity/frontend/ui';
import {
  PartnerAddressUpdateRequest,
  PartnerBankAccountUpdateRequest,
  PartnerContactUpdateRequest,
  ResellerAddressesCreateRequest,
  ResellerBankAccountsCreateRequest,
  ResellerCompanyCreateRequest,
  ResellerConfirmCreateRequest,
  ResellerContactsCreateRequest,
  ResellerCreateRequest,
  ResellerUserCreateRequest,
} from '@gravity/shared/dto';
import {
  ActionIcon,
  BackgroundImage,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Grid,
  Group,
  Input,
  Image,
  Paper,
  Stepper,
  Text,
  Alert,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { AddressType } from '@prisma/client';
import {
  IconAddressBook,
  IconBriefcase,
  IconBuildingBank,
  IconBuildingCommunity,
  IconCheck,
  IconChevronsLeft,
  IconChevronsRight,
  IconRowInsertTop,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../../hooks/use-country.hook';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { axiosInstance } from '../../../plugins/axios';
import { useAppConfig } from '../../../hooks/use-app-config.hook';

export function ResellerRegistration() {
  const companyInnerForm = useDtoForm(ResellerCompanyCreateRequest, 'entity.partner');
  const addressesInnerForm = useDtoForm(ResellerAddressesCreateRequest, 'entity.partner');
  const contactsInnerForm = useDtoForm(ResellerContactsCreateRequest, 'entity.partner');
  const bankAccountsInnerForm = useDtoForm(ResellerBankAccountsCreateRequest, 'entity.partner');
  const userInnerForm = useDtoForm(ResellerUserCreateRequest, 'entity.partner.user', {
    isEditForm: false,
    defaultValues: undefined,
    validators: {
      email: emailValidation,
    },
  });
  const completedForm = useDtoForm(ResellerConfirmCreateRequest, 'entity.partner');

  const [registrationData, setRegistrationData] = useState({
    partner: {
      name: '',
      taxIdNumber: '',
      registrationNumber: '',
      centralPhoneNumber: '',
      centralEmail: '',
      website: '',
      companyLogo: '',
      notes: '',
      addresses: [],
      contacts: [],
      bankAccounts: [],
      confirmedPTU: false,
    },
    user: {
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      position: '',
      avatar: '',
      roles: [],
      isReseller: true,
    },
  });
  const resellerMutation = useDtoMutation('reseller-registration');

  const { t } = useTranslation();
  const config = useAppConfig();
  const [successVisible, setSuccessVisible] = useState(false);

  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  useEffect(() => {
    userInnerForm.setValue('user.roles', []);
    userInnerForm.setValue('user.isReseller', true);
  }, [active]);

  const addresTypes = Object.keys(AddressType).map((addressType) => ({
    id: addressType,
    label: t(`common.addressType.${addressType}`),
  }));

  const countries = useCountry();

  async function emailValidation(email: string) {
    const result = await axiosInstance.get(`users/check-email?email=${email}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictEmail') {
      return { type: result.response?.data.message };
    }
  }

  const controlAddresses = addressesInnerForm.control;
  const controlContacts = contactsInnerForm.control;
  const controlBankAccounts = bankAccountsInnerForm.control;
  const {
    fields: addressFields,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({ name: 'addresses', control: controlAddresses, keyName: 'addressId' });
  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({ name: 'contacts', control: controlContacts, keyName: 'contactId' });
  const {
    fields: bankAccountFields,
    append: appendBankAccount,
    remove: removeBankAccount,
  } = useFieldArray({ name: 'bankAccounts', control: controlBankAccounts, keyName: 'bankId' });

  const newAddressFields = (): PartnerAddressUpdateRequest => {
    return {
      name: '',
      type: '' as AddressType,
      countryId: 'HU',
      zipCode: '',
      city: '',
      address: '',
      notes: '',
      hasForklift: false,
      hasHighLiftPalletJack: false,
      hasPalletJack: false,
      withDelivery: false,
    };
  };

  const newContactFields = (): PartnerContactUpdateRequest => {
    return {
      name: '',
      jobPosition: '',
      email: '',
      phoneNumber: '',
      notes: '',
    };
  };

  const newBankAccountFields = (): PartnerBankAccountUpdateRequest => {
    return {
      isForeignBankAccount: false,
      accountNumber: '',
      accountingBankName: '',
      ibanNumber: '',
      swiftCode: '',
      notes: '',
    };
  };

  const handleSubmit = async (confirmed: ResellerConfirmCreateRequest) => {
    const formData: ResellerCreateRequest = registrationData;

    formData.partner.addresses?.forEach((address) => {
      delete address['partnerId'];
    });
    formData.partner.contacts?.forEach((contact) => {
      delete contact['partnerId'];
    });
    formData.partner.bankAccounts?.forEach((bankAccount) => {
      delete bankAccount['partnerId'];
    });
    formData.partner.confirmedPTU = confirmed.confirmedPTU;

    try {
      await resellerMutation.create(formData);
      setSuccessVisible(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        showNotification({ message: error.response?.data.message ?? error.message, color: 'red' });
      }
    }
  };

  const handlePartnerInnerSubmit = async (formData) => {
    setRegistrationData({ ...registrationData, partner: { ...registrationData.partner, ...formData } });
    nextStep();
  };

  const handleUserInnerSubmit = (formData) => {
    delete formData.emailRepeat;
    setRegistrationData({ ...registrationData, user: { ...formData } });
    nextStep();
  };

  return (
    <Box>
      <BackgroundImage
        src={config ? config?.background : ''}
        sx={{ height: '100vh', opacity: 0.2, filter: 'blur(5px)', position: 'fixed' }}
        radius="xs"
      ></BackgroundImage>

      <Container
        pt={50}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          overflowY: 'scroll',
          height: '100vh',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Center>
          <Image src={config?.logo} width={200} mb={25} />
        </Center>

        <Text size="sm" align="center" mt={5}>
          {t('component.resellerRegistration.registrationMessage')}
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md" w={950} m="auto">
          <Stepper active={active} onStepClick={setActive} allowNextStepsSelect={false}>
            <Stepper.Step
              icon={<IconBriefcase size={18} />}
              label={'1. ' + t('component.resellerRegistration.step')}
              description={t('component.resellerRegistration.company')}
            >
              <GrForm form={companyInnerForm} onSubmit={handlePartnerInnerSubmit}>
                <Grid m={0}>
                  <Grid.Col>
                    <GrTextInput name="name" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="taxIdNumber" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="registrationNumber" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="centralPhoneNumber" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="centralEmail" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="website" link />
                  </Grid.Col>

                  <Grid.Col>
                    <GrTextareaInput name="notes" />
                  </Grid.Col>
                </Grid>

                <Group position="center" mt="xl">
                  <Button loading={companyInnerForm.isSubmitting} type="submit" rightIcon={<IconChevronsRight />}>
                    {t('common.button.next')}
                  </Button>
                </Group>
              </GrForm>
            </Stepper.Step>

            <Stepper.Step
              icon={<IconBuildingCommunity size={18} />}
              label={'2. ' + t('component.resellerRegistration.step')}
              description={t('component.resellerRegistration.addresses')}
            >
              <GrForm form={addressesInnerForm} onSubmit={handlePartnerInnerSubmit}>
                <Text size="sm" align="center" mt={5}>
                  {t('component.resellerRegistration.registrationAddresses')}
                </Text>

                {!addressFields.length && appendAddress(newAddressFields())}

                {addressFields.map((address, index) => {
                  return (
                    <Paper
                      key={address.addressId}
                      withBorder
                      mb="xs"
                      p="md"
                      sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                    >
                      <Group position="right">
                        <ActionIcon
                          onClick={() => addressFields.length > 1 && removeAddress(index)}
                          color="red"
                          size="md"
                          variant="light"
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>

                      <Grid>
                        <Grid.Col xs={7}>
                          <GrTextInput name={`addresses.${index}.name`} />
                        </Grid.Col>

                        <Grid.Col xs={5}>
                          <GrSelectInput data={addresTypes} name={`addresses.${index}.type`} simpleValue />
                        </Grid.Col>

                        <Grid.Col xl={2} lg={12}>
                          <GrSelectInput
                            data={countries}
                            labelField="name"
                            valueField="countryCode"
                            name={`addresses.${index}.countryId`}
                            simpleValue
                          />
                        </Grid.Col>

                        <Grid.Col xl={2} lg={2}>
                          <GrTextInput name={`addresses.${index}.zipCode`} />
                        </Grid.Col>

                        <Grid.Col xl={3} lg={4}>
                          <GrTextInput name={`addresses.${index}.city`} />
                        </Grid.Col>

                        <Grid.Col xl={5} lg={6}>
                          <GrTextInput name={`addresses.${index}.address`} />
                        </Grid.Col>

                        <Grid.Col>
                          <GrTextareaInput name={`addresses.${index}.notes`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrSwitchInput name={`addresses.${index}.hasForklift`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrSwitchInput name={`addresses.${index}.hasHighLiftPalletJack`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrSwitchInput name={`addresses.${index}.hasPalletJack`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrSwitchInput name={`addresses.${index}.withDelivery`} />
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  );
                })}

                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendAddress(newAddressFields())}
                >
                  {t('common.button.addRow')}
                </Button>

                <Group position="center" mt="xl">
                  <Button variant="default" onClick={prevStep} leftIcon={<IconChevronsLeft />}>
                    {t('common.button.back')}
                  </Button>

                  <Button loading={addressesInnerForm.isSubmitting} type="submit" rightIcon={<IconChevronsRight />}>
                    {t('common.button.next')}
                  </Button>
                </Group>
              </GrForm>
            </Stepper.Step>

            <Stepper.Step
              icon={<IconAddressBook size={18} />}
              label={'3. ' + t('component.resellerRegistration.step')}
              description={t('component.resellerRegistration.contacts')}
            >
              <GrForm form={contactsInnerForm} onSubmit={handlePartnerInnerSubmit}>
                <Text size="sm" align="center" mt={5}>
                  {t('component.resellerRegistration.registrationContacts')}
                </Text>

                {contactFields.map((contact, index) => {
                  return (
                    <Paper
                      key={contact.contactId}
                      withBorder
                      mb="xs"
                      p="md"
                      sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                    >
                      <Group position="right">
                        <ActionIcon onClick={() => removeContact(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>

                      <Grid>
                        <Grid.Col xs={6}>
                          <GrTextInput name={`contacts.${index}.name`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`contacts.${index}.jobPosition`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`contacts.${index}.email`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`contacts.${index}.phoneNumber`} />
                        </Grid.Col>

                        <Grid.Col>
                          <GrTextareaInput name={`contacts.${index}.notes`} />
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  );
                })}

                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendContact(newContactFields())}
                >
                  {t('common.button.addRow')}
                </Button>

                <Group position="center" mt="xl">
                  <Button variant="default" onClick={prevStep} leftIcon={<IconChevronsLeft />}>
                    {t('common.button.back')}
                  </Button>

                  <Button loading={contactsInnerForm.isSubmitting} type="submit" rightIcon={<IconChevronsRight />}>
                    {t('common.button.next')}
                  </Button>
                </Group>
              </GrForm>
            </Stepper.Step>

            <Stepper.Step
              icon={<IconBuildingBank size={18} />}
              label={'4. ' + t('component.resellerRegistration.step')}
              description={t('component.resellerRegistration.bankAccounts')}
            >
              <GrForm form={bankAccountsInnerForm} onSubmit={handlePartnerInnerSubmit}>
                <Text size="sm" align="center" mt={5}>
                  {t('component.resellerRegistration.registrationBankAccounts')}
                </Text>

                {bankAccountFields.map((bank, index) => {
                  return (
                    <Paper
                      key={bank.bankId}
                      withBorder
                      mb="xs"
                      p="md"
                      sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                    >
                      <Group position="right">
                        <ActionIcon onClick={() => removeBankAccount(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>

                      <Grid>
                        <Grid.Col xs={12}>
                          <GrSwitchInput name={`bankAccounts.${index}.isForeignBankAccount`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`bankAccounts.${index}.accountNumber`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`bankAccounts.${index}.accountingBankName`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`bankAccounts.${index}.ibanNumber`} />
                        </Grid.Col>

                        <Grid.Col xs={6}>
                          <GrTextInput name={`bankAccounts.${index}.swiftCode`} />
                        </Grid.Col>

                        <Grid.Col>
                          <GrTextareaInput name={`bankAccounts.${index}.notes`} />
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  );
                })}

                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendBankAccount(newBankAccountFields())}
                >
                  {t('common.button.addRow')}
                </Button>

                <Group position="center" mt="xl">
                  <Button variant="default" onClick={prevStep} leftIcon={<IconChevronsLeft />}>
                    {t('common.button.back')}
                  </Button>

                  <Button loading={bankAccountsInnerForm.isSubmitting} type="submit" rightIcon={<IconChevronsRight />}>
                    {t('common.button.next')}
                  </Button>
                </Group>
              </GrForm>
            </Stepper.Step>

            <Stepper.Step
              icon={<IconUser size={18} />}
              label={'5. ' + t('component.resellerRegistration.step')}
              description={t('component.resellerRegistration.adminUser')}
            >
              <GrForm form={userInnerForm} onSubmit={handleUserInnerSubmit}>
                <Grid>
                  <Grid.Col xs={6}>
                    <GrTextInput name="email" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="emailRepeat" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrPasswordInput name="password" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrPasswordInput name="passwordRepeat" />
                  </Grid.Col>
                </Grid>

                <Divider my="xl" />

                <Grid>
                  <Grid.Col xs={6}>
                    <GrTextInput name="lastName" autocomplete="family-name" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrTextInput name="firstName" autocomplete="given-name" />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col xs={6}>
                    <GrTextInput name="position" />
                  </Grid.Col>
                </Grid>

                <Group position="center" mt="xl">
                  <Button variant="default" onClick={prevStep} leftIcon={<IconChevronsLeft />}>
                    {t('common.button.back')}
                  </Button>

                  <Button loading={companyInnerForm.isSubmitting} type="submit" rightIcon={<IconChevronsRight />}>
                    {t('common.button.next')}
                  </Button>
                </Group>
              </GrForm>
            </Stepper.Step>

            <Stepper.Completed>
              {!successVisible && (
                <>
                  <Grid>
                    <Grid.Col xs={6}>
                      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                        <Input.Wrapper label={t(`${companyInnerForm.i18nPrefix}.${'name'}`) + ':'}>
                          <div>{registrationData.partner.name}</div>
                        </Input.Wrapper>
                      </Box>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                        <Input.Wrapper label={t(`${companyInnerForm.i18nPrefix}.${'taxIdNumber'}`) + ':'}>
                          <div>{registrationData.partner.taxIdNumber}</div>
                        </Input.Wrapper>
                      </Box>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                        <Input.Wrapper label={t(`${userInnerForm.i18nPrefix}.${'email'}`) + ':'}>
                          <div>{registrationData.user.email}</div>
                        </Input.Wrapper>
                      </Box>
                    </Grid.Col>
                  </Grid>

                  <GrForm form={completedForm} onSubmit={handleSubmit}>
                    <Box mt={30}>
                      <GrSwitchInput name="confirmedPTU"></GrSwitchInput>
                    </Box>

                    <Group position="center" mt="xl">
                      <Button variant="default" onClick={prevStep} leftIcon={<IconChevronsLeft />}>
                        {t('common.button.back')}
                      </Button>

                      <Button
                        color="green"
                        loading={completedForm.isSubmitting}
                        type="submit"
                        rightIcon={<IconCheck />}
                      >
                        {t('common.button.save')}
                      </Button>
                    </Group>
                  </GrForm>
                </>
              )}

              {successVisible && (
                <Alert color="green" title={t('component.resellerRegistration.successTitle')}>
                  {t('component.resellerRegistration.successMessage')}
                </Alert>
              )}
            </Stepper.Completed>
          </Stepper>
        </Paper>
      </Container>
    </Box>
  );
}
