import { ProductResponse } from './product.response';

export class ProductCategoryResponse {
  /**
   * CUID identifier of the product category
   */
  public id: string;

  /**
   * The name of the product category
   */
  public name: string;

  /**
   * Array of products of the product category
   */
  public products?: ProductResponse[];
}
