import { Group, Pagination, Select, Text } from '@mantine/core';
import { PaginationState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

interface EntityTablePaginationProps<T> {
  pagination: PaginationState;
  onPaginationChange: (pagination: PaginationState) => void;
  totalRowCount: number;
  actualRowCount: number;
}

export function EntityTablePagination<T>({
  pagination,
  onPaginationChange,
  totalRowCount,
  actualRowCount,
}: EntityTablePaginationProps<T>) {
  const { t } = useTranslation();
  const fromRow = actualRowCount ? pagination.pageSize * pagination.pageIndex + 1 : 0;
  const toRow = pagination.pageSize * pagination.pageIndex + actualRowCount;

  return (
    <Group>
      <Text size="xs">{`${fromRow} - ${toRow} ${t('entityTable.pagination.rowCount')} ${totalRowCount}`}</Text>

      <Pagination
        value={pagination.pageIndex + 1}
        onChange={(pageNumber) => onPaginationChange({ pageIndex: pageNumber - 1, pageSize: pagination.pageSize })}
        total={Math.ceil(totalRowCount / pagination.pageSize) ? Math.ceil(totalRowCount / pagination.pageSize) : 1}
        color="gray"
        size="sm"
        withEdges
      />

      <Select
        sx={{ width: 90 }}
        onChange={(pageSize) =>
          onPaginationChange({ pageSize: parseInt(pageSize ?? ''), pageIndex: pagination.pageIndex })
        }
        value={pagination.pageSize.toString()}
        defaultValue="25"
        size="xs"
        data={[
          { label: `10 ${t('entityTable.pagination.row')}`, value: '10' },
          { label: `25 ${t('entityTable.pagination.row')}`, value: '25' },
          { label: `50 ${t('entityTable.pagination.row')}`, value: '50' },
          { label: `100 ${t('entityTable.pagination.row')}`, value: '100' },
        ]}
      />
    </Group>
  );
}
