import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { NewsPostResponse } from '@gravity/shared/dto';
import { Box, Button, ScrollArea } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@prisma/client';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { Link } from 'react-router-dom';

export function NewsPostsPage() {
  const { t } = useTranslation();

  const query = useTableQuery<NewsPostResponse>('news-posts');

  const cols: EntityTableColumnDef<NewsPostResponse>[] = [
    { accessorKey: 'title' },
    { accessorKey: 'date', type: EntityTableColumnType.DateTime },
    { accessorKey: 'visible', type: EntityTableColumnType.Boolean },
  ];

  return (
    <Box p="md">
      <PageTitle title={t('coreData.newsPosts.title')} subtitle={t('coreData.newsPosts.subtitle')}>
        <PermissionCheck hasAny={[Permission.news_post_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <PermissionCheck hasAll={[Permission.news_post_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="newsPost"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Box>
  );
}
