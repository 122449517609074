import { ResellerOrdersPage } from './pages/ResellerOrdersPage';
import { PartnerProfileFormPage } from './pages/PartnerProfileFormPage';
import { ResellerUsersPage } from './pages/ResellerUsersPage';
import { redirect, RouteObject } from 'react-router-dom';
import { BasicLayout } from '../../layouts/BasicLayout';
import { ResellerRegistration } from './pages/ResellerRegistration';
import { ResellerLayout } from './layouts/ResellerLayout';
import { ResellerDashboard } from './pages/ResellerDashboard';
import { ResellerUserFormPage } from './pages/ResellerUserFormPage';
import { queryClient } from '../../plugins/react-query';
import { getMany, getOne } from '../../plugins/axios';
import { ResellerOrdersFormPage } from './pages/ResellerOrdersFormPage';
import { createGuardedLoader, createLoader } from '../../utils';
import { useAuth } from '../../stores/auth.store';
import { NotFound } from '../core/pages/NotFound';

export const resellerRoutes: RouteObject[] = [
  {
    element: <BasicLayout />,
    children: [{ path: 'reseller-registration', element: <ResellerRegistration /> }],
  },
  {
    path: 'reseller',
    element: <ResellerLayout />,
    loader: createLoader(() => {
      // Redirect if the user is not a reseller
      if (
        useAuth.getState().isAuthenticated() &&
        !useAuth.getState().isReseller() &&
        !location.pathname.includes('orders/')
      ) {
        return redirect('/');
      }
    }),
    children: [
      { path: '', element: <ResellerDashboard /> },
      { path: 'partner-profile', element: <PartnerProfileFormPage /> },
      { path: 'orders', element: <ResellerOrdersPage /> },
      {
        path: 'orders/:idOrAction',
        element: <ResellerOrdersFormPage />,
        loader: createGuardedLoader(({ params }) => {
          if (!useAuth.getState().isReseller()) {
            return redirect(`/sales/orders/${params.idOrAction}`);
          }

          if (!params.idOrAction || params.idOrAction === 'create') {
            return null;
          }

          return queryClient.fetchQuery(
            ['reseller-orders', params.idOrAction],
            getOne('reseller-orders', params.idOrAction)
          );
        }),
        errorElement: <NotFound />,
      },

      {
        path: 'users',
        element: <ResellerUsersPage />,
        loader: createGuardedLoader(() => {
          return queryClient.fetchQuery(['reseller/users'], getMany('reseller/users'));
        }),
        errorElement: <NotFound />,
      },
      {
        path: 'users/:idOrAction',
        element: <ResellerUserFormPage />,
        loader: createGuardedLoader(({ params }) => {
          if (!params.idOrAction || params.idOrAction === 'create') {
            return null;
          }

          return queryClient.fetchQuery(
            ['reseller/users', params.idOrAction],
            getOne('reseller/users', params.idOrAction)
          );
        }),
        errorElement: <NotFound />,
      },
    ],
  },
];
