import {
  GrForm,
  GrFormTitle,
  GrTextInput,
  useDtoForm,
  GrRichTextInput,
  GrSwitchInput,
  GrDateInput,
  GrFileInput,
  GrFormTab,
  GrFormTabs,
} from '@gravity/frontend/ui';
import {
  NewsPostResponse,
  NewsPostCreateRequest,
  NewsPostUpdateRequest,
  StorageUploadResponse,
} from '@gravity/shared/dto';
import { Button, Group, ActionIcon, Grid, Paper, ScrollArea, Text } from '@mantine/core';
import { Permission, StorageFile } from '@prisma/client';
import {
  IconCheck,
  IconChevronLeft,
  IconFileUpload,
  IconPencil,
  IconRowInsertTop,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { useFiles } from '../../../hooks/use-files.hook';
import { axiosInstance } from '../../../plugins/axios';
import { useStorageService } from '../../../stores/storage.store';

export function NewsPostFormPage() {
  const { t } = useTranslation();
  const storageService = useStorageService();

  const navigate = useNavigate();
  const entity = useLoaderData() as NewsPostResponse | undefined;

  const newsPostForm = useDtoForm([NewsPostCreateRequest, NewsPostUpdateRequest], 'entity.newsPost', {
    isEditForm: !!entity,
    defaultValues: entity,
  });

  const postMutation = useDtoMutation<NewsPostCreateRequest | NewsPostUpdateRequest>('news-posts', {
    successNotification: true,
    invalidateQueries: [['news-posts']],
  });

  const newAttachmentFields = () => {
    return '';
  };

  const { control, watch } = newsPostForm;
  const {
    fields: attachmentsFields,
    append: appendAttachment,
    remove: removeAttachment,
  } = useFieldArray({ name: 'attachments', control, keyName: 'attachmentId' });

  let uploadedFiles: StorageFile[] = [];
  if (!newsPostForm.isCreateForm) {
    uploadedFiles = useFiles(newsPostForm.getValues('id')) ?? [];
  }

  const filteredFiles = watch('attachments')
    ? uploadedFiles.filter((file) => watch('attachments')?.includes(file.id))
    : uploadedFiles.slice();

  const handleClick = async (fileId) => {
    const res = await axiosInstance.get(`storage/${fileId}`, { responseType: 'blob' });
    const data = res.data;

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers['content-type'],
      })
    );

    const link = document.createElement('a');
    link.href = url;
    const filename = res.headers['content-disposition'].match(/filename="?([^"]+)"?/)[1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  async function handleSubmit(data) {
    if (data.attachments && data.attachments.length > 0) {
      for (let i = 0; i < data.attachments.length; i++) {
        if (typeof data.attachments[`${i}`] !== 'string' && data.attachments[`${i}`] !== '') {
          try {
            const result = (await storageService.uploadNewsPostAttachment(
              data.attachments[`${i}`] as File
            )) as StorageUploadResponse;
            data.attachments[`${i}`] = result.id;
          } catch (e) {
            console.log(e);
          }
        }
      }
    }

    if (newsPostForm.isCreateForm) {
      const newsPost = (await postMutation.create(data)) as NewsPostResponse;
      navigate(`/core-data/news-posts/${newsPost.id}`);
      newsPostForm.toggleReadonlyMode(newsPost);
    } else {
      const newsPost = (await postMutation.update(data)) as NewsPostResponse;
      newsPostForm.toggleReadonlyMode(newsPost);
    }
  }

  return (
    <Page>
      <GrForm form={newsPostForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/core-data/news-posts">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="coreData.newsPostForm" />
          </Group>

          <Group>
            {newsPostForm.readonlyMode ? (
              <PermissionCheck hasAll={[Permission.news_post_read, Permission.news_post_update]}>
                <Button onClick={() => newsPostForm.toggleReadonlyMode()} variant="light" leftIcon={<IconPencil />}>
                  {t('common.button.edit')}
                </Button>
              </PermissionCheck>
            ) : (
              <>
                <PermissionCheck
                  hasAll={
                    newsPostForm.isEditForm
                      ? [Permission.news_post_read, Permission.news_post_update]
                      : [Permission.news_post_create]
                  }
                >
                  <Button loading={newsPostForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                    {t('common.button.save')}
                  </Button>
                </PermissionCheck>

                <Button
                  loading={newsPostForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() => (newsPostForm.isCreateForm ? navigate(-1) : newsPostForm.toggleReadonlyMode())}
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {newsPostForm.isEditForm && newsPostForm.readonlyMode && (
              <PermissionCheck
                hasAll={[Permission.news_post_read, Permission.news_post_update, Permission.news_post_delete]}
              >
                <DeleteConfirmButton
                  loading={newsPostForm.isSubmitting}
                  onDelete={async () => {
                    try {
                      await postMutation.delete(newsPostForm.getValues('id' as never));
                      navigate('/core-data/news-posts');
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </PermissionCheck>
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col xs={6}>
              <GrTextInput name="title" />
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrDateInput name="date" withTime={true} defaultValue={new Date()} />
            </Grid.Col>

            <Grid.Col xs={2}>
              <GrSwitchInput name="visible" />
            </Grid.Col>

            <Grid.Col>
              <GrRichTextInput name="body" />
            </Grid.Col>
          </Grid>

          <GrFormTabs
            tabs={[
              {
                icon: <IconFileUpload />,
                name: 'attachments',
                label: 'entity.newsPost.attachments',
                badge: attachmentsFields.length,
              },
            ]}
          >
            <GrFormTab name="attachments">
              {attachmentsFields.map((attachment, index) => {
                return (
                  <Paper
                    key={attachment.attachmentId}
                    style={{ display: 'inline-block' }}
                    withBorder
                    mr="xs"
                    mb="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    {!newsPostForm.readonlyMode && (
                      <Group position="right">
                        <ActionIcon onClick={() => removeAttachment(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    )}

                    <Grid>
                      <Grid.Col>
                        <GrFileInput
                          name={`attachments.${index}`}
                          label={'entity.newsPost.file'}
                          onClick={handleClick}
                          file={filteredFiles[`${index}`] ?? undefined}
                        />
                      </Grid.Col>
                    </Grid>
                  </Paper>
                );
              })}

              {newsPostForm.readonlyMode && attachmentsFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

              {!newsPostForm.readonlyMode && (
                <Button
                  leftIcon={<IconRowInsertTop />}
                  display="block"
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendAttachment(newAttachmentFields())}
                >
                  {t('common.button.addAttachment')}
                </Button>
              )}
            </GrFormTab>
          </GrFormTabs>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
