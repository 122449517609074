import { MantineThemeOverride, Tuple } from '@mantine/core';
import ShadeGenerator from 'shade-generator';

import 'dayjs/locale/hu';

export const gravityTheme = (color: string): MantineThemeOverride => {
  // Generate color scheme from the the provided base color
  const primaryColorScheme = ShadeGenerator.hue(color).shadesMap('hex');
  const shadeSelectors: Tuple<number, 10> = [10, 20, 30, 40, 60, 80, 100, 200, 300, 400];

  return {
    primaryColor: 'gravityColor',
    colors: {
      gravityColor: shadeSelectors.map((shade) => primaryColorScheme[shade]) as Tuple<string, 10>,
    },
    colorScheme: 'light',
    globalStyles: (theme) => ({
      '.gravity-InputWrapper-label': {
        color: theme.colors.gray[6],
      },
      '.gravity-InputWrapper-error': {
        animationName: 'error-animation',
        animationDuration: '150ms',
        animationTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
      },
    }),
    components: {
      Grid: {
        defaultProps: {
          gutter: 'xs',
        },
      },
      Button: {
        styles: {
          icon: {
            width: 20,
            height: 20,
          },
        },
      },
      Text: {
        defaultProps: {
          size: 'sm',
        },
      },
      Divider: {
        styles: (theme) => ({
          label: {
            color: theme.colors.gray[7],
          },
        }),
        defaultProps: {
          labelPosition: 'center',
        },
      },
      Title: {
        styles: (theme) => ({
          root: {
            paddingBottom: theme.spacing.xl,
          },
        }),
      },
      Badge: {
        styles: () => ({
          root: {
            textTransform: 'none',
          },
        }),
      },
      Tooltip: {
        defaultProps: {
          withArrow: true,
        },
      },
      Switch: {
        styles: {
          track: {
            cursor: 'pointer',
          },
        },
      },
    },
  };
};
