import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { MosquitoNetRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface MosquitoNetProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: MosquitoNetRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function MosquitoNet(props: MosquitoNetProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const mnPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  const productForm = useDtoForm([MosquitoNetRequest, MosquitoNetRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), mn_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;
  useEffect(() => {
    console.log('render');
  }, [watch()]);
  const hasRal = productForm.getValues('mn_color') === 'RAL';

  //Calculated values for pricing
  useEffect(() => {
    const width = productForm.getValues('mn_width') ?? 0;
    const height = productForm.getValues('mn_height') ?? 0;

    productForm.setValue('mn_area', (width * height) / 10000);
    productForm.setValue('mn_roundedArea', getRoundedArea(productForm.getValues('mn_area')));

    console.log('Terület', watch('mn_area'));
    console.log('Kerekített terület', watch('mn_roundedArea'));
  }, [watch('mn_width'), watch('mn_height')]);

  useEffect(() => {
    getColorPrice();
  }, [watch('mn_color'), watch('mn_type')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('mn_roundedArea'), watch('priceList'), watch('mn_color'), watch('mn_petNet')]);

  useEffect(() => {
    if (watch('mn_type') !== 'Alu Isso fix' && watch('mn_type') !== 'PVC Isso fix') {
      productForm.setValue('mn_reversibleStrap', undefined);
      productForm.setValue('mn_withoutAdditionalStraps', undefined);
    }

    if (
      watch('mn_type') === 'Alu tokos Mobil' ||
      watch('mn_type') === 'PVC Isso fix' ||
      watch('mn_type') === 'PVC 18x32 fix'
    ) {
      productForm.setValue('mn_petNet', undefined);
    }
  }, [watch('mn_type')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    const colorPrice = (productForm.getValues('priceList') as any)['type_color'] ?? 0;
    areaPrice += colorPrice;

    const petNetPrice =
      mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_077')?.price ?? 0;

    areaPrice +=
      watch('mn_petNet') &&
      watch('mn_type') !== 'Alu tokos Mobil' &&
      watch('mn_type') !== 'PVC Isso fix' &&
      watch('mn_type') !== 'PVC 18x32 fix'
        ? petNetPrice
        : 0;

    const area = watch('mn_roundedArea');
    areaPrice = areaPrice * area;
    productForm.setValue('price', areaPrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1) {
      return 1;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getColorPrice() {
    let price = 0;
    switch (watch('mn_type')) {
      case 'Alu tokos Mobil':
        switch (watch('mn_color')) {
          case 'Fehér':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_001')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_002')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_004')?.price ?? 0;
            break;
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_003')?.price ?? 0;
            break;
        }
        break;
      case 'PVC Isso fix':
        switch (watch('mn_color')) {
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_009')?.price ?? 0;
            break;
        }
        break;
      case 'Alu Isso fix':
        switch (watch('mn_color')) {
          case 'Fehér':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_010')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_011')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_013')?.price ?? 0;
            break;
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_012')?.price ?? 0;
            break;
        }
        break;
      case 'PVC 18x32 fix':
        switch (watch('mn_color')) {
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_014')?.price ?? 0;
            break;
        }
        break;
      case 'Alu 17x7 fix':
        switch (watch('mn_color')) {
          case 'Fehér':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_015')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_016')?.price ?? 0;
            break;
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_015')?.price ?? 0;
            break;
        }
        break;
      case 'Alu 26x11 fix':
        switch (watch('mn_color')) {
          case 'Fehér':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_017')?.price ?? 0;
            break;
          case 'Barna':
          case 'Antracit':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_018')?.price ?? 0;
            break;
          case 'RAL':
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_020')?.price ?? 0;
            break;
          default:
            price =
              mnPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'szh_019')?.price ?? 0;
            break;
        }
        break;

      default:
        price = 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ type_color: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: MosquitoNetRequest) => {
        if (!hasRal) {
          await delete data.mn_ralCode;
        }
        data.summary =
          'Szélesség: ' + data.mn_width.toLocaleString() + ' cm, Magasság: ' + data.mn_height.toLocaleString() + ' cm';
        data.summary += ', Típus: ' + data.mn_type;
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="mn_width" label={getLabelFor('mn_width')} min={0} precision={2} controls />

            {getErrorMessageFor('mn_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('mn_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="mn_height" label={getLabelFor('mn_height')} min={0} precision={2} controls />

            {getErrorMessageFor('mn_height') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('mn_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="mn_type"
              label={getLabelFor('mn_type')}
              data={getConditionSublistsFor('mn_type')}
              description={getConditionDescriptionFor('mn_type')}
              disabled={getDisabledStateFor('mn_type')}
              onEmptyState={() => warningNotification(getLabelFor('mn_type'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="mn_color"
              label={getLabelFor('mn_color')}
              data={getConditionSublistsFor('mn_color')}
              description={getConditionDescriptionFor('mn_color')}
              disabled={getDisabledStateFor('mn_color')}
              onEmptyState={() => warningNotification(getLabelFor('mn_color'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          {(watch('mn_type') === 'Alu Isso fix' || watch('mn_type') === 'PVC Isso fix') && (
            <Grid.Col xs={4}>
              <GrSelectInput
                name="mn_reversibleStrap"
                label={getLabelFor('mn_reversibleStrap')}
                data={getConditionSublistsFor('mn_reversibleStrap')}
                description={getConditionDescriptionFor('mn_reversibleStrap')}
                disabled={getDisabledStateFor('mn_reversibleStrap')}
                onEmptyState={() => warningNotification(getLabelFor('mn_reversibleStrap'))}
                emptyOnStateChange
                simpleValue
              />
            </Grid.Col>
          )}

          {watch('mn_type') !== 'Alu tokos Mobil' &&
            watch('mn_type') !== 'PVC Isso fix' &&
            watch('mn_type') !== 'PVC 18x32 fix' && (
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="mn_petNet"
                  label={getLabelFor('mn_petNet')}
                  description={getDescriptionFor('mn_petNet')}
                />
              </Grid.Col>
            )}
        </Grid>

        <Grid m={10}>
          {hasRal && (
            <Grid.Col xs={2}>
              <GrTextInput name="mn_ralCode" label={getLabelFor('mn_ralCode')} mask="9999" />

              {!orderReadonlyMode && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {'(4 számjegy)'}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="mn_quantity" label={getLabelFor('mn_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        {(watch('mn_type') === 'Alu Isso fix' || watch('mn_type') === 'PVC Isso fix') && (
          <Grid m={10}>
            <Grid.Col xs={3}>
              <GrSwitchInput name="mn_withoutAdditionalStraps" label={getLabelFor('mn_withoutAdditionalStraps')} />
            </Grid.Col>
          </Grid>
        )}

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
