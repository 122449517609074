import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrTextInput,
  GrTextareaInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { LindabLedgeRequest, PriceListItemResponse, PriceListResponse } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface LindabLedgeProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: LindabLedgeRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function LindabLedge(props: LindabLedgeProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  const llPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const productForm = useDtoForm([LindabLedgeRequest, LindabLedgeRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), ll_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  const hasRal = productForm.getValues('ll_color') === 'RAL';

  useEffect(() => {
    setEndCloser();
  }, []);

  //Calculated values for pricing
  useEffect(() => {
    productForm.setValue(
      'll_roundedWidth',
      Number((Math.ceil(productForm.getValues('ll_width') / 10) / 10).toFixed(1))
    );

    console.log('Kerekített szélesség', watch('ll_roundedWidth'));
  }, [watch('ll_width')]);

  useEffect(() => {
    getColorPrice();
  }, [watch('ll_color'), watch('ll_depth')]);

  useEffect(() => {
    setEndCloser();
  }, [watch('ll_endClosingColor'), watch('ll_depth')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('ll_width'), watch('ll_depth'), watch('ll_endClosingColor')]);

  function calculatePrice() {
    let fmPrice = 0;
    let piecePrice = 0; // Prices by piece for the entire folding door

    const width = watch('ll_roundedWidth');
    fmPrice += (productForm.getValues('priceList') as any)['ll_color'];
    piecePrice +=
      (productForm.getValues('priceList') as any)['ll_endCloser21'] +
        (productForm.getValues('priceList') as any)['ll_endCloser30'] +
        (productForm.getValues('priceList') as any)['ll_endCloser35'] +
        (productForm.getValues('priceList') as any)['ll_endCloser40'] ?? 0;

    productForm.setValue('price', fmPrice * width + piecePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function setEndCloser() {
    let ll_endCloser21 = 0;
    let ll_endCloser30 = 0;
    let ll_endCloser35 = 0;
    let ll_endCloser40 = 0;
    const endClosers = getConditionSublistsFor('ll_endClosingType');
    const price =
      llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.label.includes(endClosers[0]))?.price ??
      0;
    const price2 =
      endClosers.length === 2
        ? llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.label.includes(endClosers[1]))
            ?.price ?? 0
        : 0;

    if (endClosers[0].includes('21')) {
      productForm.setValue('ll_endCloser21Quantity', 1);
      productForm.setValue('ll_endCloser21', endClosers[0]);
      ll_endCloser21 = price;
    } else {
      productForm.setValue('ll_endCloser21Quantity', 0);
      productForm.setValue('ll_endCloser21', '');
    }

    if (endClosers[0].includes('30')) {
      productForm.setValue('ll_endCloser30Quantity', 1);
      productForm.setValue('ll_endCloser30', endClosers[0]);
      ll_endCloser30 = price;
    } else {
      productForm.setValue('ll_endCloser30Quantity', 0);
      productForm.setValue('ll_endCloser30', '');
    }

    if (endClosers[0].includes('35')) {
      const quantity = Math.ceil(watch('ll_depth') / 35);
      productForm.setValue('ll_endCloser35Quantity', quantity);
      productForm.setValue('ll_endCloser35', endClosers[0]);
      ll_endCloser35 = price * quantity;
    } else {
      productForm.setValue('ll_endCloser35Quantity', 0);
      productForm.setValue('ll_endCloser35', '');
    }

    if (endClosers[0].includes('40') || (endClosers.length === 2 && endClosers[1].includes('40'))) {
      productForm.setValue('ll_endCloser40Quantity', 1);
      productForm.setValue('ll_endCloser40', endClosers.length === 2 ? endClosers[1] : endClosers[0]);
      ll_endCloser40 = price2 ? price2 : price;
    } else {
      productForm.setValue('ll_endCloser40Quantity', 0);
      productForm.setValue('ll_endCloser40', '');
    }
    productForm.setValue('ll_endClosingType', endClosers);

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ll_endCloser21, ll_endCloser30, ll_endCloser35, ll_endCloser40 },
    });
  }

  function getColorPrice() {
    let price = 0;
    if (getConditionSublistsFor('ll_roundedDepth').length > 1) {
      productForm.setValue('ll_roundedDepth', '');
    } else {
      productForm.setValue('ll_roundedDepth', getConditionSublistsFor('ll_roundedDepth')[0]);
    }
    switch (watch('ll_roundedDepth')) {
      case '5 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_002_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_003_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_004_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_001_fm')?.price ??
              0;
            break;
        }
        break;
      case '7 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_006_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_007_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_008_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_005_fm')?.price ??
              0;
            break;
        }
        break;
      case '9 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_010_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_011_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_012_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_009_fm')?.price ??
              0;
            break;
        }
        break;
      case '11 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_014_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_015_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_016_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_013_fm')?.price ??
              0;
            break;
        }
        break;
      case '13 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_018_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_019_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_020_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_017_fm')?.price ??
              0;
            break;
        }
        break;
      case '15 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_022_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_023_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_024_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_021_fm')?.price ??
              0;
            break;
        }
        break;
      case '17 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_026_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_027_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_028_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_025_fm')?.price ??
              0;
            break;
        }
        break;
      case '18 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_030_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_031_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_032_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_029_fm')?.price ??
              0;
            break;
        }
        break;
      case '20 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_034_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_035_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_036_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_033_fm')?.price ??
              0;
            break;
        }
        break;
      case '22 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_038_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_039_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_040_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_037_fm')?.price ??
              0;
            break;
        }
        break;
      case '23 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_042_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_043_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_044_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_041_fm')?.price ??
              0;
            break;
        }
        break;
      case '25 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_046_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_047_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_048_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_045_fm')?.price ??
              0;
            break;
        }
        break;
      case '28 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_050_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_051_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_052_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_049_fm')?.price ??
              0;
            break;
        }
        break;
      case '30 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_054_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_055_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_056_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_053_fm')?.price ??
              0;
            break;
        }
        break;
      case '33 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_058_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_059_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_060_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_057_fm')?.price ??
              0;
            break;
        }
        break;
      case '35 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_062_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_063_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_064_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_061_fm')?.price ??
              0;
            break;
        }
        break;
      case '38 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_066_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_067_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_068_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_065_fm')?.price ??
              0;
            break;
        }
        break;
      case '41 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_070_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_071_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_072_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_069_fm')?.price ??
              0;
            break;
        }
        break;
      case '44 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_074_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_075_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_076_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_073_fm')?.price ??
              0;
            break;
        }
        break;
      case '47 cm':
        switch (watch('ll_color')) {
          case 'Barna':
          case 'Antracit':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_078_fm')?.price ??
              0;
            break;
          case 'RAL':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_079_fm')?.price ??
              0;
            break;
          case 'Aranytölgy':
          case 'Dió':
          case 'Mahagóni':
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_080_fm')?.price ??
              0;
            break;
          default:
            price =
              llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_077_fm')?.price ??
              0;
            break;
        }
        break;
      default:
        price =
          llPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'llp_001_fm')?.price ?? 0;
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ['ll_color']: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: LindabLedgeRequest) => {
        if (!hasRal) {
          await delete data.ll_ralCode;
        }
        data.summary =
          'Szélesség: ' + data.ll_width.toLocaleString() + ' cm, Mélység: ' + data.ll_depth.toLocaleString() + ' cm';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="ll_width" label={getLabelFor('ll_width')} min={0} max={300} precision={2} controls />

            {getErrorMessageFor('ll_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('ll_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="ll_depth" label={getLabelFor('ll_depth')} min={0} max={47} precision={2} controls />

            {getErrorMessageFor('ll_depth') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('ll_depth')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="ll_color"
              label={getLabelFor('ll_color')}
              data={getConditionSublistsFor('ll_color')}
              description={getConditionDescriptionFor('ll_color')}
              disabled={getDisabledStateFor('ll_color')}
              onEmptyState={() => warningNotification(getLabelFor('ll_color'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          {hasRal && (
            <Grid.Col xs={2}>
              <GrTextInput name="ll_ralCode" label={getLabelFor('ll_ralCode')} mask="9999" />

              {!orderReadonlyMode && (
                <Grid style={{ color: 'dodgerblue' }}>
                  <Grid.Col xs={1}>
                    <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                  </Grid.Col>

                  <Grid.Col xs={11}>
                    <Text fz="xs" ta="center">
                      {'(4 számjegy)'}
                    </Text>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
          )}

          <Grid.Col xs={4}>
            <GrSelectInput
              name="ll_endClosingColor"
              label={getLabelFor('ll_endClosingColor')}
              data={getConditionSublistsFor('ll_endClosingColor')}
              description={getConditionDescriptionFor('ll_endClosingColor')}
              disabled={getDisabledStateFor('ll_endClosingColor')}
              onEmptyState={() => warningNotification(getLabelFor('ll_endClosingColor'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="ll_quantity" label={getLabelFor('ll_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{`${t('entity.form.ll.endCloser')} 21 cm`}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('ll_endCloser21')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ll_endCloser21Quantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{`${t('entity.form.ll.endCloser')} 30 cm`}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('ll_endCloser30')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ll_endCloser30Quantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{`${t('entity.form.ll.endCloser')} 35 cm`}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('ll_endCloser35')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ll_endCloser35Quantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={3}>
            <Text>{`${t('entity.form.ll.endCloser')} 40 cm`}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('ll_endCloser40')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('ll_endCloser40Quantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
