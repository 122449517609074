import { IsEmail, IsNotEmpty } from 'class-validator';

export class RequestPasswordResetRequest {
  /**
   * E-mail address of the user
   */
  @IsEmail()
  @IsNotEmpty()
  email: string;
}
