import { IsNotEmpty, IsString } from 'class-validator';

export class BomComponentCreateRequest {
  /**
   * The product of the bom component
   */
  @IsString()
  @IsNotEmpty()
  public productId: string;
}

export class BomComponentUpdateRequest {
  /**
   * The product of the bom component
   */
  @IsString()
  @IsNotEmpty()
  public productId: string;
}
