import { MultiSelect, SelectItem } from '@mantine/core';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ET_FILTER_ARRAY_SEPARATOR } from '../../../constants';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  selectData: string[] | SelectItem[];
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
  onPopoverLock: (lockState: boolean) => void;
}

export function EntityTableEnumFilter<T>({
  selectedColumn,
  selectData,
  filterFormValues,
  onChange,
  onPopoverLock,
}: FilterProps<T>) {
  const { t } = useTranslation();

  // Saved ref for the focusFixer input. This input is required to solve Enter form sending if the focused element is the MultiSelect.
  // It's a hidden input to capture focus after the blur event of the MultiSelect
  const ref = useRef<HTMLInputElement>(null);

  return (
    <MultiSelect
      label={`${selectedColumn.header}:`}
      data={selectData}
      value={filterFormValues.value?.split(ET_FILTER_ARRAY_SEPARATOR)}
      onChange={(selectedValues) => {
        onChange({
          value: selectedValues.join(ET_FILTER_ARRAY_SEPARATOR),
          operation: 'in',
        });
      }}
      onBlur={() => {
        ref.current?.focus();
      }}
      placeholder={t('entityTable.filter.filterSelectInputPlaceholder')}
      onDropdownOpen={() => onPopoverLock(true)}
      onDropdownClose={() => onPopoverLock(false)}
      autoFocus
    />
  );
}
