import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { deleteOne, patchOne, postOne } from '../plugins/axios';

interface ErrorStructure {
  statusCode: number;
  message: string;
  details: string;
  refFields: string[];
  meta: {
    field: string;
  };
}

export function useDtoMutation<T>(
  resource: string,
  options?: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<ErrorStructure>) => void;
    closeDrawer?: boolean;
    successNotification?: boolean | string;
    invalidateQueries: string[][];
    mutationFunctions?: {
      post?: MutationFunction<T, T>;
      patch?: MutationFunction<T, T>;
      delete?: MutationFunction<T, T>;
    };
  }
) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();

  function getMutationOptions(generationOptions: { successMessage: string }) {
    return {
      onSuccess: () => {
        if (options?.onSuccess) {
          options.onSuccess();
        }

        if (options?.closeDrawer ?? false) {
          closeDrawerView();
        }

        if (options?.successNotification ?? true) {
          showNotification({
            color: 'green',
            message: t(
              typeof options?.successNotification === 'string'
                ? options.successNotification
                : generationOptions.successMessage
            ),
          });
        }

        if (options?.invalidateQueries) {
          options.invalidateQueries.forEach((queryKey) => {
            void queryClient.invalidateQueries(queryKey);
          });
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }

        showNotification({
          color: 'red',
          message: t('common.' + error.response?.data.message ?? error.message, error.response?.data.meta).toString(),
        });
      },
    };
  }

  const postMutation = useMutation<T, AxiosError<ErrorStructure>, T>(
    postOne(resource),
    getMutationOptions({ successMessage: 'common.notification.saveSuccess' })
  );
  const patchMutation = useMutation<T, AxiosError<ErrorStructure>, T>(
    patchOne(resource),
    getMutationOptions({ successMessage: 'common.notification.updateSuccess' })
  );
  const deleteMutation = useMutation<T, AxiosError<ErrorStructure>, T>(
    deleteOne(resource),
    getMutationOptions({ successMessage: 'common.notification.deleteSuccess' })
  );

  return {
    create: <T>(data: T) => postMutation.mutateAsync(data as any),
    update: <T>(data: T) => patchMutation.mutateAsync(data as any),
    delete: <T>(data: T) => deleteMutation.mutateAsync(data as any),
  };
}
