import { Type } from 'class-transformer';
import { IsNotEmpty, IsObject, IsString, ValidateNested } from 'class-validator';
import { BomComponentCreateRequest, BomComponentUpdateRequest } from './bom-component.request';
import { ProductResponse } from './product.response';

export class BillOfMaterialsCreateRequest {
  /**
   * The name of the bill of materials
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The related product to the bill of materials
   */
  @IsObject()
  @IsNotEmpty()
  public product: ProductResponse;

  /**
   * The components of the bill of materials
   */
  @ValidateNested({ each: true, context: { type: BomComponentCreateRequest } })
  @Type(() => BomComponentCreateRequest)
  public components?: BomComponentCreateRequest[];

  /**
   * The internal notes of the bill of materials
   */
  @IsString()
  public internalNotes?: string;
}

export class BillOfMaterialsUpdateRequest {
  /**
   * CUID identifier of the bill of materials
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the bill of materials
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The related product to the bill of materials
   */
  @IsObject()
  @IsNotEmpty()
  public product: ProductResponse;

  /**
   * The components of the bill of materials
   */
  @ValidateNested({ each: true, context: { type: BomComponentUpdateRequest } })
  @Type(() => BomComponentUpdateRequest)
  public components?: BomComponentUpdateRequest[];

  /**
   * The internal notes of the bill of materials
   */
  @IsString()
  public internalNotes?: string;
}
