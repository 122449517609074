import { Box, Button, Center, Checkbox, Grid, Group, LoadingOverlay, Stack, Table, Text } from '@mantine/core';
import { useDebouncedState, useHover, useShallowEffect } from '@mantine/hooks';
import {
  IconArrowsSort,
  IconEraser,
  IconFileInvoice,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import {
  ColumnOrderState,
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  TableState,
  useReactTable,
} from '@tanstack/react-table';
import { cloneElement, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTableFilterBuilder } from './components/EntityTableFilterBuilder/EntityTableFilterBuilder';
import { EntityTablePagination } from './components/EntityTablePagination';
import { EntityTableRowActions } from './components/EntityTableRowActions';
import { EntityTableColumn, EntityTableColumnDef } from './interfaces/entity-table-column.interface';
import { EntityTableSettings } from './interfaces/entity-table-settings.interface';
import { useNavigate, useLocation } from 'react-router';
import { stringify, parse } from 'qs';
import { EntityTableColumnType } from './enums/entity-table-column-type.enum';
import { EntityTableStringRenderer } from './components/renderers/EntityTableStringRenderer';
import { useQuery } from '@tanstack/react-query';
import { EntityTableFilter } from './interfaces/entity-table-filter.interface';
import { recursiveGet } from '@gravity/shared/utils';
import { ET_FILTER_ARRAY_SEPARATOR } from './constants';
import _ from 'lodash';
import { EntityTableBooleanRenderer } from './components/renderers/EntityTableBooleanRenderer';
import { EntityTableDateTimeRenderer } from './components/renderers/EntityTableDateTimeRenderer';
import { EntityTableListRenderer } from './components/renderers/EntityTableListRenderer';
import { EntityTableIdRenderer } from './components/renderers/EntityTableIdRenderer';
import { EntityTableJsonRenderer } from './components/renderers/EntityTableJsonRenderer';
import { EntityTableJsonDiffRenderer } from './components/renderers/EntityTableJsonDiffRenderer';
import i18n from 'i18next';
import hu from './i18n/hu.json';
import { EntityTableAvatarRenderer } from './components/renderers/EntityTableAvatarRenderer';
import { EntityTableMoneyFormatRenderer } from './components/renderers/EntityTableMoneyFormatRenderer';

// Register translations
export default i18n.addResourceBundle('hu', 'entityTable', hu.entityTable);

interface ExtendedTableState extends TableState {
  filters: Record<string, EntityTableFilter>;
}

interface EntityTableProps<T extends RowData> {
  columns: EntityTableColumnDef<T>[];
  tableQuery: ReturnType<typeof useQuery<any>>;
  resourceName: string;
  initialState?: Partial<ExtendedTableState>;
  onRowClick?: (entity: T) => void;
  actionsVisible?: (row: Row<T>) => boolean;
  hideActionColumn?: boolean;
  selectColumn?: boolean;
  onSettingsChange?: (settings: EntityTableSettings) => void;
  hideTableFilter?: boolean;
  onItemsSelect?: (ids: string[]) => void;
  selectButtonName?: string;
}

const defaultTableState = {
  pageSize: 25,
  page: 1,
};

export function EntityTable<T extends RowData>(props: EntityTableProps<T>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [settings, setSettings] = useDebouncedState<EntityTableSettings>({}, 100);
  const [fullyLoaded, setFullyLoaded] = useState(false);

  function buildServerQuery() {
    return Object.keys(settings.filter ?? {}).reduce((acc, filterColumnName) => {
      const filterColumn: EntityTableColumn<T> = columns.find((c) => c.id === filterColumnName)!;
      const filterValue = (settings.filter as Record<string, EntityTableFilter>)[filterColumnName];
      const pathFragments = filterColumnName.includes('()')
        ? filterColumnName.split('().')
        : filterColumnName.split('.');

      switch (filterColumn.type) {
        case EntityTableColumnType.Date:
        case EntityTableColumnType.DateTime:
          acc[filterColumnName] = {
            [filterValue.operation]: filterValue.secondaryValue
              ? {
                  value: filterValue.value,
                  secondaryValue: filterValue.secondaryValue,
                }
              : filterValue.value,
          };
          break;
        case EntityTableColumnType.Price:
          acc[filterColumnName] = {
            [filterValue.operation]: filterValue.secondaryValue
              ? {
                  value: filterValue.value,
                  secondaryValue: filterValue.secondaryValue,
                }
              : filterValue.value,
          };
          break;
        case EntityTableColumnType.EnumArray:
        case EntityTableColumnType.SimpleArray:
          acc[filterColumnName] = {
            [filterValue.operation]: filterValue.value.trim().split(ET_FILTER_ARRAY_SEPARATOR),
          };
          break;
        case EntityTableColumnType.ObjectArray:
          acc[pathFragments[0]] = {
            [filterValue.operation === 'hasSome' ? 'OR' : 'AND']: [
              ...filterValue.value.split(ET_FILTER_ARRAY_SEPARATOR).map((value) => ({
                [pathFragments[0]]: {
                  some: _.set({}, pathFragments[1], {
                    contains: value,
                  }),
                },
              })),
            ],
          };
          break;
        case EntityTableColumnType.Enum:
          if (filterColumnName.includes('.') && !filterColumnName.includes('()')) {
            acc[pathFragments[0]] = {
              [pathFragments[0]]: {
                [pathFragments[1]]: {
                  in: [...filterValue.value.split(ET_FILTER_ARRAY_SEPARATOR).map((value) => value)],
                },
              },
            };
          } else {
            acc[pathFragments[0]] = {
              [filterColumnName]: {
                in: [...filterValue.value.split(ET_FILTER_ARRAY_SEPARATOR).map((value) => value)],
              },
            };
          }

          break;
        case EntityTableColumnType.Object:
          acc[pathFragments[0]] = {
            [pathFragments[0]]: {
              [pathFragments[1]]: {
                in: [...filterValue.value.split(ET_FILTER_ARRAY_SEPARATOR).map((value) => value)],
              },
            },
          };
          break;

        default:
          acc[filterColumnName] = {
            [filterValue.operation]: filterValue.value,
          };
      }
      return acc;
    }, {} as any);
  }

  // Notify outer component of table state change
  useShallowEffect(() => {
    // Skip notify of empty initial state
    if (Object.keys(settings).length === 0) {
      return;
    }

    if (props.onSettingsChange) {
      const parsedSettings = {
        ...settings,
        filter: buildServerQuery(),
      };

      props.onSettingsChange(parsedSettings);
      setFullyLoaded(true);
    }
  }, [settings]);

  function calculateInitialStableState() {
    const querySettings: EntityTableSettings<any> = parse(location.search.replace('?', ''), {
      depth: 50,
    });

    const calculatedState: {
      sorting: SortingState;
      pagination: PaginationState;
      filters: any;
      columnPinning: ColumnPinningState;
      rowSelection: RowSelectionState;
    } = _.merge(props.initialState, {
      sorting: [],
      pagination: {
        pageIndex: defaultTableState.page - 1,
        pageSize: defaultTableState.pageSize,
      },
      filters: {},
      columnPinning: {
        left: ['select', 'actions'],
      },
      rowSelection: false,
    });

    if (querySettings.orderBy) {
      const sortingKey = Object.keys(querySettings.orderBy)[0];
      calculatedState['sorting'] = [{ id: sortingKey, desc: querySettings.orderBy[sortingKey] === 'desc' }];
    }

    calculatedState['pagination'] = {
      pageIndex: querySettings.page ? querySettings.page - 1 : 0,
      pageSize: querySettings.pageSize ?? 25,
    };

    if (querySettings.filter) {
      calculatedState['filters'] = querySettings.filter;
    }

    return calculatedState;
  }

  // Add table functionalities
  const initialState = useMemo(() => calculateInitialStableState(), []);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(initialState.columnPinning);
  const [filters, setFilters] = useState(initialState.filters);
  const [sorting, setSorting] = useState<SortingState>(initialState.sorting);
  const [pagination, setPagination] = useState<PaginationState>(initialState.pagination);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(initialState.rowSelection);
  const [ids, setIds] = useState<string[]>([]);

  // Generate columns array and extend it with the actions column
  const columns: EntityTableColumn<T>[] = useMemo(
    () => [
      ...props.columns.map((column) => {
        (column as EntityTableColumn<T>).id = column.accessorKey.replaceAll('[]', '()');

        // Translate column header
        if (!column.header) {
          const columnName = column.accessorKey.includes('[]')
            ? column.accessorKey.split('[]')[0]
            : column.accessorKey.replaceAll('[]', '');
          column.header = t(column.i18n ?? `entity.${props.resourceName}.${columnName}`);
        }

        if (column.enableFiltering === false) {
          column.enableColumnFilter = false;
          column.enableGlobalFilter = false;
        }

        if (!column.type) {
          column.type = EntityTableColumnType.String;
        }

        if (!column.renderer) {
          switch (column.type) {
            case EntityTableColumnType.Boolean:
              column.renderer = <EntityTableBooleanRenderer />;
              break;
            case EntityTableColumnType.Date:
            case EntityTableColumnType.DateTime:
              column.renderer = <EntityTableDateTimeRenderer type={column.type} />;
              break;
            case EntityTableColumnType.ObjectArray:
            case EntityTableColumnType.EnumArray:
            case EntityTableColumnType.SimpleArray:
              column.renderer = <EntityTableListRenderer />;
              break;
            case EntityTableColumnType.Id:
              column.renderer = <EntityTableIdRenderer />;
              break;
            case EntityTableColumnType.JSON:
              column.renderer = <EntityTableJsonRenderer minWidth={column.minSize} />;
              break;
            case EntityTableColumnType.JSONDiff:
              column.renderer = <EntityTableJsonDiffRenderer minWidth={column.minSize} />;
              break;
            case EntityTableColumnType.Avatar:
              column.renderer = <EntityTableAvatarRenderer />;
              column.size = 50;
              break;
            case EntityTableColumnType.Price:
              column.renderer = <EntityTableMoneyFormatRenderer />;
              break;

            default:
              <EntityTableStringRenderer />;
              break;
          }
        }

        column.accessorFn = (row: Row<T>) => {
          return recursiveGet(row, column.accessorKey);
        };

        // Use custom renderer
        if (column.renderer) {
          column.cell = (cell) =>
            cloneElement(column.renderer as ReactElement, {
              value: cell.getValue(),
            });
        }

        return column as EntityTableColumn<T>;
      }),
    ],
    [props.columns]
  );

  // If we have a rowClick handler we add an actions column to the table
  if (!props.hideActionColumn && !columns.find((column) => column.id === 'actions')) {
    columns.push({
      id: 'actions',
      header: '',
      size: 28,
      enableSorting: false,
      cell: (innerProps) => (
        <EntityTableRowActions
          actionsVisible={props.actionsVisible}
          onRowClick={props.onRowClick}
          row={innerProps.row}
        />
      ),
    } as EntityTableColumn<T>);
  }

  if (
    props.tableQuery.data?.data.length !== 0 &&
    props.selectColumn &&
    !columns.find((column) => column.id === 'select')
  ) {
    columns.push({
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      size: 44,
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => (
        <div className="px-1">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    } as EntityTableColumn<T>);
  }

  // Create table instance
  const table = useReactTable({
    columns,
    data: (props.tableQuery.data?.data as T[]) ?? [],
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      sorting,
      pagination,
      rowSelection,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    enableMultiRowSelection: props.selectColumn,
    onRowSelectionChange: setRowSelection,
  });

  // Settings change handler
  useShallowEffect(() => {
    const settingsToPromote: EntityTableSettings = {};

    if (sorting[0]) {
      settingsToPromote['orderBy'] = { [sorting[0].id.replace('()', '')]: sorting[0].desc ? 'desc' : 'asc' };
    }

    if (filters) {
      settingsToPromote['filter'] = filters;
    }

    settingsToPromote['page'] = pagination.pageIndex + 1;
    settingsToPromote['pageSize'] = pagination.pageSize;

    // If filters are changed we need to jump to the first page
    if (fullyLoaded && !_.isEqual(settingsToPromote.filter ?? {}, settings.filter ?? {})) {
      setPagination({
        pageIndex: 0,
        pageSize: pagination.pageSize,
      });
    }

    setSettings(settingsToPromote);
    navigate({
      search: stringify(
        _.fromPairs(_.differenceWith(_.toPairs(settingsToPromote), _.toPairs(defaultTableState), _.isEqual)),
        {
          encode: false,
          addQueryPrefix: true,
        }
      ),
    });
  }, [sorting, pagination, filters]);

  // Custom sorting function
  function toggleSorting(column: EntityTableColumn<T>): void {
    if (sorting.length > 0 && sorting[0].id === column.id && sorting[0].desc === true) {
      setSorting([]);
      return;
    }

    setSorting([
      {
        id: column.id,
        desc: !(sorting.length === 0 || sorting[0].id !== column.id),
      },
    ]);
  }

  // Hover handler for the sorting icons. Checks if the table headers are hovered.
  const { hovered: headerHovered, ref: headerRef } = useHover<HTMLTableRowElement>();

  // Clear table settings
  function clearSettings(): void {
    setColumnOrder([]);
    setColumnPinning(initialState.columnPinning);
    setFilters(initialState.filters);
    setSorting(initialState.sorting);
    setPagination(initialState.pagination);
  }

  useEffect(() => {
    setIds(table.getSelectedRowModel().rows.map((row) => `${(row.original as any).id}`));
  }, [table, rowSelection]);

  return (
    <Stack justify="space-between" align="flex-start" spacing={0} sx={{ height: '100%' }}>
      <Grid align='baseline' sx={{ width: '100%' }} m={0}>
        <Grid.Col xs={9}>
          {!props.hideTableFilter && (
            <EntityTableFilterBuilder
              columns={columns}
              onFilterChange={(filters) => setFilters(filters)}
              filters={filters}
            />
          )}
        </Grid.Col>

        <Grid.Col xs={3} pr={0}>
          {props.selectColumn && ids.length > 0 && (
            <Group position={'right'} >
              <Button
                variant="light"
                leftIcon={<IconFileInvoice />}
                onClick={() => props.onItemsSelect && props.onItemsSelect(ids)}
                sx={{alignItems: "flex-end"}}
              >
                {props.selectButtonName}
              </Button>
            </Group>
          )}
        </Grid.Col>
      </Grid>

      <Box
        sx={{
          position: 'relative',
          overflow: 'auto',
          border: 'solid 1px lightgrey',
          borderRadius: 4,
          width: '100%',
        }}
      >
        <LoadingOverlay
          visible={props.tableQuery?.isFetching}
          overlayOpacity={0.6}
          overlayBlur={2}
          loaderProps={{ color: 'dark' }}
          transitionDuration={300}
          zIndex={150}
        />

        <Table striped highlightOnHover fontSize="xs">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} ref={headerRef}>
                {headerGroup.headers.map((header) => {
                  const sortingEnabled = header.column.getCanSort();

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.getIsPinned() === 'left' ? 'pinned' : ''}
                      style={{
                        maxWidth: header.column.id === 'actions' ? '' : header.column.getSize(),
                        width: ['actions', 'select'].includes(header.column.id) ? header.column.getSize() : '',
                        cursor: sortingEnabled ? 'pointer' : 'inherit',
                        height: 35,
                        position: 'sticky',
                        top: 0,
                        boxShadow: 'inset 0 -1px 0 #dee2e6',
                      }}
                      onClick={() =>
                        sortingEnabled ? toggleSorting(header.column.columnDef as EntityTableColumn<T>) : undefined
                      }
                    >
                      <Center inline>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                        <Box sx={{ paddingLeft: 6, marginBottom: -4, minWidth: 24 }}>
                          {header.column.getIsSorted() && (
                            <Box>
                              {{
                                asc: <IconSortAscending size={18} />,
                                desc: <IconSortDescending size={18} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </Box>
                          )}

                          {!header.column.getIsSorted() && headerHovered && sortingEnabled && (
                            <IconArrowsSort color="lightgrey" size={18} />
                          )}
                        </Box>
                      </Center>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cell.column.getIsPinned() === 'left' ? 'pinned' : ''}
                    style={{ maxWidth: cell.column.getSize(), overflow: 'hidden' }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          {/*<tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={
                    header.column.getIsPinned() === 'left' ? 'pinned' : ''
                  }
                  style={{ width: header.column.getSize() }}
                >
                  {header.isPlaceholder ? null : header.renderFooter()}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>*/}
        </Table>

        {props.tableQuery.data?.data.length === 0 && (
          <Center>
            <Text size="sm" my="xs">
              {t('entityTable.filter.noData')}
            </Text>
          </Center>
        )}
      </Box>

      {/* Pagination */}

      {props.tableQuery.data && (
        <Group position={props.hideTableFilter ? 'right' : 'apart'} pb="lg" pt="lg" sx={{ width: '100%' }}>
          {!props.hideTableFilter && (
            <Button
              variant="subtle"
              type="button"
              color="gray"
              size="xs"
              leftIcon={<IconEraser />}
              onClick={clearSettings}
            >
              {t('common.button.clearSettings')}
            </Button>
          )}

          <EntityTablePagination
            pagination={pagination}
            onPaginationChange={setPagination}
            totalRowCount={props.tableQuery.data.totalCount}
            actualRowCount={props.tableQuery.data.data.length}
          />
        </Group>
      )}
    </Stack>
  );
}
