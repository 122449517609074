import { createStyles, Divider, SimpleGrid } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import React, { Fragment } from 'react';
import { If, Then } from 'react-if';

export interface StepProps {
  id?: string;
  index: number;
  label: string;
  isActive: boolean;
}

interface StepperProps {
  steps: { id: string; label: string }[];
  activeStep: number;
}

const useStyles = createStyles((theme) => ({
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
    maxWidth: '120px',
  },

  steps: {
    width: '100%',
    justifyItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
  },

  stepCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: theme.colors[theme.primaryColor][7],
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: 10,
  },

  stepLabel: {
    textAlign: 'center',
    fontSize: '16px',
  },

  active: {
    backgroundColor: theme.colors[theme.primaryColor][7],
    color: '#FFF',
  },

  stepper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },

  divider: {
    width: '200%',
    marginTop: '15px',
  },
}));

const Step: React.FC<StepProps> = ({ label, isActive, index }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.step}>
      <div className={cx(classes.stepCircle, { [classes.active]: isActive })}>
        {isActive ? <IconCheck /> : index + 1}
      </div>

      <div className={classes.stepLabel}>{label}</div>
    </div>
  );
};

export const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.stepper}>
      <SimpleGrid className={classes.steps} cols={2 * steps.length - 1}>
        {steps.map((step, index) => (
          <Fragment key={step.label}>
            <Step label={step.label} isActive={index <= activeStep} index={index} />

            <If condition={index < steps.length - 1}>
              <Then>
                <Divider
                  className={classes.divider}
                  size="md"
                  variant={index <= activeStep ? 'solid' : 'dashed'}
                  color={index < activeStep ? theme.colors[theme.primaryColor][7] : theme.colors.gray[4]}
                />
              </Then>
            </If>
          </Fragment>
        ))}
      </SimpleGrid>
    </div>
  );
};
