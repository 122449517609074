import {
  GrAutocompleteInput,
  GrDateInput,
  GrForm,
  GrFormTab,
  GrFormTabs,
  GrFormTitle,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  MoneyFormat,
  Stepper,
  useDtoForm,
  usePrevious,
} from '@gravity/frontend/ui';
import {
  OrderCreateFormDto,
  OrderCreateRequest,
  OrderItemUpdateFormDto,
  OrderResponse,
  OrderUpdateFormDto,
  OrderUpdateRequest,
  PartnerResponse,
  ProductResponse,
} from '@gravity/shared/dto';
import {
  Button,
  Grid,
  Group,
  Paper,
  ActionIcon,
  Flex,
  Box,
  ScrollArea,
  SimpleGrid,
  Text,
  createStyles,
  Input,
  Accordion,
  Alert,
} from '@mantine/core';
import { Permission, OrderStatus, OrderItem } from '@prisma/client';
import {
  IconCategory2,
  IconCheck,
  IconChevronLeft,
  IconEye,
  IconPencil,
  IconRowInsertTop,
  IconTrash,
  IconX,
  IconList,
  IconCopy,
  IconTableExport,
  IconFileSpreadsheet,
  IconAlertTriangle,
} from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { usePartnerCustomer } from '../../../hooks/use-partner-customer.hook';
import { useProductFinal } from '../../../hooks/use-product-final.hook';
import { axiosInstance } from '../../../plugins/axios';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { AluminiumRollerBlind } from '../forms/AluminiumRollerBlind';
import { FoldingDoor } from '../forms/FoldingDoor';
import { MosquitoNet } from '../forms/MosquitoNet';
import { MosquitoNetDoor } from '../forms/MosquitoNetDoor';
import { Plisse } from '../forms/Plisse';
import { UpperRollerBlind } from '../forms/UpperRollerBlind';
import { PvcRollerBlind } from '../forms/PvcRollerBlind';
import { PvcLedge } from '../forms/PvcLedge';
import { LindabLedge } from '../forms/LindabLedge';
import { usePermission } from '../../../hooks/use-permission.hook';

interface LogEvent {
  date: Date;
  name: string;
  email: string;
  status?: string;
  userId: string;
  type: string;
}

const productForms = {
  AluminiumRollerBlind: AluminiumRollerBlind,
  UpperRollerBlind: UpperRollerBlind,
  PvcRollerBlind: PvcRollerBlind,
  PvcLedge: PvcLedge,
  MosquitoNet: MosquitoNet,
  MosquitoNetDoor: MosquitoNetDoor,
  Plisse: Plisse,
  FoldingDoor: FoldingDoor,
  LindabLedge: LindabLedge,
};

const useStyles = createStyles((theme) => ({
  logLabel: {
    color: '#868e96',
    fontWeight: 500,
  },
}));

interface prevValuesInterface {
  partner: PartnerResponse | null;
}

export function OrdersFormPage() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [groupedOrderItems, setGroupedOrderItems] = useState({});
  const { hasAnyPermission } = usePermission();

  const { openDrawerView } = useDrawerView();
  const navigate = useNavigate();
  const entity = useLoaderData() as OrderResponse | undefined;
  const orderForm = useDtoForm([OrderCreateFormDto, OrderUpdateFormDto], 'entity.order', {
    isEditForm: !!entity,
    defaultValues: entity ? entity : undefined,
    validators: {
      orderNumber: orderNumberValidation,
    },
  });

  const prevValues: prevValuesInterface = usePrevious({ partner: orderForm.getValues('partner') }) || { partner: null };

  const logs: LogEvent[] = orderForm.watch('logs') || [];

  const orderMutation = useDtoMutation('orders', {
    invalidateQueries: [['orders'], ['price-list-versions']],
  });

  const orderStatuses = Object.keys(OrderStatus).map((statusType) => ({
    id: statusType,
    label: t(`common.orderStatus.${statusType}`),
  }));
  const partners = usePartnerCustomer();
  const partnerAddresses = orderForm.watch('partner')
    ? orderForm
        .watch('partner')
        .addresses?.map(
          (addressObject) =>
            `${addressObject.zipCode}, ${addressObject.city}, ${addressObject.address} (${t(
              'common.addressType.' + addressObject.type
            )})`
        )
    : [];

  const products = useProductFinal();
  const { control, watch } = orderForm;
  const {
    fields: orderItemFields,
    append: appendOrderItem,
    remove: removeOrderItem,
  } = useFieldArray({ name: 'orderItems', control, keyName: 'orderItemId' });

  const newOrderItemFields = (): OrderItemUpdateFormDto => {
    return {
      product: { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true },
      productConfig: {},
      quantity: 1,
      addedByVendor: false,
      price: 0,
    };
  };

  const [priceLists, setPriceLists] = useState();

  async function getPriceList() {
    const date = orderForm.getValues('orderDate') ? orderForm.getValues('orderDate') : new Date();
    const partnerId = watch('partner') ? watch('partner').id : null;
    const result = await axiosInstance.get(`price-list-versions?date=${date}&partnerId=${partnerId}&`);
    setPriceLists(result.data.data);
  }

  useEffect(() => {
    if (priceLists === undefined) {
      getPriceList();
    }
  }, []);

  useEffect(() => {
    let sumPrice = 0;

    sumPrice = watch('orderItems')
      ? watch('orderItems').reduce((acc, item) => {
          item.price = item.price ?? 0;
          item.quantity = item.quantity ?? 1;
          return (acc += item.price * item.quantity);
        }, 0)
      : 0;

    if (!orderForm.readonlyMode && sumPrice !== watch('price')) orderForm.setValue('price', sumPrice);
  }, [watch()]);

  const handleStatusStep = async (data: OrderUpdateRequest) => {
    const order = (await orderMutation.update(data)) as OrderResponse;
    orderForm.resetForm(order);
  };

  const handleSubmit = async (formData: OrderCreateFormDto) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const verifiedOrderItems = formData.orderItems.filter((item) => item.product?.id !== '');
    const clone: any = _.cloneDeep({ ...formData, orderItems: verifiedOrderItems });

    clone.orderItems.forEach((item) => {
      delete item['orderId'];
      item['productId'] = item['product']?.id;
      delete item['product'];
    });
    clone.partnerId = clone['partner']?.id;
    delete clone['partner'];

    const data: OrderCreateRequest = clone;

    if (orderForm.isCreateForm) {
      data.orderNumber = '';
      const order = (await orderMutation.create(data)) as OrderResponse;
      navigate(`/sales/orders/${order.id}`);
      orderForm.toggleReadonlyMode(order);
    } else {
      const order = await orderMutation.update(data);
      const fullData = { ...data, orderDate: entity?.orderDate };
      orderForm.toggleReadonlyMode(fullData);
      orderForm.resetForm(order);
    }
  };

  function handlePartnerChange() {
    orderForm.getValues('orderItems').forEach((orderItem, index) => {
      orderForm.setValue(`orderItems.${index}.price`, 0);
    });
    if (orderForm.getValues('partner')?.id !== prevValues.partner?.id) {
      orderForm.setValue('deliveryAddress', '');
      orderForm.setValue('invoiceAddress', '');
    }
    getPriceList();
  }

  async function orderNumberValidation(orderNumber: string) {
    if (orderForm.isEditForm && orderNumber === entity?.orderNumber) {
      return;
    }
    const result = await axiosInstance.get(`orders/check-order-number?orderNumber=${orderNumber}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictOrderNumber') {
      return { type: result.response?.data.message };
    }
  }

  function updateOrderItemConfig(index: number, data: any) {
    const quantityKey = Object.keys(data).find((key) => key.endsWith('quantity'));
    const priceKey = Object.keys(data).find((key) => key.endsWith('price'));

    //The old property names should be deleted from price list
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'll_endCloser')) {
      delete data['priceList']['ll_endCloser'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'll_endCloser2')) {
      delete data['priceList']['ll_endCloser2'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'arb_accessories')) {
      delete data['priceList']['arb_accessories'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'prb_accessories')) {
      delete data['priceList']['prb_accessories'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'arb_wideFlangeCaseEnding')) {
      delete data['priceList']['arb_wideFlangeCaseEnding'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'prb_wideFlangeCaseEnding')) {
      delete data['priceList']['prb_wideFlangeCaseEnding'];
    }
    if (Object.prototype.hasOwnProperty.call(data['priceList'], 'set_color')) {
      delete data['priceList']['set_color'];
    }

    orderForm.setValue(`orderItems.${index}.productConfig` as string, data);
    orderForm.setValue(`orderItems.${index}.quantity`, quantityKey ? data[`${quantityKey}`] : 0);
    orderForm.setValue(`orderItems.${index}.price`, priceKey ? data[`${priceKey}`] : 0);
  }

  function resetOrderItem(index: number, prevProduct: ProductResponse) {
    orderForm.setValue(`orderItems.${index}.product`, { ...prevProduct });
  }

  function handleExportForItem(orderId: string, index: number) {
    axiosInstance
      .post(`orders/generateExcelForItem/${orderId}`, { index: index }, { responseType: 'arraybuffer' })
      .then(function (response) {
        // download the response
        if (response.status === 201) {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
          downloadLink.download = `${orderForm.getValues('orderItems')[`${index}`].product.name}_${orderForm.getValues(
            'orderNumber'
          )}.xlsx`;
          downloadLink.click();
        }
      })
      .catch(function (error) {
        // handle errors here
        console.error(error);
      });
  }

  function handleExport(orderId: string) {
    axiosInstance
      .get(`orders/generateExcel/${orderId}`, { responseType: 'arraybuffer' })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition.match(/filename=(.+)/);
        const filename = filenameMatch ? filenameMatch[1] : 'downloaded-file.zip';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  async function sortedOrderItems() {
    const result = (await axiosInstance.get(`orders/sortedOrderItems/${orderForm.getValues('id')}`)).data;
    setGroupedOrderItems(result);
  }

  return (
    <Page>
      <Box w="100%">
        <GrForm form={orderForm} onSubmit={handleSubmit}>
          <Group position="apart" mb="md">
            <Group>
              <Link to="/sales/orders">
                <ActionIcon variant="light">
                  <IconChevronLeft />
                </ActionIcon>
              </Link>

              <GrFormTitle i18nPrefix="sales.ordersForm" />
            </Group>

            <Group>
              {orderForm.readonlyMode ? (
                <PermissionCheck hasAll={[Permission.order_read, Permission.order_update]}>
                  <Button onClick={() => orderForm.toggleReadonlyMode()} variant="light" leftIcon={<IconPencil />}>
                    {t('common.button.edit')}
                  </Button>
                </PermissionCheck>
              ) : (
                <>
                  <PermissionCheck
                    hasAll={
                      orderForm.isEditForm
                        ? [Permission.order_read, Permission.order_update]
                        : [Permission.order_create]
                    }
                  >
                    <Button loading={orderForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                      {t('common.button.save')}
                    </Button>
                  </PermissionCheck>

                  <Button
                    loading={orderForm.isSubmitting}
                    type="button"
                    color="gray"
                    variant="light"
                    leftIcon={<IconX />}
                    onClick={() => (orderForm.isCreateForm ? navigate(-1) : orderForm.toggleReadonlyMode())}
                  >
                    {t('common.button.cancel')}
                  </Button>
                </>
              )}

              {orderForm.isEditForm && orderForm.readonlyMode && (
                <PermissionCheck hasAll={[Permission.order_read, Permission.order_update, Permission.order_delete]}>
                  <DeleteConfirmButton
                    loading={orderForm.isSubmitting}
                    onDelete={async () => {
                      try {
                        await orderMutation.delete(orderForm.getValues('id' as never));
                        navigate('/sales/orders');
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </PermissionCheck>
              )}
            </Group>
          </Group>

          <ScrollArea type="scroll" style={{ height: 'calc(100vh - 58px)' }} pb="sm">
            {!orderForm.isCreateForm && (
              <Group>
                <Stepper
                  steps={orderStatuses}
                  activeStep={orderStatuses.findIndex((item) => item.id === orderForm.getValues('status'))}
                />
              </Group>
            )}

            {!orderForm.isCreateForm && orderForm.getValues('status') === 'beforeSubmission' && (
              <Alert icon={<IconAlertTriangle />} title={t('sales.ordersForm.attention')} color="yellow" radius="xs">
                {t('sales.ordersForm.beforeSubmissionWarning')}{' '}
              </Alert>
            )}

            <Grid m={0}>
              <Grid.Col xs={4}>
                <GrTextInput name="orderId" />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrDateInput name="orderDate" />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrDateInput
                  name="deadlineDate"
                  onlyDate
                  minDate={orderForm.getValues('orderDate') ? new Date(orderForm.getValues('orderDate')) : new Date()}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSelectInput data={partners} name="partner" labelField="name" onChange={handlePartnerChange} />
              </Grid.Col>

              {watch('partner') && (
                <>
                  <Grid.Col xs={4}>
                    <GrAutocompleteInput data={partnerAddresses} name="deliveryAddress" labelField="name" />
                  </Grid.Col>

                  <Grid.Col xs={4}>
                    <GrAutocompleteInput data={partnerAddresses} name="invoiceAddress" labelField="name" />
                  </Grid.Col>
                </>
              )}

              {!orderForm.isCreateForm && (
                <Grid.Col xs={4}>
                  <GrTextInput name="orderNumber" />
                </Grid.Col>
              )}

              <Grid.Col xs={4}>
                <GrSwitchInput name="billed" />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSelectInput data={orderStatuses} name={'status'} simpleValue />

                {orderForm.readonlyMode &&
                  hasAnyPermission([Permission.order_create, Permission.order_update]) &&
                  orderStatuses[orderStatuses.findIndex((item) => item.id === orderForm.getValues('status')) + 1]
                    ?.id && (
                    <Button
                      onClick={() =>
                        handleStatusStep({
                          id: orderForm.getValues('id'),
                          status: orderStatuses[
                            orderStatuses.findIndex((item) => item.id === orderForm.getValues('status')) + 1
                          ].id as OrderStatus,
                        })
                      }
                    >
                      {t(
                        `common.nextStatusButton.${
                          orderStatuses[orderStatuses.findIndex((item) => item.id === orderForm.getValues('status'))].id
                        }`
                      )}
                    </Button>
                  )}
              </Grid.Col>

              <Grid.Col>
                <GrTextareaInput name="note" />
              </Grid.Col>
            </Grid>

            <GrFormTabs
              tabs={
                orderForm.readonlyMode
                  ? [
                      {
                        icon: <IconCategory2 />,
                        name: 'orderItems',
                        label: 'entity.order.orderItemsDetails',
                        badge: orderItemFields.length,
                      },
                      {
                        icon: <IconFileSpreadsheet />,
                        name: 'excelGeneration',
                        label: 'entity.order.excelGeneration',
                      },
                      {
                        icon: <IconList />,
                        name: 'logItems',
                        label: 'entity.order.logs',
                      },
                    ]
                  : [
                      {
                        icon: <IconCategory2 />,
                        name: 'orderItems',
                        label: 'entity.order.orderItemsDetails',
                        badge: orderItemFields.length,
                      },
                      {
                        icon: <IconList />,
                        name: 'logItems',
                        label: 'entity.order.logs',
                      },
                    ]
              }
              onTabChange={(value) => value === 'excelGeneration' && sortedOrderItems()}
            >
              <GrFormTab name="orderItems">
                {orderItemFields.map((item, index) => {
                  const netPrice =
                    orderForm.getValues(`orderItems.${index}.price`) *
                    orderForm.getValues(`orderItems.${index}.quantity`);
                  return (
                    <Paper
                      key={item.orderItemId}
                      withBorder
                      mb="xs"
                      p="md"
                      sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                    >
                      {!orderForm.readonlyMode && (
                        <Group position="right">
                          <ActionIcon
                            title={t('common.button.delete')}
                            onClick={() => removeOrderItem(index)}
                            color="red"
                            size="md"
                            variant="light"
                          >
                            <IconTrash size={18} />
                          </ActionIcon>
                        </Group>
                      )}

                      <Grid>
                        <Grid.Col xs={6}>
                          <Flex>
                            <Box sx={{ flexGrow: 1 }}>
                              {watch(`orderItems.${index}.product.id`) === '' && (
                                <GrSelectInput
                                  data={products}
                                  name={`orderItems.${index}.product`}
                                  labelField="name"
                                  disabled={watch(`orderItems.${index}.product.id`) !== ''}
                                  onChange={() => {
                                    setTimeout(() => {
                                      const product = orderForm.getValues(`orderItems.${index}.product`);

                                      const prevProduct =
                                        Object.keys(orderForm.getValues(`orderItems.${index}.productConfig` as string))
                                          .length === 0
                                          ? { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true }
                                          : product;
                                      if (product?.productForm && productForms[product.productForm]) {
                                        openDrawerView(productForms[product.productForm], {
                                          orderItem: orderForm.getValues(`orderItems.${index}`),
                                          onSave: (data) => updateOrderItemConfig(index, data),
                                          onClose: () => resetOrderItem(index, prevProduct),
                                          orderReadonlyMode: orderForm.readonlyMode,
                                          quantity: orderForm.getValues(`orderItems.${index}.quantity`),
                                          priceLists,
                                        });
                                      }
                                    });
                                  }}
                                />
                              )}

                              {watch(`orderItems.${index}.product.id`) !== '' && (
                                <Input.Wrapper label={t('entity.order.orderItems.product') + ':'}>
                                  <Text>{orderForm.getValues(`orderItems.${index}.product.name` as string)}</Text>

                                  <Text color={'dimmed'}>
                                    {orderForm.getValues(`orderItems.${index}.productConfig.summary` as string)}
                                  </Text>
                                </Input.Wrapper>
                              )}
                            </Box>

                            {watch(`orderItems.${index}.product.productForm`) &&
                              watch(`orderItems.${index}.product.productAttributes` as string)?.length > 0 && (
                                <>
                                  <ActionIcon
                                    mx="sm"
                                    mt="xl"
                                    title={orderForm.readonlyMode ? t('common.button.show') : t('common.button.edit')}
                                    onClick={() => {
                                      const product = orderForm.getValues(`orderItems.${index}.product`);
                                      const prevProduct =
                                        Object.keys(orderForm.getValues(`orderItems.${index}.productConfig` as string))
                                          .length === 0
                                          ? { id: '', name: '', canBeOrdered: true, isAvailableForReseller: true }
                                          : product;
                                      if (product?.productForm && productForms[product.productForm]) {
                                        openDrawerView(productForms[product.productForm], {
                                          orderItem: orderForm.getValues(`orderItems.${index}`),
                                          onSave: (data) => updateOrderItemConfig(index, data),
                                          onClose: () => resetOrderItem(index, prevProduct),
                                          orderReadonlyMode: orderForm.readonlyMode,
                                          quantity: orderForm.getValues(`orderItems.${index}.quantity`),
                                          priceLists,
                                        });
                                      }
                                    }}
                                  >
                                    {orderForm.readonlyMode ? <IconEye /> : <IconPencil />}
                                  </ActionIcon>

                                  {orderForm.readonlyMode && (
                                    <ActionIcon
                                      title={t('common.button.export')}
                                      mx="sm"
                                      mt="xl"
                                      onClick={() => handleExportForItem(orderForm.getValues('id'), index)}
                                    >
                                      {<IconTableExport />}
                                    </ActionIcon>
                                  )}
                                </>
                              )}

                            {!orderForm.readonlyMode &&
                              watch(`orderItems.${index}.product.id`) !== '' &&
                              watch(`orderItems.${index}.product.canBeOrdered`) && (
                                <ActionIcon
                                  title={t('common.button.duplicate')}
                                  onClick={() => {
                                    const newOrderItem = orderForm.getValues(`orderItems.${index}`);
                                    delete newOrderItem.id;
                                    appendOrderItem(newOrderItem);
                                    const product = orderForm.getValues(`orderItems.${index}.product`);
                                    if (product?.productForm && productForms[product.productForm]) {
                                      openDrawerView(productForms[product.productForm], {
                                        orderItem: orderForm.getValues(`orderItems.${index}`),
                                        onSave: (data) => updateOrderItemConfig(orderItemFields.length, data),
                                        onClose: () => {
                                          resetOrderItem(orderItemFields.length, {
                                            id: '',
                                            name: '',
                                            canBeOrdered: true,
                                            isAvailableForReseller: true,
                                          });
                                          orderForm.setValue(`orderItems.${orderItemFields.length}.productConfig`, {});
                                          orderForm.setValue(`orderItems.${orderItemFields.length}.price`, 0);
                                        },
                                        orderReadonlyMode: orderForm.readonlyMode,
                                        quantity: orderForm.getValues(`orderItems.${orderItemFields.length}.quantity`),
                                        priceLists,
                                      });
                                    }
                                  }}
                                  color="primaryColor"
                                  mx="sm"
                                  mt="xl"
                                >
                                  <IconCopy />
                                </ActionIcon>
                              )}
                          </Flex>
                        </Grid.Col>

                        <Grid.Col xs={2} pr={30}>
                          <GrNumberInput name={`orderItems.${index}.quantity`} min={1} max={100} controls />
                        </Grid.Col>

                        <Grid.Col xs={2}>
                          <GrNumberInput
                            name={`orderItems.${index}.price`}
                            isPrice
                            readonly={
                              watch(`orderItems.${index}.product.productForm`) !== '' &&
                              watch(`orderItems.${index}.product.productAttributes` as string)?.length > 0
                            }
                          />
                        </Grid.Col>

                        <Grid.Col xs={2}>
                          <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                            <Input.Wrapper label={t('entity.order.orderItems.netPrice') + ':'}>
                              <Text>
                                {orderForm.getValues(`orderItems.${index}.price`) ? (
                                  <MoneyFormat>{netPrice}</MoneyFormat>
                                ) : (
                                  '-'
                                )}
                              </Text>
                            </Input.Wrapper>
                          </Box>
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  );
                })}

                {orderForm.readonlyMode && orderItemFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

                {!orderForm.readonlyMode && (
                  <Button
                    leftIcon={<IconRowInsertTop />}
                    variant="subtle"
                    mt="md"
                    compact
                    onClick={() => appendOrderItem(newOrderItemFields())}
                  >
                    {t('common.button.addRow')}
                  </Button>
                )}

                {orderItemFields.length > 0 && (
                  <Grid sx={{ marginTop: '5px' }}>
                    <Grid.Col xs={2} offset={10}>
                      <Box ml={-25} mr={30}>
                        <GrNumberInput name="price" isPrice readonly />
                      </Box>
                    </Grid.Col>
                  </Grid>
                )}
              </GrFormTab>

              <GrFormTab name="excelGeneration">
                {orderForm.readonlyMode && orderItemFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

                {orderItemFields.length > 0 && (
                  <Paper withBorder mb="xs" sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)', width: 'fit-content' }}>
                    <Grid p={25}>
                      <Grid.Col xs={11} p={0}>
                        {t('entity.order.excelGenerationMessage')}:
                      </Grid.Col>

                      <Grid.Col xs={1} p={0}>
                        <ActionIcon
                          title={t('common.button.export')}
                          onClick={() => handleExport(orderForm.getValues('id'))}
                        >
                          {<IconTableExport />}
                        </ActionIcon>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                )}

                {orderForm.readonlyMode && (
                  <Accordion variant="filled" multiple>
                    {Object.keys(groupedOrderItems)
                      ?.filter((key) => key !== 'null')
                      .map((key) => {
                        return (
                          <Paper key={key} withBorder mb="xs" sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}>
                            <Accordion.Item value={key}>
                              <Accordion.Control>
                                <Text>{t(`entity.order.products.${key}`)}</Text>
                              </Accordion.Control>

                              <Accordion.Panel>
                                {Object.keys(groupedOrderItems[`${key}`]).map((subListKey, index) => {
                                  const subList: OrderItem[] = groupedOrderItems[`${key}`][`${subListKey}`];

                                  return (
                                    <Paper
                                      key={subListKey}
                                      withBorder
                                      mb="xs"
                                      sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                                    >
                                      <Text m={15}>{index + 1}.</Text>

                                      {subList.map((item) => {
                                        const netPrice = item.price * item.quantity;

                                        return (
                                          <Grid m={15} key={`${item.id}`}>
                                            <Grid.Col xs={6}>
                                              <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                                <Input.Wrapper label={t('entity.order.orderItems.product') + ':'}>
                                                  <Text color={'dimmed'}>
                                                    {item.productConfig && item.productConfig['summary']}
                                                  </Text>
                                                </Input.Wrapper>
                                              </Box>
                                            </Grid.Col>

                                            <Grid.Col xs={2}>
                                              <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                                <Input.Wrapper label={t('entity.order.orderItems.quantity') + ':'}>
                                                  <Text>{item.quantity}</Text>
                                                </Input.Wrapper>
                                              </Box>
                                            </Grid.Col>

                                            <Grid.Col xs={2}>
                                              <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                                <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
                                                  <Text>
                                                    {item.price ? <MoneyFormat>{item.price}</MoneyFormat> : '-'}
                                                  </Text>
                                                </Input.Wrapper>
                                              </Box>
                                            </Grid.Col>

                                            <Grid.Col xs={2}>
                                              <Box sx={{ wordWrap: 'break-word' }} mt={6}>
                                                <Input.Wrapper label={t('entity.order.orderItems.netPrice') + ':'}>
                                                  <Text>
                                                    {orderForm.getValues(`orderItems.${index}.price`) ? (
                                                      <MoneyFormat>{netPrice}</MoneyFormat>
                                                    ) : (
                                                      '-'
                                                    )}
                                                  </Text>
                                                </Input.Wrapper>
                                              </Box>
                                            </Grid.Col>
                                          </Grid>
                                        );
                                      })}
                                    </Paper>
                                  );
                                })}
                              </Accordion.Panel>
                            </Accordion.Item>
                          </Paper>
                        );
                      })}
                  </Accordion>
                )}
              </GrFormTab>

              <GrFormTab name="logItems">
                {logs.map((item, index) => {
                  const eventType = !item.type
                    ? '-'
                    : item.type.includes('Számlázott')
                    ? item.type
                    : t(`entity.order.logDetails.${item.type}`);
                  return (
                    <Paper key={index} withBorder mb="xs" p="md" sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}>
                      <SimpleGrid cols={5} breakpoints={[{ maxWidth: 'md', cols: 2 }]}>
                        <div>
                          <Text className={classes.logLabel}>{t('entity.order.logDetails.name')}:</Text>

                          <Text> {item.name}</Text>
                        </div>

                        <div>
                          <Text className={classes.logLabel}>{t('entity.order.logDetails.email')}:</Text>

                          <Text>{item.email}</Text>
                        </div>

                        <div>
                          <Text className={classes.logLabel}>{t('entity.order.logDetails.event')}:</Text>

                          <Text>{eventType}</Text>
                        </div>

                        <div>
                          <Text className={classes.logLabel}>{t('entity.order.logDetails.status')}:</Text>

                          <Text>{t(`common.orderStatus.${item.status}`)}</Text>
                        </div>

                        <div>
                          <Text className={classes.logLabel}>{t('entity.order.logDetails.date')}:</Text>

                          <Text>
                            {new Date(item.date).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
                          </Text>
                        </div>
                      </SimpleGrid>
                    </Paper>
                  );
                })}
              </GrFormTab>
            </GrFormTabs>
          </ScrollArea>
        </GrForm>
      </Box>
    </Page>
  );
}
