import { Box, Button, Center, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconAlertTriangle, IconPlus } from '@tabler/icons-react';
import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { PageTitle } from '../../../components/PageTitle';
import { OrderStatus, Permission } from '@prisma/client';
import { OrderResponse } from '@gravity/shared/dto';
import { CellContext } from '@tanstack/react-table';
import { useCurrentPartner } from '../../../hooks/use-current-partner.hook';
import { Link } from 'react-router-dom';
import { If, Then } from 'react-if';

function orderStatusShow(ctx: CellContext<OrderResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      {ctx.getValue() === 'beforeSubmission' && (
        <IconAlertTriangle size={'1rem'} color="orange" style={{ marginRight: 10 }} />
      )}

      <>{t('common.orderStatus.' + ctx.getValue())}</>
    </Center>
  );
}

export function ResellerOrdersPage() {
  const { t } = useTranslation();

  const query = useTableQuery<OrderResponse>('reseller-orders');

  const { permission } = useCurrentPartner();
  const hasWritePermission =
    permission === Permission.reseller_system_admin || permission === Permission.reseller_system_write;

  const hasPermission =
    permission === Permission.reseller_system_admin ||
    permission === Permission.reseller_system_write ||
    permission === Permission.reseller_system_read;

  const cols: EntityTableColumnDef<OrderResponse>[] = [
    { accessorKey: 'orderId' },
    { accessorKey: 'orderNumber' },
    {
      accessorKey: 'deliveryAddress',
    },
    { accessorKey: 'orderDate', type: EntityTableColumnType.Date },
    { accessorKey: 'deadlineDate', type: EntityTableColumnType.Date },
    {
      accessorKey: 'status',
      type: EntityTableColumnType.Enum,
      filterOptions: Object.keys(OrderStatus).map((status) => ({
        value: status,
        label: t(`common.orderStatus.${status}`),
      })),
      cell: (row) => orderStatusShow(row),
    },
    { accessorKey: 'price', type: EntityTableColumnType.Price },
  ];

  return (
    <Box p="lg">
      <PageTitle title={t('sales.orders.title')} subtitle={t('component.reseller.orders.subtitle')}>
        <If condition={hasWritePermission}>
          <Then>
            <Link to="create">
              <Button leftIcon={<IconPlus />} variant="light">
                {t('common.button.add')}
              </Button>
            </Link>
          </Then>
        </If>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
        <Box pt="md">
          {hasPermission && (
            <EntityTable
              resourceName="order"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          )}
        </Box>
      </ScrollArea>
    </Box>
  );
}
