import { GrForm, GrPasswordInput, useDtoForm } from '@gravity/frontend/ui';
import { ResetPasswordRequest } from '@gravity/shared/dto';
import {
  Box,
  Button,
  Container,
  Paper,
  Title,
  Text,
  Collapse,
  Alert,
  Group,
  Anchor,
  BackgroundImage,
  Center,
  Image,
  ScrollArea,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../plugins/axios';
import { useAppConfig } from '../../../hooks/use-app-config.hook';

export function ResetPassword() {
  const { token } = Object.fromEntries(new URLSearchParams(location.search));
  const { t } = useTranslation();
  const config = useAppConfig();
  const navigate = useNavigate();
  const passwordForm = useDtoForm(ResetPasswordRequest, 'entity.user', {
    isEditForm: false,
    defaultValues: { token },
  });
  const [tokenError, setTokenError] = useState('error.tokenNotFound');
  const [errorVisible, setErrorVisible] = useState(!token);

  function handleSubmit({ password, passwordRepeat }: ResetPasswordRequest) {
    axiosInstance.post(`users/reset-password`, { password, passwordRepeat, token }).then((result) => {
      if (result instanceof AxiosError) {
        setTokenError(result.response?.data.message);
        setErrorVisible(true);
      } else {
        showNotification({
          color: 'green',
          message: t('component.resetPassword.successMessage'),
          autoClose: 10000,
        });
        setTimeout(() => {
          navigate('/login');
        }, 300);
      }
    });
  }

  return (
    <Box>
      <BackgroundImage
        src={config ? config?.background : ''}
        sx={{ height: '100vh', opacity: 0.2, filter: 'blur(5px)' }}
        radius="xs"
      ></BackgroundImage>

      <Container size={420} sx={{ position: 'absolute', top: 0, left: 'calc(50vw - 210px)' }}>
        <ScrollArea type="scroll" h="100vh" pb="sm">
          <Box
            sx={{
              height: 150,
              maxWidth: 300,
              backgroundImage: `url(${config?.logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
            mb={{ base: 10, sm: 25 }}
            mt={{ base: 20, sm: 120 }}
            mx="auto"
          ></Box>

          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 'bold',
              fontSize: 20,
              marginBottom: 10,
            })}
          >
            {t('component.resetPassword.resetPasswordTitle')}
          </Title>

          <Text size="sm" align="center" mt={5}>
            {t('component.resetPassword.resetPasswordMessage')}
          </Text>

          <Paper withBorder shadow="md" p={30} mt={30} radius="md" miw="388px">
            {errorVisible ? (
              <Collapse in={errorVisible}>
                <Alert
                  icon={<IconAlertCircle size={16} />}
                  title={t('component.resetPassword.errorTitle')}
                  color="red"
                  withCloseButton
                  onClose={() => setErrorVisible(false)}
                  mt="md"
                >
                  {t(tokenError)}
                </Alert>
              </Collapse>
            ) : (
              <GrForm form={passwordForm} onSubmit={handleSubmit}>
                <GrPasswordInput form={passwordForm} name="password" />

                <GrPasswordInput form={passwordForm} name="passwordRepeat" />

                <Button loading={passwordForm.isSubmitting} type="submit" fullWidth mt="xl">
                  {t('common.button.save')}
                </Button>
              </GrForm>
            )}

            <Group position="center" mt="md">
              <Anchor component={Link} to="/login" size="sm">
                {t('component.logIn.signIn')}
              </Anchor>
            </Group>
          </Paper>
        </ScrollArea>
      </Container>
    </Box>
  );
}
