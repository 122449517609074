import { IsString, IsNotEmpty } from 'class-validator';

export class CheckNameRequest {
  /**
   * Name of the product category
   */
  @IsString()
  @IsNotEmpty()
  public name: string;
}
