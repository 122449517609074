import { LocalNavLink } from '../../../components/LocalNav/components/LocalNavLink';
import { IconIdBadge2, IconReportSearch, IconTool, IconUsers } from '@tabler/icons-react';
import { LocalNavTitle } from '../../../components/LocalNav/components/LocalNavTitle';
import { useTranslation } from 'react-i18next';
import { LocalNavDivider } from '../../../components/LocalNav/components/LocalNavDivider';
import { usePermission } from '../../../hooks/use-permission.hook';
import { Permission } from '@prisma/client';
import { LocalNav } from '../../../components/LocalNav/LocalNav';
import { Flex } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { ContentLayout } from '../../../layouts/ContentLayout';

export function SettingsLayout() {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();

  return (
    <Flex>
      <LocalNav>
        <LocalNavTitle>{t('settings.menu.title')}</LocalNavTitle>

        <LocalNavDivider label={t('settings.menu.userSettings')} />

        <LocalNavLink
          to="/settings/users"
          icon={IconUsers}
          label={t('settings.menu.users')}
          disabled={
            !hasAnyPermission([
              Permission.user_read,
              Permission.user_create,
              Permission.user_update,
              Permission.user_delete,
            ])
          }
        />

        <LocalNavLink
          to="/settings/user-roles"
          icon={IconIdBadge2}
          label={t('settings.menu.userRoles')}
          disabled={
            !hasAnyPermission([
              Permission.user_role_create,
              Permission.user_role_delete,
              Permission.user_role_read,
              Permission.user_role_update,
            ])
          }
        />

        <LocalNavDivider label={t('settings.menu.systemSettings')} />

        <LocalNavLink
          to="/settings/app-settings"
          icon={IconTool}
          label={t('settings.menu.appSettings')}
          disabled={!hasAnyPermission([Permission.appAdmin])}
        />

        <LocalNavLink
          to="/settings/audit-logs"
          icon={IconReportSearch}
          label={t('settings.menu.auditLog')}
          disabled={!hasAnyPermission([Permission.entity_audit_log_read])}
        />
      </LocalNav>

      <ContentLayout localNav>
        <Outlet />
      </ContentLayout>
    </Flex>
  );
}
