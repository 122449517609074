import { Permission } from '@prisma/client';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class PartnerUserCreateRequest {
  /**
   * The ID of the partner
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The ID of the user
   */
  @IsString()
  @IsNotEmpty()
  public userId: string;

  /**
   * The provided permission for the partner user
   */
  @IsString()
  @IsOptional()
  public permission?: Permission;
}

export class ResellerPartnerUserCreateRequest {
  /**
   * The ID of the partner
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The ID of the user
   */
  @IsString()
  @IsOptional()
  public userId?: string;

  /**
   * The provided permission for the partner user
   */
  @IsString()
  @IsOptional()
  public permission?: Permission;
}

export class PartnerUserUpdateRequest {
  /**
   * CUID identifier of the partner user
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The ID of the partner
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * The ID of the user
   */
  @IsString()
  @IsOptional()
  public userId?: string;

  /**
   * The provided permission for the partner user
   */
  @IsString()
  @IsOptional()
  public permission?: Permission;
}
