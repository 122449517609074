import _ from 'lodash';

export function recursiveGet(object: any, path: string): any {
  if (!path.includes('[]')) {
    return _.get(object, path);
  }

  const fragments: string[] = path.split('.');

  if (fragments.length > 1) {
    const key = fragments.shift()?.replace('[]', '');
    const newObject = object[key!];
    const newKey = fragments.join('.');

    if (Array.isArray(newObject)) {
      return newObject.map((o) => recursiveGet(o, newKey));
    } else {
      return recursiveGet(newObject, newKey);
    }
  }
}
