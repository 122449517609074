import { ApiProperty } from '@nestjs/swagger';
import { Transform } from 'class-transformer';
import { IsNumber, IsOptional, Min } from 'class-validator';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export class FilterQuery<Entity> {
  @ApiProperty({ description: 'Page number', default: 1 })
  @Transform(({ value }) => parseInt(value, 10))
  @IsNumber()
  @Min(1)
  @IsOptional()
  public page?: number;

  @ApiProperty({ description: 'Page size', default: 10 })
  @Transform(({ value }) => parseInt(value, 10))
  @IsNumber()
  @Min(1)
  @IsOptional()
  public pageSize?: number;

  @ApiProperty({
    name: 'orderBy',
    description: 'Ordering object',
    example: 'orderBy[name]=asc',
    type: 'object',
  })
  @IsOptional()
  public orderBy?: PartialRecord<keyof Entity, 'asc' | 'desc'>;

  @ApiProperty({
    name: 'filter',
    description: 'Filtering object',
    example: 'filter[name][contains]=administrator',
    type: 'object',
  })
  @IsOptional()
  public filter?: PartialRecord<keyof Entity, unknown>;
}
