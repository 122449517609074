import { Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../../hooks/use-dto-form.hook';

export function GrFormTitle(props: {
  i18nPrefix: string;
  form?: UseDtoFormReturn;
  showTitle?: string;
  editTitle?: string;
  createTitle?: string;
  variable?: string;
}) {
  const { t } = useTranslation();
  const order = 4;

  function renderTitle() {
    if (props.form && props.form.isEditForm) {
      if (props.form.readonlyMode) {
        return t(`${props.i18nPrefix}.${props.showTitle ?? 'showTitle'}`, {
          variable: props.variable,
        });
      } else {
        return t(`${props.i18nPrefix}.${props.editTitle ?? 'editTitle'}`, {
          variable: props.variable,
        });
      }
    } else {
      return t(`${props.i18nPrefix}.${props.createTitle ?? 'createTitle'}`, {
        variable: props.variable,
      });
    }
  }

  return (
    <Title order={order} pb={0}>
      {renderTitle()}
    </Title>
  );
}
