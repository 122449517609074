import { Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDateString, IsNotEmpty, IsString } from 'class-validator';

export class NewsPostCreateRequest {
  /**
   * The title of the news post
   */
  @IsString()
  @IsNotEmpty()
  public title: string;

  @IsDateString()
  @IsNotEmpty()
  public date: Date;

  /**
   * The body of the news post
   */
  @IsString()
  public body: string;

  /**
   * The visibility of the news post
   */
  @IsBoolean()
  public visible: boolean;

  /**
   * The visibility of the news post
   */
  @IsArray()
  @Type(() => Object)
  @Transform((obj: any) => {
    return obj.obj.attachments;
  })
  public attachments?: string[];
}

export class NewsPostUpdateRequest {
  /**
   * The ID of the unit of measurement
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The title of the news post
   */
  @IsString()
  @IsNotEmpty()
  public title: string;
  /**
   * The body of the news post
   */
  @IsString()
  public body?: string;

  @IsDateString()
  @IsNotEmpty()
  public date: Date;

  /**
   * The visibility of the news post
   */
  @IsBoolean()
  public visible?: boolean;

  /**
   * The visibility of the news post
   */
  @IsArray()
  @Type(() => Object)
  @Transform((obj: any) => {
    return obj.obj.attachments;
  })
  public attachments?: string[];
}
