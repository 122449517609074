import { Badge, Divider, Tabs } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../../hooks/use-dto-form.hook';

interface GrFormTabsProps {
  tabs: { name: string; label: string; icon?: ReactElement; badge?: number }[];
  withBorders?: boolean;
  variant?: 'default' | 'outline' | 'pills';
  children: ReactElement | ReactElement[];
  [key: string]: unknown;
  onTabChange?: (value: any) => void;
}

export function GrFormTabs(props: GrFormTabsProps) {
  const { t } = useTranslation();
  const id = useId();

  const form = props['form'] as UseDtoFormReturn;

  const [activeTab, setActiveTab] = useState(props.tabs[0].name);
  useEffect(() => {
    if (!props.tabs.find((tab) => tab.name === activeTab)) {
      setActiveTab(props.tabs[0].name);
    }
  }, [props.tabs, activeTab]);

  if (!form) {
    return null;
  }

  function hasError(property: string) {
    return ((form.formState.errors[`${property}`] as unknown as FieldError[])?.length || 0) > 0;
  }

  return (
    <>
      <Tabs
        value={activeTab}
        mt="md"
        variant={props.variant}
        sx={(theme) => ({
          'button[aria-selected="true"]': {
            color: theme.colors[theme.primaryColor][7],
          },
        })}
        onTabChange={(value) => {
          setActiveTab(value || '');
          props.onTabChange && props.onTabChange(value);
        }}
      >
        <Tabs.List>
          {props.tabs.map((tab) => (
            <Tabs.Tab
              value={tab.name}
              key={`tab_${id}_${tab.name}`}
              icon={tab.icon ? cloneElement(tab.icon, { size: 18 }) : null}
              sx={hasError(tab.name) ? (theme) => ({ color: theme.colors.red[7] + ' !important' }) : {}}
              color={hasError(tab.name) ? 'red' : undefined}
              rightSection={
                tab.badge ? (
                  <Badge sx={{ width: 16, height: 16, pointerEvents: 'none' }} variant="filled" size="xs" p={0}>
                    {tab.badge}
                  </Badge>
                ) : null
              }
            >
              {t(tab.label)}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {(Array.isArray(props.children) ? props.children : [props.children]).map((tabPanel) => (
          <Tabs.Panel
            value={tabPanel.props.name}
            key={`tabPanel_${id}_${tabPanel.props.name}`}
            p="md"
            sx={(theme) => ({
              borderLeft: props.withBorders ? 'solid thin ' + theme.colors.gray[3] : '',
              borderRight: props.withBorders ? 'solid thin ' + theme.colors.gray[3] : '',
              borderBottom: props.withBorders ? 'solid thin ' + theme.colors.gray[3] : '',
              borderRadius: '0 0 4px 4px',
            })}
          >
            {tabPanel.props.children}
          </Tabs.Panel>
        ))}
      </Tabs>

      <Divider hidden={props.variant === 'outline'} color="gray.3" />
    </>
  );
}

export function GrFormTab(props: { name: string; children: any | any[] }) {
  return props.children;
}
