import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { PriceListItemResponse, PriceListResponse, PvcRollerBlindRequest } from '@gravity/shared/dto';
import {
  Group,
  Button,
  Divider,
  ScrollArea,
  Grid,
  Title,
  Text,
  Collapse,
  Input,
  ActionIcon,
  Modal,
  Image,
} from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle, IconZoomIn } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';

interface PvcRollerBlindProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: PvcRollerBlindRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function PvcRollerBlind(props: PvcRollerBlindProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const [opened, { open, close }] = useDisclosure(false);

  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  const prbPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];
  const prbAccessoriesPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.attributeDefinition?.variableName === 'arb_accessories')
      ?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }
  const productForm = useDtoForm([PvcRollerBlindRequest, PvcRollerBlindRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues:
      {
        ...(orderItem.productConfig as any),
        prb_quantity: props.quantity,
        prb_wideFlangeCaseEnding: false,
      } ?? undefined,
  });

  const { watch } = productForm;

  const itemsBySide = ['prb_upperOutlet'];
  const itemsByPiece = ['prb_split', 'prb_automation', 'prb_inlet', 'prb_spring', 'prb_coverButton'];
  const itemsByTypeAndSide = [
    'prb_typeLeftSide',
    'prb_typeRightSide',
    'prb_combinedRail',
    'prb_combinedRail',
    'prb_combinedRailRight',
  ];

  useEffect(() => {
    productForm.setValue('prb_caseColor', 'Fehér');
    productForm.setValue('prb_reedColor', 'Fehér');
  }, []);

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  function getCoverButtonQuantity() {
    const height = productForm.getValues('prb_height') ?? 0;
    const coverButtonByHeight = Math.ceil(height / 50) * 2;
    const coverButtonQuantity =
      watch('prb_withoutCoverButtons') || watch('prb_withoutAccessories')
        ? 0
        : watch('prb_split')
        ? (coverButtonByHeight ?? 0) * 2
        : coverButtonByHeight;

    productForm.setValue('prb_coverButtonQuantity', coverButtonQuantity);

    if (coverButtonQuantity === 0) {
      productForm.setValue('prb_coverButton', '');
    } else {
      const coverButtonCode =
        watch('prb_type') === 'Extra ALU műanyag' || watch('prb_type') === 'Bonus max'
          ? 'rt_032_csomag'
          : 'rt_036_csomag';
      const coverButton = prbAccessoriesPriceList.find(
        (priceListItem: PriceListItemResponse) => priceListItem.code === coverButtonCode
      )?.label;

      productForm.setValue('prb_coverButton', coverButton);
    }
  }

  //Calculated area values for pricing
  useEffect(() => {
    const width = productForm.getValues('prb_width') ?? 0;
    const height = productForm.getValues('prb_height') ?? 0;
    const rightSideWidth = productForm.getValues('prb_splitRightSideWidth') ?? 0;

    getCoverButtonQuantity();

    productForm.setValue('prb_areaLeftSide', ((width - rightSideWidth) * height) / 10000);
    productForm.setValue('prb_areaRightSide', (rightSideWidth * height) / 10000);
    productForm.setValue('prb_roundedAreaLeft', getRoundedArea(productForm.getValues('prb_areaLeftSide')));
    productForm.setValue('prb_roundedAreaRight', getRoundedArea(productForm.getValues('prb_areaRightSide')));

    console.log('Terület (bal)', watch('prb_areaLeftSide'));
    console.log('Terület (jobb)', watch('prb_areaRightSide'));
    console.log('Kerekített terület (bal)', watch('prb_roundedAreaLeft'));
    console.log('Kerekített terület (jobb)', watch('prb_roundedAreaRight'));

    getAccessoriesPrice();
  }, [watch('prb_width'), watch('prb_height'), watch('prb_splitRightSideWidth')]);

  //Set default values if split is unchecked
  useEffect(() => {
    if (!watch('prb_split')) {
      productForm.setValue('prb_splitRightSideWidth', 0);
      productForm.setValue('prb_combinedRailRight', false);
      productForm.setValue('prb_builtInMosquitoNetRight', false);
    }
  }, [watch('prb_split')]);

  //Built in mosquito net: true -> combined rail: true
  useEffect(() => {
    if (watch('prb_builtInMosquitoNet') || watch('prb_builtInMosquitoNetRight')) {
      productForm.setValue('prb_combinedRail', true);
      if (watch('prb_split')) {
        productForm.setValue('prb_combinedRailRight', true);
      }
    } else if (watch('prb_split')) {
      productForm.setValue('prb_combinedRailRight', watch('prb_combinedRail'));
    }
  }, [
    watch('prb_builtInMosquitoNet'),
    watch('prb_builtInMosquitoNetRight'),
    watch('prb_combinedRail'),
    watch('prb_split'),
  ]);

  useEffect(() => {
    if (watch('prb_operationType') !== 'Zsinór') {
      productForm.setValue('prb_wihtoutSpring', false);
    }
  }, [watch('prb_operationType')]);

  useEffect(() => {
    getTypePrice('prb_typeLeftSide');
  }, [watch('prb_caseColor'), watch('prb_builtInMosquitoNet'), watch('prb_type')]);

  useEffect(() => {
    if (watch('prb_split')) {
      getTypePrice('prb_typeRightSide');
      getCombinedRailPrice('prb_combinedRail');
      getCombinedRailPrice('prb_combinedRailRight');
    }
  }, [
    watch('prb_caseColor'),
    watch('prb_builtInMosquitoNet'),
    watch('prb_builtInMosquitoNetRight'),
    watch('prb_split'),
    watch('prb_combinedRail'),
    watch('prb_type'),
  ]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('prb_width'), watch('prb_height'), watch('prb_splitRightSideWidth')]);

  useEffect(() => {
    getCaseSizePrice();
  }, [watch('prb_caseSize'), watch('prb_combinedRail')]);

  // useEffect(() => {
  //   if (!watch('prb_plasterable')) {
  //     productForm.setValue('prb_wideFlangeCaseEnding', false);
  //     getPriceFor('prb_wideFlangeCaseEnding', 'mr_014');
  //   }
  // }, [watch('prb_plasterable')]);

  function calculatePrice() {
    let areaPrice = 0; // Prices that only depend on the entire area
    let leftTypePrice = 0; // Prices that depend on the type and split properties by area
    let rightTypePrice = 0; // Prices that depend on the type and split properties by area
    let piecePrice = 0; // Prices by piece for the entire aluminium roller blind
    let sidePrice = 0; // Prices by piece and by side

    Object.keys(productForm.getValues('priceList') as any).forEach((key) => {
      if (itemsBySide.includes(key)) {
        sidePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByPiece.includes(key)) {
        piecePrice += (productForm.getValues('priceList') as any)[`${key}`];
      } else if (itemsByTypeAndSide.includes(key)) {
        if (key.includes('Right')) {
          rightTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        } else {
          leftTypePrice += (productForm.getValues('priceList') as any)[`${key}`];
        }
      } else {
        areaPrice += (productForm.getValues('priceList') as any)[`${key}`];
      }
    });

    const leftArea = watch('prb_roundedAreaLeft');
    const rightArea = watch('prb_roundedAreaRight');
    const area = leftArea + rightArea;
    areaPrice = areaPrice * area;
    leftTypePrice = leftTypePrice * leftArea;
    rightTypePrice = rightTypePrice * rightArea;
    sidePrice = watch('prb_split') ? sidePrice * 2 : sidePrice;
    productForm.setValue('price', areaPrice + sidePrice + piecePrice + leftTypePrice + rightTypePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getCaseSizeSublist(): string[] {
    if (getConditionSublistsFor('prb_caseSize')[0] === 'Egyedi') {
      return getConditionSublistsFor('prb_caseSize');
    }

    const caseSizeList = getConditionSublistsFor('prb_caseSize');

    if (!watch('prb_plasterable')) {
      return caseSizeList.filter((caseSize) => caseSize.includes('45'));
    } else {
      return caseSizeList.filter((caseSize) => caseSize.includes('20'));
    }
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;

    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function getRoundedArea(area: number) {
    const halfRound = Math.ceil(area * 10) === Math.round(area * 10) ? 0 : 0.05;
    const roundedArea = Math.round(area * 10) / 10 + halfRound;
    if (roundedArea > 0 && roundedArea < 1.3) {
      return 1.3;
    }

    return Number(roundedArea.toFixed(2));
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function getTypePrice(prb_type: string) {
    let type = '';
    if (getConditionSublistsFor(`${prb_type}`).length === 1) {
      type = getConditionSublistsFor(`${prb_type}`)[0];
    }
    productForm.setValue(`${prb_type}`, type);
    const price =
      prbPriceList.find(
        (priceListItem: PriceListItemResponse) =>
          type.includes(priceListItem.label) &&
          (type.includes('kombi') ? priceListItem.code.includes('k') : priceListItem.code.includes('s'))
      )?.price ?? 0;

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ [`${prb_type}`]: price },
    });
  }

  function getCaseSizePrice() {
    const prb_caseSize =
      prbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'mr_011')?.price ?? 0;
    if (getCaseSizeSublist().sort()[0] !== watch('prb_caseSize')) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ prb_caseSize } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ prb_caseSize: 0 } });
    }
  }

  function getCombinedRailPrice(label: string) {
    const aluminium = ['Extra ALU műanyag', 'Bonus max'];
    const code = aluminium.includes(watch('prb_type')) ? 'mr_017' : 'mr_016';
    const price = prbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0;
    const isbuiltInMosquitoNet = label.includes('Right')
      ? watch('prb_builtInMosquitoNetRight')
      : watch('prb_builtInMosquitoNet');

    if (watch(label) && !isbuiltInMosquitoNet) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: 0 } });
    }
  }

  function getAccessoriesPrice() {
    const accessoriesList = getConditionSublistsFor('prb_accessories');
    let prb_automation = 0;
    let prb_inlet = 0;
    let prb_spring = 0;
    let prb_coverButton = 0;

    // Automata és bevezető felár és darabszám
    if (accessoriesList.length === 2) {
      productForm.setValue('prb_automation', accessoriesList[0]);
      productForm.setValue('prb_inlet', accessoriesList[1]);
      accessoriesList.forEach((accessory, index) => {
        const accessoryPrice =
          prbAccessoriesPriceList.find(
            (priceListItem: PriceListItemResponse) =>
              priceListItem.label.includes(accessory) &&
              !priceListItem.code.includes('db') &&
              !priceListItem.label.includes('(')
          )?.price ?? 0;
        if (index === 0) {
          prb_automation = watch('prb_split') ? accessoryPrice * 2 : accessoryPrice;
        } else {
          prb_inlet = watch('prb_split') ? accessoryPrice * 2 : accessoryPrice;
        }
      });
    } else {
      productForm.setValue('prb_automation', '');
      productForm.setValue('prb_inlet', '');
    }
    const accessoryQuantity =
      !watch('prb_operationType') || watch('prb_withoutAccessories') || watch('prb_withoutAutomation')
        ? 0
        : watch('prb_split')
        ? 2
        : 1;
    productForm.setValue('prb_automationQuantity', accessoryQuantity);
    productForm.setValue('prb_inletQuantity', accessoryQuantity);

    // Rugó felár és darabszám
    if (watch('prb_operationType') === 'Zsinór' && !watch('prb_wihtoutSpring') && !watch('prb_withoutAccessories')) {
      const spring = prbAccessoriesPriceList.find(
        (priceListItem: PriceListItemResponse) => priceListItem.code === 'rt_028_csomag'
      );
      const springPrice = spring?.price ?? 0;
      prb_spring = watch('prb_split') ? springPrice * 2 : springPrice;
      productForm.setValue('prb_spring', spring?.label);
    } else {
      productForm.setValue('prb_spring', '');
    }
    productForm.setValue(
      'prb_springQuantity',
      watch('prb_withoutAccessories') || watch('prb_operationType') !== 'Zsinór' || watch('prb_wihtoutSpring')
        ? 0
        : watch('prb_split')
        ? 2
        : 1
    );

    // Takarógomb/tükörgomb felár és adarabszám
    getCoverButtonQuantity();
    if (!watch('prb_withoutCoverButtons') && !watch('prb_withoutAccessories')) {
      const coverButtonCode =
        watch('prb_type') === 'Extra ALU műanyag' || watch('prb_type') === 'Bonus max'
          ? 'rt_032_csomag'
          : 'rt_036_csomag';
      const coverButtonPrice =
        prbAccessoriesPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === coverButtonCode)
          ?.price ?? 0;
      prb_coverButton = coverButtonPrice * (watch('prb_coverButtonQuantity') ?? 0);
    }

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ prb_automation, prb_inlet, prb_spring, prb_coverButton },
    });
  }

  function getPriceFor(label: string, code: string) {
    const price = prbPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code)?.price ?? 0;
    if (watch(label)) {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: price } });
    } else {
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ [`${label}`]: 0 } });
    }
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: PvcRollerBlindRequest) => {
        data.summary = !data.prb_split
          ? 'Szélesség: ' +
            data.prb_width.toLocaleString() +
            ' cm, Magasság: ' +
            data.prb_height.toLocaleString() +
            ' cm'
          : 'Bal oldal szélessége: ' +
            (data.prb_width - (data.prb_splitRightSideWidth ? data.prb_splitRightSideWidth : 0)).toLocaleString() +
            ' cm, Jobb oldal szélessége: ' +
            (data.prb_splitRightSideWidth ? data.prb_splitRightSideWidth.toLocaleString() : 0) +
            ' cm, Magasság: ' +
            data.prb_height.toLocaleString() +
            ' cm';
        data.summary += ', Típus: ' + data.prb_type;
        data.summary += ', Működtetés: ' + data.prb_operationType;
        data.summary += ', Kezelés: ' + data.prb_operationSide;

        const dash = ' - ';
        const combined = data.prb_builtInMosquitoNet || data.prb_builtInMosquitoNetRight;
        const withCombinedRail =
          !data.prb_builtInMosquitoNet && !data.prb_builtInMosquitoNetRight && data.prb_combinedRail;
        data.invoiceName =
          (data.prb_plasterable ? getLabelFor('prb_plasterable') + ' ' : '') + //Vakolható
          product['name'].split(' ')[0] + //Műanyag
          ' ' +
          (combined ? uppercaseFirstLetter(getLabelFor('prb_combinedRail').split(' ')[0]) + ' ' : '') + //Kombi
          uppercaseFirstLetter(product['name'].split(' ')[1]) + //Redőny
          (withCombinedRail ? ', ' + uppercaseFirstLetter(getLabelFor('prb_combinedRail')) + 'val ' : '') + //Kombi lefutóval
          dash +
          data.prb_reedColor + //Szín
          ' színben';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="prb_width" label={getLabelFor('prb_width')} min={0} precision={2} controls />

            {!orderReadonlyMode && getErrorMessageFor('prb_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={27} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('prb_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput
              name="prb_height"
              label={getLabelFor('prb_height')}
              max={330}
              min={0}
              precision={2}
              controls
            />

            {!orderReadonlyMode && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={25} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getDescriptionFor('prb_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>

          {!orderReadonlyMode && getErrorMessageFor('prb_height') && (
            <Grid.Col xs={4} mt="lg">
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('prb_height')}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          <Grid.Col>
            <GrSwitchInput
              name="prb_split"
              label={getLabelFor('prb_split')}
              description={getDescriptionFor('prb_split')}
              onChange={() => getPriceFor('prb_split', 'mr_012_db')}
            />
          </Grid.Col>

          <Grid.Col>
            <Title pb={0} order={5}>
              {t('entity.form.combined')}
            </Title>

            <Text fs="italic">{getDescriptionFor('prb_builtInMosquitoNet')}</Text>
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="prb_builtInMosquitoNet" label={getLabelFor('prb_builtInMosquitoNet')} />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput name="prb_combinedRail" label={getLabelFor('prb_combinedRail')} />
          </Grid.Col>
        </Grid>

        <Collapse in={!!watch('prb_split')}>
          <Divider m="lg" />

          <Grid m={10}>
            <Grid.Col xs={4}>
              <GrNumberInput
                name="prb_splitRightSideWidth"
                label={getLabelFor('prb_splitRightSideWidth')}
                min={10}
                max={watch('prb_width') - 10}
                precision={2}
                controls
              />
            </Grid.Col>
          </Grid>

          <Grid m={10}>
            <Grid.Col>
              <Title pb={0} order={5}>
                {t('entity.form.combinedRight')}
              </Title>

              <Text fs="italic">{getDescriptionFor('prb_builtInMosquitoNetRight')}</Text>
            </Grid.Col>

            <Grid.Col xs={4}>
              <GrSwitchInput name="prb_builtInMosquitoNetRight" label={getLabelFor('prb_builtInMosquitoNetRight')} />
            </Grid.Col>

            <Grid.Col xs={8}>
              <GrSwitchInput name="prb_combinedRailRight" label={getLabelFor('prb_combinedRailRight')} />

              {!orderReadonlyMode && (
                <Text c="dimmed" fz="xs">
                  {getDescriptionFor('prb_combinedRailRight')}
                </Text>
              )}
            </Grid.Col>
          </Grid>
        </Collapse>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="prb_type"
              label={getLabelFor('prb_type')}
              data={getConditionSublistsFor('prb_type')}
              description={getConditionDescriptionFor('prb_type')}
              disabled={getDisabledStateFor('prb_type')}
              onChange={getAccessoriesPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="prb_operationType"
              label={getLabelFor('prb_operationType')}
              data={getConditionSublistsFor('prb_operationType')}
              description={getConditionDescriptionFor('prb_operationType')}
              disabled={getDisabledStateFor('prb_operationType')}
              onEmptyState={() => warningNotification(getLabelFor('prb_operationType'))}
              onChange={getAccessoriesPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="prb_operationSide"
              label={getLabelFor('prb_operationSide')}
              data={getConditionSublistsFor('prb_operationSide')}
              description={getConditionDescriptionFor('prb_operationSide')}
              disabled={getDisabledStateFor('prb_operationSide')}
              onEmptyState={() => warningNotification(getLabelFor('prb_operationSide'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput
              name="prb_upperOutlet"
              label={getLabelFor('prb_upperOutlet')}
              description={getDescriptionFor('prb_upperOutlet')}
              onChange={() => getPriceFor('prb_upperOutlet', 'mr_015_db')}
            />
          </Grid.Col>

          {watch('prb_operationType') === 'Zsinór' && (
            <Grid.Col xs={3}>
              <GrSwitchInput
                name="prb_wihtoutSpring"
                label={getLabelFor('prb_wihtoutSpring')}
                onChange={getAccessoriesPrice}
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="prb_caseSize"
              label={getLabelFor('prb_caseSize')}
              data={getCaseSizeSublist()}
              description={getConditionDescriptionFor('prb_caseSize')}
              disabled={getDisabledStateFor('prb_caseSize')}
              onEmptyState={() => warningNotification(getLabelFor('prb_caseSize'))}
              onChange={getCaseSizePrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="prb_quantity" label={getLabelFor('prb_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={2}>
            <GrSelectInput
              name="prb_reedColor"
              label={getLabelFor('prb_reedColor')}
              data={getConditionSublistsFor('prb_reedColor')}
              description={getConditionDescriptionFor('prb_reedColor')}
              disabled={getDisabledStateFor('prb_reedColor')}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSelectInput
              name="prb_caseColor"
              label={getLabelFor('prb_caseColor')}
              data={getConditionSublistsFor('prb_caseColor')}
              description={getConditionDescriptionFor('prb_caseColor')}
              disabled={getDisabledStateFor('prb_caseColor')}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={3}>
            <GrSelectInput
              name="prb_internalFitmentColor"
              label={getLabelFor('prb_internalFitmentColor')}
              data={getConditionSublistsFor('prb_internalFitmentColor')}
              description={getConditionDescriptionFor('prb_internalFitmentColor')}
              disabled={getDisabledStateFor('prb_internalFitmentColor')}
              onChange={getAccessoriesPrice}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={'auto'}>
            <Grid m={10}>
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="prb_withoutAccessories"
                  label={getLabelFor('prb_withoutAccessories')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="prb_plasterable"
                  label={getLabelFor('prb_plasterable')}
                  onChange={() => getPriceFor('prb_plasterable', 'mr_013')}
                />
              </Grid.Col>

              {/* {watch('prb_plasterable') === true && (
                <Grid.Col xs={4}>
                  <GrSwitchInput
                    name="prb_wideFlangeCaseEnding"
                    label={getLabelFor('prb_wideFlangeCaseEnding')}
                    onChange={() => getPriceFor('prb_wideFlangeCaseEnding', 'mr_014')}
                  />
                </Grid.Col>
              )} */}
            </Grid>

            <Grid m={10}>
              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="prb_withoutAutomation"
                  label={getLabelFor('prb_withoutAutomation')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput name="prb_top10SlatUnperforated" label={getLabelFor('prb_top10SlatUnperforated')} />
              </Grid.Col>

              <Grid.Col xs={4}>
                <GrSwitchInput
                  name="prb_withoutCoverButtons"
                  label={getLabelFor('prb_withoutCoverButtons')}
                  onChange={getAccessoriesPrice}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={'content'}>
            <Modal.Root opened={opened} onClose={close} zIndex={1000}>
              <Modal.Overlay opacity={0.7} />

              <Modal.Content>
                <Modal.Header>
                  <Modal.CloseButton pos={'fixed'} right={10} top={10} />
                </Modal.Header>

                <Modal.Body>
                  <Image
                    mx="auto"
                    radius="md"
                    src={`/assets/images/${!watch('prb_plasterable') ? 'prb-nv' : 'prb-v'}.jpg`}
                  />
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>

            <Group pos={'relative'}>
              <div style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 10 }}>
                <ActionIcon onClick={open} variant="light">
                  <IconZoomIn />
                </ActionIcon>
              </div>

              <Image
                width={150}
                mx="auto"
                radius="md"
                src={`/assets/images/${!watch('prb_plasterable') ? 'prb-nv' : 'prb-v'}.jpg`}
                pr={20}
                onClick={open}
                sx={{ cursor: 'pointer' }}
              />
            </Group>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.prb.automation')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('prb_automation')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('prb_automationQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.prb.inlet')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('prb_inlet')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('prb_inletQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.prb.spring')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{`${productForm.getValues('prb_spring')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('prb_springQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs={2}>
            <Text>
              {watch('prb_type') === 'Extra ALU műanyag' || watch('prb_type') === 'Bonus max'
                ? t('entity.form.prb.coverButton')
                : t('entity.form.prb.mirrorButton')}
            </Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{`${productForm.getValues('prb_coverButton')}`}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('prb_coverButtonQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
