import { IsEmail, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';

export class PartnerContactCreateRequest {
  /**
   * The name of the partner contact
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The job position of the partner contact
   */
  @IsString()
  public jobPosition?: string;

  /**
   * The e-mail address of the partner contact
   */
  @IsEmail()
  @ValidateIf((o, e) => e !== '')
  public email?: string;

  /**
   * The phone number of the partner contact
   */
  @IsString()
  public phoneNumber?: string;

  /**
   * Internal notes of the partner contact
   */
  @IsString()
  public notes?: string;
}

export class PartnerContactUpdateRequest {
  /**
   * CUID identifier of the partner contact
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The name of the partner contact
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The job position of the partner contact
   */
  @IsString()
  public jobPosition?: string;

  /**
   * The e-mail address of the partner contact
   */
  @IsEmail()
  @ValidateIf((o, e) => e !== '')
  public email?: string;

  /**
   * The phone number of the partner contact
   */
  @IsString()
  public phoneNumber?: string;

  /**
   * Internal notes of the partner contact
   */
  @IsString()
  public notes?: string;
}
