import { Box, Button, Center, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconAlertTriangle, IconPlus } from '@tabler/icons-react';
import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { PageTitle } from '../../../components/PageTitle';
import { OrderStatus, Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { OrderResponse } from '@gravity/shared/dto';
import { CellContext } from '@tanstack/react-table';
import { usePartnerCustomer } from '../../../hooks/use-partner-customer.hook';
import { Link, useNavigate } from 'react-router-dom';

function orderStatusShow(ctx: CellContext<OrderResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      {ctx.getValue() === 'beforeSubmission' && (
        <IconAlertTriangle size={'1rem'} color="orange" style={{ marginRight: 10 }} />
      )}

      <>{t('common.orderStatus.' + ctx.getValue())}</>
    </Center>
  );
}

export function OrdersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const query = useTableQuery<OrderResponse>('orders');

  const partners = usePartnerCustomer();

  const cols: EntityTableColumnDef<OrderResponse>[] = [
    { accessorKey: 'orderId' },
    { accessorKey: 'orderNumber' },
    {
      accessorKey: 'partner[].name',
      type: EntityTableColumnType.Object,
      filterOptions: partners?.map((partner) => partner.name),
    },
    {
      accessorKey: 'deliveryAddress',
    },
    { accessorKey: 'orderDate', type: EntityTableColumnType.Date },
    { accessorKey: 'deadlineDate', type: EntityTableColumnType.Date },
    {
      accessorKey: 'status',
      type: EntityTableColumnType.Enum,
      filterOptions: Object.keys(OrderStatus).map((status) => ({
        value: status,
        label: t(`common.orderStatus.${status}`),
      })),
      cell: (row) => orderStatusShow(row),
    },
    { accessorKey: 'billed', type: EntityTableColumnType.Boolean },
    { accessorKey: 'price', type: EntityTableColumnType.Price },
  ];

  async function openBillableItems(ids: string[]) {
    const formatIds = ids.map((id) => `ids[]=${id}`);
    const query = formatIds.toString().replaceAll(',', '&');
    navigate(`billable-items?${query}`);
  }

  return (
    <Box p="lg">
      <PageTitle title={t('sales.orders.title')} subtitle={t('sales.orders.subtitle')}>
        <PermissionCheck hasAll={[Permission.order_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 90px)' }}>
        <PermissionCheck hasAll={[Permission.order_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="order"
              tableQuery={query}
              columns={cols}
              selectColumn
              onSettingsChange={query.handleTableChange}
              initialState={{
                filters: {
                  status: {
                    column: 'status',
                    value: 'submitted; draft; awaitingConfirmation; confirmed; manufacturing; finished; delivered',
                    operation: 'in',
                  },
                },
              }}
              onItemsSelect={openBillableItems}
              selectButtonName={t('common.button.billableItems')}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Box>
  );
}
