import { IsNotEmpty, IsString } from 'class-validator';
import { IsSameAs } from '../decorators/is-same-as.decorator';

export class ResetPasswordRequest {
  /**
   * The plain password of the user
   */
  @IsString()
  @IsNotEmpty()
  public password: string;

  /**
   * The repeated plain password of the user
   */
  @IsString()
  @IsSameAs('password')
  @IsNotEmpty()
  public passwordRepeat: string;

  /**
   * The password reset token from the email sent to the user.
   */
  @IsString()
  @IsNotEmpty()
  public token: string;
}
