import { Popover, Button, Text, Group, Stack } from '@mantine/core';
import { IconTrash, IconAlertTriangle, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmButtonProps {
  loading?: boolean;
  onDelete: () => void;
}

export function DeleteConfirmButton({ loading, onDelete }: DeleteConfirmButtonProps) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <Popover opened={opened} width={320} withArrow position="top" shadow="md">
      <Popover.Target>
        <Button
          variant="light"
          onClick={() => setOpened(true)}
          color="red"
          loading={loading}
          type="button"
          leftIcon={<IconTrash />}
        >
          {t('common.button.delete')}
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack align="center">
          <IconAlertTriangle size={40} color="red" />

          <Text align="center" size="sm" sx={{ whiteSpace: 'pre-line' }}>
            {t('common.message.deleteConfirmation')}
          </Text>
        </Stack>

        <Group position="apart" mt="md">
          <Button
            loading={loading}
            color="gray.5"
            type="button"
            variant="filled"
            onClick={() => setOpened(false)}
            leftIcon={<IconX />}
          >
            {t('common.button.cancel')}
          </Button>

          <Button
            loading={loading}
            color="red"
            type="button"
            variant="filled"
            onClick={() => onDelete()}
            leftIcon={<IconTrash />}
          >
            {t('common.button.delete')}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
