import { Box, Button, Center, ScrollArea, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconLock, IconPlus } from '@tabler/icons-react';
import { ProductAttributeDefinitionResponse } from '@gravity/shared/dto';
import { EntityTable, EntityTableColumnDef, EntityTableColumnType } from '@gravity/frontend/ui';
import { useTableQuery } from '../../../hooks/use-table-query.hook';
import { PageTitle } from '../../../components/PageTitle';
import { Permission, ProductAttributeType } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { CellContext } from '@tanstack/react-table';
import { useSelectValues } from '../../../hooks/use-select-values.hook';
import { Link } from 'react-router-dom';
import { usePermission } from '../../../hooks/use-permission.hook';

function generateAttributeType(ctx: CellContext<ProductAttributeDefinitionResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      <>{t(`common.productAttributeType.${ctx.getValue()}`)}</>
    </Center>
  );
}

function generateName(ctx: CellContext<ProductAttributeDefinitionResponse, unknown>) {
  const { t } = useTranslation();

  return (
    <Center inline>
      <>
        {ctx.row.original.internal && (
          <Tooltip label={t('entity.productAttributeDefinitions.internal')} position="right">
            {ctx.row.original.internal && (
              <Box mr={4} sx={{ position: 'relative', top: 2 }}>
                <IconLock color="grey" size={20} />
              </Box>
            )}
          </Tooltip>
        )}

        {ctx.getValue()}
      </>
    </Center>
  );
}

export function ProductAttributeDefinitionsPage() {
  const { t } = useTranslation();
  const query = useTableQuery<ProductAttributeDefinitionResponse>('product-attribute-definitions');
  const { hasAnyPermission } = usePermission();

  const selectValues = hasAnyPermission(['product_read', 'product_create', 'product_update', 'product_delete'])
    ? useSelectValues()
    : [];

  const cols: EntityTableColumnDef<ProductAttributeDefinitionResponse>[] = [
    { accessorKey: 'name', cell: (row) => generateName(row) },
    { accessorKey: 'variableName' },
    {
      accessorKey: 'type',
      type: EntityTableColumnType.Enum,
      cell: (row) => generateAttributeType(row),
      filterOptions: Object.keys(ProductAttributeType).map((attributeType) => ({
        value: attributeType,
        label: t(`common.productAttributeType.${attributeType}`),
      })),
    },
    {
      accessorKey: 'selectValues',
      type: EntityTableColumnType.SimpleArray,
      enableSorting: false,
      filterOptions: selectValues,
    },
  ];

  return (
    <Box p="md">
      <PageTitle
        title={t('sales.productAttributeDefinitions.title')}
        subtitle={t('sales.productAttributeDefinitions.subtitle')}
      >
        <PermissionCheck hasAll={[Permission.product_create]}>
          <Link to="create">
            <Button leftIcon={<IconPlus />} variant="light">
              {t('common.button.add')}
            </Button>
          </Link>
        </PermissionCheck>
      </PageTitle>

      <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 90px)' }}>
        <PermissionCheck hasAll={[Permission.product_read]}>
          <Box pt="md">
            <EntityTable
              resourceName="productAttributeDefinitions"
              tableQuery={query}
              columns={cols}
              onSettingsChange={query.handleTableChange}
            />
          </Box>
        </PermissionCheck>
      </ScrollArea>
    </Box>
  );
}
