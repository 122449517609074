import {
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Box,
  Image,
  Alert,
  Collapse,
  BackgroundImage,
  Center,
  Grid,
  ScrollArea,
} from '@mantine/core';
import { IconAlertCircle, IconAt, IconKey } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { LoginRequest } from '@gravity/shared/dto';
import { useTranslation } from 'react-i18next';
import { GrForm, GrPasswordInput, GrTextInput, useActionError, useDtoForm } from '@gravity/frontend/ui';
import { useAppConfig } from '../../../hooks/use-app-config.hook';
import { hungarianArticleFormat } from '@gravity/shared/utils';

export function Login() {
  const { t } = useTranslation();
  const { error, clearError } = useActionError();
  const config = useAppConfig();

  const loginForm = useDtoForm(LoginRequest, 'entity.user');

  return (
    <Box>
      <BackgroundImage
        src={config ? config?.background : ''}
        sx={{ height: '100vh', opacity: 0.2, filter: 'blur(5px)' }}
        radius="xs"
      ></BackgroundImage>

      <Container size={420} sx={{ position: 'absolute', top: 0, left: 'calc(50vw - 210px)' }}>
        <ScrollArea type="scroll" h="100vh" pb="sm">
          <Box
            sx={{
              height: 150,
              maxWidth: 300,
              backgroundImage: `url(${config?.logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
            mb={{ base: 10, sm: 25 }}
            mt={{ base: 20, sm: 120 }}
            mx="auto"
          ></Box>

          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 'bold',
              fontSize: 20,
              marginBottom: 10,
            })}
          >
            {t('component.logIn.welcome', {
              company: config?.company,
              article: hungarianArticleFormat(config?.company),
            })}
          </Title>

          <Text size="sm" align="center" mt={5}>
            {t('component.logIn.logInMessage')}
          </Text>

          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <GrForm form={loginForm}>
              <Grid>
                <Grid.Col>
                  <GrTextInput name="email" autocomplete="email" icon={<IconAt />} />
                </Grid.Col>

                <Grid.Col>
                  <GrPasswordInput name="password" autocomplete="password" icon={<IconKey />} />
                </Grid.Col>
              </Grid>

              <Collapse in={!!error}>
                <Alert
                  icon={<IconAlertCircle size={16} />}
                  title={t('common.error.errorOccured')}
                  withCloseButton
                  onClose={clearError}
                  color="red"
                  mt="md"
                >
                  {error && error.message && t(error.message)}
                </Alert>
              </Collapse>

              <Button loading={loginForm.isSubmitting} type="submit" fullWidth mt="xl">
                {t('component.logIn.signIn')}
              </Button>

              <Group position="center" mt="md">
                <Anchor component={Link} to="/request-password-reset" size="sm">
                  {t('component.logIn.forgotPassword')}
                </Anchor>
              </Group>
            </GrForm>
          </Paper>
        </ScrollArea>
      </Container>
    </Box>
  );
}
