import { Box, Button } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const deleted = { color: 'red', label: '-' };
const added = { color: 'green', label: '+' };

export function EntityTableJsonDiffRenderer(props: { value?: string; minWidth?: number }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const hightlightedLines: Record<number, { color: string; label: string }> = {};
  let jsonString = '';

  if (props.value) {
    jsonString = props.value
      .split('\n')
      .map((line, lineIndex) => {
        if (line.startsWith('+')) {
          hightlightedLines[lineIndex + 1] = added;
          return line.replace('+', '');
        }
        if (line.startsWith('-')) {
          hightlightedLines[lineIndex + 1] = deleted;
          return line.replace('-', '');
        }

        return line;
      })
      .join('\n');
  }

  return (
    <Box sx={{ minWidth: props.minWidth }}>
      {props.value && (
        <>
          <Button size="xs" variant="subtle" onClick={() => setOpened((o) => !o)}>
            {t('entityTable.prism.showChanges')}
          </Button>

          {opened && (
            <Prism
              language="json"
              withLineNumbers
              styles={{ code: { fontSize: 10 } }}
              copyLabel={t('entityTable.prism.copyCode')}
              copiedLabel={t('entityTable.prism.codeCopied')}
              highlightLines={hightlightedLines}
            >
              {jsonString}
            </Prism>
          )} 
        </>
      )}

      {!props.value && '-'}
    </Box>
  );
}
