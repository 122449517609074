import { Permission } from '@prisma/client';
import { ArrayNotEmpty, IsNotEmpty, IsString } from 'class-validator';

export class UserRoleCreateRequest {
  /**
   * The name of the role
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * Optional description of the role
   */
  @IsString()
  public description?: string;

  /**
   * The provided permissions strings for the role
   */
  @IsString({ each: true })
  @ArrayNotEmpty()
  public permissions: Permission[];
}

export class UserRoleUpdateRequest {
  /**
   * The ID of the role
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The name of the role
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * Optional description of the role
   */
  @IsString()
  public description?: string;

  /**
   * The provided permissions strings for the role
   */
  @IsString({ each: true })
  @ArrayNotEmpty()
  public permissions?: Permission[];
}
