import { Box, Input, NumberInput, Text } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MoneyFormat } from '../components/MoneyFormat/MoneyFormat';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrNumberInputProps extends BaseInputProps {
  currency?: string;
  isPrice?: boolean;
  max?: number;
  min?: number;
  controls?: boolean;
  readonly?: boolean;
  precision?: number;
}

export function GrNumberInput({ name, currency, isPrice, precision, ...props }: GrNumberInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName) || props.readonly) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Text>
            {form.getValues(name) ? (
              isPrice ? (
                <MoneyFormat>{form.getValues(name)}</MoneyFormat>
              ) : (
                form.getValues(name)
              )
            ) : (
              '-'
            )}
          </Text>
        </Input.Wrapper>
      </Box>
    );
  }

  const currencySection = () => {
    return currency || '€'; //until currency is not thinked over
  };

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => (
        <NumberInput
          {...field}
          {...props.forwardedProps}
          label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
          error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
          description={props.description}
          disabled={formState.isSubmitting}
          precision={isPrice ? 2 : precision ?? undefined}
          decimalSeparator={','}
          required={form.requiredFields.includes(labelName)}
          max={props.max}
          min={props.min}
          value={form.getValues(name) !== 0.0 ? form.getValues(name) : ''}
          autoComplete="0"
          hideControls={!props.controls}
          rightSection={isPrice ? currencySection() : null}
        />
      )}
    />
  );
}
