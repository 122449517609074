import {
  GrForm,
  GrNumberInput,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  MoneyFormat,
  useDtoForm,
} from '@gravity/frontend/ui';
import { PriceListItemResponse, PriceListResponse, PvcLedgeRequest } from '@gravity/shared/dto';
import { Group, Button, Divider, ScrollArea, Grid, Title, Text, Input } from '@mantine/core';
import { OrderItem, Product } from '@prisma/client';
import { IconX, IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDrawerView } from '../../../stores/drawer-view.store';
import { showNotification } from '@mantine/notifications';
import jsonLogic from 'json-logic-js';
import { useEffect } from 'react';

interface PvcLedgeProps {
  orderItem: OrderItem & { product: Product };
  onSave: (data: PvcLedgeRequest) => void;
  onClose: () => void;
  orderReadonlyMode: boolean;
  quantity: number;
  priceLists: PriceListResponse[];
}

export function PvcLedge(props: PvcLedgeProps) {
  const { t } = useTranslation();
  const { closeDrawerView } = useDrawerView();
  const orderItem = props.orderItem;
  const product: any = orderItem.product;
  const orderReadonlyMode = props.orderReadonlyMode;

  const plPriceList: PriceListItemResponse[] =
    props.priceLists?.find((priceList) => priceList.product?.name === product['name'])?.priceListItems ?? [];

  if (!product) {
    closeDrawerView();
    console.error('Error during configuration form render', orderItem);
    return <></>;
  }

  const productForm = useDtoForm([PvcLedgeRequest, PvcLedgeRequest], 'entity.form', {
    isEditForm: true,
    readonlyMode: orderReadonlyMode,
    defaultValues: { ...(orderItem.productConfig as any), pl_quantity: props.quantity } ?? undefined,
  });

  const { watch } = productForm;

  useEffect(() => {
    console.log('render');
  }, [watch()]);

  //Calculated values for pricing

  useEffect(() => {
    productForm.setValue(
      'pl_roundedWidth',
      Number((Math.ceil(productForm.getValues('pl_width') / 10) / 10).toFixed(1))
    );
    console.log('Kerekített szélesség', watch('pl_roundedWidth'));
  }, [watch('pl_width')]);

  useEffect(() => {
    getColorPrice();
  }, [watch('pl_color'), watch('pl_depth')]);

  useEffect(() => {
    if (watch('pl_endCloser')) {
      setEndCloser();
    } else {
      productForm.setValue('pl_endCloserQuantity', 0);
      productForm.setValue('pl_endCloserName', '');
      productForm.setValue('priceList', { ...(productForm.getValues('priceList') as any), ...{ pl_endCloser: 0 } });
    }
  }, [watch('pl_endCloser'), watch('pl_color'), watch('pl_depth'), watch('pl_color')]);

  useEffect(() => {
    calculatePrice();
  }, [watch('priceList'), watch('pl_width'), watch('pl_depth')]);

  function calculatePrice() {
    let fmPrice = 0; // Prices that only depend on the entire area
    let piecePrice = 0; // Prices by piece for the entire folding door

    const width = watch('pl_roundedWidth');
    fmPrice += (productForm.getValues('priceList') as any)['pl_color'];
    piecePrice += (productForm.getValues('priceList') as any)['pl_endCloser'];
    piecePrice *= watch('pl_endCloserQuantity');
    productForm.setValue('price', fmPrice * width + piecePrice);

    console.log(watch('priceList'));
  }

  function getProductAttribute(name: string) {
    return product.productAttributes.find((pa) => pa.attributeDefinition?.variableName === name);
  }

  function getConditionDescriptionFor(name: string): string | undefined {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
          return c.errorMessage;
        }
      }
    }

    return;
  }

  function getConditionSublistsFor(name: string): string[] {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'subselect') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.conditionalSelectValues;
          }
        }
      }
    }

    return productAttribute.attributeDefinition?.selectValues;
  }

  function getLabelFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.attributeDefinition.name;
  }

  function getDescriptionFor(name: string) {
    const productAttribute = getProductAttribute(name);
    return productAttribute?.description;
  }

  function getDisabledStateFor(name: string) {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'enable') {
          return !jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        } else if (c.condition && c.conditionType === 'disable') {
          return jsonLogic.apply(JSON.parse(c.condition), productForm.getValues());
        }
      }
    }

    return false;
  }

  function getErrorMessageFor(name: string): string {
    const productAttribute = getProductAttribute(name);
    const conditions = productAttribute.conditions;
    if (conditions) {
      for (const c of conditions) {
        if (c.condition && c.conditionType === 'show') {
          if (jsonLogic.apply(JSON.parse(c.condition), productForm.getValues())) {
            return c.errorMessage;
          }
        }
      }
    }
    return '';
  }

  function warningNotification(attribute: string) {
    showNotification({ color: 'orange', message: t('entity.form.notificationMessage', { attribute }) });
  }

  function setEndCloser() {
    let code = '';
    let depth = 0;
    switch (watch('pl_color')) {
      case 'Dió':
        code = 'pvcp_0361';
        depth = 25;
        break;
      case 'Aranytölgy':
        code = 'pvcp_0360';
        depth = 25;
        break;
      default:
        code = 'pvcp_035';
        depth = 30;
        break;
    }
    const endCloser = plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === code);
    const pl_endCloser = endCloser?.price ?? 0;
    productForm.setValue('pl_endCloserName', endCloser?.label ?? '');

    if (Number(watch('pl_depth')) <= depth) {
      productForm.setValue('pl_endCloserQuantity', 1);
    } else {
      productForm.setValue('pl_endCloserQuantity', 2);
    }

    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ pl_endCloser },
    });
  }

  function getColorPrice() {
    let price = 0;
    switch (watch('pl_depth')) {
      case '10':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_004_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_002_fm')?.price ??
              0;
            break;
        }
        break;
      case '15':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_008_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_006_fm')?.price ??
              0;
            break;
        }
        break;
      case '20':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_012_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_010_fm')?.price ??
              0;
            break;
        }
        break;
      case '25':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_016_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_014_fm')?.price ??
              0;
            break;
        }
        break;
      case '30':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_020_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_018_fm')?.price ??
              0;
            break;
        }
        break;
      case '35':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_024_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_022_fm')?.price ??
              0;
            break;
        }
        break;
      case '40':
        switch (watch('pl_color')) {
          case 'Aranytölgy':
          case 'Dió':
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_028_fm')?.price ??
              0;
            break;
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_026_fm')?.price ??
              0;
            break;
        }
        break;
      case '45':
        switch (watch('pl_color')) {
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_030_fm')?.price ??
              0;
            break;
        }
        break;
      case '50':
        switch (watch('pl_color')) {
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_032_fm')?.price ??
              0;
            break;
        }
        break;
      case '60':
        switch (watch('pl_color')) {
          default:
            price =
              plPriceList.find((priceListItem: PriceListItemResponse) => priceListItem.code === 'pvcp_034_fm')?.price ??
              0;
            break;
        }
        break;
    }
    productForm.setValue('priceList', {
      ...(productForm.getValues('priceList') as any),
      ...{ ['pl_color']: price },
    });
  }
  console.log(productForm.getValues());

  return (
    <GrForm
      form={productForm}
      onSubmit={async (data: PvcLedgeRequest) => {
        data.summary = 'Szélesség: ' + data.pl_width.toLocaleString() + ' cm, Mélység: ' + data.pl_depth + ' cm';
        props.onSave(data);
        closeDrawerView();
      }}
    >
      <Group py="xs" px="xl" position="apart">
        <Group>
          <Title pb={0} order={3}>
            {product.name}
          </Title>
        </Group>

        <Grid>
          <Input.Wrapper label={t('entity.order.orderItems.price') + ':'}>
            <Text>
              <MoneyFormat>{productForm.getValues('price')}</MoneyFormat>
            </Text>
          </Input.Wrapper>
        </Grid>

        <Group>
          {!productForm.readonlyMode && (
            <Button loading={productForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
              {t('common.button.save')}
            </Button>
          )}

          <Button
            loading={productForm.isSubmitting}
            type="button"
            color="gray"
            variant="light"
            leftIcon={<IconX />}
            onClick={() => {
              props.onClose();
              closeDrawerView();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </Group>
      </Group>

      <Divider />

      <ScrollArea type="always" style={{ height: 'calc(100vh - 90px)' }} p="xl" offsetScrollbars>
        <Title pb={0} order={3}>
          {t('sales.ordersForm.mainConfigTitle', { product: product.name })}
        </Title>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="pl_depth"
              label={getLabelFor('pl_depth')}
              data={getConditionSublistsFor('pl_depth')}
              description={getConditionDescriptionFor('pl_depth')}
              disabled={getDisabledStateFor('pl_depth')}
              onEmptyState={() => warningNotification(getLabelFor('pl_depth'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrNumberInput name="pl_width" label={getLabelFor('pl_width')} min={0} max={600} precision={2} controls />

            {getErrorMessageFor('pl_width') && (
              <Grid style={{ color: 'dodgerblue' }}>
                <Grid.Col xs={1}>
                  <IconAlertCircle size={28} style={{ marginRight: '5px' }} />
                </Grid.Col>

                <Grid.Col xs={11}>
                  <Text fz="xs" ta="center">
                    {getErrorMessageFor('pl_width')}
                  </Text>
                </Grid.Col>
              </Grid>
            )}
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrSelectInput
              name="pl_color"
              label={getLabelFor('pl_color')}
              data={getConditionSublistsFor('pl_color')}
              description={getConditionDescriptionFor('pl_color')}
              disabled={getDisabledStateFor('pl_color')}
              onEmptyState={() => warningNotification(getLabelFor('pl_color'))}
              emptyOnStateChange
              simpleValue
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <GrSwitchInput
              name="pl_endCloser"
              label={getLabelFor('pl_endCloser')}
              description={getDescriptionFor('pl_endCloser')}
            />
          </Grid.Col>
        </Grid>

        <Grid m={10}>
          <Grid.Col xs={4}>
            <GrNumberInput name="pl_quantity" label={getLabelFor('pl_quantity')} min={1} max={100} controls />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={0} order={3}>
          {t('sales.ordersForm.generalConfigTitle', { product: product.name })}
        </Title>

        <Grid>
          <Grid.Col>
            <GrTextareaInput name="notes" />
          </Grid.Col>
        </Grid>

        <Divider m="lg" />

        <Title pb={20} order={3}>
          {t('entity.form.accessories')}
        </Title>

        <Grid>
          <Grid.Col xs={2}>
            <Text>{t('entity.form.pl.endCloser')}</Text>
          </Grid.Col>

          <Grid.Col xs={6}>
            <Text>{productForm.getValues('pl_endCloserName')}</Text>
          </Grid.Col>

          <Grid.Col xs={3}>
            <Text>{`${productForm.getValues('pl_endCloserQuantity')} db`}</Text>
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </GrForm>
  );
}
