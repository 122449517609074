import { useQuery } from '@tanstack/react-query';
import { getOne } from '../plugins/axios';
import { useAuth } from '../stores/auth.store';
import { UserResponse } from '@gravity/shared/dto';

export function useCurrentUser() {
  const results = useQuery(['users', 'me'], getOne<UserResponse>('users', 'me'), {
    staleTime: Infinity,
  });
  const { token } = useAuth();

  return {
    ...results,
    token,
    user: results.data,
  };
}
