import { GrForm, GrTextInput, useDtoForm } from '@gravity/frontend/ui';
import { RequestPasswordResetRequest } from '@gravity/shared/dto';
import {
  Box,
  Button,
  Container,
  Paper,
  Title,
  Text,
  Group,
  Anchor,
  Collapse,
  Alert,
  BackgroundImage,
  Center,
  Image,
  ScrollArea,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../plugins/axios';
import { useAppConfig } from '../../../hooks/use-app-config.hook';

export function RequestPasswordReset() {
  const { t } = useTranslation();
  const config = useAppConfig();
  const emailForm = useDtoForm(RequestPasswordResetRequest, 'entity.user');
  const [emailError, setEmailError] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  const [successVisible, setSuccessVisible] = useState(false);

  async function handleSubmit(formData: RequestPasswordResetRequest) {
    return axiosInstance.post('users/request-password-reset', formData).then((result) => {
      if (result instanceof AxiosError) {
        setEmailError(result.response?.data.message);
        setErrorVisible(true);
      } else {
        setEmailError('');
        setErrorVisible(false);
        setSuccessVisible(true);
      }
    });
  }

  return (
    <Box>
      <BackgroundImage
        src={config ? config?.background : ''}
        sx={{ height: '100vh', opacity: 0.2, filter: 'blur(5px)' }}
        radius="xs"
      ></BackgroundImage>

      <Container size={420} sx={{ position: 'absolute', top: 0, left: 'calc(50vw - 210px)' }}>
        <ScrollArea type="scroll" h="100vh" pb="sm">
          <Box
            sx={{
              height: 150,
              maxWidth: 300,
              backgroundImage: `url(${config?.logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
            mb={{ base: 10, sm: 25 }}
            mt={{ base: 20, sm: 120 }}
            mx="auto"
          ></Box>

          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 'bold',
              fontSize: 20,
              marginBottom: 10,
            })}
          >
            {t('component.requestPasswordReset.resetPasswordTitle')}
          </Title>

          <Text size="sm" align="center" mt={5}>
            {t('component.requestPasswordReset.requestPasswordReset')}
          </Text>

          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            {!successVisible && (
              <GrForm form={emailForm} onSubmit={handleSubmit}>
                <GrTextInput form={emailForm} name="email" />

                <Collapse in={errorVisible}>
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    title={t('component.requestPasswordReset.emailError')}
                    color="red"
                    withCloseButton
                    onClose={() => setErrorVisible(false)}
                    mt="md"
                  >
                    {t(emailError)}
                  </Alert>
                </Collapse>

                <Button loading={emailForm.isSubmitting} type="submit" fullWidth mt="xl">
                  {t('component.requestPasswordReset.sendPasswordResetEmail')}
                </Button>
              </GrForm>
            )}

            {successVisible && (
              <Alert color="green" title={t('component.requestPasswordReset.successTitle')}>
                {t('component.requestPasswordReset.successMessage')}
              </Alert>
            )}

            <Group position="center" mt="md">
              <Anchor component={Link} to="/login" size="sm">
                {t('component.logIn.signIn')}
              </Anchor>
            </Group>
          </Paper>
        </ScrollArea>
      </Container>
    </Box>
  );
}
