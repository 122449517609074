import { ConditionType } from '@prisma/client';
import { IsArray, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class ProductAttributeConditionCreateRequest {
  /**
   * The condition of the product attribute condition
   */
  @IsString()
  @IsNotEmpty()
  public condition: string;

  /**
   * The type of the product attribute condition
   */
  @IsString()
  @IsNotEmpty()
  public conditionType: ConditionType;

  /**
   * The conditional select values of the product attribute condition
   */
  @IsArray()
  @IsOptional()
  public conditionalSelectValues?: string[];

  /**
   * The error message of the product attribute condition
   */
  @IsString()
  @IsOptional()
  public errorMessage?: string;
}

export class ProductAttributeConditionUpdateRequest {
  /**
   * CUID identifier of the product attribute condition
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The condition of the product attribute condition
   */
  @IsString()
  @IsNotEmpty()
  public condition: string;

  /**
   * The type of the product attribute condition
   */
  @IsString()
  @IsNotEmpty()
  public conditionType: ConditionType;

  /**
   * The conditional select values of the product attribute condition
   */
  @IsArray()
  @IsOptional()
  public conditionalSelectValues?: string[];

  /**
   * The error message of the product attribute condition
   */
  @IsString()
  @IsOptional()
  public errorMessage?: string;
}
