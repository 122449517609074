import { Flex } from '@mantine/core';
import { Permission } from '@prisma/client';
import { IconBrandCodesandbox, IconCategory2, IconListCheck, IconPackage, IconBasket } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { LocalNavDivider } from '../../../components/LocalNav/components/LocalNavDivider';
import { LocalNavLink } from '../../../components/LocalNav/components/LocalNavLink';
import { LocalNavTitle } from '../../../components/LocalNav/components/LocalNavTitle';
import { LocalNav } from '../../../components/LocalNav/LocalNav';
import { usePermission } from '../../../hooks/use-permission.hook';
import { ContentLayout } from '../../../layouts/ContentLayout';

export function SalesLayout() {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();

  return (
    <Flex>
      <LocalNav>
        <LocalNavTitle>{t('sales.menu.title')}</LocalNavTitle>

        <LocalNavDivider label={t('sales.menu.orderManagement')} />

        <LocalNavLink
          to="/sales/orders"
          icon={IconBasket}
          label={t('sales.menu.orders')}
          disabled={
            !hasAnyPermission([
              Permission.order_read,
              Permission.order_create,
              Permission.order_update,
              Permission.order_delete,
            ])
          }
        />

        <LocalNavDivider label={t('sales.menu.productManagement')} />

        <LocalNavLink
          to="/sales/product-categories"
          icon={IconCategory2}
          label={t('sales.menu.productCategories')}
          disabled={
            !hasAnyPermission([
              Permission.product_read,
              Permission.product_create,
              Permission.product_update,
              Permission.product_delete,
            ])
          }
        />

        <LocalNavLink
          to="/sales/products"
          icon={IconPackage}
          label={t('sales.menu.products')}
          disabled={
            !hasAnyPermission([
              Permission.product_read,
              Permission.product_create,
              Permission.product_update,
              Permission.product_delete,
            ])
          }
        />

        <LocalNavLink
          to="/sales/product-attribute-definitions"
          icon={IconListCheck}
          label={t('sales.menu.productAttributeDefinitions')}
          disabled={
            !hasAnyPermission([
              Permission.product_read,
              Permission.product_create,
              Permission.product_update,
              Permission.product_delete,
            ])
          }
        />

        {/* <LocalNavLink
          to="/sales/bills-of-materials"
          icon={IconBrandCodesandbox}
          label={t('sales.menu.billsOfMaterials')}
          disabled={
            !hasAnyPermission([
              Permission.product_read,
              Permission.product_create,
              Permission.product_update,
              Permission.product_delete,
            ])
          }
        /> */}
      </LocalNav>

      <ContentLayout localNav>
        <Outlet />
      </ContentLayout>
    </Flex>
  );
}
