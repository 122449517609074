import { Box, Center, Input, Loader, Text, TextInput, createStyles } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';
import { If, Then, Else } from 'react-if';
import InputMask from 'react-input-mask';

export interface GrTextInputProps extends BaseInputProps {
  link?: boolean;
  mask?: string;
  maskPlaceholder?: string;
  autocomplete?: string;
}

const useStyles = createStyles((theme) => ({
  input: {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
    height: '2.25rem',
    WebkitTapHighlightColor: 'transparent',
    lineHeight: 'calc(2.25rem - 0.125rem)',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    resize: 'none',
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    width: '100%',
    color: '#000',
    display: 'block',
    textAlign: 'left',
    border: '0.0625rem solid #ced4da',
    backgroundColor: '#fff',
    WebkitTransition: 'border-color 100ms ease',
    transition: 'border-color 100ms ease',
    minHeight: '2.25rem',
    paddingLeft: 'calc(2.25rem  / 3)',
    paddingRight: 'calc(2.25rem  / 3)',
    borderRadius: '0.25rem',
    '&:focus': {
      outline: 'none',
      borderColor: '#fd7e14',
    },
  },
}));

export function GrTextInput({ name, ...props }: GrTextInputProps) {
  const { classes } = useStyles();
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    return (
      <Box sx={{ wordWrap: 'break-word' }} mt={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <If condition={props.link && form.getValues(name)}>
            <Then>
              <Text>
                <a
                  href={
                    form.getValues(name)?.startsWith('http') ? form.getValues(name) : 'https://' + form.getValues(name)
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Center inline>
                    <IconLink size={18} style={{ marginRight: 2 }} />

                    {form.getValues(name) ? form.getValues(name) : '-'}
                  </Center>
                </a>
              </Text>
            </Then>

            <Else>
              <Text>{form.getValues(name) ? form.getValues(name) : '-'}</Text>
            </Else>
          </If>
        </Input.Wrapper>
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => {
        if (props.mask) {
          return (
            <Input.Wrapper
              {...props.forwardedProps}
              label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
              error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
              required={form.requiredFields.includes(labelName)}
              sx={{ wordWrap: 'break-word' }}
            >
              <InputMask
                type="text"
                disabled={formState.isSubmitting}
                mask={props.mask}
                maskPlaceholder={props.maskPlaceholder}
                className={classes.input}
                {...field}
              />
            </Input.Wrapper>
          );
        } else {
          return (
            <TextInput
              {...field}
              {...props.forwardedProps}
              disabled={formState.isSubmitting}
              label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
              error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
              description={props.description}
              required={form.requiredFields.includes(labelName)}
              icon={props.icon}
              autoComplete={props.autocomplete ?? 'new-password'}
              rightSection={props.loading && <Loader size="xs" color="dark" />}
              onBlur={(e) => {
                form.setValue(name, e.target.value.trim());
              }}
            />
          );
        }
      }}
    />
  );
}
