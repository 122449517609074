import { NewsPostResponse } from '@gravity/shared/dto';
import { Button, Center, createStyles, Group, Paper, Text, TypographyStylesProvider } from '@mantine/core';
import { IconFileAnalytics, IconFileText, IconNewsOff, IconPhoto } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useFiles } from '../../../hooks/use-files.hook';
import { useNewsPostsQuery } from '../../../hooks/use-news-posts.hook';
import { axiosInstance } from '../../../plugins/axios';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  comment: {
    padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    marginBottom: `${theme.spacing.lg} `,
    '& + &': { borderTop: `2px solid ${theme.colors.gray[2]}`, borderRadius: '0' },
  },
  body: {
    paddingTop: theme.spacing.sm,
    fontSize: theme.fontSizes.sm,
  },
  content: {
    '& > p:last-child': {
      marginBottom: 0,
    },
  },
}));

function News(post: NewsPostResponse) {
  const { classes } = useStyles();
  const uploadedFiles = useFiles(post.id);

  const handleClick = async (fileId) => {
    const res = await axiosInstance.get(`storage/${fileId}`, { responseType: 'blob' });
    const data = res.data;

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers['content-type'],
      })
    );

    const link = document.createElement('a');
    link.href = url;
    const filename = res.headers['content-disposition'].match(/filename="?([^"]+)"?/)[1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return (
    <Paper className={classes.comment}>
      <Group>
        <div>
          <Text size="sm" weight={700}>
            {post.title}
          </Text>

          <Text size="xs" color="dimmed">
            {new Date(post.date).toLocaleDateString()}
          </Text>
        </div>
      </Group>

      <TypographyStylesProvider className={classes.body}>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: post.body }} />
      </TypographyStylesProvider>

      <Group>
        {uploadedFiles?.map((file, index) => (
          <Center
            key={index + file.filename}
            inline
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
              fontSize: theme.fontSizes.xs,
              padding: '3px 7px',
              borderRadius: theme.radius.sm,
            })}
          >
            <>
              {(file.mimetype.startsWith('image') && <IconPhoto size={18} />) ||
                ((file.mimetype.startsWith('application/pdf') ||
                  file.mimetype.startsWith('application/msword') ||
                  file.mimetype.startsWith(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  )) && <IconFileText size={18} />) ||
                ((file.mimetype.startsWith('application/vnd.ms-excel') ||
                  file.mimetype.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) && (
                  <IconFileAnalytics size={18} />
                ))}

              <Button
                style={{
                  backgroundColor: 'inherit',
                  color: 'inherit',
                }}
                onClick={() => handleClick(file.id)}
              >
                {file.filename + '.' + file.extension}

                <br />

                {Math.ceil((file.fileSize.valueOf() / (1024 * 1024)) * 100) / 100 + 'MB'}
              </Button>
            </>
          </Center>
        ))}
      </Group>
    </Paper>
  );
}

export function NewsCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const newsPostsQuery = useNewsPostsQuery({
    page: 1,
    pageSize: 5,
  });

  const newsPosts = newsPostsQuery?.data.length ? newsPostsQuery?.data : undefined;

  return (
    <Paper radius="md" withBorder p="md">
      <Text color="dimmed" transform="uppercase" weight={700} size="xs" className={classes.label}>
        {t('coreData.menu.news')}
      </Text>

      {newsPosts &&
        newsPosts.map((post, idx) => {
          if (!post) return null;
          return <News {...post} key={idx} />;
        })}

      {!newsPosts && (
        <>
          <Center mt={20}>
            <IconNewsOff color="grey" size={35} strokeWidth={1.5} />
          </Center>

          <Center>
            <Text color="dimmed" weight={700} size="xs" className={classes.label}>
              {t('coreData.newsPosts.noNews')}
            </Text>
          </Center>
        </>
      )}
    </Paper>
  );
}
