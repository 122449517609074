import { DashboardOrdersType } from '@gravity/shared/dto';
import { createStyles, Paper, Group, SimpleGrid, Box, Title, ScrollArea } from '@mantine/core';
import { round, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDashboard } from '../../../hooks/use-dashboard.hook';
import { useAuth } from '../../../stores/auth.store';
import { BarchartCard } from '../components/BarchartCard';
import { InfoCard } from '../components/InfoCard';
import { useAppConfig } from '../../../hooks/use-app-config.hook';
import { hungarianArticleFormat } from '@gravity/shared/utils';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },
  infos: {
    marginBottom: theme.spacing.sm,
  },
}));

export function Dashboard() {
  const { user } = useAuth();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dashboardData = useDashboard();
  const config = useAppConfig();

  function prepareBarchartData(data: DashboardOrdersType): {
    label: string;
    count: number;
    color: string;
    part: number;
  }[] {
    const barchartColors = ['#F8230C', '#F07B00', '#F8D00C', '#A2AD40', '#478749', '#000000'];
    const sumData = sum(Object.values(data));

    return Object.entries(data).map(([key, value], idx) => {
      return {
        label: t(`common.orderStatus.${key}`),
        count: value,
        color: barchartColors[idx],
        part: round((data[key] / sumData) * 100, 1),
      };
    });
  }

  const infos = [
    {
      title: t('dashboard.infos.partnerCount'),
      value: dashboardData?.partnerStats.all,
      subStats: [
        { title: t('entity.partner.isSupplier'), value: dashboardData?.partnerStats.supplier },
        { title: t('entity.partner.isCustomer'), value: dashboardData?.partnerStats.customer },
        { title: t('entity.partner.isReseller'), value: dashboardData?.partnerStats.reseller },
      ],
    },
    {
      title: t('dashboard.infos.orderCount'),
      value: dashboardData?.allTimeOrderCount,
    },
  ];

  return (
    <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
      <div className={classes.root}>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} mb={35}>
          <Paper p="md">
            <Group>
              <img src={config?.logo} style={{ maxHeight: 60, marginRight: 10 }} />

              <Box>
                <Title order={4} pb={0}>
                  {t('dashboard.greeting', { user: user?.firstName ? user?.firstName : 'Felhasználó' })}
                </Title>

                <Title order={6} pb={0} sx={{ fontWeight: 'normal' }}>
                  {t('dashboard.welcome', {
                    company: config?.company,
                    article: hungarianArticleFormat(config?.company),
                  })}
                </Title>
              </Box>
            </Group>
          </Paper>
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} className={classes.infos}>
          {infos.map((info, idx) => (
            <InfoCard {...info} key={idx} />
          ))}
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <BarchartCard
            title={t('sales.menu.orders')}
            data={dashboardData && prepareBarchartData(dashboardData?.orders)}
          />
        </SimpleGrid>
      </div>
    </ScrollArea>
  );
}
