import { ActionIcon, Button, Divider, Grid, Group, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UserCreateRequest, UserResponse, UserUpdateRequest } from '@gravity/shared/dto';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import {
  GrForm,
  GrFormTitle,
  GrMultiSelectInput,
  GrPasswordInput,
  GrSwitchInput,
  GrTextInput,
  useDtoForm,
} from '@gravity/frontend/ui';
import { useUserRoles } from '../../../hooks/use-user-roles.hook';
import { axiosInstance } from '../../../plugins/axios';
import { AxiosError } from 'axios';
import { Permission } from '@prisma/client';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { Page } from '../../../components/Page';
import { useAuth } from '../../../stores/auth.store';

export function UserFormPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUser } = useAuth();
  const entity = useLoaderData() as UserResponse | undefined;
  const userRoles = useUserRoles().data;

  const partner = entity?.partnerUser
    ? `${entity.partnerUser?.partner?.name} (${entity.partnerUser?.partner?.taxIdNumber})`
    : undefined;

  const userForm = useDtoForm([UserCreateRequest, UserUpdateRequest], 'entity.user', {
    isEditForm: !!entity,
    defaultValues: entity
      ? {
          ...entity,
          roles: entity?.roles?.map((r) => r.userRole),
          partner,
        }
      : undefined,
    validators: {
      email: emailValidation,
    },
  });

  const userMutation = useDtoMutation('users', {
    invalidateQueries: [['users'], ['users', 'me'], ['users', ':id'], ['users', 'check-email']],
  });

  async function handleSubmit(data: UserCreateRequest | UserUpdateRequest) {
    if (userForm.isCreateForm) {
      const user = (await userMutation.create(data)) as UserResponse;
      navigate(`/settings/users/${user.id}`);
      userForm.toggleReadonlyMode(user);
    } else {
      if (data.partnerUser) {
        delete data.partnerUser['partner'];
        delete data.partnerUser['userId'];
      }
      await userMutation.update(data);
      updateUser();
      const updatedData = {
        ...data,
        email: entity?.email,
        partner,
      };
      userForm.toggleReadonlyMode(updatedData);
    }
  }

  async function emailValidation(email: string) {
    const result = await axiosInstance.get(`users/check-email?email=${email}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictEmail') {
      return { type: result.response?.data.message };
    }
  }

  return (
    <Page>
      {!userRoles ? (
        <LoadingOverlay
          visible={true}
          overlayOpacity={0.6}
          overlayBlur={2}
          loaderProps={{ color: 'dark' }}
          transitionDuration={300}
          zIndex={150}
        />
      ) : (
        <GrForm form={userForm} onSubmit={handleSubmit}>
          <Group position="apart" mb="md">
            <Group>
              <Link to="/settings/users">
                <ActionIcon variant="light">
                  <IconChevronLeft />
                </ActionIcon>
              </Link>

              <GrFormTitle i18nPrefix="settings.userForm" />
            </Group>

            <Group>
              {userForm.readonlyMode ? (
                <PermissionCheck hasAll={[Permission.user_read, Permission.user_update]}>
                  <Button
                    onClick={() => {
                      userForm.toggleReadonlyMode();
                    }}
                    variant="light"
                    leftIcon={<IconPencil />}
                  >
                    {t('common.button.edit')}
                  </Button>
                </PermissionCheck>
              ) : (
                <>
                  <PermissionCheck
                    hasAll={
                      userForm.isEditForm ? [Permission.user_read, Permission.user_update] : [Permission.user_create]
                    }
                  >
                    <Button
                      loading={userForm.isSubmitting}
                      color="green"
                      type="submit"
                      variant="light"
                      leftIcon={<IconCheck />}
                    >
                      {t('common.button.save')}
                    </Button>
                  </PermissionCheck>

                  <Button
                    loading={userForm.isSubmitting}
                    type="button"
                    color="gray"
                    variant="light"
                    leftIcon={<IconX />}
                    onClick={() => (userForm.isCreateForm ? navigate(-1) : userForm.toggleReadonlyMode())}
                  >
                    {t('common.button.cancel')}
                  </Button>
                </>
              )}

              {userForm.isEditForm && userForm.readonlyMode && (
                <PermissionCheck hasAll={[Permission.user_read, Permission.user_update, Permission.user_delete]}>
                  <DeleteConfirmButton
                    loading={userForm.isSubmitting}
                    onDelete={async () => {
                      try {
                        await userMutation.delete(userForm.getValues('id' as never));
                        navigate('/settings/users');
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </PermissionCheck>
              )}
            </Group>
          </Group>

          <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
            <Grid>
              <Grid.Col xs={6}>
                <GrTextInput name="email" />
              </Grid.Col>

              <Grid.Col xs={6}>
                <GrSwitchInput name="locked" description={t('settings.userForm.lockedHint')} />
              </Grid.Col>

              {userForm.isEditForm && entity?.isReseller && (
                <Grid.Col>
                  <GrTextInput name="partner" />
                </Grid.Col>
              )}

              {userForm.isCreateForm && (
                <>
                  <Grid.Col xs={6}>
                    <GrPasswordInput name="password" />
                  </Grid.Col>

                  <Grid.Col xs={6}>
                    <GrPasswordInput name="passwordRepeat" />
                  </Grid.Col>
                </>
              )}
            </Grid>

            <Divider my="xl" />

            <Grid>
              <Grid.Col xs={6}>
                <GrTextInput name="lastName" autocomplete="family-name" />
              </Grid.Col>

              <Grid.Col xs={6}>
                <GrTextInput name="firstName" autocomplete="given-name" />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col xs={6}>
                <GrTextInput name="position" />
              </Grid.Col>
            </Grid>

            <GrMultiSelectInput data={userRoles} multiple labelField="name" name="roles" />
          </ScrollArea>
        </GrForm>
      )}
    </Page>
  );
}
