import { Box, Group, Paper, Stack, Tooltip } from '@mantine/core';
import { IconCode, IconHelp } from '@tabler/icons-react';
import { useState } from 'react';
import { Field, formatQuery, parseJsonLogic, QueryBuilder, RuleGroupType } from 'react-querybuilder';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import i18n, { t } from 'i18next';
import hu from './i18n/hu.json';

import 'react-querybuilder/dist/query-builder.css';
import './styles.scss';

// Register translations
export default i18n.addResourceBundle('hu', 'queryBuilder', hu.queryBuilder);

export interface GrQueryBuilderProps {
  fields: Field[];
  disabled?: boolean;
  query?: string; // JsonLogic string
  onChange?: (query: string) => void;
}

export function GrQueryBuilder(props: GrQueryBuilderProps) {
  const [query, setQuery] = useState<RuleGroupType>(parseJsonLogic(props.query ?? ''));

  return (
    <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
      <Paper withBorder shadow="sm" p={6} sx={{ backgroundColor: '#f9f9f9' }}>
        <Group position="apart" spacing={0} sx={{ alignItems: 'start' }}>
          <QueryBuilder
            fields={props.fields}
            query={query}
            onQueryChange={(q) => {
              setQuery(q);
              if (props.onChange) {
                props.onChange(JSON.stringify(formatQuery(q, 'jsonlogic')));
              }
            }}
            disabled={props.disabled}
            translations={translations}
            operators={operators}
            combinators={combinators}
          />

          <Stack spacing={0} justify="flex-start">
            <Tooltip label="TODO: Help a feltétel szerkesztőhöz" position="left">
              <Box>
                <IconHelp color="grey" size={20} />
              </Box>
            </Tooltip>

            <Tooltip label="DEBUG: JsonLogic feltétel megjelenítése" position="left">
              <Box>
                <IconCode
                  color="grey"
                  size={20}
                  style={{ cursor: 'pointer', marginTop: 10 }}
                  onClick={() => {
                    alert(JSON.stringify(formatQuery(query, 'jsonlogic')));
                    console.info(JSON.stringify(formatQuery(query, 'jsonlogic')));
                  }}
                />
              </Box>
            </Tooltip>
          </Stack>
        </Group>
      </Paper>
    </QueryBuilderDnD>
  );
}

const combinators = [
  { name: 'and', label: t('queryBuilder.combinator.and') },
  { name: 'or', label: t('queryBuilder.combinator.or') },
];

const operators = [
  { name: '=', label: '=' },
  { name: '!=', label: '!=' },
  { name: '<', label: '<' },
  { name: '>', label: '>' },
  { name: '<=', label: '<=' },
  { name: '>=', label: '>=' },
  { name: 'contains', label: 'contains' },
  { name: 'beginsWith', label: 'begins with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'doesNotContain', label: 'does not contain' },
  { name: 'doesNotBeginWith', label: 'does not begin with' },
  { name: 'doesNotEndWith', label: 'does not end with' },
  { name: 'null', label: 'is null' },
  { name: 'notNull', label: 'is not null' },
  { name: 'in', label: 'in' },
  { name: 'notIn', label: 'not in' },
  { name: 'between', label: 'between' },
  { name: 'notBetween', label: 'not between' },
];

const translations = {
  fields: {
    title: 'Fields',
    placeholderName: '~',
    placeholderLabel: '------',
    placeholderGroupLabel: '------',
  },
  operators: {
    title: 'Operators',
    placeholderName: '~',
    placeholderLabel: '------',
    placeholderGroupLabel: '------',
  },
  value: {
    title: 'Value',
  },
  removeRule: {
    label: 'x',
    title: 'Remove rule',
  },
  removeGroup: {
    label: 'x',
    title: 'Remove group',
  },
  addRule: {
    label: '+ Feltétel',
    title: 'Feltétel hozzáadása',
  },
  addGroup: {
    label: '+ Csoport',
    title: 'Csoport hozzáadása',
  },
  combinators: {
    title: 'Feltétel típusa',
  },
  notToggle: {
    label: 'Ellenkezője igaz',
    title: 'A feltétel ellenkezője lesz igaz',
  },
  cloneRule: {
    label: '⧉',
    title: 'Clone rule',
  },
  cloneRuleGroup: {
    label: '⧉',
    title: 'Clone group',
  },
  dragHandle: {
    label: '⁞⁞',
    title: 'Drag handle',
  },
  lockRule: {
    label: '🔓',
    title: 'Lock rule',
  },
  lockGroup: {
    label: '🔓',
    title: 'Lock group',
  },
  lockRuleDisabled: {
    label: '🔒',
    title: 'Unlock rule',
  },
  lockGroupDisabled: {
    label: '🔒',
    title: 'Unlock group',
  },
};
