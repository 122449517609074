import {
  Center,
  Chip,
  Popover,
  Box,
  NavLink,
  Button,
  Text,
  CloseButton,
  Divider,
  Tooltip,
  MantineTheme,
  Kbd,
  Group,
  SelectItem,
  Transition,
} from '@mantine/core';
import {
  IconFilter,
  IconAlphabetLatin,
  IconCalendar,
  IconNumbers,
  IconCheckbox,
  IconList,
  IconCoin,
} from '@tabler/icons-react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTableColumnType } from '../../enums/entity-table-column-type.enum';
import { EntityTableColumn } from '../../interfaces/entity-table-column.interface';
import { useSetState } from '@mantine/hooks';
import { EntityTableFilter } from '../../interfaces/entity-table-filter.interface';
import { EntityTableStringFilter } from './components/EntityTableStringFilter';
import { EntityTableDateTimeFilter } from './components/EntityTableDateTimeFilter';
import { EntityTablePriceFilter } from './components/EntityTablePriceFilter';
import { EntityTableArrayFilter } from './components/EntityTableArrayFilter';
import { EntityTableBooleanFilter } from './components/EntityTableBooleanFilter';
import { EntityTableNumberFilter } from './components/EntityTableNumberFilter';
import { EntityTableEnumFilter } from './components/EntityTableEnumFilter';
import { ET_FILTER_ARRAY_SEPARATOR } from '../../constants';

interface EntityTableFilterBuilderProps<T> {
  columns: EntityTableColumn<T>[];
  filters: Record<string, EntityTableFilter>;
  onFilterChange: (filters: Record<string, any>) => void;
}

const DEFAULT_FORM_STATE = {
  column: '',
  value: '',
  secondaryValue: undefined,
  operation: '',
};

export function EntityTableFilterBuilder<T>({ columns, filters, onFilterChange }: EntityTableFilterBuilderProps<T>) {
  const { t } = useTranslation();

  const [filterFormOpened, setFilterFormOpened] = useState(false);
  const [filterFormState, setFilterFormState] = useState(1);
  const [filterFormValues, setFilterFormValues] = useSetState<EntityTableFilter>(DEFAULT_FORM_STATE);
  const [popoverLocked, setPopoverLocked] = useState(false);

  const hasFilters = Object.keys(filters).length > 0;

  const selectedColumn = columns.find((column) => column.id === filterFormValues?.column)!;
  let selectData: SelectItem[] = [];

  if (selectedColumn && selectedColumn.filterOptions) {
    if (selectedColumn.filterOptions[0] as SelectItem) {
      selectData = selectedColumn.filterOptions as SelectItem[];
    } else {
      selectData = (selectedColumn.filterOptions as string[]).map((o) => ({ label: o, value: o }));
    }
  }

  function handleFilterSave(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    setFilterFormOpened(false);
    resetFilterForm();

    // Handle secondaryDate
    if (
      !(filterFormValues.operation === 'between' || filterFormValues.operation === 'betweenP') &&
      filterFormValues.secondaryValue
    ) {
      delete filterFormValues.secondaryValue;
    }

    onFilterChange({
      ...filters,
      [filterFormValues.column]: filterFormValues,
    });
  }

  function handleFilterRemove(columnName: string) {
    const newFilters = { ...filters };
    delete newFilters[columnName];

    onFilterChange(newFilters);
  }

  function resetFilterForm() {
    setFilterFormValues(DEFAULT_FORM_STATE);
    setFilterFormState(1);
  }

  function enterEditMode(columnName: string) {
    setFilterFormValues(filters[columnName]);
    setFilterFormState(2);
    setFilterFormOpened(true);
  }

  function getFilterableColumns() {
    return columns
      .filter((column) => !Object.keys(filters).includes(column.id))
      .filter((column) => column.header && column.enableColumnFilter !== false);
  }

  function getColumnTypeIcon(type?: EntityTableColumnType) {
    switch (type) {
      case EntityTableColumnType.String:
      default:
        return <IconAlphabetLatin />;
      case EntityTableColumnType.Date:
      case EntityTableColumnType.DateTime:
        return <IconCalendar />;
      case EntityTableColumnType.Number:
        return <IconNumbers />;
      case EntityTableColumnType.Price:
        return <IconCoin />;
      case EntityTableColumnType.SimpleArray:
      case EntityTableColumnType.EnumArray:
      case EntityTableColumnType.ObjectArray:
        return <IconList />;
      case EntityTableColumnType.Boolean:
        return <IconCheckbox />;
    }
  }

  function findColumn(columnId: string): EntityTableColumn<T> {
    return columns.find((column) => column.id === columnId)!;
  }

  function formatFilterValue(column: EntityTableColumn<T>, value?: string): string | undefined {
    if (!value) {
      return undefined;
    }

    switch (column.type) {
      case EntityTableColumnType.Date:
      case EntityTableColumnType.DateTime:
        return new Date(value).toLocaleDateString();
      case EntityTableColumnType.EnumArray:
      case EntityTableColumnType.Enum: {
        const filters = value.split(ET_FILTER_ARRAY_SEPARATOR);
        const labels = column.filterOptions ?? [];
        const values: string[] = [];
        labels.forEach((item: SelectItem | string) => {
          item = item as SelectItem;
          if (filters.includes(item.value)) {
            if (item.label) {
              values.push(item.label);
            }
          }
        });
        value = values.join(ET_FILTER_ARRAY_SEPARATOR);
        return value;
      }
      case EntityTableColumnType.Boolean:
        return value === 'true' ? t('common.message.true') : t('common.message.false');
      default:
        return value;
    }
  }

  const buttonTransition = {
    in: { opacity: 1, transform: 'translateX(0)' },
    out: { opacity: 0, transform: 'translateX(-10px)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  return (
    <Center pb="md" pt="xs" inline>
      <Popover
        width={500}
        position="bottom-start"
        withArrow
        shadow="xl"
        opened={filterFormOpened}
        closeOnClickOutside
        closeOnEscape
        trapFocus
        onClose={() => {
          if (!popoverLocked) {
            resetFilterForm();
            setFilterFormOpened(false);
          }
        }}
      >
        <Popover.Target>
          <Tooltip label={t('entityTable.filter.addFilter')} disabled={!hasFilters} position="right">
            <Button
              radius="xl"
              mr="xs"
              size="xs"
              onClick={() => setFilterFormOpened(true)}
              color="gray"
              variant="subtle"
              sx={(theme: MantineTheme) => ({ '&:hover': { backgroundColor: theme.colors['gray'][2] } })}
              px={10}
              py={0}
              disabled={getFilterableColumns().length === 0}
            >
              <IconFilter style={{ marginRight: 0 }} />

              <Transition mounted={!hasFilters} transition={buttonTransition} duration={400} exitDuration={0}>
                {(styles) => (
                  <Box style={styles} ml={8} mr={4}>
                    {t('entityTable.filter.addFilter')}
                  </Box>
                )}
              </Transition>
            </Button>
          </Tooltip>
        </Popover.Target>

        <Popover.Dropdown px={0}>
          {filterFormState === 1 && (
            <Box>
              <Text size="sm" mb="xs" px="md" weight={600}>
                {t('entityTable.filter.selectColumn')}
              </Text>

              <Divider mb="xs" />

              {getFilterableColumns().map((column) => (
                <NavLink
                  icon={getColumnTypeIcon(column.type)}
                  px="md"
                  label={column.header}
                  key={`filterColumn_${column.id}`}
                  onClick={() => {
                    setFilterFormValues({ column: column.id });
                    setFilterFormState(2);
                  }}
                />
              ))}
            </Box>
          )}

          {filterFormState === 2 && (
            <Box>
              <Text size="sm" mb="xs" px="md" weight={600}>
                {t('entityTable.filter.setValue')}
              </Text>

              <Divider mb="xs" />

              <Box px="md">
                <form onSubmit={(e) => handleFilterSave(e)}>
                  {[EntityTableColumnType.String, EntityTableColumnType.Id].includes(selectedColumn.type) && (
                    <EntityTableStringFilter
                      selectedColumn={selectedColumn}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                    />
                  )}

                  {[EntityTableColumnType.Number].includes(selectedColumn.type) && (
                    <EntityTableNumberFilter
                      selectedColumn={selectedColumn}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                    />
                  )}

                  {[EntityTableColumnType.Date, EntityTableColumnType.DateTime].includes(selectedColumn.type) && (
                    <EntityTableDateTimeFilter
                      selectedColumn={selectedColumn}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                      onPopoverLock={setPopoverLocked}
                    />
                  )}

                  {[EntityTableColumnType.Price].includes(selectedColumn.type) && (
                    <EntityTablePriceFilter
                      selectedColumn={selectedColumn}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                    />
                  )}

                  {[
                    EntityTableColumnType.ObjectArray,
                    EntityTableColumnType.SimpleArray,
                    EntityTableColumnType.EnumArray,
                  ].includes(selectedColumn.type) && (
                    <EntityTableArrayFilter
                      selectedColumn={selectedColumn}
                      selectData={selectData}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                      onPopoverLock={setPopoverLocked}
                    />
                  )}

                  {[EntityTableColumnType.Enum, EntityTableColumnType.Object].includes(selectedColumn.type) && (
                    <EntityTableEnumFilter
                      selectedColumn={selectedColumn}
                      selectData={selectData}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                      onPopoverLock={setPopoverLocked}
                    />
                  )}

                  {[EntityTableColumnType.Boolean].includes(selectedColumn.type) && (
                    <EntityTableBooleanFilter
                      selectedColumn={selectedColumn}
                      filterFormValues={filterFormValues}
                      onChange={setFilterFormValues}
                    />
                  )}

                  <Group spacing="xs" mt="lg">
                    <Button
                      type="submit"
                      disabled={
                        !filterFormValues.value.trim() ||
                        ((filterFormValues.operation === 'between' || filterFormValues.operation === 'betweenP') &&
                          !filterFormValues.secondaryValue?.trim())
                      }
                    >
                      Szűrés
                    </Button>

                    <Text size="sm">{t('common.message.or')}</Text>

                    <Kbd>Enter</Kbd>

                    <Text size="sm">{t('common.message.key')}</Text>
                  </Group>
                </form>
              </Box>
            </Box>
          )}
        </Popover.Dropdown>
      </Popover>

      <Group position="left">
        <Chip.Group value={''}>
          {Object.keys(filters).map((columnName, index) => (
            <Chip
              variant="filled"
              sx={(theme) => ({
                label: {
                  paddingLeft: 12,
                  paddingRight: 4,
                  backgroundColor: `${theme.colors['gray'][2]} !important`,
                  '&:hover': { backgroundColor: `${theme.colors['gray'][3]} !important` },
                },
              })}
              key={`filter_${index}`}
              onClick={() => enterEditMode(columnName)}
            >
              <Center>
                <Box>
                  {`${findColumn(columnName).header}: `}

                  <strong>
                    {t(`entityTable.filter.operationSign.${filters[columnName].operation}`, {
                      value: formatFilterValue(findColumn(columnName), filters[columnName].value),
                      secondaryValue: formatFilterValue(findColumn(columnName), filters[columnName].secondaryValue),
                    })}
                  </strong>
                </Box>

                <CloseButton
                  sx={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: 'transparent' } }}
                  onClick={() => handleFilterRemove(columnName)}
                />
              </Center>
            </Chip>
          ))}
        </Chip.Group>
      </Group>
    </Center>
  );
}
