import { GrForm, GrFormTitle, GrSelectInput, GrNumberInput, GrTextInput, useDtoForm } from '@gravity/frontend/ui';
import {
  UnitOfMeasurementResponse,
  UnitOfMeasurementCreateRequest,
  UnitOfMeasurementUpdateRequest,
} from '@gravity/shared/dto';
import { Button, Text, Group, ActionIcon, Grid, ScrollArea } from '@mantine/core';
import { Permission, UnitOfMeasurementReferenceType } from '@prisma/client';
import { IconCheck, IconChevronLeft, IconPencil, IconX } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { DeleteConfirmButton } from '../../../components/DeleteConfirmButton';
import { Page } from '../../../components/Page';
import { PermissionCheck } from '../../../components/Permission/PermissionCheck';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { axiosInstance } from '../../../plugins/axios';

const SelectItemComponent = forwardRef<HTMLDivElement, { label: string }>(({ label, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Text>{label}</Text>
  </div>
));

export function UnitOfMeasurementFormPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const entity = useLoaderData() as UnitOfMeasurementResponse | undefined;

  const unitOfMeasurementForm = useDtoForm(
    [UnitOfMeasurementCreateRequest, UnitOfMeasurementUpdateRequest],
    'entity.unitOfMeasurement',
    {
      isEditForm: !!entity,
      defaultValues: entity,
      validators: {
        name: nameValidation,
      },
    }
  );

  const unitOfMeasurementMutation = useDtoMutation<UnitOfMeasurementUpdateRequest | UnitOfMeasurementCreateRequest>(
    'units-of-measurement',
    {
      successNotification: true,
      invalidateQueries: [['units-of-measurement']],
    }
  );

  const referenceTypes = Object.keys(UnitOfMeasurementReferenceType).map((referenceType) => ({
    id: referenceType,
    label: t(`common.unitOfMeasurementReferenceType.${referenceType}`),
  }));

  async function nameValidation(name: string) {
    if (unitOfMeasurementForm.isEditForm && name === entity?.name) {
      return;
    }

    const result = await axiosInstance.get(`units-of-measurement/check-unit-of-measurement?name=${name}`);
    if (result instanceof AxiosError && result.response?.data.message === 'error.conflictUnitOfMeasurement') {
      return { type: result.response?.data.message };
    }
  }

  async function handleSubmit(data: UnitOfMeasurementCreateRequest | UnitOfMeasurementUpdateRequest) {
    if (unitOfMeasurementForm.isCreateForm) {
      const uom = (await unitOfMeasurementMutation.create(data)) as UnitOfMeasurementResponse;
      navigate(`/core-data/units-of-measurement/${uom.id}`);
      unitOfMeasurementForm.toggleReadonlyMode(uom);
    } else {
      await unitOfMeasurementMutation.update(data);
      unitOfMeasurementForm.toggleReadonlyMode(data);
    }
  }

  return (
    <Page>
      <GrForm form={unitOfMeasurementForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <Link to="/core-data/units-of-measurement">
              <ActionIcon variant="light">
                <IconChevronLeft />
              </ActionIcon>
            </Link>

            <GrFormTitle i18nPrefix="coreData.unitOfMeasurementForm" />
          </Group>

          <Group>
            {unitOfMeasurementForm.readonlyMode ? (
              <PermissionCheck hasAll={[Permission.appAdmin]}>
                <Button
                  onClick={() => unitOfMeasurementForm.toggleReadonlyMode()}
                  variant="light"
                  leftIcon={<IconPencil />}
                >
                  {t('common.button.edit')}
                </Button>
              </PermissionCheck>
            ) : (
              <>
                <PermissionCheck hasAll={[Permission.appAdmin]}>
                  <Button
                    loading={unitOfMeasurementForm.isSubmitting}
                    color="green"
                    type="submit"
                    leftIcon={<IconCheck />}
                  >
                    {t('common.button.save')}
                  </Button>
                </PermissionCheck>

                <Button
                  loading={unitOfMeasurementForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() =>
                    unitOfMeasurementForm.isCreateForm ? navigate(-1) : unitOfMeasurementForm.toggleReadonlyMode()
                  }
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}

            {unitOfMeasurementForm.isEditForm && unitOfMeasurementForm.readonlyMode && (
              <PermissionCheck hasAll={[Permission.appAdmin]}>
                <DeleteConfirmButton
                  loading={unitOfMeasurementForm.isSubmitting}
                  onDelete={async () => {
                    try {
                      await unitOfMeasurementMutation.delete(unitOfMeasurementForm.getValues('id' as never));
                      navigate('/core-data/units-of-measurement');
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </PermissionCheck>
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid>
            <Grid.Col>
              <GrTextInput name="name" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="sign" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="category" />
            </Grid.Col>

            <Grid.Col>
              <GrSelectInput
                data={referenceTypes}
                name="type"
                simpleValue
                forwardedProps={{
                  itemComponent: SelectItemComponent,
                }}
              />
            </Grid.Col>

            <Grid.Col>
              <GrNumberInput name="conversionRatio" />
            </Grid.Col>
          </Grid>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
