import { IsOptional, IsString } from 'class-validator';

export class CheckDateRequest {
  /**
   * Start date of the price list version
   */
  @IsString()
  @IsOptional()
  public startDate?: Date;

  /**
   * End date of the price list version
   */
  @IsString()
  @IsOptional()
  public endDate?: Date;
  /**
   * End date of the price list version
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;
}
