import { Prisma } from '@prisma/client';
import { IsBoolean, IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, Max } from 'class-validator';

export class PvcLedgeRequest {
  @IsString()
  @IsNotEmpty()
  public pl_depth: string; // Mélység

  @Max(600)
  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public pl_width: number; // Szélesség

  @IsNumber()
  @IsNotEmpty()
  public pl_roundedWidth: number; // Kerekített szélesség

  @IsNumber()
  @IsOptional()
  public pl_endCloserQuantity: number; // Végzáró darab

  @IsString()
  @IsOptional()
  public pl_endCloserName: string; // Végzáró neve

  @IsBoolean()
  @IsOptional()
  public pl_endCloser: boolean; // Végzáró

  @IsString()
  @IsNotEmpty()
  public pl_color: string; // Szín

  @IsNumber()
  @IsNotEmpty()
  @IsPositive()
  public pl_quantity: number; // Darabszám

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public summary?: string;

  @IsNumber()
  @IsOptional()
  public price?: number;

  @IsOptional()
  @IsObject()
  public priceList: Prisma.JsonValue;
}
