import { Box, Button, createStyles, MediaQuery } from '@mantine/core';
import { ResellerNavLink } from './components/ResellerNavLink';
import { IconAddressBook, IconHome, IconLogout, IconBasket, IconUsers } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@prisma/client';
import { useCurrentPartner } from '../../../../hooks/use-current-partner.hook';
import { useAuth } from '../../../../stores/auth.store';
import { useAppConfig } from '../../../../hooks/use-app-config.hook';

const useStyles = createStyles((theme) => ({
  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 45,
    marginTop: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
    color: 'white',
    img: {
      objectFit: 'contain',
      maxWidth: '100%',
      height: 'auto',
    },
  },

  button: {
    backgroundColor: theme.colors[theme.primaryColor][9],
  },

  aside: {
    padding: ' 0 10px 0',
    flex: '0 0 220px',
    backgroundColor: theme.colors[theme.primaryColor][9],
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '@media (max-width: 767px)': {
      height: `calc(100vh - 55px)`,
    },
    zIndex: 1000,
  },
}));

export function ResellerNav() {
  const { classes } = useStyles();
  const auth = useAuth();
  const { permission } = useCurrentPartner();
  const { t } = useTranslation();
  const config = useAppConfig();

  function checkMobilePlatform() {
    if (!navigator.maxTouchPoints) {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB10|PlayBook|Windows Phone|RIM Tablet|SymbianOS|Kindle|Silk/i.test(
        navigator.userAgent
      );
    } else {
      return true;
    }
  }

  return (
    <div className={classes.aside}>
      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <div className={classes.logo}>
          <img src={config?.logo} alt="Logo" />
        </div>
      </MediaQuery>

      <ResellerNavLink label={t('component.reseller.menu.home')} icon={IconHome} to="/reseller" />

      <ResellerNavLink label={t('component.reseller.menu.orders')} icon={IconBasket} to="/reseller/orders" />

      <ResellerNavLink
        label={t('component.reseller.menu.partnerProfile')}
        icon={IconAddressBook}
        to="/reseller/partner-profile"
      />

      {permission === Permission.reseller_system_admin && (
        <ResellerNavLink label={t('component.reseller.menu.users')} icon={IconUsers} to="/reseller/users" />
      )}

      <Box sx={{ flexGrow: 1 }} />

      <Button
        className={classes.button}
        leftIcon={<IconLogout size={16} />}
        onClick={auth.logout}
        sx={{ color: 'white', marginBottom: '5px' }}
      >
        {t('component.userMenu.logOut')}
      </Button>

      {checkMobilePlatform() && <Box sx={{ height: 55 }} />}
    </div>
  );
}
