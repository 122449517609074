import { Box, Input, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { DateTimePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';

export interface GrDateInputProps extends BaseInputProps {
  minDate?: Date;
  onlyDate?: boolean;
  withTime?: boolean;
  disabled?: boolean;
  defaultValue?: Date;
}

export function GrDateInput({ name, onlyDate, ...props }: GrDateInputProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();
  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  useEffect(() => {
    if (props.defaultValue && !form.readonlyMode) form.setValue(name, props.defaultValue.toISOString());
  }, [labelName]);

  if (!form) {
    return null;
  }

  // Return readonly visualization of the field
  if (!form.existingFields.includes(labelName)) {
    let dateString: string;
    if (form.getValues(name)) {
      if (onlyDate) {
        dateString = new Date(form.getValues(name))?.toLocaleDateString();
      } else {
        dateString = new Date(dayjs(form.getValues(name)).format('YYYY. MM. DD. HH:mm')).toLocaleString();
        dateString = dateString.slice(0, dateString.lastIndexOf(':'));
      }
    } else {
      dateString = '-';
    }
    return (
      <Box sx={{ wordWrap: 'break-word' }} mb={6}>
        <Input.Wrapper label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`) + ':'}>
          <Text>{dateString}</Text>
        </Input.Wrapper>
      </Box>
    );
  }

  if (props['withTime']) {
    return (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState, formState }) => (
          <DateTimePicker
            {...field}
            {...props.forwardedProps}
            label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
            error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
            description={props.description}
            disabled={formState.isSubmitting}
            required={form.requiredFields.includes(labelName)}
            value={form.getValues(name) ? new Date(form.getValues(name)) : undefined}
            onChange={(date: Date) => {
              if (date) {
                field.onChange(date.toISOString());
              } else {
                field.onChange(undefined);
              }
            }}
            defaultValue={form.isEditForm ? new Date(form.getValues(name)) : props.defaultValue}
            minDate={props.minDate}
            valueFormat={'YYYY. MM. DD. HH:mm'}
            locale="hu"
            clearable={!form.requiredFields.includes(labelName)}
          />
        )}
      />
    );
  } else {
    return (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState, formState }) => (
          <DatePickerInput
            {...field}
            {...props.forwardedProps}
            label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
            error={props.error ?? (fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined)}
            description={props.description}
            disabled={formState.isSubmitting}
            required={form.requiredFields.includes(labelName)}
            value={form.getValues(name) ? new Date(form.getValues(name)) : undefined}
            onChange={(date: Date) => {
              if (date) {
                field.onChange(dayjs(date).endOf('day').toISOString());
              } else {
                field.onChange(undefined);
              }
            }}
            defaultValue={form.isEditForm ? new Date(form.getValues(name)) : props.defaultValue}
            minDate={props.minDate}
            valueFormat="YYYY. MM. DD."
            locale="hu"
            clearable={!form.requiredFields.includes(labelName)}
          />
        )}
      />
    );
  }
}
