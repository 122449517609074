import { IconEye } from '@tabler/icons-react';
import { ActionIcon, Center } from '@mantine/core';
import { Row } from '@tanstack/react-table';
import { Else, If, Then } from 'react-if';
import { Link } from 'react-router-dom';

interface EntityTableRowActionsProps<T> {
  row: Row<T>;
  onRowClick?: (entity: T) => void;
  actionsVisible?: (row: Row<T>) => boolean;
}

export function EntityTableRowActions<T>({ row, onRowClick, actionsVisible }: EntityTableRowActionsProps<T>) {
  return (
    <Center sx={{ color: 'grey' }} hidden={actionsVisible ? !actionsVisible(row) : false}>
      <If condition={!!onRowClick}>
        <Then>
          <ActionIcon size={22} color="primary" onClick={() => onRowClick && onRowClick(row.original)}>
            <IconEye />
          </ActionIcon>
        </Then>

        <Else>
          <Link to={(row.original as any).id}>
            <ActionIcon size={22} color="primary">
              <IconEye />
            </ActionIcon>
          </Link>
        </Else>
      </If>
    </Center>
  );
}
