import {
  GrForm,
  GrFormTab,
  GrFormTabs,
  GrFormTitle,
  GrSelectInput,
  GrSwitchInput,
  GrTextareaInput,
  GrTextInput,
  useDtoForm,
} from '@gravity/frontend/ui';
import {
  PartnerAddressUpdateRequest,
  PartnerBankAccountUpdateRequest,
  PartnerContactUpdateRequest,
  PartnerCreateRequest,
  PartnerUpdateRequest,
  StorageUploadResponse,
} from '@gravity/shared/dto';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Image,
  ScrollArea,
  Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { AddressType, Permission } from '@prisma/client';
import {
  IconAddressBook,
  IconBuildingBank,
  IconBuildingCommunity,
  IconCheck,
  IconPencil,
  IconRowInsertTop,
  IconTrash,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page';
import { useCountry } from '../../../hooks/use-country.hook';
import { useCurrentPartner } from '../../../hooks/use-current-partner.hook';
import { useDtoMutation } from '../../../hooks/use-dto-mutation.hook';
import { useStorageService } from '../../../stores/storage.store';

export function PartnerProfileFormPage() {
  const { t } = useTranslation();
  const { user, partner, permission, isFetching } = useCurrentPartner();

  const isAdmin = permission === Permission.reseller_system_admin;

  const partnerForm = useDtoForm([PartnerCreateRequest, PartnerUpdateRequest], 'entity.partner', {
    isEditForm: true,
    defaultValues: partner ? partner : undefined,
  });

  // File upload
  const storageService = useStorageService();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [selectedFile, selectFile] = useState();
  function handleFileSelect(e: any) {
    const reader = new FileReader();
    if (e.target.files[0].size > 1048576) {
      return showNotification({ message: t('validation.error.imageTooLarge1'), color: 'red' });
    }
    if (!e.target.files[0].type.startsWith('image')) {
      return showNotification({ message: t('validation.error.notAnImage'), color: 'red' });
    }

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => selectFile(e.target?.result as any);
  }

  useEffect(() => {
    partnerForm.reset(partner);
  }, [partner]);

  const partnerMutation = useDtoMutation('partner-profile', {
    invalidateQueries: [['partner-profile']],
  });

  const addressTypes = Object.keys(AddressType).map((addressType) => ({
    id: addressType,
    label: t(`common.addressType.${addressType}`),
  }));

  const countries = useCountry();

  const { control } = partnerForm;
  const {
    fields: addressFields,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({ name: 'addresses', control, keyName: 'addressId' });
  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({ name: 'contacts', control, keyName: 'contactId' });
  const {
    fields: bankAccountFields,
    append: appendBankAccount,
    remove: removeBankAccount,
  } = useFieldArray({ name: 'bankAccounts', control, keyName: 'bankId' });

  const newAddressFields = (): PartnerAddressUpdateRequest => {
    return {
      name: '',
      type: AddressType.delivery_address,
      countryId: 'HU',
      zipCode: '',
      city: '',
      address: '',
      notes: '',
      hasForklift: false,
      hasHighLiftPalletJack: false,
      hasPalletJack: false,
      withDelivery: false,
    };
  };

  const newContactFields = (): PartnerContactUpdateRequest => {
    return {
      name: '',
      jobPosition: '',
      email: '',
      phoneNumber: '',
      notes: '',
    };
  };

  const newBankAccountFields = (): PartnerBankAccountUpdateRequest => {
    return {
      isForeignBankAccount: false,
      accountNumber: '',
      accountingBankName: '',
      ibanNumber: '',
      swiftCode: '',
      notes: '',
    };
  };

  const handleSubmit = async (data: PartnerCreateRequest | PartnerUpdateRequest) => {
    data['userId'] = user?.id;
    data.addresses?.forEach((address) => {
      delete address['partnerId'];
    });
    data.contacts?.forEach((contact) => {
      delete contact['partnerId'];
    });
    data.bankAccounts?.forEach((bankAccount) => {
      delete bankAccount['partnerId'];
    });

    const file = fileUploadRef.current?.files![0];
    if (file) {
      if (file.size > 1048576) {
        return showNotification({ message: t('validation.error.imageTooLarge1'), color: 'red' });
      }
      if (!file.type.startsWith('image')) {
        return showNotification({ message: t('validation.error.notAnImage'), color: 'red' });
      }

      const result = (await storageService.upload(file, { public: true })) as StorageUploadResponse & {
        publicUrl: string;
      };
      data.companyLogo = result.publicUrl;
    }

    await partnerMutation.update(data);
    partnerForm.toggleReadonlyMode(data);
  };

  return (
    <Page>
      <LoadingOverlay
        visible={isFetching}
        overlayOpacity={0.6}
        overlayBlur={2}
        loaderProps={{ color: 'dark' }}
        transitionDuration={300}
        zIndex={150}
      />

      <GrForm form={partnerForm} onSubmit={handleSubmit}>
        <Group position="apart" mb="md">
          <Group>
            <GrFormTitle i18nPrefix="component.reseller.resellerDetails" />
          </Group>

          <Group>
            {partnerForm.readonlyMode ? (
              isAdmin && (
                <Button
                  onClick={() => {
                    partnerForm.toggleReadonlyMode();
                  }}
                  variant="light"
                  leftIcon={<IconPencil />}
                >
                  {t('common.button.edit')}
                </Button>
              )
            ) : (
              <>
                <Button loading={partnerForm.isSubmitting} color="green" type="submit" leftIcon={<IconCheck />}>
                  {t('common.button.save')}
                </Button>

                <Button
                  loading={partnerForm.isSubmitting}
                  type="button"
                  color="gray"
                  variant="light"
                  leftIcon={<IconX />}
                  onClick={() => partnerForm.toggleReadonlyMode()}
                >
                  {t('common.button.cancel')}
                </Button>
              </>
            )}
          </Group>
        </Group>

        <ScrollArea type="scroll" pb="sm" style={{ height: 'calc(100vh - 58px)' }}>
          <Grid m={0}>
            <Grid.Col>
              <GrTextInput name="name" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="taxIdNumber" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="registrationNumber" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="centralPhoneNumber" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="centralEmail" />
            </Grid.Col>

            <Grid.Col xs={6}>
              <GrTextInput name="website" link />
            </Grid.Col>

            <Grid.Col xs={6}>
              <Input.Wrapper label={t('entity.partner.companyLogo')}>
                <Box>
                  <Center inline>
                    <Image
                      width={150}
                      height={75}
                      withPlaceholder
                      fit="contain"
                      radius="md"
                      mt="xs"
                      src={selectedFile ?? partner?.companyLogo}
                    />

                    <input
                      type="file"
                      id="image-upload"
                      style={{ display: 'none' }}
                      ref={fileUploadRef}
                      onChange={handleFileSelect}
                      accept="image/*"
                    />

                    {!partnerForm.readonlyMode && (
                      <Button variant="subtle" compact ml="md" onClick={() => fileUploadRef.current?.click()}>
                        <IconUpload size={20} />

                        <Box ml="xs">{t('common.button.uploadLogo')}</Box>
                      </Button>
                    )}
                  </Center>
                </Box>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col>
              <GrTextareaInput name="notes" />
            </Grid.Col>
          </Grid>

          <GrFormTabs
            tabs={[
              {
                icon: <IconBuildingCommunity />,
                name: 'addresses',
                label: 'entity.partner.addressDetails',
                badge: addressFields.length,
              },
              {
                icon: <IconAddressBook />,
                name: 'contacts',
                label: 'entity.partner.contactDetails',
                badge: contactFields.length,
              },
              {
                icon: <IconBuildingBank />,
                name: 'bankAccounts',
                label: 'entity.partner.bankAccountDetails',
                badge: bankAccountFields.length,
              },
            ]}
          >
            <GrFormTab name="addresses">
              {addressFields.map((address, index) => {
                return (
                  <Paper
                    key={address.addressId}
                    withBorder
                    mb="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    {!partnerForm.readonlyMode && (
                      <Group position="right">
                        <ActionIcon onClick={() => removeAddress(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    )}

                    <Grid>
                      <Grid.Col xs={7}>
                        <GrTextInput name={`addresses.${index}.name`} />
                      </Grid.Col>

                      <Grid.Col xs={5}>
                        <GrSelectInput data={addressTypes} name={`addresses.${index}.type`} simpleValue />
                      </Grid.Col>

                      <Grid.Col xl={2} lg={12}>
                        <GrSelectInput
                          data={countries}
                          labelField="name"
                          valueField="countryCode"
                          name={`addresses.${index}.countryId`}
                          simpleValue
                        />
                      </Grid.Col>

                      <Grid.Col xl={2} lg={2}>
                        <GrTextInput name={`addresses.${index}.zipCode`} />
                      </Grid.Col>

                      <Grid.Col xl={3} lg={4}>
                        <GrTextInput name={`addresses.${index}.city`} />
                      </Grid.Col>

                      <Grid.Col xl={5} lg={6}>
                        <GrTextInput name={`addresses.${index}.address`} />
                      </Grid.Col>

                      <Grid.Col>
                        <GrTextareaInput name={`addresses.${index}.notes`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrSwitchInput name={`addresses.${index}.hasForklift`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrSwitchInput name={`addresses.${index}.hasHighLiftPalletJack`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrSwitchInput name={`addresses.${index}.hasPalletJack`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrSwitchInput name={`addresses.${index}.withDelivery`} />
                      </Grid.Col>
                    </Grid>
                  </Paper>
                );
              })}

              {partnerForm.readonlyMode && addressFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

              {!partnerForm.readonlyMode && (
                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendAddress(newAddressFields())}
                >
                  {t('common.button.addRow')}
                </Button>
              )}
            </GrFormTab>

            <GrFormTab name="contacts">
              {contactFields.map((contact, index) => {
                return (
                  <Paper
                    key={contact.contactId}
                    withBorder
                    mb="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    {!partnerForm.readonlyMode && (
                      <Group position="right">
                        <ActionIcon onClick={() => removeContact(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    )}

                    <Grid>
                      <Grid.Col xs={6}>
                        <GrTextInput name={`contacts.${index}.name`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`contacts.${index}.jobPosition`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`contacts.${index}.email`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`contacts.${index}.phoneNumber`} />
                      </Grid.Col>

                      <Grid.Col>
                        <GrTextareaInput name={`contacts.${index}.notes`} />
                      </Grid.Col>
                    </Grid>
                  </Paper>
                );
              })}

              {partnerForm.readonlyMode && contactFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

              {!partnerForm.readonlyMode && (
                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendContact(newContactFields())}
                >
                  {t('common.button.addRow')}
                </Button>
              )}
            </GrFormTab>

            <GrFormTab name="bankAccounts">
              {bankAccountFields.map((bank, index) => {
                return (
                  <Paper
                    key={bank.bankId}
                    withBorder
                    mb="xs"
                    p="md"
                    sx={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    {!partnerForm.readonlyMode && (
                      <Group position="right">
                        <ActionIcon onClick={() => removeBankAccount(index)} color="red" size="md" variant="light">
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    )}

                    <Grid>
                      <Grid.Col xs={12}>
                        <GrSwitchInput name={`bankAccounts.${index}.isForeignBankAccount`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`bankAccounts.${index}.accountNumber`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`bankAccounts.${index}.accountingBankName`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`bankAccounts.${index}.ibanNumber`} />
                      </Grid.Col>

                      <Grid.Col xs={6}>
                        <GrTextInput name={`bankAccounts.${index}.swiftCode`} />
                      </Grid.Col>

                      <Grid.Col>
                        <GrTextareaInput name={`bankAccounts.${index}.notes`} />
                      </Grid.Col>
                    </Grid>
                  </Paper>
                );
              })}

              {partnerForm.readonlyMode && bankAccountFields.length === 0 && <Text>{t('common.message.noData')}</Text>}

              {!partnerForm.readonlyMode && (
                <Button
                  leftIcon={<IconRowInsertTop />}
                  variant="subtle"
                  mt="md"
                  compact
                  onClick={() => appendBankAccount(newBankAccountFields())}
                >
                  {t('common.button.addRow')}
                </Button>
              )}
            </GrFormTab>
          </GrFormTabs>
        </ScrollArea>
      </GrForm>
    </Page>
  );
}
