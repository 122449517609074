import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseDtoFormReturn } from '../hooks/use-dto-form.hook';
import { BaseInputProps } from '../interfaces/base-input-props.interface';
import { GrQueryBuilder, GrQueryBuilderProps } from '../components/QueryBuilder/GrQueryBuilder';
import { Input } from '@mantine/core';

export function GrQueryBuilderInput({ name, ...props }: BaseInputProps & GrQueryBuilderProps) {
  const form = props['form'] as UseDtoFormReturn;
  const { t } = useTranslation();

  // In case of nested objects the '.(index)' part should be removed for translation
  // Example: "addresses.3.name" => "addresses.name"
  const labelName = name.split(/\.\d+/).join('');

  if (!form) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState, formState }) => {
        return (
          <Input.Wrapper
            id={name}
            label={t(props.label ?? `${form.i18nPrefix ?? 'NO-PREFIX'}.${labelName}`)}
            withAsterisk={form.requiredFields.includes(labelName)}
            error={fieldState.error ? t(`common.validation.${fieldState.error.type}`) : undefined}
          >
            <GrQueryBuilder
              fields={props.fields}
              query={field.value}
              onChange={field.onChange}
              disabled={form.readonlyMode || formState.isSubmitting}
            />
          </Input.Wrapper>
        );
      }}
    />
  );
}
