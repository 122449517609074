import { uppercaseFirstLetter } from '@gravity/shared/utils';
import { MultiSelect, SegmentedControl, SelectItem, Space, Text } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ET_FILTER_ARRAY_SEPARATOR } from '../../../constants';
import { EntityTableColumn } from '../../../interfaces/entity-table-column.interface';
import { EntityTableFilter } from '../../../interfaces/entity-table-filter.interface';

interface FilterProps<T> {
  selectedColumn: EntityTableColumn<T>;
  selectData: string[] | SelectItem[];
  filterFormValues: EntityTableFilter;
  onChange: (formValue: Partial<EntityTableFilter>) => void;
  onPopoverLock: (lockState: boolean) => void;
}

export function EntityTableArrayFilter<T>({
  selectedColumn,
  selectData,
  filterFormValues,
  onChange,
  onPopoverLock,
}: FilterProps<T>) {
  const { t } = useTranslation();

  // Saved ref for the focusFixer input. This input is required to solve Enter form sending if the focused element is the MultiSelect.
  // It's a hidden input to capture focus after the blur event of the MultiSelect
  const ref = useRef<HTMLInputElement>(null);

  // Initialize missing operation
  useEffect(() => {
    if (!filterFormValues.operation) {
      filterFormValues.operation = 'hasSome';
      onChange({
        operation: filterFormValues.operation,
      });
    }
  }, []);

  return (
    <>
      <Text size="sm">{t('entityTable.filter.conditionType')}:</Text>

      <input name="focusFixer" style={{ position: 'absolute', width: 0, opacity: 0 }} ref={ref}></input>

      <SegmentedControl
        fullWidth
        value={filterFormValues.operation}
        onChange={(type) => {
          onChange({
            operation: type,
          });
        }}
        data={[
          { label: t('entityTable.filter.operation.hasSome'), value: 'hasSome' },
          { label: t('entityTable.filter.operation.hasEvery'), value: 'hasEvery' },
        ].map((r) => ({ ...r, label: uppercaseFirstLetter(r.label) }))}
      />

      <Space h={16} />

      <MultiSelect
        label={`${selectedColumn.header}:`}
        data={selectData}
        value={filterFormValues.value?.split(ET_FILTER_ARRAY_SEPARATOR)}
        onChange={(selectedValues) =>
          onChange({
            value: selectedValues.join(ET_FILTER_ARRAY_SEPARATOR),
            operation: filterFormValues.operation ?? 'hasSome',
          })
        }
        onBlur={() => {
          ref.current?.focus();
        }}
        placeholder={t('entityTable.filter.filterSelectInputPlaceholder')}
        onDropdownOpen={() => onPopoverLock(true)}
        onDropdownClose={() => onPopoverLock(false)}
        autoFocus
      />
    </>
  );
}
