import { Box, createStyles, Space } from '@mantine/core';
import { GlobalNavLink } from './components/GlobalNavLink';
import { UserMenu } from '../UserMenu/UserMenu';
import { IconDatabase, IconHome, IconSettings, IconReceipt2, IconDiscount2 } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 50,
    paddingTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    color: 'white',
    img: {
      width: '100%',
    },
  },

  aside: {
    flex: '0 0 50px',
    width: 50,
    backgroundColor: theme.colors[theme.primaryColor][9],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    zIndex: 1000,
  },
}));

export function GlobalNav() {
  const { classes } = useStyles();

  return (
    <div className={classes.aside}>
      {process.env.NX_APP_WHITELABEL === 'true' ? (
        <Space h={25} />
      ) : (
        <Link to="/" className={classes.logo}>
          <img src="/assets/images/logo-white.svg" alt="Gravity Logo" />
        </Link>
      )}

      <GlobalNavLink tooltip="menu.home" icon={<IconHome />} to="/" exact />

      <GlobalNavLink tooltip="menu.coreData" icon={<IconDatabase />} to="/core-data/partners" />

      <GlobalNavLink tooltip="menu.sales" icon={<IconReceipt2 />} to="/sales/orders" />

      <GlobalNavLink tooltip="menu.pricing" icon={<IconDiscount2 />} to="/pricing/price-list-versions" />

      <Box sx={{ flexGrow: 1 }} />

      <GlobalNavLink tooltip="menu.settings" icon={<IconSettings />} to="/settings/users" />

      <UserMenu />
    </div>
  );
}
