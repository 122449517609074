import { Badge, Center, CopyButton, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function EntityTableIdRenderer(props: { value?: string }) {
  const { t } = useTranslation();

  return (
    <Center inline>
      {props.value && (
        <CopyButton value={props.value} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? t('entityTable.copiedToClipboard') : t('entityTable.copyToClipboard')}
              color={copied ? 'green' : 'dark'}
              withArrow
              position="right"
            >
              <Badge
                color="dark"
                radius="sm"
                sx={(theme) => ({
                  fontFamily: 'monospace',
                  backgroundColor: copied ? theme.colors.green[1] : theme.colors.gray[2],
                  color: copied ? theme.colors.green[9] : 'black',
                  cursor: 'pointer',
                  paddingLeft: 5,
                  paddingRight: 5,
                })}
                onClick={copy}
              >
                {props.value}
              </Badge>
            </Tooltip>
          )}
        </CopyButton>
      )}
    </Center>
  );
}
