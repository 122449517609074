import { Collapse, List } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function EntityTableListRenderer(props: { value?: string[]; skipSorting?: boolean; i18nPrefix?: string }) {
  const keyPrefix = useId();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const handleClick: MouseEventHandler = (event) => {
    const selection = window.getSelection();
    if (selection?.type !== 'Range') {
      setOpened(!opened);
    }
  };

  const renderedValue = props.value ? (props.skipSorting ? props.value : props.value?.sort()) : [];

  function getValue(value: string): string {
    if (props.i18nPrefix) {
      return t(`${props.i18nPrefix}.${value}`);
    }

    return value;
  }

  return (
    <List
      size="xs"
      onClick={(event) => handleClick(event)}
      sx={{ cursor: renderedValue.length > 2 ? (opened ? 'n-resize' : 's-resize') : 'default' }}
      listStyleType="none"
      spacing={4}
    >
      {renderedValue.slice(0, 2).map((valueItem, valueIndex) => (
        <List.Item key={`${keyPrefix}_${valueIndex}`}>{getValue(valueItem)}</List.Item>
      ))}

      {renderedValue.length > 2 && (
        <List.Item key={opened ? `${keyPrefix}_2` : `${keyPrefix}_dotdotdot`} sx={{ color: opened ? 'dark' : 'gray' }}>
          {opened ? getValue(renderedValue[2]) : t('entityTable.moreItem', { value: renderedValue.length - 2 })}
        </List.Item>
      )}

      <Collapse in={opened}>
        {renderedValue.slice(3, renderedValue.length).map((valueItem, valueIndex) => (
          <List.Item key={`${keyPrefix}_${valueIndex}`} mt={4}>
            {getValue(valueItem)}
          </List.Item>
        ))}
      </Collapse>
    </List>
  );
}
