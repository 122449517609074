import { Type } from 'class-transformer';
import { IsDateString, IsNotEmpty, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { PriceListCreateRequest, PriceListUpdateRequest } from './price-list.request';

export class PriceListVersionCreateRequest {
  /**
   * The currency code of the price list version
   */
  @IsString()
  @IsOptional()
  public currencyCode?: string;

  /**
   * The validity start date of the price list version
   */
  @IsDateString()
  @IsNotEmpty()
  public validityStartDate: Date;

  /**
   * The validity end date of the price list version
   */
  @IsDateString()
  @IsNotEmpty()
  @ValidateIf((o) => o.partnerId !== '' && o.partnerId)
  public validityEndDate?: Date;

  /**
   * The id of customer of the order
   */
  @IsString()
  @IsOptional()
  public partnerId?: string;

  /**
   * Array of price lists for the price list version
   */
  @ValidateNested({ each: true, context: { type: PriceListCreateRequest } })
  @Type(() => PriceListCreateRequest)
  @ValidateIf((o, e) => o !== '' && e !== '')
  public priceLists?: PriceListCreateRequest[];
}

export class PriceListVersionUpdateRequest {
  /**
   * CUID identifier of the price list version
   */
  @IsString()
  @IsNotEmpty()
  public id: string;

  /**
   * The currency code of the price list version
   */
  @IsString()
  @IsNotEmpty()
  public currencyCode: string;

  /**
   * The validity start date of the price list version
   */
  @IsDateString()
  @IsNotEmpty()
  public validityStartDate: Date;

  /**
   * The validity end date of the price list version
   */
  @IsDateString()
  @IsNotEmpty()
  public validityEndDate: Date;

  /**
   * Array of price lists for the price list version
   */
  @ValidateNested({ each: true, context: { type: PriceListUpdateRequest } })
  @Type(() => PriceListUpdateRequest)
  public priceLists?: PriceListUpdateRequest[];
}
