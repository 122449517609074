import { Type } from 'class-transformer';
import { IsBoolean, IsNotEmpty, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { UnitOfMeasurementResponse } from '../core-data/unit-of-measurement.response';
import { ProductAttributeCreateRequest, ProductAttributeUpdateRequest } from './product-attribute.request';
import { ProductCategoryResponse } from './product-category.response';

export class ProductCreateRequest {
  /**
   * The name of the product
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The description of the product
   */
  @IsString()
  public description?: string;

  /**
   * True if the product is final
   */
  @IsBoolean()
  public isFinalProduct?: boolean;

  /**
   * True if the product can be ordered
   */
  @IsBoolean()
  public canBeOrdered: boolean;

  /**
   * True if the product is available for resellers
   */
  @IsBoolean()
  public isAvailableForReseller: boolean;

  /**
   * The category of the product
   */
  @IsObject()
  @IsNotEmpty()
  public category: ProductCategoryResponse;

  /**
   * The unit of measurement of the product
   */
  @IsObject()
  @IsNotEmpty()
  public unitOfMeasurement: UnitOfMeasurementResponse;

  /**
   * Array of product attributes for the product
   */
  @ValidateNested({ each: true, context: { type: ProductAttributeCreateRequest } })
  @Type(() => ProductAttributeCreateRequest)
  public productAttributes?: ProductAttributeCreateRequest[];
}

export class ProductUpdateRequest {
  /**
   * CUID identifier of the product
   */
  @IsString()
  @IsOptional()
  public id?: string;

  /**
   * The name of the product
   */
  @IsString()
  @IsNotEmpty()
  public name: string;

  /**
   * The description of the product
   */
  @IsString()
  public description?: string;

  /**
   * True if the product is final
   */
  @IsBoolean()
  public isFinalProduct: boolean;

  /**
   * True if the product can be ordered
   */
  @IsBoolean()
  public canBeOrdered: boolean;

  /**
   * True if the product is available for resellers
   */
  @IsBoolean()
  public isAvailableForReseller: boolean;

  /**
   * The category of the product
   */
  @IsObject()
  @IsNotEmpty()
  public category: ProductCategoryResponse;

  /**
   * The unit of measurement of the product
   */
  @IsObject()
  @IsNotEmpty()
  public unitOfMeasurement: UnitOfMeasurementResponse;

  /**
   * Array of product attributes for the product
   */
  @ValidateNested({ each: true, context: { type: ProductAttributeUpdateRequest } })
  @Type(() => ProductAttributeUpdateRequest)
  public productAttributes?: ProductAttributeUpdateRequest[];
}
